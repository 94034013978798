import { Box, Heading } from "@chakra-ui/react"
import FigmaPartnerRewardStatus from "components/Figma/components/FigmaPartnerRewardStatus"
import { EPOCH } from "constants/config"
import { DATE_RANGE_PLACE_HOLDER, NUMBER_PLACE_HOLDER } from "constants/strings"
import { PerpWalletConnectorContainer } from "hooks/containers/usePerpWalletConnectorContainer"
import { useVePerpUserDataAtQuery } from "modules/vePerp"
import React from "react"
import { useGetServerTimestampQuery } from "services/AWSClient/flow"
import { formatNumber, getCalibratedTime, getTimeString } from "utils"

import {
    useReferralCodeQuery,
    useReferralCurrentRewardQuery,
    useReferralLastRewardQuery,
    useReferralRewardUserAtQuery,
} from "../flow"
import { ReferralStatusFooter } from "./ReferralMyStatus"

export function ReferralAsPartnerStatus() {
    const { account } = PerpWalletConnectorContainer.useContainer()

    const { data: serverTimeData } = useGetServerTimestampQuery()
    const timestamp = serverTimeData?.timestamp

    // NOTE: current week's reward
    const currentWeekStartTime = timestamp ? getCalibratedTime(timestamp, EPOCH) : undefined
    const currentWeekEndTime = currentWeekStartTime ? currentWeekStartTime + EPOCH : undefined
    const { data: referralCodeData } = useReferralCodeQuery({ account: account! }, { skip: !account })
    const { data: currentRewardData } = useReferralCurrentRewardQuery(
        {
            account: account!,
            startTime: currentWeekStartTime!,
            endTime: currentWeekEndTime!,
            referralCode: referralCodeData?.referrer!,
        },
        { skip: !account || !currentWeekStartTime || !currentWeekEndTime || !referralCodeData?.referrer },
    )

    // NOTE: last week's reward
    const { data: lastWeekRewardTotal } = useReferralLastRewardQuery()
    const { data: lastWeekRewardUser } = useReferralRewardUserAtQuery(
        {
            account: account!,
            week: lastWeekRewardTotal?.week!,
        },
        { skip: !account || !lastWeekRewardTotal?.week },
    )
    const { data: vePerpUserDataAt } = useVePerpUserDataAtQuery(
        { account: account!, timestamp: lastWeekRewardTotal?.weekEnd! },
        { skip: !account || !lastWeekRewardTotal?.weekEnd },
    )

    // NOTE: reward status data
    const currentDateRange =
        currentWeekStartTime &&
        currentWeekEndTime &&
        `${getTimeString(currentWeekStartTime)} - ${getTimeString(currentWeekEndTime)}`
    const dateRange =
        lastWeekRewardTotal?.weekStart &&
        lastWeekRewardTotal?.weekEnd &&
        `${getTimeString(lastWeekRewardTotal.weekStart)} - ${getTimeString(lastWeekRewardTotal.weekEnd)}`
    const currentMarketVol =
        lastWeekRewardTotal?.accumulatedTradingVolume &&
        currentRewardData?.accumulatedTradingVolume &&
        currentRewardData.accumulatedTradingVolume.minus(lastWeekRewardTotal.accumulatedTradingVolume)
    const currentMyVol = currentRewardData?.referrer?.tradingVolume
    const marketVol = lastWeekRewardTotal?.totalReferrerVolume
    const myVol = lastWeekRewardUser?.referrerVolume
    const marketVePerp = lastWeekRewardTotal?.totalReferrerVePERPBalance
    const myVePerp = vePerpUserDataAt?.vePerpTokenAmountUnweighted
    const marketRewards = lastWeekRewardTotal?.totalReferrerReward
    const myRewards = lastWeekRewardUser?.referrerReward

    return (
        <Box>
            <Heading variant="h3" mb={8}>
                Reward Status (Partner)
            </Heading>
            <FigmaPartnerRewardStatus
                currentDateRange={currentDateRange || DATE_RANGE_PLACE_HOLDER}
                currentMarketVol={currentMarketVol ? formatNumber(currentMarketVol) : NUMBER_PLACE_HOLDER}
                currentMyVol={currentMyVol ? formatNumber(currentMyVol) : NUMBER_PLACE_HOLDER}
                dateRange={dateRange || DATE_RANGE_PLACE_HOLDER}
                marketVol={marketVol ? formatNumber(marketVol) : NUMBER_PLACE_HOLDER}
                myVol={myVol ? formatNumber(myVol) : NUMBER_PLACE_HOLDER}
                marketVePerp={marketVePerp ? formatNumber(marketVePerp) : NUMBER_PLACE_HOLDER}
                myVePerp={myVePerp ? formatNumber(myVePerp) : NUMBER_PLACE_HOLDER}
                marketRewards={marketRewards ? formatNumber(marketRewards) : NUMBER_PLACE_HOLDER}
                myRewards={myRewards ? formatNumber(myRewards) : NUMBER_PLACE_HOLDER}
            />
            <ReferralStatusFooter />
        </Box>
    )
}
