import { IS_STAGING } from "./env"

export const MIN = 60

export const HOUR = 60 * MIN

export const DAY = 24 * HOUR

export const WEEK = 7 * DAY

export const EPOCH = IS_STAGING ? HOUR : WEEK

export const TOKEN_CHECKPOINT_DEADLINE = IS_STAGING ? 10 * MIN : DAY

export const POLLING_PERIOD = {
    SHORT: 3000,
    MEDIUM: 5000,
}

export const NOTIFICATION_DURATION = {
    SHORT: 3000,
    MEDIUM: 5000,
}

export const LAZY_RIVER_2_START_TIME = IS_STAGING ? 1670803200 : 1671062400

// NOTE: InsuranceFund > SurplusBeneficiary > TreasuryDAO
export const TREASURY_DAO_ADDRESS = IS_STAGING
    ? "0x9E9DFaCCABeEcDA6dD913b3685c9fe908F28F58c"
    : "0x5A06D52F38965904CF15c3f55286263AB9A237d7"
