/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  ISurplusBeneficiary,
  ISurplusBeneficiaryInterface,
} from "../ISurplusBeneficiary";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "amountToTreasury",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amountToFeeDistributor",
        type: "uint256",
      },
    ],
    name: "Dispatch",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "oldValue",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "newValue",
        type: "address",
      },
    ],
    name: "FeeDistributorChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "oldValue",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "newValue",
        type: "address",
      },
    ],
    name: "TreasuryChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint24",
        name: "oldValue",
        type: "uint24",
      },
      {
        indexed: false,
        internalType: "uint24",
        name: "newValue",
        type: "uint24",
      },
    ],
    name: "TreasuryPercentageChanged",
    type: "event",
  },
  {
    inputs: [],
    name: "dispatch",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getFeeDistributor",
    outputs: [
      {
        internalType: "address",
        name: "feeDistributor",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getToken",
    outputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getTreasury",
    outputs: [
      {
        internalType: "address",
        name: "treasury",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getTreasuryPercentage",
    outputs: [
      {
        internalType: "uint24",
        name: "percentage",
        type: "uint24",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class ISurplusBeneficiary__factory {
  static readonly abi = _abi;
  static createInterface(): ISurplusBeneficiaryInterface {
    return new utils.Interface(_abi) as ISurplusBeneficiaryInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ISurplusBeneficiary {
    return new Contract(address, _abi, signerOrProvider) as ISurplusBeneficiary;
  }
}
