import { Icon, IconProps } from "@chakra-ui/react"
import React from "react"

export function BTCIcon({ ...props }: IconProps) {
    return (
        <Icon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_95_918)">
                <path
                    d="M31.5195 19.8705C29.3825 28.442 20.701 33.6585 12.1285 31.521C3.5595 29.384 -1.657 20.702 0.481 12.131C2.617 3.55852 11.2985 -1.65848 19.8685 0.478523C28.4405 2.61552 33.6565 11.2985 31.5195 19.8705Z"
                    fill="#F7931A"
                />
                <g style={{ mixBlendMode: "multiply" }} opacity="0.8">
                    <path
                        d="M22.9608 13.6384C23.2729 11.6419 21.6844 10.5687 19.5124 9.8527L20.217 7.14826L18.4967 6.738L17.8107 9.37117C17.3585 9.26333 16.894 9.16159 16.4324 9.06078L17.1233 6.41026L15.404 6L14.6989 8.7035C14.3246 8.62192 13.9571 8.54127 13.6004 8.45641L13.6023 8.44797L11.2299 7.8811L10.7723 9.63937C10.7723 9.63937 12.0486 9.91928 12.0217 9.93663C12.7184 10.1031 12.8444 10.5443 12.8233 10.8941L12.0207 13.975C12.0687 13.9867 12.131 14.0036 12.1995 14.0299C12.1422 14.0163 12.081 14.0013 12.0178 13.9867L10.8928 18.3027C10.8075 18.5052 10.5915 18.809 10.1044 18.6937C10.1216 18.7176 8.85402 18.395 8.85402 18.395L8 20.2794L10.2387 20.8135C10.6552 20.9133 11.0633 21.0179 11.4651 21.1164L10.7532 23.8517L12.4715 24.262L13.1766 21.5557C13.6459 21.6776 14.1016 21.7901 14.5475 21.8961L13.8449 24.5897L15.5652 25L16.2771 22.2698C19.2106 22.801 21.4164 22.5867 22.3449 20.0478C23.0931 18.0035 22.3077 16.8243 20.7643 16.0554C21.8882 15.8073 22.7349 15.0998 22.9608 13.6384ZM19.0302 18.9127C18.4986 20.9569 14.9017 19.8518 13.7356 19.5747L14.6803 15.9508C15.8464 16.2293 19.5859 16.7807 19.0302 18.9127ZM19.5624 13.6088C19.0773 15.4684 16.0836 14.5236 15.1124 14.292L15.9689 11.0052C16.94 11.2368 20.0675 11.6691 19.5624 13.6088Z"
                        fill="#141319"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_95_918">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </Icon>
    )
}
