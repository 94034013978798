import Big from "big.js"

import {
    APP_SYNC_API_KEY_LIQUIDITY_MINING,
    APP_SYNC_API_KEY_REFERRAL,
    APP_SYNC_API_URL_LIQUIDITY_MINING,
    APP_SYNC_API_URL_REFERRAL,
} from "../constants/env"

export interface ClaimableRewardsItem {
    week: number
    balance: Big
    merkleProof: string[]
    weekEndTimeIso: string
    weekStartTimeIso: string
}

export interface ClaimableRewards {
    items: ClaimableRewardsItem[]
    totalBalance: Big // in PERP
    totalBalanceUsd: Big
}

export interface ClaimableRewardsLiquidityMining extends ClaimableRewards {
    itemsOp: ClaimableRewardsItem[]
    totalBalanceOp: Big // in OP
    totalBalanceUsdOp: Big
}

export interface ReferralRewardUser {
    referrerVolume: Big
    referrerBoostedReward: Big
    referrerReward: Big
    refereeFee: Big
    refereeReward: Big
}

export interface DefaultMarket {
    baseSymbol: string // "vBTC"
    quoteSymbol: string // "vUSD"
    marketSymbol: string // "vBTC/vUSD"

    lowerBaseApr: number // 3.222
    upperBaseApr: number // 215.253

    lowerRewardApr: number // 19.962
    upperRewardApr: number // 927.846
    lowerRewardOpApr: number // 19.962 $OP
    upperRewardOpApr: number // 927.846 $OP
    rewardCapWeeklyPerp: number // 5000 $PERP
    rewardCapWeeklyOp: number // 5000 $OP
}

export interface DefaultMarkets {
    items: DefaultMarket[]
    nextToken: string | null // "exampleNextTokenString" | null
}

export interface MakerMarket {
    baseSymbol: string //"vETH"
    quoteSymbol: string //"vUSD"
    marketSymbol: string //"vETH/vUSD"

    baseApr: number // 20.99832014815356
    rewardApr: number // 190.50873682456827
    rewardOpApr: number // 190.50873682456827 $OP

    netValue: number // 103.652
    estimatedRewardAmount: number // 0.299
    estimatedRewardUsd: number // 3.873
    estimatedRewardOpAmount: number // 0.299 $OP
    estimatedRewardOpUsd: number // 3.873 $OP
}

export class AppSyncClient {
    private readonly appSyncApiUrlReferral: string
    private readonly appSyncApiKeyReferral: string
    private readonly appSyncApiUrlLiquidityMining: string
    private readonly appSyncApiKeyLiquidityMining: string

    constructor() {
        this.appSyncApiUrlReferral = APP_SYNC_API_URL_REFERRAL
        this.appSyncApiKeyReferral = APP_SYNC_API_KEY_REFERRAL
        this.appSyncApiUrlLiquidityMining = APP_SYNC_API_URL_LIQUIDITY_MINING
        this.appSyncApiKeyLiquidityMining = APP_SYNC_API_KEY_LIQUIDITY_MINING
    }

    async getReferralProgramClaimableRewards(address: string): Promise<ClaimableRewards> {
        const headers = {
            "x-api-key": this.appSyncApiKeyReferral,
            "Content-Type": "application/json",
        }
        const result = await fetch(this.appSyncApiUrlReferral, {
            method: "POST",
            headers,
            body: JSON.stringify({
                query: `
                    query GetClaimableRewards($address:String!) {  
                        getClaimableRewards(address: $address) {
                            items {
                                balance
                                merkleProof
                                week
                                weekEndTimeIso
                                weekStartTimeIso
                            }
                            totalBalance
                            totalBalanceUsd
                        }
                    }
                `,
                variables: { address },
            }),
        })
        const resultJson = await result.json()

        // data
        const getClaimableRewards = resultJson.data.getClaimableRewards
        return {
            items: getClaimableRewards.items.map((item: any) => ({
                week: item.week,
                balance: Big(item.balance).div(10 ** 18),
                merkleProof: item.merkleProof,
                weekEndTimeIso: item.weekEndTimeIso,
                weekStartTimeIso: item.weekStartTimeIso,
            })),
            totalBalance: Big(getClaimableRewards.totalBalance),
            totalBalanceUsd: Big(getClaimableRewards.totalBalanceUsd),
        }
    }

    async getReferralRewardUser(address: string, week: number): Promise<ReferralRewardUser> {
        const headers = {
            "x-api-key": this.appSyncApiKeyReferral,
            "Content-Type": "application/json",
        }
        const result = await fetch(this.appSyncApiUrlReferral, {
            method: "POST",
            headers,
            body: JSON.stringify({
                query: `
                    query GetPartnerTraderInfo($address:String!, $week:String!) {  
                        getPartnerTraderInfo(address: $address, week: $week) {
                            refereeFee
                            refereeReward
                            referrerBoostedReward
                            referrerReward
                            referrerVolume
                        }
                    }
                `,
                variables: { address, week: String(week) },
            }),
        })
        const resultJson = await result.json()
        const referralRewardUser = resultJson.data.getPartnerTraderInfo
        return {
            referrerVolume: referralRewardUser.referrerVolume ? Big(referralRewardUser.referrerVolume) : Big(0),
            referrerReward: referralRewardUser.referrerReward ? Big(referralRewardUser.referrerReward) : Big(0),
            referrerBoostedReward: referralRewardUser.referrerBoostedReward
                ? Big(referralRewardUser.referrerBoostedReward)
                : Big(0),
            refereeFee: referralRewardUser.refereeFee ? Big(referralRewardUser.refereeFee) : Big(0),
            refereeReward: referralRewardUser.refereeReward ? Big(referralRewardUser.refereeReward) : Big(0),
        }
    }

    async getLiquidityMiningClaimableRewards(_address: string): Promise<ClaimableRewardsLiquidityMining> {
        const headers = {
            "x-api-key": this.appSyncApiKeyLiquidityMining,
            "Content-Type": "application/json",
        }
        // TODO: add weekEndTimeIso and weekStartTimeIso
        const result = await fetch(this.appSyncApiUrlLiquidityMining, {
            method: "POST",
            headers,
            body: JSON.stringify({
                query: `
                    query GetClaimableRewards($address:String!) {  
                        getClaimableRewards(address: $address) {
                            items {
                                balance
                                merkleProof
                                week
                            }
                            totalBalance
                            totalBalanceUsd
                            itemsOp {
                                balance
                                merkleProof
                                week
                            }
                            totalBalanceOp
                            totalBalanceUsdOp
                        }
                    }
                `,
                // TODO: use real address
                variables: { address: "0x8A88DFcC83C2999a81634899f55512A88D0d84b2" },
            }),
        })
        const resultJson = await result.json()

        // data
        const liquidityMiningClaimableRewards = resultJson.data.getClaimableRewards
        return {
            items: liquidityMiningClaimableRewards.items.map((item: any) => ({
                week: item.week,
                balance: Big(item.balance).div(10 ** 18),
                merkleProof: item.merkleProof,
                // TODO: fix this once the API is fixed
                // weekEndTimeIso: item.weekEndTimeIso,
                // weekStartTimeIso: item.weekStartTimeIso,
                weekEndTimeIso: "2022-10-23T00:00:00.000Z",
                weekStartTimeIso: "2022-10-16T00:00:00.000Z",
            })),
            itemsOp: liquidityMiningClaimableRewards.itemsOp.map((itemOp: any) => ({
                week: itemOp.week,
                balance: Big(itemOp.balance).div(10 ** 18),
                merkleProof: itemOp.merkleProof,
                // TODO: fix this once the API is fixed
                // weekEndTimeIso: itemOp.weekEndTimeIso,
                // weekStartTimeIso: itemOp.weekStartTimeIso,
                weekEndTimeIso: "2022-10-23T00:00:00.000Z",
                weekStartTimeIso: "2022-10-16T00:00:00.000Z",
            })),
            totalBalance: Big(liquidityMiningClaimableRewards.totalBalance),
            totalBalanceUsd: Big(liquidityMiningClaimableRewards.totalBalanceUsd),
            totalBalanceOp: Big(liquidityMiningClaimableRewards.totalBalanceOp),
            totalBalanceUsdOp: Big(liquidityMiningClaimableRewards.totalBalanceUsdOp),
        }
    }

    async getLiquidityMiningDefaultMarkets(limit = 100, nextToken = ""): Promise<DefaultMarkets> {
        const headers = {
            "x-api-key": this.appSyncApiKeyLiquidityMining,
            "Content-Type": "application/json",
        }
        const result = await fetch(this.appSyncApiUrlLiquidityMining, {
            method: "POST",
            headers,
            body: JSON.stringify({
                query: `
                    query ListMarketAprs($limit:Int!, $nextToken:String!) {  
                        listMarketAprs(limit: $limit, nextToken: $nextToken) {
                            nextToken
                            items {
                                baseSymbol
                                quoteSymbol
                                marketSymbol
    
                                lowerBaseApr
                                upperBaseApr
    
                                lowerRewardApr
                                upperRewardApr
    
                                lowerRewardOpApr
                                upperRewardOpApr
    
                                rewardCapWeeklyPerp
                                rewardCapWeeklyOp
                            }
                        }
                    }
                `,
                variables: { limit, nextToken },
            }),
        })
            .then(res => res.json())
            .catch(error => console.error("error", error))

        return result.data.listMarketAprs
    }

    // The function below is known as 'listMakerAprs' from the original reward dashboard
    async getLiquidityMiningMakerMarkets(address: string): Promise<MakerMarket[]> {
        const headers = {
            "x-api-key": this.appSyncApiKeyLiquidityMining,
            "Content-Type": "application/json",
        }
        const result = await fetch(this.appSyncApiUrlLiquidityMining, {
            method: "POST",
            headers,
            body: JSON.stringify({
                query: `
                    query ListMakerAprs($address:String!) {  
                        listMakerAprs(address: $address) {
                            items {
                                baseSymbol
                                quoteSymbol
                                marketSymbol
    
                                baseApr
                                rewardApr
                                rewardOpApr 
    
                                netValue
                                estimatedRewardAmount
                                estimatedRewardUsd
                                estimatedRewardOpAmount 
                                estimatedRewardOpUsd 
                            }
                        }
                    }
                `,
                variables: { address },
            }),
        })
            .then(res => res.json())
            .catch(error => console.error("error", error))

        return result.data.listMakerAprs?.items
    }
}

export const appSyncClient = new AppSyncClient()
