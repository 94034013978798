import { Button, GridItem, HStack, Skeleton, Text, VStack } from "@chakra-ui/react"
import Big from "big.js"
import { MotionBox, MotionGrid, MotionHStack } from "components/Motion"
import { NUMBER_PLACE_HOLDER } from "constants/strings"
import React from "react"
import { useAppSelector } from "store"

import { formatNumber } from "../../../utils/format"

interface LazyRiverClaimCardProps {
    icon: JSX.Element
    title: string
    amount?: Big
    onClick: () => void
    isLoading: boolean
    isInline?: boolean
}

export function LazyRiverClaimCard({
    icon,
    title,
    amount,
    onClick,
    isLoading,
    isInline = false,
}: LazyRiverClaimCardProps) {
    const { isTxLoading } = useAppSelector(state => state.wallet)
    const isDisabled = isTxLoading || isLoading || !amount || amount.lte(0)

    return !isInline ? (
        <MotionGrid minW={"300px"} templateColumns="1fr auto" w="100%" gap={"16px"}>
            <GridItem>
                <HStack spacing={4}>
                    <MotionBox layoutId={"tokenicon" + title}>{icon}</MotionBox>
                    <VStack spacing={0} align={"stretch"}>
                        <Text fontSize="14px" color="perp.text.secondary">
                            {title}
                        </Text>
                        <MotionBox layoutId={"token-amount" + title}>
                            <Skeleton isLoaded={!isLoading}>
                                <Text fontSize="16px">{(amount && formatNumber(amount)) || NUMBER_PLACE_HOLDER}</Text>
                            </Skeleton>
                        </MotionBox>
                    </VStack>
                </HStack>
            </GridItem>
            <GridItem>
                <MotionBox initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.1 } }}>
                    <Button onClick={onClick} disabled={isDisabled} isLoading={isTxLoading}>
                        {"Claim"}
                    </Button>
                </MotionBox>
            </GridItem>
        </MotionGrid>
    ) : (
        <MotionHStack>
            <MotionBox layoutId={"token-icon" + title}>{icon}</MotionBox>
            <MotionBox layoutId={"token-amount" + title}>
                <Skeleton isLoaded={!isLoading}>
                    <Text fontSize="16px">{(amount && formatNumber(amount)) || NUMBER_PLACE_HOLDER}</Text>
                </Skeleton>
            </MotionBox>
        </MotionHStack>
    )
}
