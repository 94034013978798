import { checkboxAnatomy as parts } from "@chakra-ui/anatomy"
import { mode } from "@chakra-ui/theme-tools"

import type { PartsStyleFunction, SystemStyleFunction } from "@chakra-ui/theme-tools"

const baseStyleControl: SystemStyleFunction = props => {
    const { colorScheme: c } = props

    return {
        w: "100%",
        transitionProperty: "box-shadow",
        transitionDuration: "normal",
        border: "2px solid",
        borderRadius: "sm",
        borderColor: "inherit",
        color: "white",

        _checked: {
            bg: mode(`${c}`, `${c}`)(props),
            borderColor: mode(`${c}`, `${c}`)(props),
            color: mode("white", "gray.900")(props),

            _hover: {
                bg: mode(`${c}`, `${c}`)(props),
                borderColor: mode(`${c}`, `${c}`)(props),
            },

            _disabled: {
                borderColor: mode("gray.200", "transparent")(props),
                bg: mode("gray.200", "whiteAlpha.300")(props),
                color: mode("gray.500", "whiteAlpha.500")(props),
            },
        },
    }
}

const baseStyle: PartsStyleFunction<typeof parts> = props => ({
    control: baseStyleControl(props),
})

export const Checkbox = {
    baseStyle,
}
