import { Box, Flex, Heading } from "@chakra-ui/react"
import React from "react"
import { CurrentVePerp } from "./CurrentVePerp"
import { LastWeekStatus } from "./LastWeekStatus"
import { UpcomingRewards } from "./UpcomingRewards"

export function LazyRiverAllRewardsStatus() {
    return (
        <Box mb="72px">
            <Flex gap="8px" mb={6}>
                <Heading variant="h2">Rewards Status</Heading>
            </Flex>
            <LastWeekStatus />
            <CurrentVePerp />
            <UpcomingRewards />
        </Box>
    )
}
