import { Icon, IconProps } from "@chakra-ui/react"
import React from "react"

export function OPIcon({ ...props }: IconProps) {
    return (
        <Icon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                fill="#FF364D"
            />
            <g style={{ mixBlendMode: "multiply" }} opacity="0.8">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.01964 20.2222C8.70846 20.7407 9.59211 21 10.6707 21C11.9758 21 13.0181 20.6898 13.7975 20.0694C14.577 19.4398 15.1253 18.4907 15.4425 17.2222C15.6329 16.4445 15.796 15.6435 15.932 14.8194C15.9773 14.5231 16 14.2777 16 14.0833C16 13.4352 15.8413 12.8796 15.5241 12.4167C15.2069 11.9444 14.7719 11.5925 14.219 11.3611C13.6661 11.1204 13.0408 11 12.3429 11C9.77795 11 8.1873 12.2731 7.57101 14.8194C7.3535 15.7546 7.18584 16.5556 7.06796 17.2222C7.02268 17.5185 7 17.7685 7 17.9722C7 18.9445 7.33988 19.6944 8.01964 20.2222ZM12.1526 18.5417C11.7991 18.8472 11.3686 19 10.861 19C9.99097 19 9.55588 18.5741 9.55588 17.7222C9.55588 17.5185 9.574 17.324 9.61029 17.1389C9.77339 16.2407 9.93655 15.4815 10.0997 14.8611C10.2538 14.2315 10.5076 13.7639 10.861 13.4583C11.2236 13.1527 11.6586 12.9999 12.1662 12.9999C13.0271 12.9999 13.4577 13.4213 13.4577 14.2638C13.4577 14.4676 13.4396 14.6666 13.4033 14.8611C13.2946 15.5092 13.1359 16.2685 12.9274 17.1389C12.7734 17.7685 12.5151 18.2361 12.1526 18.5417Z"
                    fill="#141319"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.0525 20.9C16.1112 20.9667 16.1945 21 16.3023 21H18.3006C18.3985 21 18.4916 20.9667 18.5798 20.9C18.6679 20.8333 18.7218 20.7476 18.7414 20.6429L19.4173 17.5143H21.4009C22.6841 17.5143 23.6931 17.2524 24.4278 16.7286C25.1723 16.2048 25.667 15.3952 25.9118 14.3C25.9706 14.0429 26 13.7952 26 13.5571C26 12.7286 25.667 12.0953 25.0008 11.6571C24.3445 11.219 23.4727 11 22.3854 11H18.4769C18.3789 11 18.2859 11.0333 18.1977 11.1C18.1095 11.1667 18.0557 11.2524 18.0361 11.3571L16.0084 20.6429C15.9888 20.7381 16.0035 20.8238 16.0525 20.9ZM22.5764 15.2143C22.2727 15.4333 21.9152 15.5429 21.5038 15.5429H19.814L20.3723 12.9857H22.1356C22.5372 12.9857 22.8213 13.0619 22.9878 13.2143C23.1544 13.3571 23.2376 13.5666 23.2376 13.8429C23.2376 13.9666 23.2229 14.1095 23.1935 14.2714C23.0956 14.6809 22.8898 14.9952 22.5764 15.2143Z"
                    fill="#141319"
                />
            </g>
        </Icon>
    )
}
