import { createApi } from "@reduxjs/toolkit/dist/query/react"
import Big from "big.js"
import { opPerpLiquidityMiningClient } from "contracts/PerpLiquidityMiningClient"
import { LMDistributorClient } from "contracts/VePerpRewardDistributorClient"
import { Claim } from "modules/claim"
import { appSyncClient, ClaimableRewardsItem, DefaultMarkets, MakerMarket } from "services/AppSyncClient"
import { bugsnagClient } from "services/BugsnagClient"
import { createMutation } from "utils"

interface ILiquidityMiningMakerMarketsArg {
    account: string
}

interface ILMRewards {
    items: ClaimableRewardsItem[]
    totalBalance: Big
    totalBalanceUsd: Big
    itemsOp: ClaimableRewardsItem[]
    totalBalanceOp: Big
    totalBalanceUsdOp: Big
    minLockDuration: number
}

interface ILMRewardsArg {
    account: string
}

type ILiquidityMiningMakerMarkets = MakerMarket[]
type ILiquidityMiningDefaultMarkets = DefaultMarkets

export const liquidityMiningQueryApi = createApi({
    reducerPath: "liquidityMiningQuery",
    baseQuery: () => ({ data: undefined }),
    endpoints: builder => ({
        defaultMarkets: builder.query<ILiquidityMiningDefaultMarkets, void>({
            queryFn: liquidityMiningDefaultMarketsQuery,
        }),
        makerMarkets: builder.query<ILiquidityMiningMakerMarkets, ILiquidityMiningMakerMarketsArg>({
            queryFn: liquidityMiningMakerMarketsQuery,
        }),
        LMRewards: builder.query<ILMRewards, ILMRewardsArg>({
            queryFn: LMRewardsQuery,
        }),
    }),
})

export const liquidityMiningMutationApi = createApi({
    reducerPath: "liquidityMiningMutation",
    baseQuery: () => ({ data: undefined }),
    endpoints: builder => ({
        liquidityMiningClaimWeeks: builder.mutation<any, [string, Claim[]]>({
            queryFn: createMutation((liquidityProvider: string, claims: Claim[]) =>
                LMDistributorClient.getClaimWeeksTx(liquidityProvider, claims),
            ),
        }),
        LMClaimWeeksOp: builder.mutation<any, [string, Claim[]]>({
            queryFn: createMutation((liquidityProvider: string, claims: Claim[]) =>
                opPerpLiquidityMiningClient.getClaimWeeksTx(liquidityProvider, claims),
            ),
        }),
    }),
})

export const { useDefaultMarketsQuery, useMakerMarketsQuery, useLMRewardsQuery } = liquidityMiningQueryApi
export const { useLiquidityMiningClaimWeeksMutation, useLMClaimWeeksOpMutation } = liquidityMiningMutationApi

async function liquidityMiningDefaultMarketsQuery() {
    try {
        const defaultMarkets = await appSyncClient.getLiquidityMiningDefaultMarkets()
        return { data: defaultMarkets }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}

async function liquidityMiningMakerMarketsQuery(args: ILiquidityMiningMakerMarketsArg) {
    try {
        const { account } = args
        const makerMarkets = await appSyncClient.getLiquidityMiningMakerMarkets(account)
        return { data: makerMarkets }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}

async function LMRewardsQuery(args: ILMRewardsArg) {
    try {
        const { account } = args
        const [rewards, minLockDuration] = await Promise.all([
            appSyncClient.getLiquidityMiningClaimableRewards(account),
            LMDistributorClient.getMinLockDuration(),
        ])
        return {
            data: {
                ...rewards,
                minLockDuration,
            },
        }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}
