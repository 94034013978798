import { Modal as ChakraModal, ModalBody, ModalContent, ModalOverlay, VStack } from "@chakra-ui/react"
import Big from "big.js"
import { DEFAULT_WEEKS_MIN } from "components/Locker/constants"
import { CreateLockData, LockerCreateLock } from "components/Locker/LockerCreateLock"
import { Header } from "components/Modal/Header"
import { EventGroup, EventName, EventTypeInteraction } from "modules/analytics"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { analyticsClient } from "services/AnalyticsClient"
import { useAppSelector } from "store"

import { useLazyRiverVePerpInfoPreview } from "../hook/useLazyRiverVePerpInfoPreview"
import { lazyRiverSlice } from "../slice"
import { LazyRiverLockerInfo } from "./LazyRiverLockerInfo"
import { LazyRiverLockerWarning } from "./LazyRiverLockerWarning"

const INIT_PERP_INFO_PREVIEW = { lockAmount: Big(0), lockWeeks: DEFAULT_WEEKS_MIN }

export function ModalCreateLock() {
    const { isOpen, size, motion } = useAppSelector(state => state.lazyRiver.modal.createLock)

    const dispatch = useDispatch()
    const { closeModalCreateLock: closeModalBeforeClaimCreateLock } = lazyRiverSlice.actions

    const { info, handleValueChange } = useLazyRiverVePerpInfoPreview(INIT_PERP_INFO_PREVIEW)

    const handleClose = useCallback(() => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.MODAL_CLOSED,
            eventName: EventName.EMISSION_EXTEND_TIME_MODAL_OPENED,
        })
        dispatch(closeModalBeforeClaimCreateLock())
        handleValueChange(INIT_PERP_INFO_PREVIEW)
    }, [closeModalBeforeClaimCreateLock, dispatch, handleValueChange])

    const handleClick = useCallback(({ lockAmount, lockWeeks }: CreateLockData) => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.BUTTON_CLICKED,
            eventName: EventName.EMISSION_CREATE_LOCK_BUTTON_CLICKED,
            payload: { lockAmount: lockAmount.toString(), lockWeeks },
        })
    }, [])

    return (
        <ChakraModal motionPreset={motion} onClose={handleClose} size={size} isOpen={isOpen} isCentered>
            <ModalOverlay />
            <ModalContent
                bg="perp.background.background"
                color="perp.text.primary"
                boxShadow="0px 20px 20px 0px rgba(0, 0, 0, 0.15)"
            >
                <Header onCloseButtonClick={handleClose}>PERP Locker</Header>
                <ModalBody>
                    <VStack spacing={6} align="stretch">
                        <LazyRiverLockerWarning />
                        <LockerCreateLock
                            onClick={handleClick}
                            onChange={handleValueChange}
                            onFinished={handleClose}
                            successMsg="You have locked PERP successfully. Please continue to claim your rewards."
                            overrides={{ minStartWeek: 2 }}
                        />
                        <LazyRiverLockerInfo
                            lockedPerpAmount={info.lockedPerpAmount}
                            vePerpAmount={info.vePerpAmount}
                            eligibleWeeks={info.eligibleWeeks}
                            lastValidTimestamp={info.lastValidTimestamp}
                        />
                    </VStack>
                </ModalBody>
            </ModalContent>
        </ChakraModal>
    )
}
