export function getVSymbolFullName(symbol: string) {
    switch (symbol) {
        case "vBTC": {
            return "Bitcoin"
        }
        case "vETH": {
            return "Ethereum"
        }
        case "vATOM": {
            return "Cosmos"
        }
        case "vUSD": {
            return "USD"
        }
        case "vFTM": {
            return "Fantom"
        }
        case "vCRV": {
            return "Curve"
        }
        case "vSAND": {
            return "The Sandbox"
        }
        case "vLINK": {
            return "ChainLink"
        }
        case "vDAI": {
            return "Dai"
        }
        case "vSOL": {
            return "Solana"
        }
        case "vAVAX": {
            return "Avalanche"
        }
        case "vAAVE": {
            return "Aave"
        }
        default:
            return symbol
    }
}
