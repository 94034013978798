import { Box, Grid } from "@chakra-ui/react"
import { PERPIcon } from "components/Icons/Tokens/PERPIcon"
import { PerpInfoRow } from "components/PerpInfoRow"
import { PerpWalletConnectorContainer } from "hooks/containers/usePerpWalletConnectorContainer"
import { useVePerpUserDataQuery } from "modules/vePerp"
import React from "react"
import { formatNumber } from "utils"

export function HomeLockerInfo() {
    const { account } = PerpWalletConnectorContainer.useContainer()
    const { data: vePerpUserData } = useVePerpUserDataQuery({ account: account! }, { skip: !account })
    const lockedPerpTokenAmount = vePerpUserData?.lockedAmount

    return (
        <Grid mt="16px" gap="8px">
            <PerpInfoRow
                title="Total Locked PERP"
                isLoading={!lockedPerpTokenAmount}
                content={
                    <Box display="flex" alignItems="center">
                        <PERPIcon boxSize="16px" mr="4px" />
                        {lockedPerpTokenAmount && formatNumber(lockedPerpTokenAmount)}
                    </Box>
                }
            />
        </Grid>
    )
}
