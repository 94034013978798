import { Box, ModalHeader } from "@chakra-ui/react"
import React from "react"
import { ChevronLeftIcon } from "../Icons/ChevronLeftIcon"
import { CloseIcon } from "../Icons/CloseIcon"
import { useAppSelector } from "../../store"

type HeaderProps = {
    children?: React.ReactNode
    onBackButtonClick?: () => void
    onCloseButtonClick?: () => void
}

const invisibleStyle = {
    visibility: "hidden",
    pointerEvents: "none",
}

const loadingStyle = {
    pointerEvents: "none",
    color: "perp.text.secondary",
}

function Header({ children, onBackButtonClick, onCloseButtonClick }: HeaderProps) {
    const { isTxLoading } = useAppSelector(state => state.wallet)
    const defaultButtonStyle = isTxLoading ? loadingStyle : {}

    return (
        <ModalHeader borderBottom="1px solid" borderColor="perp.background.border">
            <Box display="flex" alignItems="center" justifyContent={"space-between"}>
                <Box
                    cursor="pointer"
                    onClick={onBackButtonClick}
                    sx={!!onBackButtonClick ? defaultButtonStyle : invisibleStyle}
                >
                    <ChevronLeftIcon />
                </Box>
                <Box flex={1} textAlign="center">
                    {children}
                </Box>
                <Box
                    cursor="pointer"
                    onClick={onCloseButtonClick}
                    sx={!!onCloseButtonClick ? defaultButtonStyle : invisibleStyle}
                >
                    <CloseIcon />
                </Box>
            </Box>
        </ModalHeader>
    )
}

export { Header }
