import React from "react"
import { Image, Container, Grid, Text, Box } from "@chakra-ui/react"
import { ExternalLink } from "../ExternalLink"
import twitterIcon from "../../assets/icons/twitter.svg"
import youtubeIcon from "../../assets/icons/youtube.svg"
import discordIcon from "../../assets/icons/discord.svg"
import telegramIcon from "../../assets/icons/telegram.svg"
import mediumIcon from "../../assets/icons/medium.svg"
import { colors } from "../../theme/colors"

const ecosystemMapContent = {
    product: {
        title: "Product",
        content: [
            { name: "Launch App", link: "https://app.perp.com/" },
            { name: "Tokenomics", link: "https://token.perp.com" },
            { name: "Vaults", link: "https://vaults.perp.com" },
        ],
    },
    protocol: {
        title: "Protocol",
        content: [
            { name: "About", link: "https://perp.com" },
            { name: "Blog", link: "https://blog.perp.fi/" },
            { name: "Newsletter", link: "https://www.getrevue.co/profile/perpprotocol/" },
            { name: "Jobs", link: "https://jobs.perp.fi/" },
            { name: "Shop", link: "https://shop.perp.fi/" },
        ],
    },
    resources: {
        title: "Resources",
        content: [
            { name: "FAQ", link: "http://support.perp.com/" },
            { name: "Developers", link: "https://perp.com/developers" },
            { name: "Learn", link: "https://perp.com/learn" },
            { name: "Ecosystem", link: "https://perp.com/ecosystem" },
            { name: "Perpvangelist", link: "https://perp.com/perpvangelist" },
            { name: "Governance", link: "https://perp.com/governance" },
            { name: "Brand", link: "https://perp.com/branding" },
        ],
    },
    socials: {
        title: "Social Media",
        content: [
            { name: "Twitter", link: "https://twitter.com/perpprotocol", img: twitterIcon },
            { name: "YouTube", link: "https://www.youtube.com/c/PerpetualProtocol", img: youtubeIcon },
            { name: "Discord", link: "https://discord.com/invite/Dq9mTmCaBb", img: discordIcon },
            { name: "Telegram", link: "https://t.me/perpetualprotocol", img: telegramIcon },
            { name: "Medium", link: "https://perpetualprotocol.medium.com/", img: mediumIcon },
        ],
    },
}

function EcosystemMap() {
    return (
        <Container maxW="1080px" paddingInline={["24px", "24px"]} color="perp.text.primary">
            <Grid
                templateColumns={["repeat(2, 1fr)", "repeat(2, 1fr)", "repeat(4, 1fr)"]}
                gridGap={["16px", "16px"]}
                justifyContent="space-between"
            >
                <MapColumn data={ecosystemMapContent.product} />
                <MapColumn data={ecosystemMapContent.protocol} />
                <MapColumn data={ecosystemMapContent.resources} />
                <MapColumn data={ecosystemMapContent.socials} />
            </Grid>
        </Container>
    )
}

export { EcosystemMap }

interface MapColumnProps {
    data: {
        title: string
        content: {
            name: string
            link: string
            img?: string
        }[]
    }
}

function MapColumn({ data }: MapColumnProps) {
    const { title, content } = data
    return (
        <Grid gap="16px" alignContent="start">
            <Text fontWeight="bold" fontSize="16px">
                {title}
            </Text>
            {content.map(({ name, link, img }) => (
                <ExternalLink href={link} key={name}>
                    <Box
                        display="flex"
                        fontSize="14px"
                        _hover={{ color: colors.perp.text.primary }}
                        color={colors.perp.text.article}
                    >
                        {img && <Image src={img} mr="6px" />}
                        {name}
                    </Box>
                </ExternalLink>
            ))}
        </Grid>
    )
}
