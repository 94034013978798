import { Icon, IconProps } from "@chakra-ui/react"
import React from "react"

export function VePERPIcon({ ...props }: IconProps) {
    return (
        <Icon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_1707_3376)">
                <path
                    d="M15.7597 9.9354C14.6912 14.2212 10.3505 16.8294 6.06423 15.7607C1.77973 14.6922 -0.828515 10.3512 0.240485 6.06565C1.30848 1.7794 5.64923 -0.829101 9.93423 0.239399C14.2202 1.3079 16.8282 5.6494 15.7597 9.9354Z"
                    fill="#03BCE4"
                />
                <g style={{ mixBlendMode: "multiply" }} opacity="0.8">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.90549 4.42202C7.40142 3.9394 6.59502 3.9394 6.09096 4.42202L3.88922 6.53007C3.37024 7.02697 3.37024 7.84364 3.88922 8.34054L7.47715 11.7758C7.48164 11.7805 7.4862 11.7852 7.49085 11.7899C7.63168 11.9307 7.81809 12.0013 8.00461 12.0012C8.19209 12.0017 8.37959 11.9311 8.52115 11.7899C8.53224 11.7788 8.54288 11.7675 8.55307 11.7559L12.1117 8.34054C12.6294 7.84364 12.6294 7.02697 12.1117 6.53007L10.9212 5.3875C10.4183 4.90488 9.61387 4.90488 9.11101 5.3875L9.01249 5.48192L7.90549 4.42202ZM9.03344 7.43766C9.36219 7.1229 9.68774 6.80498 10.0161 6.48983L11.0012 7.4353L10.0161 8.38077L9.03344 7.43766ZM7.98371 6.46791L5.98983 8.38077L5.00235 7.43531L6.99822 5.52435L7.98371 6.46791ZM8.00753 8.41992L8.98927 9.36627L8.00538 10.3106L7.01913 9.36627L8.00753 8.41992Z"
                        fill="#141319"
                    />
                </g>
                <g style={{ mixBlendMode: "multiply" }} opacity="0.4">
                    <circle cx="8" cy="8" r="6.5" stroke="#141319" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1707_3376">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </Icon>
    )
}
