import { createSlice } from "@reduxjs/toolkit"
import { ReactNode } from "react"

export enum IStatus {
    info = "info",
    success = "success",
    error = "error",
}
interface NotificationState {
    isOpen: boolean
    status: IStatus
    description: string | ReactNode
    duration?: number
}

const initialState: NotificationState = {
    isOpen: false,
    status: IStatus.info,
    description: "",
    duration: undefined,
}

const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        open(state, action: { payload: { status: IStatus; description: string | ReactNode; duration?: number } }) {
            state.description = action.payload.description
            state.status = action.payload.status
            state.isOpen = true
            state.duration = action.payload.duration
        },
        close(state) {
            state.description = initialState.description
            state.status = initialState.status
            state.isOpen = initialState.isOpen
            state.duration = initialState.duration
        },
    },
})

export { notificationSlice }
