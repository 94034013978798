import { NumberInput, NumberInputField, NumberInputFieldProps, NumberInputProps } from "@chakra-ui/react"
import React, { useCallback } from "react"
import { formatPrecisionNumber } from "utils/format"

type OnChange = Exclude<NumberInputProps["onChange"], undefined>

interface PerpNumberInputProps extends Omit<NumberInputFieldProps, "onChange" | "size"> {
    value: string | number
    precision: number
    onChange: OnChange
    isDisabled?: boolean
    isInvalid?: boolean
}

function PerpNumberInput({
    value,
    precision,
    onChange,
    isDisabled,
    isInvalid,
    ...inputFieldProps
}: PerpNumberInputProps) {
    const formattedValue = value === undefined ? undefined : formatPrecisionNumber({ input: value || "", precision })

    const handleOnChange = useCallback<OnChange>(
        (valueString, valueAsNumber) => {
            // NOTE: NumberInput doesn't support scientific notation.
            const isValidInput = !valueString.match(/e/g)

            if ((isValidInput && !isNaN(valueAsNumber)) || valueString === "" || valueString === ".") {
                // NOTE: Only trigger onChange when input is valid.
                onChange(formatPrecisionNumber({ input: valueString || "", precision }), valueAsNumber)
            }
        },
        [onChange, precision],
    )

    return (
        <NumberInput
            pl="8px"
            pr="8px"
            variant="unstyled"
            value={formattedValue}
            precision={precision}
            onChange={handleOnChange}
            isDisabled={isDisabled}
            isInvalid={isInvalid}
            clampValueOnBlur={false}
        >
            <NumberInputField {...inputFieldProps} p="0" />
        </NumberInput>
    )
}

export { PerpNumberInput, PerpNumberInputProps }
