import { Icon, IconProps } from "@chakra-ui/react"
import React from "react"

function OutboundLinkIcon({ ...props }: IconProps) {
    return (
        <Icon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.88637 17.8863L14.592 8.18064L7.18524 8.23719L7.17379 6.73723L16.4109 6.66672C16.6118 6.66518 16.8049 6.74431 16.947 6.88636C17.089 7.02842 17.1682 7.22153 17.1666 7.42242L17.0961 16.6596L15.5962 16.6481L15.6527 9.2413L5.94703 18.947L4.88637 17.8863Z"
                fill="currentColor"
            />
        </Icon>
    )
}

export { OutboundLinkIcon }
