import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { analyticsClient } from "services/AnalyticsClient"
import { useGetGeoBlockInfoQuery } from "services/AWSClient/flow"

export function usePageTracker() {
    const { data } = useGetGeoBlockInfoQuery()
    const location = useLocation()
    useEffect(() => {
        analyticsClient.trackPage({ countryCode: data?.countryCode || "" })
    }, [location, data?.countryCode])
}
