import { Box, Grid, Text } from "@chakra-ui/react"
import Big from "big.js"
import { OPIcon } from "components/Icons/Tokens/OPIcon"
import { PERPIcon } from "components/Icons/Tokens/PERPIcon"
import { PerpWalletConnectorContainer } from "hooks/containers/usePerpWalletConnectorContainer"
import React from "react"

import { useLMRewardsQuery } from "../flow"
import { useLMClaim } from "../hook/useLMClaim"
import { LiquidityMiningClaimCard } from "./LiquidityMiningClaimCard"

export function LiquidityMiningControllerPanel() {
    const { account } = PerpWalletConnectorContainer.useContainer()
    const { data, isLoading } = useLMRewardsQuery({ account: account! }, { skip: !account })
    const { onLMPerpClaim, onLMOPClaim } = useLMClaim()

    return (
        <Grid
            gap="16px"
            color="perp.text.primary"
            bgColor="perp.background.background"
            border="1px solid"
            borderRadius={12}
            borderColor="perp.background.border"
            p={6}
            width={["390p", "354.5px"]}
            boxShadow="0px 20px 20px 0px rgba(0, 0, 0, 0.15)"
            style={{ position: "sticky", top: "16px" }}
        >
            <Text fontSize="20px">Your Rewards</Text>
            <Box bg="perp.background.card" borderRadius="12px" p="16px" alignContent="center">
                <LiquidityMiningClaimCard
                    icon={<PERPIcon boxSize="48px" />}
                    title="PERP (Locked)"
                    amount={data?.totalBalance ? data.totalBalance : Big(0)}
                    onClick={onLMPerpClaim}
                    isLoading={isLoading}
                />
            </Box>
            <Box bg="perp.background.card" borderRadius="12px" p="16px" alignContent="center">
                <LiquidityMiningClaimCard
                    icon={<OPIcon boxSize="48px" />}
                    title="OP"
                    amount={data?.totalBalanceOp ? data.totalBalanceOp : Big(0)}
                    onClick={onLMOPClaim}
                    isLoading={isLoading}
                />
            </Box>
        </Grid>
    )
}
