import {
    Box,
    Modal as ChakraModal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Portal,
    Text,
    VStack,
} from "@chakra-ui/react"
import { CreateLockData, LockerCreateLock } from "components/Locker/LockerCreateLock"
import { Header } from "components/Modal/Header"
import { PerpWalletConnectorContainer } from "hooks/containers/usePerpWalletConnectorContainer"
import { EventGroup, EventName, EventTypeInteraction } from "modules/analytics"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { analyticsClient } from "services/AnalyticsClient"
import { useAppSelector } from "store"

import { useReferralRewardsQuery } from "../../flow"
import { selectDuration } from "../../selector"
import { referralSlice } from "../../slice"

/**
 * NOTE: This component will open when the user is trying to claim rewards
 * but who doesn't have any PERP locked.
 */
function ModalCreateLock() {
    const { account } = PerpWalletConnectorContainer.useContainer()
    const { minLockDurationInWeeks } = useReferralRewardsQuery(
        { account: account! },
        { skip: !account, selectFromResult: selectDuration },
    )
    const { isOpen, size, motion } = useAppSelector(state => state.referral.modal.createLock)

    const dispatch = useDispatch()
    const { closeModalCreateLock: closeModalBeforeClaimCreateLock } = referralSlice.actions

    const handleClose = useCallback(() => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.MODAL_CLOSED,
            eventName: EventName.REFERRAL_CREATE_LOCK_MODAL_CLOSED,
        })
        dispatch(closeModalBeforeClaimCreateLock())
    }, [closeModalBeforeClaimCreateLock, dispatch])

    const handleClick = useCallback(({ lockAmount, lockWeeks }: CreateLockData) => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.BUTTON_CLICKED,
            eventName: EventName.REFERRAL_CREATE_LOCK_BUTTON_CLICKED,
            payload: { lockAmount: lockAmount.toString(), lockWeeks },
        })
    }, [])

    return (
        <Portal>
            <ChakraModal motionPreset={motion} onClose={handleClose} size={size} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent
                    bg="perp.background.background"
                    color="perp.text.primary"
                    boxShadow="0px 20px 20px 0px rgba(0, 0, 0, 0.15)"
                >
                    <Header onCloseButtonClick={handleClose}>Locked PERP Required</Header>
                    <ModalBody>
                        <VStack spacing={6} align="stretch">
                            <Text variant={"body2"}>
                                You don't have any Locked PERP currently. You'll need to lock some
                                <Box as="span" opacity={0.65}>
                                    *
                                </Box>{" "}
                                PERP for at least{" "}
                                <Box color="perp.primary.default" fontWeight={"bold"} as="span">
                                    {minLockDurationInWeeks} week(s)
                                </Box>{" "}
                                before you can claim your rewards.
                            </Text>
                            <Text variant={"body2"} opacity={0.65}>
                                * Any amount will do. Even 0.0000001 PERP works.
                            </Text>
                            <LockerCreateLock
                                onClick={handleClick}
                                onFinished={handleClose}
                                successMsg="You have locked PERP successfully. Please continue to claim your rewards."
                            />
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </ChakraModal>
        </Portal>
    )
}

export { ModalCreateLock }
