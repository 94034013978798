import React, { ReactNode } from "react"
import { Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverArrow, VStack, Flex, Box } from "@chakra-ui/react"
import { useIsDesktop } from "hooks/useDevice"

interface TooltipProps {
    triggerEl: ReactNode
    baseAprText: ReactNode
    rewardAprText: ReactNode
}

export function LiquidityMiningAprTooltip({ triggerEl, baseAprText, rewardAprText }: TooltipProps) {
    const isDesktop = useIsDesktop()
    return (
        <>
            <Popover trigger={isDesktop ? "hover" : "click"} placement="top">
                <PopoverTrigger>{triggerEl}</PopoverTrigger>
                <PopoverContent
                    borderRadius="12px"
                    bg="perp.background.background"
                    borderColor="perp.background.border"
                    w="auto"
                >
                    <PopoverArrow />
                    <VStack>
                        <Box m="16px">
                            <PopoverBody>
                                <Flex>
                                    Base APR {"\u00a0"} {baseAprText}
                                </Flex>
                            </PopoverBody>
                            <PopoverBody>
                                <Flex>
                                    Rewards APR {"\u00a0"} {rewardAprText}
                                </Flex>
                            </PopoverBody>
                        </Box>
                    </VStack>
                </PopoverContent>
            </Popover>
        </>
    )
}
