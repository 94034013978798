import { Flex, Grid, Text } from "@chakra-ui/react"
import Big from "big.js"
import { PERPIcon } from "components/Icons/Tokens/PERPIcon"
import { VePERPIcon } from "components/Icons/Tokens/VePERPIcon"
import { PerpInfoRow } from "components/PerpInfoRow"
import React from "react"
import { formatNumber, getTimeString } from "utils"

export interface LazyRiverLockerInfoProps {
    lockedPerpAmount?: Big
    vePerpAmount?: Big
    eligibleWeeks?: number
    lastValidTimestamp?: number
}

export function LazyRiverLockerInfo({
    lockedPerpAmount,
    vePerpAmount,
    eligibleWeeks,
    lastValidTimestamp,
}: LazyRiverLockerInfoProps) {
    return (
        <Grid gap="8px" fontSize="14px">
            <PerpInfoRow
                title="Locked PERP"
                isLoading={lockedPerpAmount === undefined}
                content={
                    <Flex alignItems="center" gap="4px">
                        <PERPIcon boxSize="16px" />
                        <Text>{lockedPerpAmount && formatNumber(lockedPerpAmount)}</Text>
                    </Flex>
                }
            />
            <PerpInfoRow
                title="vePERP"
                isLoading={vePerpAmount === undefined}
                content={
                    <Flex alignItems="center" gap="4px">
                        <VePERPIcon boxSize="16px" />
                        <Text>{vePerpAmount && formatNumber(vePerpAmount)}</Text>
                    </Flex>
                }
            />
            <PerpInfoRow
                title="Eligible for"
                isLoading={eligibleWeeks === undefined}
                content={<Text>{eligibleWeeks !== undefined && `${eligibleWeeks} Week(s)`}</Text>}
            />
            <PerpInfoRow
                title="Valid Through"
                isLoading={lastValidTimestamp === undefined}
                content={<Text>{lastValidTimestamp && getTimeString(lastValidTimestamp)}</Text>}
            />
        </Grid>
    )
}
