import { Box, Button } from "@chakra-ui/react"
import Big from "big.js"
import { NOTIFICATION_DURATION, POLLING_PERIOD } from "constants/config"
import { analyticsTx } from "modules/analytics"
import { IStatus, notificationSlice } from "modules/notification/slice"
import { useVePerpIncreaseAmountMutation } from "modules/vePerp"
import React, { ReactNode, useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import { useCoingeckoPriceForPERPQuery } from "services/CoingeckoClient/flow"
import { str2Big } from "utils"

import { Erc20PERPContainer } from "../../hooks/containers/useErc20PERPContainer"
import { useAppSelector } from "../../store"
import { PerpTokenAmountInput } from "../PerpTokenAmountInput"

export interface IncreaseAmountData {
    lockAmount: Big
}
type LockerIncreaseAmountProps = {
    onClick?: (data: IncreaseAmountData) => void
    onChange?: (data: IncreaseAmountData) => void
    onSucceed?: () => void
    onFinished?: () => void
    successMsg?: string | ReactNode
    failedMsg?: string | ReactNode
}

export function LockerIncreaseAmount({
    onClick,
    onChange,
    onSucceed,
    onFinished,
    successMsg = "You have locked PERP successfully.",
    failedMsg = "Lock PERP failed.",
}: LockerIncreaseAmountProps) {
    // NOTE: unstated-next
    const { allowance, balance, approve } = Erc20PERPContainer.useContainer()

    // NOTE: redux
    const dispatch = useDispatch()
    const [increaseAmount] = useVePerpIncreaseAmountMutation()
    const { open } = notificationSlice.actions
    const { isTxLoading } = useAppSelector(state => state.wallet)
    const { data: pricePerpData } = useCoingeckoPriceForPERPQuery(undefined, { pollingInterval: POLLING_PERIOD.MEDIUM })

    const perpPrice = pricePerpData?.price

    // NOTE: local state
    const [amount, setAmount] = useState<string>("")

    // NOTE: derivative states
    const amountBig = str2Big(amount) || Big(0)
    const valueInUSD = perpPrice ? amountBig.times(perpPrice) : undefined

    // NOTE: handlers
    const handleAmountChange = useCallback(
        (amountNext: string) => {
            setAmount(amountNext)
            const amountNextBig = str2Big(amountNext) || Big(0)
            onChange?.({ lockAmount: amountNextBig })
        },
        [setAmount, onChange],
    )

    const handleIncreaseAmount = useCallback(async () => {
        onClick?.({ lockAmount: amountBig })
        const result = await increaseAmount([amountBig])
        if ("error" in result) {
            analyticsTx.vePerp.increaseAmount.failed(result.error)
            dispatch(
                open({
                    status: IStatus.error,
                    description: failedMsg,
                    duration: NOTIFICATION_DURATION.SHORT,
                }),
            )
        } else {
            analyticsTx.vePerp.increaseAmount.succeeded(result.data)
            dispatch(
                open({
                    status: IStatus.success,
                    description: successMsg,
                    duration: NOTIFICATION_DURATION.SHORT,
                }),
            )
            onSucceed?.()
        }
        onFinished?.()
    }, [onClick, amountBig, increaseAmount, onFinished, dispatch, open, failedMsg, successMsg, onSucceed])

    // NOTE: ui conditions
    const isLoading = allowance === undefined || balance === undefined || isTxLoading
    const isApprovalNeeded = allowance && (allowance.lte(Big(0)) || allowance.lt(amountBig))
    const isDisabledToIncreaseAmount = amountBig.lte(0)

    return (
        <Box color="perp.text.primary">
            <Box minH="96px">
                <PerpTokenAmountInput
                    value={amount}
                    precision={18}
                    onChange={handleAmountChange}
                    tokenBalance={balance}
                    valueInUSD={valueInUSD}
                />
            </Box>
            <Box mt="24px">
                <Button
                    w="100%"
                    size="lg"
                    isLoading={isLoading}
                    onClick={isApprovalNeeded ? approve : handleIncreaseAmount}
                    disabled={isLoading || (isApprovalNeeded ? false : isDisabledToIncreaseAmount)}
                    loadingText="Waiting for transaction"
                >
                    {isApprovalNeeded ? "Approve" : "Increase"}
                </Button>
            </Box>
        </Box>
    )
}
