/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  IAccountBalance,
  IAccountBalanceInterface,
} from "../IAccountBalance";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "trader",
        type: "address",
      },
      {
        indexed: false,
        internalType: "int256",
        name: "amount",
        type: "int256",
      },
    ],
    name: "PnlRealized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "vault",
        type: "address",
      },
    ],
    name: "VaultChanged",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "trader",
        type: "address",
      },
      {
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
    ],
    name: "deregisterBaseToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "trader",
        type: "address",
      },
      {
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
    ],
    name: "getAccountInfo",
    outputs: [
      {
        components: [
          {
            internalType: "int256",
            name: "takerPositionSize",
            type: "int256",
          },
          {
            internalType: "int256",
            name: "takerOpenNotional",
            type: "int256",
          },
          {
            internalType: "int256",
            name: "lastTwPremiumGrowthGlobalX96",
            type: "int256",
          },
        ],
        internalType: "struct AccountMarket.Info",
        name: "traderAccountInfo",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "trader",
        type: "address",
      },
      {
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
    ],
    name: "getBase",
    outputs: [
      {
        internalType: "int256",
        name: "baseAmount",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "trader",
        type: "address",
      },
    ],
    name: "getBaseTokens",
    outputs: [
      {
        internalType: "address[]",
        name: "baseTokens",
        type: "address[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getClearingHouseConfig",
    outputs: [
      {
        internalType: "address",
        name: "clearingHouseConfig",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "trader",
        type: "address",
      },
      {
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
      {
        internalType: "int256",
        name: "accountValue",
        type: "int256",
      },
    ],
    name: "getLiquidatablePositionSize",
    outputs: [
      {
        internalType: "int256",
        name: "",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "trader",
        type: "address",
      },
    ],
    name: "getMarginRequirementForLiquidation",
    outputs: [
      {
        internalType: "int256",
        name: "marginRequirementForLiquidation",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getOrderBook",
    outputs: [
      {
        internalType: "address",
        name: "orderBook",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "trader",
        type: "address",
      },
    ],
    name: "getPnlAndPendingFee",
    outputs: [
      {
        internalType: "int256",
        name: "owedRealizedPnl",
        type: "int256",
      },
      {
        internalType: "int256",
        name: "unrealizedPnl",
        type: "int256",
      },
      {
        internalType: "uint256",
        name: "pendingFee",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "trader",
        type: "address",
      },
      {
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
    ],
    name: "getQuote",
    outputs: [
      {
        internalType: "int256",
        name: "quoteAmount",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "trader",
        type: "address",
      },
      {
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
    ],
    name: "getTakerOpenNotional",
    outputs: [
      {
        internalType: "int256",
        name: "openNotional",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "trader",
        type: "address",
      },
      {
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
    ],
    name: "getTakerPositionSize",
    outputs: [
      {
        internalType: "int256",
        name: "takerPositionSize",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "trader",
        type: "address",
      },
    ],
    name: "getTotalAbsPositionValue",
    outputs: [
      {
        internalType: "uint256",
        name: "totalAbsPositionValue",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "trader",
        type: "address",
      },
    ],
    name: "getTotalDebtValue",
    outputs: [
      {
        internalType: "uint256",
        name: "totalDebtValue",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "trader",
        type: "address",
      },
      {
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
    ],
    name: "getTotalOpenNotional",
    outputs: [
      {
        internalType: "int256",
        name: "totalOpenNotional",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "trader",
        type: "address",
      },
      {
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
    ],
    name: "getTotalPositionSize",
    outputs: [
      {
        internalType: "int256",
        name: "totalPositionSize",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "trader",
        type: "address",
      },
      {
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
    ],
    name: "getTotalPositionValue",
    outputs: [
      {
        internalType: "int256",
        name: "totalPositionValue",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getVault",
    outputs: [
      {
        internalType: "address",
        name: "vault",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "trader",
        type: "address",
      },
    ],
    name: "hasOrder",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "trader",
        type: "address",
      },
      {
        internalType: "int256",
        name: "amount",
        type: "int256",
      },
    ],
    name: "modifyOwedRealizedPnl",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "trader",
        type: "address",
      },
      {
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
      {
        internalType: "int256",
        name: "base",
        type: "int256",
      },
      {
        internalType: "int256",
        name: "quote",
        type: "int256",
      },
    ],
    name: "modifyTakerBalance",
    outputs: [
      {
        internalType: "int256",
        name: "takerPositionSize",
        type: "int256",
      },
      {
        internalType: "int256",
        name: "takerOpenNotional",
        type: "int256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "trader",
        type: "address",
      },
      {
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
    ],
    name: "registerBaseToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "trader",
        type: "address",
      },
      {
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
      {
        internalType: "int256",
        name: "takerBase",
        type: "int256",
      },
      {
        internalType: "int256",
        name: "takerQuote",
        type: "int256",
      },
      {
        internalType: "int256",
        name: "realizedPnl",
        type: "int256",
      },
      {
        internalType: "int256",
        name: "makerFee",
        type: "int256",
      },
    ],
    name: "settleBalanceAndDeregister",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "trader",
        type: "address",
      },
    ],
    name: "settleOwedRealizedPnl",
    outputs: [
      {
        internalType: "int256",
        name: "pnl",
        type: "int256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "trader",
        type: "address",
      },
      {
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
    ],
    name: "settlePositionInClosedMarket",
    outputs: [
      {
        internalType: "int256",
        name: "positionNotional",
        type: "int256",
      },
      {
        internalType: "int256",
        name: "openNotional",
        type: "int256",
      },
      {
        internalType: "int256",
        name: "realizedPnl",
        type: "int256",
      },
      {
        internalType: "uint256",
        name: "closedPrice",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "trader",
        type: "address",
      },
      {
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
      {
        internalType: "int256",
        name: "amount",
        type: "int256",
      },
    ],
    name: "settleQuoteToOwedRealizedPnl",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "trader",
        type: "address",
      },
      {
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
      {
        internalType: "int256",
        name: "lastTwPremiumGrowthGlobalX96",
        type: "int256",
      },
    ],
    name: "updateTwPremiumGrowthGlobal",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class IAccountBalance__factory {
  static readonly abi = _abi;
  static createInterface(): IAccountBalanceInterface {
    return new utils.Interface(_abi) as IAccountBalanceInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IAccountBalance {
    return new Contract(address, _abi, signerOrProvider) as IAccountBalance;
  }
}
