import type { ComponentStyleConfig } from "@chakra-ui/react"

const Input: ComponentStyleConfig = {
    baseStyle: {
        field: {
            borderRadius: 12,
            bg: "perp.background.input",
            px: 4,
        },
    },
    defaultProps: {
        size: null,
        variant: null,
    },
}

export { Input }
