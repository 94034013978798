import Big from "big.js"
import { getCalibratedTime } from "utils"

export function getReferralLink(code: string) {
    return `https://app.perp.com?code=${code}`
}

export function getEligibleWeeks(
    basicValue: number,
    qualifiedMultiplier: number,
    currentTime: number,
    endTime: number,
    lockedAmount: Big,
) {
    const weekSecs = 86400 * 7
    const yearSecs = 86400 * 7 * 52
    const minVePerpValidValue = Big(basicValue).mul(qualifiedMultiplier)
    const maxValidTimeRaw = minVePerpValidValue.div(lockedAmount).mul(yearSecs).minus(endTime).mul(-1)
    const maxValidTime = maxValidTimeRaw.round(0, Big.roundUp).toNumber()
    const lastThuTime = getCalibratedTime(currentTime, weekSecs)
    const maxValidThuTime = getCalibratedTime(maxValidTime, weekSecs)
    return (maxValidThuTime - lastThuTime) / weekSecs
}
