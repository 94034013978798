import { Box, Heading } from "@chakra-ui/react"
import FigmaTraderRewardsStatus from "components/Figma/components/FigmaTraderRewardsStatus"
import { EPOCH } from "constants/config"
import { DATE_RANGE_PLACE_HOLDER, NUMBER_PLACE_HOLDER } from "constants/strings"
import { PerpWalletConnectorContainer } from "hooks/containers/usePerpWalletConnectorContainer"
import React from "react"
import { useGetServerTimestampQuery } from "services/AWSClient/flow"
import { formatNumber, getCalibratedTime, getTimeString } from "utils"

import {
    useReferralCodeQuery,
    useReferralCurrentRewardQuery,
    useReferralLastRewardQuery,
    useReferralRewardUserAtQuery,
} from "../flow"
import { ReferralStatusFooter } from "./ReferralMyStatus"

export function ReferralAsTraderStatus() {
    const { account } = PerpWalletConnectorContainer.useContainer()
    const { data: serverTimeData } = useGetServerTimestampQuery()
    const timestamp = serverTimeData?.timestamp

    // NOTE: current week's reward
    const currentWeekStartTime = timestamp ? getCalibratedTime(timestamp, EPOCH) : undefined
    const currentWeekEndTime = currentWeekStartTime ? currentWeekStartTime + EPOCH : undefined
    const { data: referralCodeData } = useReferralCodeQuery({ account: account! }, { skip: !account })
    const { data: currentRewardData } = useReferralCurrentRewardQuery(
        {
            account: account!,
            startTime: currentWeekStartTime!,
            endTime: currentWeekEndTime!,
            referralCode: referralCodeData?.referrer!,
        },
        { skip: !account || !currentWeekStartTime || !currentWeekEndTime || !referralCodeData?.referrer },
    )

    // NOTE: last week's reward
    const { data: lastWeekRewardTotal } = useReferralLastRewardQuery()
    const { data: lastWeekRewardUser } = useReferralRewardUserAtQuery(
        {
            account: account!,
            week: lastWeekRewardTotal?.week!,
        },
        { skip: !account || !lastWeekRewardTotal?.week },
    )

    // NOTE: reward status data
    const currentDateRange =
        currentWeekStartTime &&
        currentWeekEndTime &&
        `${getTimeString(currentWeekStartTime)} - ${getTimeString(currentWeekEndTime)}`
    const dateRange =
        lastWeekRewardTotal?.weekStart &&
        lastWeekRewardTotal?.weekEnd &&
        `${getTimeString(lastWeekRewardTotal.weekStart)} - ${getTimeString(lastWeekRewardTotal.weekEnd)}`
    const currentTradingVol = currentRewardData?.referee?.tradingVolume
    const tradingFees = lastWeekRewardUser?.refereeFee
    const tradingVolume = tradingFees?.mul(1000)
    const perpRebate = lastWeekRewardUser?.refereeReward

    return (
        <Box>
            <Heading variant="h3" mb={8}>
                Reward Status (Trader)
            </Heading>
            <FigmaTraderRewardsStatus
                currentDataRange={currentDateRange || DATE_RANGE_PLACE_HOLDER}
                currentTradingVol={currentTradingVol ? formatNumber(currentTradingVol) : NUMBER_PLACE_HOLDER}
                dateRange={dateRange || DATE_RANGE_PLACE_HOLDER}
                tradingVol={tradingVolume ? formatNumber(tradingVolume) : NUMBER_PLACE_HOLDER}
                tradingFees={tradingFees ? formatNumber(tradingFees) : NUMBER_PLACE_HOLDER}
                perpRebate={perpRebate ? formatNumber(perpRebate) : NUMBER_PLACE_HOLDER}
                maxCap={"(Max: $500 per week)"}
            />
            <ReferralStatusFooter />
        </Box>
    )
}
