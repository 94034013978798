import { Icon, IconProps } from "@chakra-ui/react"
import React from "react"

export function AVAXIcon({ ...props }: IconProps) {
    return (
        <Icon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                fill="#E84142"
            />
            <g style={{ mixBlendMode: "multiply" }} opacity="0.8">
                <path
                    d="M19.9832 16.5905C20.3744 15.9263 21.0057 15.9263 21.3969 16.5905L23.833 20.794C24.2242 21.4582 23.9042 22 23.1217 22H18.2139C17.4404 22 17.1203 21.4582 17.5026 20.794L19.9832 16.5905ZM15.2709 8.49813C15.6621 7.83396 16.2845 7.83396 16.6757 8.49813L17.2181 9.45943L18.4984 11.6704C18.8096 12.2996 18.8096 13.0424 18.4984 13.6717L14.204 20.9863C13.8128 21.5805 13.1637 21.9563 12.4436 22H8.87825C8.09584 22 7.77576 21.4669 8.16696 20.794L15.2709 8.49813Z"
                    fill="#141319"
                />
            </g>
        </Icon>
    )
}
