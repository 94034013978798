import Big from "big.js"
import { EPOCH } from "constants/config"
import { useUsdcFeeDistributorRewardQuery } from "modules/usdcFeeDistributor"
import { useVePerpInfoAtQuery } from "modules/vePerp"
import { useVePERPEmissionFeeDistributorLastRewardQuery } from "modules/vePERPEmissionFeeDistributor"
import { useGetServerTimestampQuery } from "services/AWSClient/flow"
import { getCalibratedTime, getRatio } from "utils"
import { IUseEpochStatusArgs } from "./useEpochStatus"

// TODO: need to rename
export function useLastEpochTotalAPR({ epochDeviation = 1 }: IUseEpochStatusArgs) {
    const { data } = useGetServerTimestampQuery()
    const currentTimestamp = data?.timestamp
    const weekStartTimestamp = currentTimestamp ? getCalibratedTime(currentTimestamp, EPOCH) : undefined

    const { data: lastEpochVePERPEmissionFeeRewards } = useVePERPEmissionFeeDistributorLastRewardQuery(2)
    const { data: lastEpochUsdcFeeDistributorReward } = useUsdcFeeDistributorRewardQuery(
        { timestamp: weekStartTimestamp! - EPOCH * epochDeviation },
        {
            skip: !weekStartTimestamp,
        },
    )
    const { data: lastEpochVePerpInfoAt } = useVePerpInfoAtQuery(weekStartTimestamp! - EPOCH * epochDeviation, {
        skip: !weekStartTimestamp,
    })

    // last epoch USDC per vePERP
    const lastEpochPerp7dTwap = lastEpochVePERPEmissionFeeRewards?.perp7dTwap
    const lastEpochUsdcTotalReward = lastEpochUsdcFeeDistributorReward?.totalReward
    const lastEpochTotalVePerp = lastEpochVePerpInfoAt?.totalUnweightedVePerpAmount
    const lastEpochRewardInUsdcPerVePerp =
        lastEpochUsdcTotalReward && lastEpochTotalVePerp && getRatio(lastEpochTotalVePerp, lastEpochUsdcTotalReward)

    const lastEpochTotalLockedAmount = lastEpochVePerpInfoAt?.balancePERP

    const lastEpochTotalApr = lastEpochTotalLockedAmount?.eq(0)
        ? Big(0)
        : lastEpochUsdcTotalReward &&
          lastEpochTotalLockedAmount &&
          lastEpochPerp7dTwap &&
          lastEpochUsdcTotalReward.div(lastEpochTotalLockedAmount.mul(lastEpochPerp7dTwap)).mul(52).mul(100)

    return {
        lastEpochTotalApr,
        lastEpochRewardInUsdcPerVePerp,
    }
}
