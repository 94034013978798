import Bugsnag, { Client } from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import { BUGSNAG_API_KEY, IS_DEV, STAGE, VERSION } from "constants/env"
import React from "react"

class BugsnagClient {
    readonly APP_TYPE: string = "perp-veperp"
    client: Client | null = null

    init() {
        if (BUGSNAG_API_KEY) {
            this.client = Bugsnag.start({
                appType: this.APP_TYPE,
                apiKey: BUGSNAG_API_KEY,
                appVersion: IS_DEV ? "localDev" : VERSION,
                releaseStage: IS_DEV ? "localDev" : STAGE,
                enabledReleaseStages: ["production", "staging", "localDev"],
                plugins: [new BugsnagPluginReact()],
            })
        }
    }

    setUser(id?: string) {
        Bugsnag.setUser(id)
    }

    sendError(error: Error) {
        Bugsnag.notify(error)
    }

    createErrorBoundary() {
        if (this.client) {
            const plugin = Bugsnag.getPlugin("react")
            if (plugin) {
                return plugin.createErrorBoundary(React)
            }
        }
        return ({ children }: { children: React.ReactNode }) => <>{children}</>
    }
}

export const bugsnagClient = new BugsnagClient()
