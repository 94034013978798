import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Code,
    Divider,
    Flex,
    Heading,
    Input,
    InputProps,
    Link,
    Skeleton,
    Text,
    VStack,
} from "@chakra-ui/react"
import { CopyIcon } from "components/Icons/CopyIcon"
import { NOTIFICATION_DURATION } from "constants/config"
import { PerpWalletConnectorContainer } from "hooks/containers/usePerpWalletConnectorContainer"
import { analyticsTx, EventGroup, EventName, EventTypeInteraction } from "modules/analytics"
import { IStatus, notificationSlice } from "modules/notification"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useCopyToClipboard } from "react-use"
import { analyticsClient } from "services/AnalyticsClient"
import { useAppSelector } from "store"

import { useReferralCodeQuery, useReferralCreateCodeMutation } from "../flow"
import { getReferralLink } from "../util"

export function ReferralAsPartner() {
    const dispatch = useDispatch()
    const { isTxLoading } = useAppSelector(state => state.wallet)
    const { open } = notificationSlice.actions
    const { account } = PerpWalletConnectorContainer.useContainer()
    const {
        data: referralCode,
        isLoading: isLoadingReferralCode,
        refetch: refetchReferralCode,
        isFetching: isFetchingReferralCode,
    } = useReferralCodeQuery({ account: account! }, { skip: !account })
    const [createCode] = useReferralCreateCodeMutation()

    // NOTE: states
    const [code, setCode] = useState<string>("")
    const [, copy] = useCopyToClipboard()

    // NOTE: update referral code when switching accounts or when getting a new referral code
    useEffect(() => {
        if (!account || !referralCode?.referrer) {
            setCode("")
            return
        }
        setCode(referralCode.referrer)
    }, [account, referralCode?.referrer])

    // NOTE: action handlers
    const handleChangeCode = useCallback<Required<InputProps>["onChange"]>(e => {
        setCode(e.target.value?.trim())
    }, [])

    const handleCreateCode = useCallback(async () => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.BUTTON_CLICKED,
            eventName: EventName.REFERRAL_CREATE_CODE_BUTTON_CLICKED,
        })
        if (!code) {
            return
        }
        const result = await createCode([code])
        if ("error" in result) {
            analyticsTx.perpReferral.createReferralCode.failed(result.error)
            dispatch(
                open({
                    status: IStatus.error,
                    description: "Create referral code failed.",
                    duration: NOTIFICATION_DURATION.SHORT,
                }),
            )
        } else {
            analyticsTx.perpReferral.createReferralCode.succeeded(result.data)
            refetchReferralCode()
            dispatch(
                open({
                    status: IStatus.success,
                    description: "Create referral code successfully.",
                    duration: NOTIFICATION_DURATION.SHORT,
                }),
            )
        }
    }, [code, createCode, dispatch, open, refetchReferralCode])

    return (
        <Box>
            <VStack align={"stretch"} spacing={12}>
                <Box>
                    <Heading variant="h4">1. Generate and share code</Heading>
                    <Text mt="16px" color="perp.text.article">
                        Share your referral code with other traders and earn rewards. The more traders use your code to
                        trade, the more rewards you can get. You can’t change the code once it's been generated.
                    </Text>
                    <Skeleton isLoaded={!isLoadingReferralCode && !isFetchingReferralCode}>
                        {referralCode?.referrer && (
                            <Flex
                                mt="16px"
                                justifyContent="space-between"
                                bg="perp.background.input"
                                p="16px"
                                borderRadius="12px"
                            >
                                <Text flexGrow="1">{getReferralLink(referralCode.referrer)}</Text>
                                <CopyIcon
                                    cursor="pointer"
                                    boxSize="24px"
                                    onClick={() =>
                                        referralCode?.referrer && copy(getReferralLink(referralCode.referrer))
                                    }
                                />
                            </Flex>
                        )}
                        {!referralCode?.referrer && (
                            <Flex gap="16px" mt="16px">
                                <Input placeholder="Enter your custom code" value={code} onChange={handleChangeCode} />
                                <Button
                                    size="lg"
                                    onClick={handleCreateCode}
                                    isLoading={isTxLoading}
                                    isDisabled={isTxLoading || !code}
                                >
                                    Generate
                                </Button>
                            </Flex>
                        )}
                    </Skeleton>
                </Box>
                <Box>
                    <Heading variant="h4">2. Lock 10+ vePERP</Heading>
                    <Text mt="16px" color="perp.text.article">
                        Both referral partners and referred traders will be required to hold a minimum of 10 vePERP at
                        the time of the weekly snapshot. So if PERP is locked up for 52 weeks, you'd need at least 10
                        PERP. Otherwise, you will need <Code>10 / (n/52)</Code> PERP, where n ={" "}
                        <Code>number of weeks locked up</Code>. All referral rewards are calculated from 00:00 UTC
                        Thursday until 00:00 UTC the next Thursday.
                    </Text>{" "}
                    <Alert my={6} fontSize="14px" borderRadius={"xl"} status="info">
                        <AlertIcon />
                        <Box p={3} color="whiteAlpha.800">
                            Traders/partners using contracts need to delegate to an EOA to be eligible for rewards. If
                            your EOA has more than 1 delegation, the requirement of locked vePERP will change
                            accordingly.{" "}
                            <Link
                                color="perp.link.default"
                                as="a"
                                href="https://docs.perp.com/docs/guides/referral-program-delegation/"
                            >
                                Learn more
                            </Link>
                        </Box>
                    </Alert>
                </Box>
                <Box>
                    <Heading variant="h4">3. Claim your rewards </Heading>
                    <Text mt="16px" color="perp.text.article">
                        After completing steps above, you will receive rewards weekly. To claim rewards you have to lock
                        PERP for at least 4 weeks.
                    </Text>
                    <Text mt="16px" color="perp.text.article">
                        If you have less than 10 vePERP with more than 4-week lock time, you can still claim any
                        available rewards, but receive no further rewards in the following weeks. 
                    </Text>
                </Box>
            </VStack>
            <Divider my="48px" />
        </Box>
    )
}
