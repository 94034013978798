import { Alert, AlertDescription, AlertIcon, CloseButton } from "@chakra-ui/react"
import React, { ReactNode, useEffect } from "react"

import { CheckIcon, ErrorIcon, InfoIcon } from "./icons"

const STATUSES = {
    success: { icon: CheckIcon, colorScheme: "perp.primary.default" },
    error: { icon: ErrorIcon, colorScheme: "perp.support.error" },
    info: { icon: InfoIcon, colorScheme: "perp.support.alert" },
    // loading: { icon: Spinner, colorScheme: "blue" },
}

type Statuses = keyof typeof STATUSES

type PerpAlertProps = {
    isOpen?: boolean
    hasClose?: boolean
    onClose?: () => void
    status?: Statuses
    description: ReactNode
    duration?: number
}

function PerpAlert({
    isOpen = false,
    hasClose = true,
    onClose = () => {},
    status = "success",
    description,
    duration,
}: PerpAlertProps) {
    const BaseIcon = STATUSES[status].icon

    useEffect(() => {
        if (duration) {
            const timer = setTimeout(() => onClose(), duration)
            return () => clearTimeout(timer)
        }
    }, [duration, onClose])

    return (
        <Alert
            data-testid="perp-alert"
            hidden={!isOpen}
            colorScheme={STATUSES[status].colorScheme}
            status={status}
            minHeight="48px"
            background={STATUSES[status].colorScheme}
            color="black"
            w="100%"
        >
            <AlertDescription
                fontWeight={400}
                flex={1}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
            >
                <AlertIcon mr="2">
                    <BaseIcon h="100%" w="100%" />
                </AlertIcon>
                {description}
            </AlertDescription>
            <CloseButton hidden={!hasClose} size="sm" onClick={onClose} />
        </Alert>
    )
}

export { PerpAlert }
export type { Statuses }
