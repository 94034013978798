import {
    ChakraProvider,
    ChakraProviderProps,
    createLocalStorageManager,
    extendTheme,
    ThemeConfig,
} from "@chakra-ui/react"
import "focus-visible/dist/focus-visible"
import React from "react"

import { colors } from "./colors"
import { components } from "./components"
import { fonts } from "./fonts"
import { styles } from "./styles"

const manager = createLocalStorageManager("vePerp-chakra-ui-color-mode")

const config: ThemeConfig = {
    initialColorMode: "dark",
    useSystemColorMode: false,
}

const theme = extendTheme({
    config,
    colors,
    fonts,
    components,
    styles,
})

function ThemeProvider({ children }: ChakraProviderProps) {
    return (
        <ChakraProvider theme={theme} colorModeManager={manager}>
            {children}
        </ChakraProvider>
    )
}

export { ThemeProvider }
