import {
    Modal as ChakraModal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Portal,
    VStack,
} from "@chakra-ui/react"
import Big from "big.js"
import { IncreaseAmountData, LockerIncreaseAmount } from "components/Locker/LockerIncreseAmount"
import { Header } from "components/Modal/Header"
import { EventGroup, EventName, EventTypeInteraction } from "modules/analytics"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { analyticsClient } from "services/AnalyticsClient"
import { useAppSelector } from "store"

import { referralSlice } from "../../slice"
import { LockerInfoPreview } from "./LockerInfoPreview"
import { useLockerInfoPreview } from "./useLockerInfoPreview"

const INIT_PERP_INFO_PREVIEW = { lockAmount: Big(0) }

export function ModalIncreaseAmount() {
    const { isOpen, size, motion } = useAppSelector(state => state.referral.modal.increaseAmountByMyStatus)

    const dispatch = useDispatch()
    const { closeModalIncreaseAmountByMyStatus } = referralSlice.actions
    const { lockerInfoPreview, setLockerInfoPreview } = useLockerInfoPreview(INIT_PERP_INFO_PREVIEW)

    const handleClick = useCallback(({ lockAmount }: IncreaseAmountData) => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.BUTTON_CLICKED,
            eventName: EventName.REFERRAL_INCREASE_AMOUNT_BY_MY_STATUS_BUTTON_CLICKED,
            payload: { lockAmount: lockAmount.toString() },
        })
    }, [])

    const handleClose = useCallback(() => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.MODAL_CLOSED,
            eventName: EventName.REFERRAL_INCREASE_AMOUNT_BY_MY_STATUS_MODAL_CLOSED,
        })
        dispatch(closeModalIncreaseAmountByMyStatus())
        setLockerInfoPreview(INIT_PERP_INFO_PREVIEW)
    }, [closeModalIncreaseAmountByMyStatus, dispatch, setLockerInfoPreview])

    return (
        <Portal>
            <ChakraModal motionPreset={motion} onClose={handleClose} size={size} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent
                    bg="perp.background.background"
                    color="perp.text.primary"
                    boxShadow="0px 20px 20px 0px rgba(0, 0, 0, 0.15)"
                >
                    <Header onCloseButtonClick={handleClose}>Lock PERP</Header>
                    <ModalBody>
                        <VStack spacing={6} align="stretch">
                            <LockerIncreaseAmount
                                onClick={handleClick}
                                onFinished={handleClose}
                                onChange={setLockerInfoPreview}
                            />
                            <LockerInfoPreview {...lockerInfoPreview} />
                        </VStack>
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </ChakraModal>
        </Portal>
    )
}
