import { Icon, IconProps } from "@chakra-ui/react"
import React from "react"

export function ETHIcon({ ...props }: IconProps) {
    return (
        <Icon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                fill="#627EEA"
            />
            <g style={{ mixBlendMode: "multiply" }}>
                <path d="M15.9982 5V13.1306L22.9953 16.2014L15.9982 5Z" fill="#343434" />
                <path d="M15.9982 5L9 16.2014L15.9982 13.1306V5Z" fill="#8C8C8C" />
                <path d="M15.9982 21.4704V26.9952L23 17.4811L15.9982 21.4704Z" fill="#3C3C3B" />
                <path d="M15.9982 26.9952V21.4695L9 17.4811L15.9982 26.9952Z" fill="#8C8C8C" />
                <path d="M15.9982 20.1916L22.9953 16.2015L15.9982 13.1325V20.1916Z" fill="#141414" />
                <path d="M9 16.2015L15.9982 20.1916V13.1325L9 16.2015Z" fill="#393939" />
            </g>
        </Icon>
    )
}
