/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type { IIndexPrice, IIndexPriceInterface } from "../IIndexPrice";

const _abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "interval",
        type: "uint256",
      },
    ],
    name: "getIndexPrice",
    outputs: [
      {
        internalType: "uint256",
        name: "indexPrice",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class IIndexPrice__factory {
  static readonly abi = _abi;
  static createInterface(): IIndexPriceInterface {
    return new utils.Interface(_abi) as IIndexPriceInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IIndexPrice {
    return new Contract(address, _abi, signerOrProvider) as IIndexPrice;
  }
}
