import React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

function BigPlusIcon({ ...props }: IconProps) {
    return (
        <Icon width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.75 12.25H26.25V26.25H12.25V29.75H26.25V43.75H29.75V29.75H43.75V26.25H29.75V12.25Z"
                fill="white"
                fillOpacity="0.8"
            />
        </Icon>
    )
}

export { BigPlusIcon }
