import { Icon, IconProps } from "@chakra-ui/react"
import React from "react"

export function USDCIcon({ ...props }: IconProps) {
    return (
        <Icon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_2_4218)">
                <path
                    d="M16 32C24.8667 32 32 24.8667 32 16C32 7.13328 24.8667 0 16 0C7.13328 0 0 7.13328 0 16C0 24.8667 7.13328 32 16 32Z"
                    fill="#4591E4"
                />
                <g style={{ mixBlendMode: "multiply" }} opacity="0.8">
                    <path
                        d="M20.4 18.5333C20.4 16.2 19 15.4 16.2 15.0667C14.2 14.8 13.8 14.2667 13.8 13.3333C13.8 12.3998 14.4667 11.8 15.8 11.8C17 11.8 17.6667 12.2 18 13.2C18.0667 13.4 18.2667 13.5333 18.4667 13.5333H19.5333C19.8 13.5333 20 13.3333 20 13.0667V13C19.7333 11.5333 18.5333 10.4 17 10.2667V8.66673C17 8.40001 16.8 8.20001 16.4667 8.13329H15.4667C15.2 8.13329 15 8.33329 14.9333 8.66673V10.2C12.9333 10.4667 11.6667 11.8 11.6667 13.4667C11.6667 15.6667 13 16.5333 15.8 16.8667C17.6667 17.2 18.2667 17.6 18.2667 18.6667C18.2667 19.7334 17.3333 20.4667 16.0667 20.4667C14.3333 20.4667 13.7333 19.7333 13.5333 18.7333C13.4667 18.4667 13.2667 18.3333 13.0667 18.3333H11.9333C11.6667 18.3333 11.4667 18.5333 11.4667 18.8V18.8667C11.7333 20.5333 12.8 21.7333 15 22.0667V23.6667C15 23.9333 15.2 24.1333 15.5333 24.2H16.5333C16.8 24.2 17 24 17.0667 23.6667V22.0667C19.0667 21.7333 20.4 20.3333 20.4 18.5333Z"
                        fill="#141319"
                    />
                </g>
                <g style={{ mixBlendMode: "multiply" }} opacity="0.8">
                    <path
                        d="M12.6 25.5333C7.40002 23.6667 4.7333 17.8667 6.66674 12.7333C7.66674 9.93328 9.86674 7.8 12.6 6.8C12.8667 6.66672 13 6.46672 13 6.13328V5.2C13 4.93328 12.8667 4.73328 12.6 4.66672C12.5333 4.66672 12.4 4.66672 12.3333 4.73328C6.00002 6.73328 2.5333 13.4667 4.5333 19.8C5.7333 23.5333 8.60002 26.4 12.3333 27.6C12.6 27.7333 12.8667 27.6 12.9333 27.3333C13 27.2667 13 27.2 13 27.0667V26.1333C13 25.9333 12.8 25.6667 12.6 25.5333ZM19.6667 4.73328C19.4 4.6 19.1333 4.73328 19.0667 5C19 5.06672 19 5.13328 19 5.26672V6.2C19 6.46672 19.2 6.73328 19.4 6.86672C24.6 8.73328 27.2667 14.5333 25.3333 19.6667C24.3333 22.4667 22.1333 24.6 19.4 25.6C19.1333 25.7333 19 25.9333 19 26.2667V27.2C19 27.4667 19.1333 27.6667 19.4 27.7333C19.4667 27.7333 19.6 27.7333 19.6667 27.6667C26 25.6667 29.4667 18.9333 27.4667 12.6C26.2667 8.8 23.3333 5.93328 19.6667 4.73328Z"
                        fill="#141319"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_2_4218">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </Icon>
    )
}
