export interface IEmissionFeeRewardsSnapshot {
    [key: string]: {
        weekStart: number
        weekEnd: number
        weekStartISO: string
        weekEndISO: string
        totalReward: string
        totalRewardWei: string
        totalFee: string
        perp7dTwap: string
        updatedAt: string
    }
}

export interface IVePERPDistributorRewardsSnapshot {
    [key: string]: {
        weekStart: number
        weekEnd: number
        weekStartISO: string
        weekEndISO: string
        totalReward: string
        totalRewardWei: string
        totalFee: string
        perp7dTwap: string
        updatedAt: string
    }
}

/**
 * NOTE: since referral v10 will continue to use the same snapshot endpoint,
 * the totalReferrerVolume, totalReferrerVePERPBalance, and totalReferrerReward need to be optional
 */
export interface IReferralRewardsSnapshot {
    [key: string]: {
        weekStart: number
        weekEnd: number
        weekStartISO: string
        weekEndISO: string
        weekOrdinal: number
        totalReward: string
        totalRewardWei: string
        hash: string
        rewardedAccounts: number
        rewardMap: string
        refereeMap: string
        referrerMap: string
        totalReferrerVolume?: string
        totalReferrerVePERPBalance?: string
        totalReferrerReward?: string
        updatedAt: string
    }
}

export interface IGeoBlockInfo {
    isBlock: boolean
    countryCode: string
}

export class AWSClient {
    async getServerTimestamp(): Promise<number> {
        const apiUrl = "https://rz9g25ah3a.execute-api.ap-southeast-1.amazonaws.com/production/"
        const result = (await fetch(apiUrl).then(res => res.json())) as { time: string }
        const timeString = result.time || new Date().valueOf()
        return Math.floor(new Date(timeString).valueOf() / 1000)
    }

    // TODO: remove this function when we launch lazy river 2.0
    async getVePERPEmissionFeeDistributorRewardsSnapshot(isProd: boolean): Promise<IEmissionFeeRewardsSnapshot> {
        const apiUrl = isProd
            ? "https://s3.us-west-1.amazonaws.com/reward-snapshot.perp.com/veperp-emission/production/snapshot.json"
            : "https://s3.us-west-1.amazonaws.com/reward-snapshot.perp.com/veperp-emission/staging/snapshot.json"
        return await fetch(apiUrl).then(res => res.json())
    }

    async getVePERPDistributorRewardsSnapshot(isProd: boolean): Promise<IVePERPDistributorRewardsSnapshot> {
        const apiUrl = isProd
            ? "https://s3.us-west-1.amazonaws.com/reward-snapshot.perp.com/veperp-emission/production/snapshot.json"
            : "https://s3.us-west-1.amazonaws.com/reward-snapshot.perp.com/veperp-emission/staging/snapshot.json"
        return await fetch(apiUrl).then(res => res.json())
    }

    async getReferralRewardsSnapshot(isProd: boolean): Promise<IReferralRewardsSnapshot> {
        const apiUrl = isProd
            ? "https://s3.us-west-1.amazonaws.com/reward-snapshot.perp.com/referral/production/snapshot.json"
            : "https://s3.us-west-1.amazonaws.com/reward-snapshot.perp.com/referral/staging/snapshot.json"
        return await fetch(apiUrl).then(res => res.json())
    }

    async getGeoBlockInfo(): Promise<IGeoBlockInfo> {
        const apiUrl = "https://api.perp.exchange/geolocation-block"
        return await fetch(apiUrl).then(res => res.json())
    }
}

export const awsClient = new AWSClient()
