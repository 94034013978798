import { Box } from "@chakra-ui/react"
import { Article } from "components/Article"
import React from "react"
import { LazyRiverAllRewardsStatus } from "./LazyRiverAllRewardsStatus"

export function LazyRiverContent() {
    return (
        <Box>
            <LazyRiverAllRewardsStatus />
            <Article route="lazy-river-v2" />
        </Box>
    )
}
