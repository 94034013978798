import { EPOCH } from "constants/config"
import { formatDate } from "utils/format"
import { getCalibratedTime, getWeeksDiff } from "utils/time"

import { IReferralRewards } from "./flow"

export const selectSoonestUnlockDate = (timestamp?: number, lockEndTimestamp?: number) => {
    return (result: { data?: IReferralRewards }) => {
        if (!result.data || !timestamp || !lockEndTimestamp) {
            return {}
        }
        const { minLockDuration } = result.data
        const soonestUnlockTimestamp = getCalibratedTime(timestamp, EPOCH) + minLockDuration
        const soonestUnlockDate = formatDate(soonestUnlockTimestamp * 1000)
        const weeksToSoonestUnlockDate = getWeeksDiff(lockEndTimestamp, soonestUnlockTimestamp)
        return {
            soonestUnlockDate,
            weeksToSoonestUnlockDate,
        }
    }
}

export const selectDuration = (result: { data?: IReferralRewards }) => {
    if (!result.data) {
        return {}
    }
    const { minLockDuration } = result.data
    const minLockDurationInWeeks = minLockDuration && Math.ceil(minLockDuration / (86400 * 7))
    return {
        minLockDuration,
        minLockDurationInWeeks,
    }
}
