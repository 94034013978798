import { Box } from "@chakra-ui/react"
import Big from "big.js"
import { LockedPERPIcon } from "components/Icons/Tokens/LockedPERPIcon"
import { PerpWalletConnectorContainer } from "hooks/containers/usePerpWalletConnectorContainer"
import React from "react"

import { useReferralRewardsQuery } from "../flow"
import { useReferralClaim } from "../hook/useReferralClaim"
import { ReferralClaimCard } from "./ReferralClaimCard"

export function ReferralMobileMenu() {
    const { account } = PerpWalletConnectorContainer.useContainer()
    const { data, isLoading } = useReferralRewardsQuery({ account: account! }, { skip: !account })
    const { onReferralClaim } = useReferralClaim()
    return (
        <Box
            position="fixed"
            left="0"
            bottom="0"
            width="100%"
            background="perp.background.background"
            height="114px"
            color="perp.text.primary"
            borderTop={"1px solid"}
            borderColor="perp.background.border"
            borderRadius={"16px"}
            display="flex"
            justifyContent="center"
            alignItems="center"
            p="16px"
            zIndex={1}
        >
            <ReferralClaimCard
                icon={<LockedPERPIcon boxSize="48px" />}
                title="Locked PERP"
                amount={data?.totalBalance ? data.totalBalance : Big(0)}
                onClick={onReferralClaim}
                isLoading={isLoading}
            />
        </Box>
    )
}
