import { Box, Slide, useOutsideClick } from "@chakra-ui/react"
import { PerpAlert } from "components/PerpAlert"
import React, { RefObject, useRef } from "react"
import { useDispatch } from "react-redux"
import { useAppSelector } from "store"

import { notificationSlice } from "../slice"

function Notification() {
    const ref = useRef<any>()
    const { close } = notificationSlice.actions
    const dispatch = useDispatch()
    const { status, description, isOpen, duration } = useAppSelector(state => state.notification)
    useOutsideClick({
        ref: ref as RefObject<HTMLElement>,
        handler: () => close(),
    })

    return (
        <Box position={"fixed"} zIndex={3} left={0} right={0} top={0}>
            <Slide direction="top" in={!!isOpen} unmountOnExit={true} ref={ref}>
                <PerpAlert
                    onClose={() => dispatch(close())}
                    status={status}
                    isOpen={isOpen}
                    description={description}
                    duration={duration}
                />
            </Slide>
        </Box>
    )
}

export { Notification }
