const styles = {
    global: {
        body: {
            fontFamily: "body",
        },
        // Styling for rich text from CMS
        ".rich-text": {
            table: {
                width: "100%",
                borderColor: "perp.background.border",
                borderWidth: "1px",
                td: {
                    borderWidth: "1px",
                    p: 4,
                    borderColor: "perp.background.border",
                },
            },
            color: "perp.text.primary",
            lineHeight: "body",
            "strong, b": {
                fontWeight: "semibold",
            },
            img: {
                ml: 0,
            },
            h1: {
                marginTop: 12,
                marginBottom: 8,
                fontSize: "48px",
                lineHeight: "56px",
                fontWeight: "medium",
            },
            h2: {
                marginTop: 12,
                fontSize: "32px",
                lineHeight: "40px",
                fontWeight: "medium",
            },
            // the goal is to make first child no margin top, first-of-type will break other headings
            // and we don't use ssr so please disable that warning
            "h1:first-child, h2:first-child, h3:first-child": {
                marginTop: 0,
            },
            h3: {
                marginTop: 12,
                fontSize: "24px",
                lineHeight: "32px",
                fontWeight: "medium",
            },
            h4: {
                marginTop: 12,
                fontSize: "20px",
                lineHeight: "24px",
                fontWeight: "medium",
            },
            h5: {
                marginTop: 12,
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: "medium",
            },
            h6: {
                marginTop: 12,
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: "medium",
            },
            p: {
                color: "perp.text.article",
            },
            li: {
                marginLeft: 8,
                color: "perp.text.article",
                mb: 2,
            },
            a: {
                color: "perp.link.default",
            },
            "a:hover": { color: "perp.link.default", textDecoration: "underline" },
            hr: {
                borderColor: "perp.background.border",
                marginTop: 12,
                marginBottom: 12,
            },
            pre: {
                borderRadius: "sm",
                fontSize: "sm",
                padding: 6,
                overflowX: "scroll",
                background: "perp.background.card",
            },
            blockquote: {
                borderColor: "perp.text.disable",
                borderLeftWidth: 3,
                paddingLeft: 5,
                paddingTop: 2,
                paddingBottom: 2,
                fontWeight: "medium",

                footer: {
                    fontSize: "sm",
                    opacity: 0.65,
                },
            },
        },

        ".rich-text > *": {
            marginLeft: "auto",
            marginBottom: 6,
        },
    },
}

export { styles }
