import { Grid, Skeleton } from "@chakra-ui/react"
import React from "react"

export function LoadingCard() {
    return (
        <Grid w="100%" gap="16px">
            <Skeleton h="20px" />
            <Skeleton h="20px" />
            <Skeleton h="20px" />
            <Skeleton h="20px" />
            <Skeleton h="20px" w="80%" />
        </Grid>
    )
}
