import React, { ReactNode } from "react"
import { Grid, Text, Box, Skeleton } from "@chakra-ui/react"

interface PerpInfoRowProps {
    title: string
    content: ReactNode
    isLoading?: boolean
}

function PerpInfoRow({ title, content, isLoading }: PerpInfoRowProps) {
    return (
        <Grid w="100%" justifyContent="space-between" templateColumns="auto auto" alignItems="center">
            <Text color="perp.text.secondary">{title}</Text>
            <Skeleton isLoaded={!isLoading}>
                <Box>{content}</Box>
            </Skeleton>
        </Grid>
    )
}

export { PerpInfoRow }
