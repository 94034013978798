import React, { useCallback } from "react"
import { Box, Text } from "@chakra-ui/react"
import { switchNetworkForInjectedProvider } from "../../utils/switchNetwork"
import { useSupportedChain } from "../../hooks/useSupportedChain"
import { useLocalStorage } from "react-use"

function ContentSwitchNetwork() {
    const { name, id } = useSupportedChain()
    const [walletId, _setWalletId] = useLocalStorage("wallet-id", "")

    const handleSwitchButtonClick = useCallback(() => {
        if (walletId === "METAMASK_WALLET") {
            switchNetworkForInjectedProvider(id)
        }
    }, [id, walletId])

    return (
        <Box display="flex">
            You have to switch to {name} chain.{" "}
            {isSupportSwitchNetwork(walletId || "") && (
                <Text decoration="underline" ml="4px" cursor="pointer" onClick={handleSwitchButtonClick}>
                    Click to switch
                </Text>
            )}
        </Box>
    )
}

export { ContentSwitchNetwork }

function isSupportSwitchNetwork(walletId: string) {
    return walletId === "METAMASK_WALLET"
}
