import { Box, Flex, Heading, Image, Skeleton, Text } from "@chakra-ui/react"
import arrowRightSvg from "assets/images/arrow-right.svg"
import Big from "big.js"
import { OPIcon } from "components/Icons/Tokens/OPIcon"
import { PERPIcon } from "components/Icons/Tokens/PERPIcon"
import { MotionVStack } from "components/Motion"
import { useQuery } from "graphql-hooks"
import { PerpWalletConnectorContainer } from "hooks/containers/usePerpWalletConnectorContainer"
import { EventGroup, EventName, EventTypeInteraction } from "modules/analytics"
import { useLMRewardsQuery } from "modules/liquidityMining"
import { useReferralRewardsQuery } from "modules/referral"
import React, { ReactNode, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { analyticsClient } from "services/AnalyticsClient"
import { formatNumber } from "utils/format"

import { LockedPERPIcon } from "components/Icons/Tokens/LockedPERPIcon"
import { USDCIcon } from "components/Icons/Tokens/USDCIcon"
import { useUsdcFeeDistributorClaim } from "modules/usdcFeeDistributor"
import { useVePERPDistributorClaim } from "modules/vePerpDistributor"
import { LoadingCard } from "./LoadingCard"

const PROGRAMS_QUERY = `query Programs {
    programs() {
      id
      title
      stage
      slug
      icon {
        url
      }
      summary
      description {
        text
      }
    }
}`

function getRedirectTo(slug: string) {
    switch (slug) {
        // NOTE: referralv10 page is equal to referral page
        case "referralv10":
            return "/referral"
        case "pool-party":
            return "/pool-party"
        case "lazy-river-v2":
            return "/lazy-river"
        default:
            return "/"
    }
}

export function ProgramCards() {
    const { account } = PerpWalletConnectorContainer.useContainer()
    const { data: referralRewardData, isLoading: isReferralRewardLoading } = useReferralRewardsQuery(
        { account: account! },
        { skip: !account },
    )
    const { data: LMRewardsData, isLoading: isLMRewardsLoading } = useLMRewardsQuery(
        { account: account! },
        { skip: !account },
    )
    const { data: cmsData, loading: isCMSLoading } = useQuery(PROGRAMS_QUERY)
    const { data: usdcFeeDistributorData, isLoading: usdcFeeDistributorIsLoading } = useUsdcFeeDistributorClaim()
    const { data: vePERPDistributorData, isLoading: vePERPDistributorIsLoading } = useVePERPDistributorClaim()

    const getRewards = useCallback(
        (slug: string) => {
            switch (slug) {
                case "referralv10":
                    return [
                        {
                            icon: <LockedPERPIcon boxSize="16px" />,
                            amount: referralRewardData?.totalBalance,
                            isLoading: isReferralRewardLoading,
                        },
                    ]
                case "pool-party":
                    return [
                        {
                            icon: <PERPIcon boxSize="16px" />,
                            amount: LMRewardsData?.totalBalance,
                            isLoading: isLMRewardsLoading,
                        },
                        {
                            icon: <OPIcon boxSize="16px" />,
                            amount: LMRewardsData?.totalBalanceOp,
                            isLoading: isLMRewardsLoading,
                        },
                    ]
                case "lazy-river-v2":
                    return [
                        {
                            icon: <USDCIcon boxSize="16px" />,
                            amount: usdcFeeDistributorData?.amount,
                            isLoading: usdcFeeDistributorIsLoading,
                        },
                        {
                            icon: <LockedPERPIcon boxSize="16px" />,
                            amount: vePERPDistributorData?.amount,
                            isLoading: vePERPDistributorIsLoading,
                        },
                    ]
                default:
                    return [
                        {
                            icon: <PERPIcon boxSize="16px" />,
                            isLoading: false,
                        },
                    ]
            }
        },
        [
            referralRewardData?.totalBalance,
            isReferralRewardLoading,
            LMRewardsData?.totalBalance,
            LMRewardsData?.totalBalanceOp,
            isLMRewardsLoading,
            usdcFeeDistributorData?.amount,
            usdcFeeDistributorIsLoading,
            vePERPDistributorData?.amount,
            vePERPDistributorIsLoading,
        ],
    )

    return (
        <>
            {isCMSLoading && <LoadingCard />}
            {cmsData?.programs &&
                cmsData.programs.map((program: any) => (
                    <ProgramCard
                        key={program.id}
                        title={program.title}
                        imgUrl={program.icon.url}
                        description={program.summary}
                        rewards={getRewards(program.slug)}
                        redirectTo={getRedirectTo(program.slug)}
                    />
                ))}
        </>
    )
}

interface ProgramCardProps {
    title: string
    description: string
    rewards?: {
        icon: ReactNode
        amount?: Big
        isLoading: boolean
    }[]
    imgUrl?: string
    redirectTo: string
}

function ProgramCard({ title, description, rewards, redirectTo, imgUrl }: ProgramCardProps) {
    const navigate = useNavigate()
    const handleClick = useCallback(() => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventName: EventName.HOME_PROGRAM_CARD_CLICKED,
            eventType: EventTypeInteraction.BUTTON_CLICKED,
        })
        navigate(redirectTo)
    }, [navigate, redirectTo])
    return (
        <MotionVStack
            whileTap={{ y: 2, scale: 0.98 }}
            whileHover={{ y: -6 }}
            align={"stretch"}
            bgColor="black"
            borderRadius="16px"
            cursor="pointer"
            onClick={handleClick}
        >
            <Flex justify="space-between" align="center" py="24px" px="24px">
                <Heading variant="h4">{title}</Heading>
                <Box>
                    <Image src={imgUrl} borderRadius="16px" h={"32px"} w={"32px"} />
                </Box>
            </Flex>
            <Box flexGrow={1} px="24px" pb="24px">
                <Text>{description}</Text>
            </Box>

            <Flex justify="space-between" px="24px" h="56px" borderTop="1px solid" borderColor="perp.background.border">
                <Flex>
                    {rewards &&
                        rewards.map((reward, index) => (
                            <Flex mr="8px" alignItems="center" key={index} gap="4px">
                                {reward.icon}
                                <Skeleton minH="12px" minW="30px" isLoaded={!reward.isLoading}>
                                    <Text ml="4px" variant="body2" opacity={rewards ? 1 : 0.5}>
                                        {reward.amount ? formatNumber(reward.amount) : "--"}
                                    </Text>
                                </Skeleton>
                            </Flex>
                        ))}
                </Flex>
                <Flex align="center">
                    <Text mr="7px" variant="body2" fontWeight="bold">
                        Join
                    </Text>
                    <Image w="24px" h="24px" src={arrowRightSvg} />
                </Flex>
            </Flex>
        </MotionVStack>
    )
}
