import { Box, Grid, SimpleGrid, Skeleton, Text, VStack } from "@chakra-ui/react"
import { RichText } from "@graphcms/rich-text-react-renderer"
import React from "react"

import { useProgramDetail } from "../../hooks/useCMS"

/**
 *
 * This is the article for program details.
 * @export
 * @param {string} route string should be the same as react-router
 * @return {*}
 */
export function Article(props: { route: String }) {
    const { data, loading } = useProgramDetail(props.route)

    return (
        <Box className="rich-text" my={12}>
            {loading && (
                <Grid w="100%" gap="16px">
                    <Skeleton h="30px" />
                    <Skeleton h="30px" />
                    <Skeleton h="30px" />
                    <Skeleton h="30px" w="80%" />
                </Grid>
            )}
            {!loading && data && data.program && (
                <RichText
                    references={data.program.description.references}
                    renderers={{
                        embed: {
                            EligibilityBlock: items => {
                                return <Eligibility data={items} />
                            },
                        },
                    }}
                    content={data.program.description.json}
                />
            )}
        </Box>
    )
}

export function Eligibility(data: any) {
    const eligibility = data.data.eligibility
    return (
        <SimpleGrid mt={12} columns={2} borderColor="perp.background.border" borderTopWidth={1} borderBottomWidth={1}>
            {eligibility.map((item: { id: string; description: string; title: string }, index: number) => (
                <VStack
                    py={6}
                    pl={index >= 1 ? 6 : 0}
                    borderLeft={index >= 1 ? "1px" : "none"}
                    borderColor="perp.background.border"
                    spacing={1}
                    key={item.id}
                    align="flex-start"
                >
                    <Text fontSize={"sm"}>{item.title}</Text>
                    <h4>{item.description}</h4>
                </VStack>
            ))}
        </SimpleGrid>
    )
}
