import { METADATA } from "constants/env"
import { bigNum2Big } from "utils"
import { ContractClientFactory, contractClientFactory } from "./ContractClientFactory"

export class VaultClient {
    private readonly contractClientFactory
    private readonly contractAddress

    constructor(contractClientFactory: ContractClientFactory, contractAddress: string) {
        this.contractClientFactory = contractClientFactory
        this.contractAddress = contractAddress
    }

    getContract() {
        return this.contractClientFactory.getVault(this.contractAddress)
    }

    async getFreeCollateralByToken(address: string, tokenAddress: string, decimals: number = 18) {
        const contract = this.getContract()
        return bigNum2Big(await contract.getFreeCollateralByToken(address, tokenAddress), decimals)
    }
}

export const vaultClient = new VaultClient(contractClientFactory, METADATA.CORE.contracts.Vault.address)
