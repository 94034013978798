import React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

function CloseIcon({ ...props }: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" boxSize="24px" fill="none" {...props}>
            <path
                d="M11.9998 13.0604L17.9389 18.9995L18.9995 17.9389L13.0604 11.9998L18.9995 6.06066L17.9389 5L11.9998 10.9391L6.06066 5L5 6.06066L10.9391 11.9998L5 17.9389L6.06066 18.9995L11.9998 13.0604Z"
                fill="currentColor"
            />
        </Icon>
    )
}

export { CloseIcon }
