import { EPOCH, TOKEN_CHECKPOINT_DEADLINE } from "constants/config"
import { useGetServerTimestampQuery } from "services/AWSClient/flow"
import { getCalibratedTime } from "utils"
import { useUsdcFeeDistributorInfoQuery } from "../../usdcFeeDistributor/flow"

export function useLastTokenTimeDeviation() {
    const { data } = useGetServerTimestampQuery()
    const currentTimestamp = data && data.timestamp
    const currentWeekStartTime = currentTimestamp && getCalibratedTime(currentTimestamp, EPOCH)

    const { data: usdcFeeDistributorInfo } = useUsdcFeeDistributorInfoQuery()
    const lastTokenTime = usdcFeeDistributorInfo && usdcFeeDistributorInfo.lastTokenTime

    // if lastTokenTime is in this week, then epochDeviation is 1, otherwise 2
    const epochDeviation = currentWeekStartTime && lastTokenTime && lastTokenTime >= currentWeekStartTime ? 1 : 2

    const adjustedWeekStartTime =
        currentWeekStartTime && epochDeviation === 2 ? currentWeekStartTime - EPOCH : currentWeekStartTime

    const nextDistributionTime = lastTokenTime && lastTokenTime + TOKEN_CHECKPOINT_DEADLINE

    const nextDistributionTimeUtcString = nextDistributionTime && new Date(nextDistributionTime * 1000).toUTCString()

    //   old epoch | new epoch (^ means last token time)
    // -----^------|------^------
    // only show nextDistributionTimeUtcString if lastTokenTime plus TOKEN_CHECKPOINT_DEADLINE will cross the new epoch
    const newEpoch = currentWeekStartTime && epochDeviation === 1 ? currentWeekStartTime + EPOCH : currentWeekStartTime
    const isNextCheckpointTimeAcrossNewEpoch = !!(
        lastTokenTime &&
        newEpoch &&
        lastTokenTime < newEpoch &&
        lastTokenTime + TOKEN_CHECKPOINT_DEADLINE >= newEpoch
    )

    return {
        lastTokenTime,
        epochDeviation,
        nextDistributionTimeUtcString,
        adjustedWeekStartTime,
        isNextCheckpointTimeAcrossNewEpoch,
    }
}
