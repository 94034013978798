import { Box, Button, Grid, GridItem, HStack, Skeleton, Text, VStack } from "@chakra-ui/react"
import Big from "big.js"
import React from "react"
import { formatNumber } from "utils/format"

import { ModalClaim } from "./ModalClaim"
import { ModalCreateLock } from "./ModalCreateLock"
import { ModalExpired } from "./ModalExpired"
import { ModalExtendTime } from "./ModalExtendTime"

interface ReferralClaimCardProps {
    icon: JSX.Element
    title: string
    amount?: Big
    onClick: () => void
    isLoading: boolean
}

function ReferralClaimCard({ icon, title, amount, onClick, isLoading }: ReferralClaimCardProps) {
    const isDisabled = isLoading || !amount || amount.lte(0)

    return (
        <Grid minW={"300px"} templateColumns="1fr auto" w="100%" gap={"16px"}>
            <GridItem>
                <HStack spacing={4}>
                    {icon}
                    <VStack spacing={0} align={"stretch"}>
                        <Text fontSize="14px" color="perp.text.secondary">
                            {title}
                        </Text>
                        {isLoading && (
                            <Box h="24px">
                                <Skeleton w="70%" h="16px" />
                            </Box>
                        )}
                        {!isLoading && amount && <Text fontSize="16px">{formatNumber(amount)}</Text>}
                        {!isLoading && !amount && <Text fontSize="16px">-,--</Text>}
                    </VStack>
                </HStack>
            </GridItem>
            <GridItem>
                <Button onClick={onClick} disabled={isDisabled}>
                    Claim
                </Button>
            </GridItem>
            <ModalCreateLock />
            <ModalExpired />
            <ModalExtendTime />
            <ModalClaim />
        </Grid>
    )
}

export { ReferralClaimCard }
