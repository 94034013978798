import { Box, Grid, GridItem, HStack, Slider, SliderFilledTrack, SliderThumb, SliderTrack } from "@chakra-ui/react"
import React from "react"

// NOTE: chakra will round the value. for supporting floating-point number
const SCALE = 100

export type LockerSliderProps = {
    step: number
    from: number
    to: number
    value: number
    isDisabled?: boolean
    onChange: (v: number) => void
}

function LockerSlider({ step, from, to, value, isDisabled = false, onChange }: LockerSliderProps) {
    const scaledFrom = from * SCALE
    const scaledTo = to * SCALE
    const handleOnChange = (v: number) => {
        const result = v / SCALE
        onChange(result)
    }

    return (
        <Box bgColor="perp.background.input" borderRadius="12px" color="perp.text.primary" p="16px" w="100%">
            <Grid templateColumns="repeat(5, 1fr)" gap={4} pb={3}>
                <GridItem colSpan={2}>Lock Time</GridItem>
                <GridItem
                    colStart={4}
                    colEnd={6}
                    color={value > 0 ? "perp.text.primary" : "perp.text.secondary"}
                    textAlign={"right"}
                >
                    {value} week(s)
                </GridItem>
            </Grid>

            <HStack spacing={0}>
                <Box width="12px" h="4px" bg="perp.primary.default" borderLeftRadius={"full"} />
                <Slider
                    min={scaledFrom}
                    max={scaledTo}
                    step={step * SCALE}
                    aria-label="slider-ex-1"
                    defaultValue={value ? value * SCALE : 0}
                    isDisabled={isDisabled}
                    onChange={handleOnChange}
                >
                    <SliderTrack borderRadius="none">
                        <SliderFilledTrack borderRadius="none" />
                    </SliderTrack>
                    <SliderThumb boxSize={6} />
                </Slider>
                <Box width="12px" h="4px" bg="rgb(38,38, 45)" borderRightRadius={"full"} />
            </HStack>
        </Box>
    )
}

export { LockerSlider }
