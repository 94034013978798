import { Box, Grid, Heading } from "@chakra-ui/react"
import { LockedPERPIcon } from "components/Icons/Tokens/LockedPERPIcon"
import { PerpWalletConnectorContainer } from "hooks/containers/usePerpWalletConnectorContainer"
import React from "react"

import { useReferralRewardsQuery } from "../flow"
import { useReferralClaim } from "../hook/useReferralClaim"
import { ReferralClaimCard } from "./ReferralClaimCard"

function ReferralControllerPanel() {
    const { account } = PerpWalletConnectorContainer.useContainer()
    const { data, isLoading, isFetching } = useReferralRewardsQuery({ account: account! }, { skip: !account })
    const { onReferralClaim } = useReferralClaim()

    return (
        <Grid
            gap="16px"
            color="perp.text.primary"
            bgColor="perp.background.background"
            border="1px solid"
            borderRadius={12}
            borderColor="perp.background.border"
            px={6}
            py={{ base: 6, lg: 8 }}
            boxShadow="0px 20px 20px 0px rgba(0, 0, 0, 0.15)"
            style={{ position: "sticky", top: "120px" }}
        >
            <Heading variant="h4">Your Rewards</Heading>
            <Box bg="perp.background.card" borderRadius="12px" p="16px" alignContent="center">
                <ReferralClaimCard
                    icon={<LockedPERPIcon boxSize="48px" />}
                    title="Locked PERP"
                    amount={data?.totalBalance}
                    onClick={onReferralClaim}
                    isLoading={isLoading || isFetching}
                />
            </Box>
        </Grid>
    )
}

export { ReferralControllerPanel }
