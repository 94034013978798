import { Grid, GridItem } from "@chakra-ui/react"
import "@fontsource/inter/400.css"
import "@fontsource/inter/500.css"
import "@fontsource/inter/600.css"
import "@fontsource/inter/700.css"
import { Fonts } from "components/Fonts"
import { Footer } from "components/Footer"
import { GeoblockContent } from "components/GeoBlockContainer"
import { TopAppBanner } from "components/TopAppBanner"
import { usePageTracker } from "modules/analytics"
import { Notification } from "modules/notification"
import React, { Suspense, useRef } from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import { Banner } from "../components/Banner"
import { Header } from "../components/Header"
import { PerpLoading } from "../components/PerpLoading"
import ScrollToTop from "../components/ScrollToTop"
import { Home } from "./Home/Loadable"
import { LazyRiver } from "./LazyRiver/Loadable"
import { LiquidityMining } from "./LiquidityMining/Loadable"
import { Referral } from "./Referral/Loadable"
export function App() {
    usePageTracker()
    const bodyRef = useRef<HTMLDivElement>(null)

    return (
        <GeoblockContent>
            <Fonts />
            <Grid bgColor="perp.background.background" templateRows="auto 1fr">
                <TopAppBanner />
                <Banner />
                <Header />
                <GridItem ref={bodyRef}>
                    <Suspense fallback={<PerpLoading />}>
                        <ScrollToTop target={bodyRef}>
                            <Routes>
                                <Route path="/" element={<Home />} />
                                {/* NOTE: legacy route redirection */}
                                <Route path="/referralv10/*" element={<Navigate to="/referral" />} />
                                <Route path="/referral/*" element={<Referral />} />
                                <Route path="/pool-party/*" element={<LiquidityMining />} />
                                <Route path="/lazy-river/*" element={<LazyRiver />} />
                                <Route path="*" element={<Navigate to="/" />} />
                            </Routes>
                        </ScrollToTop>
                    </Suspense>
                    <Footer />
                </GridItem>
            </Grid>
            <Notification />
        </GeoblockContent>
    )
}
