import Big from "big.js"
import { SUBGRAPH_URL_PERP_V2 } from "constants/env"

interface ReferralCodeData {
    tradingVolume: string
}

interface RefereeDayData {
    tradingVolume: string
}

interface ProtocolData {
    tradingVolume: string
}

interface FeeDistributedsData {
    id: string
    surplus: string
    insuranceFundCapacity: string
    insuranceFundFreeCollateral: string
    timestamp: string
}

class TheGraphClient {
    private readonly perpUrl: string
    constructor(perpUrl: string) {
        this.perpUrl = perpUrl
    }

    async getReferralCodeDayData(startTime: number, endTime: number, referralCode: string) {
        const result = await fetch(this.perpUrl, {
            method: "POST",
            body: JSON.stringify({
                query: `
							query ReferralCodeData($referralCode:String!, $startTime:Int!, $endTime:Int!) {  
									referralCodeDayDatas(where: {
										date_gte: $startTime,
										date_lt: $endTime,
										referralCode: $referralCode
									}) {
                                        tradingVolume
									}
							}
					`,
                variables: { referralCode, startTime, endTime },
            }),
        })
        const resultJson = await result.json()
        const data: ReferralCodeData[] = resultJson.data.referralCodeDayDatas
        const tradingVolume = data.reduce((accumulator: Big, current) => accumulator.add(current.tradingVolume), Big(0))
        return {
            tradingVolume,
        }
    }

    async getRefereeDayData(account: string, startTime: number, endTime: number) {
        const result = await fetch(this.perpUrl, {
            method: "POST",
            body: JSON.stringify({
                query: `
							query RefereeDayData($account:String!, $startTime:Int!, $endTime:Int!) {  
									traderDayDatas(where: {
										trader: $account
										date_gte: $startTime,
										date_lt: $endTime
									}) {
                                        tradingVolume
									}
							}
					`,
                variables: { account, startTime, endTime },
            }),
        })
        const resultJson = await result.json()
        const data: RefereeDayData[] = resultJson.data.traderDayDatas
        const tradingVolume = data.reduce((accumulator: Big, current) => accumulator.add(current.tradingVolume), Big(0))
        return {
            tradingVolume,
        }
    }

    async getPERPProtocolDataAt(block: number) {
        const result = await fetch(this.perpUrl, {
            method: "POST",
            body: JSON.stringify({
                query: `
							query ProtocolData($block:Int!) {  
									protocol(id: "perpetual-protocol", block: {
										number: $block
									}) {
                                        tradingVolume
									}
							}
					`,
                variables: { block },
            }),
        })
        const resultJson = await result.json()
        const data: ProtocolData = resultJson.data.protocol
        return {
            tradingVolume: Big(data.tradingVolume),
        }
    }

    async getPERPProtocol() {
        const result = await fetch(this.perpUrl, {
            method: "POST",
            body: JSON.stringify({
                query: `
							query {  
									protocol(id: "perpetual-protocol") {
                                        tradingVolume
									}
							}
					`,
            }),
        })
        const resultJson = await result.json()
        const data: ProtocolData = resultJson.data.protocol
        return {
            tradingVolume: Big(data.tradingVolume),
        }
    }

    async getFeeDistributeds(startTime: number, endTime: number) {
        const result = await fetch(this.perpUrl, {
            method: "POST",
            body: JSON.stringify({
                query: `
                query ($startTime:Int!, $endTime:Int!) {
                    feeDistributeds (
                        where: {
                        timestamp_gt: $startTime, 
                        timestamp_lte: $endTime
                        }
                    ) {
                        id,
                        surplus,
                        insuranceFundCapacity,
                        insuranceFundFreeCollateral,
                        timestamp
                    }
                }
                `,
                variables: { startTime, endTime },
            }),
        })
        const resultJson = await result.json()
        const data: FeeDistributedsData[] = resultJson.data.feeDistributeds
        return data
    }
}

export const theGraphClient = new TheGraphClient(SUBGRAPH_URL_PERP_V2)
