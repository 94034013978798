import { Icon, IconProps } from "@chakra-ui/react"
import React from "react"

export function LINKIcon({ ...props }: IconProps) {
    return (
        <Icon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                fill="#3063EC"
            />
            <path
                d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                fill="#426EE4"
            />
            <g style={{ mixBlendMode: "multiply" }} opacity="0.8">
                <path
                    d="M16 5.5L14.0952 6.6078L8.90476 9.6422L7 10.75V21.25L8.90476 22.3578L14.1429 25.3922L16.0476 26.5L17.9524 25.3922L23.0952 22.3578L25 21.25V10.75L23.0952 9.6422L17.9048 6.6078L16 5.5ZM10.8095 19.0344V12.9656L16 9.93119L21.1905 12.9656V19.0344L16 22.0688L10.8095 19.0344Z"
                    fill="#141319"
                />
            </g>
        </Icon>
    )
}
