import { Icon, IconProps } from "@chakra-ui/react"
import React from "react"

export function AAVEIcon({ ...props }: IconProps) {
    return (
        <Icon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M16 31.52C24.5714 31.52 31.52 24.5715 31.52 16C31.52 7.42854 24.5714 0.480003 16 0.480003C7.42852 0.480003 0.47998 7.42854 0.47998 16C0.47998 24.5715 7.42852 31.52 16 31.52Z"
                fill="#B6509E"
            />
            <path
                d="M16 31.52C24.5714 31.52 31.52 24.5715 31.52 16C31.52 7.42854 24.5714 0.480003 16 0.480003C7.42852 0.480003 0.47998 7.42854 0.47998 16C0.47998 24.5715 7.42852 31.52 16 31.52Z"
                fill="black"
                fillOpacity="0.2"
            />
            <g style={{ mixBlendMode: "multiply" }} opacity="0.8">
                <path
                    d="M22.792 22.184L17.544 9.496C17.248 8.84 16.808 8.52 16.228 8.52H15.764C15.184 8.52 14.744 8.84 14.448 9.496L12.164 15.024H10.436C9.91999 15.028 9.49999 15.444 9.49599 15.964V15.976C9.49999 16.492 9.91999 16.912 10.436 16.916H11.364L9.184 22.184C9.144 22.3 9.12 22.42 9.12 22.544C9.12 22.84 9.21199 23.072 9.37599 23.252C9.53999 23.432 9.776 23.52 10.072 23.52C10.268 23.516 10.456 23.456 10.612 23.34C10.78 23.224 10.896 23.056 10.988 22.864L13.388 16.912H15.052C15.568 16.908 15.988 16.492 15.992 15.972V15.948C15.988 15.432 15.568 15.012 15.052 15.008H14.164L15.996 10.444L20.988 22.86C21.08 23.052 21.196 23.22 21.364 23.336C21.52 23.452 21.712 23.512 21.904 23.516C22.2 23.516 22.432 23.428 22.6 23.248C22.768 23.068 22.856 22.836 22.856 22.54C22.86 22.42 22.84 22.296 22.792 22.184Z"
                    fill="#141319"
                />
            </g>
        </Icon>
    )
}
