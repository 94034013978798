import React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

function DownIcon({ ...props }: IconProps) {
    return (
        <Icon {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.46973 7.53039L0.469727 1.53039L1.53039 0.469727L7.00006 5.9394L12.4697 0.469727L13.5304 1.53039L7.53039 7.53039C7.23749 7.82328 6.76262 7.82328 6.46973 7.53039Z"
                fill="white"
            />
        </Icon>
    )
}

export { DownIcon }
