import { TransactionReceipt } from "@ethersproject/providers"
import { createApi } from "@reduxjs/toolkit/dist/query/react"
import Big from "big.js"
import { IS_PRODUCTION } from "constants/env"
import { vePERPEmissionFeeDistributorClient } from "contracts/FeeDistributorClient"
import { awsClient } from "services/AWSClient"
import { bugsnagClient } from "services/BugsnagClient"
import { bigNum2Big, createMutation } from "utils"

export interface IVePERPEmissionFeeDistributorLastReward {
    weekStart: number
    weekEnd: number
    totalReward: Big
    perp7dTwap: Big
}

export interface IVePERPEmissionFeeDistributorClaimable {
    amount: Big
}

interface IVePERPEmissionFeeDistributorClaimableArgs {
    account: string
}

export const vePERPEmissionFeeDistributorQueryApi = createApi({
    reducerPath: "vePERPEmissionFeeDistributorQuery",
    baseQuery: () => ({ data: undefined }),
    endpoints: builder => ({
        vePERPEmissionFeeDistributorLastReward: builder.query<IVePERPEmissionFeeDistributorLastReward, number>({
            queryFn: vePERPEmissionFeeDistributorLastRewardQuery,
        }),
        vePERPEmissionFeeDistributorClaimable: builder.query<
            IVePERPEmissionFeeDistributorClaimable,
            IVePERPEmissionFeeDistributorClaimableArgs
        >({
            queryFn: vePERPEmissionFeeDistributorClaimableQuery,
        }),
    }),
})

export const vePERPEmissionFeeDistributorMutationApi = createApi({
    reducerPath: "vePERPEmissionMutation",
    baseQuery: () => ({ data: undefined }),
    endpoints: builder => ({
        vePERPEmissionFeeDistributorClaim: builder.mutation<TransactionReceipt, void>({
            // @ts-ignore TODO: fix createMutation args typing issue
            queryFn: createMutation(() => vePERPEmissionFeeDistributorClient.getClaimTx()),
        }),
    }),
})

export const { useVePERPEmissionFeeDistributorLastRewardQuery, useVePERPEmissionFeeDistributorClaimableQuery } =
    vePERPEmissionFeeDistributorQueryApi
export const { useVePERPEmissionFeeDistributorClaimMutation } = vePERPEmissionFeeDistributorMutationApi

async function vePERPEmissionFeeDistributorLastRewardQuery(lastWeek = 1) {
    try {
        const rewards = await awsClient.getVePERPEmissionFeeDistributorRewardsSnapshot(IS_PRODUCTION)
        const lastReward = rewards[Object.keys(rewards)[Object.keys(rewards).length - lastWeek]]
        const data = {
            weekStart: lastReward.weekStart,
            weekEnd: lastReward.weekEnd,
            totalReward: Big(lastReward.totalReward),
            perp7dTwap: Big(lastReward.perp7dTwap),
        }
        return { data }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}

async function vePERPEmissionFeeDistributorClaimableQuery(args: IVePERPEmissionFeeDistributorClaimableArgs) {
    try {
        const { account } = args
        const amount = await vePERPEmissionFeeDistributorClient.getClaimable(account)
        return { data: { amount: bigNum2Big(amount) } }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}
