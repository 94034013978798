import { Modal as ChakraModal, ModalBody, ModalContent, ModalOverlay, Portal, Text, VStack } from "@chakra-ui/react"
import { DEFAULT_WEEKS_MIN } from "components/Locker/constants"
import { ExtendTimeData, LockerExtendTime } from "components/Locker/LockerExtendTime"
import { Header } from "components/Modal/Header"
import { EventGroup, EventName, EventTypeInteraction } from "modules/analytics"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { analyticsClient } from "services/AnalyticsClient"
import { useAppSelector } from "store"

import { referralSlice } from "../../slice"
import { LockerInfoPreview } from "./LockerInfoPreview"
import { useLockerInfoPreview } from "./useLockerInfoPreview"

const INIT_PERP_INFO_PREVIEW = { lockWeeks: DEFAULT_WEEKS_MIN }

export function ModalExtendTime() {
    const { isOpen, size, motion } = useAppSelector(state => state.referral.modal.extendTimeByMyStatus)

    const dispatch = useDispatch()
    const { closeModalExtendTimeByMyStatus } = referralSlice.actions
    const { lockerInfoPreview, setLockerInfoPreview } = useLockerInfoPreview(INIT_PERP_INFO_PREVIEW)

    const handleClick = useCallback(({ lockWeeks }: ExtendTimeData) => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.BUTTON_CLICKED,
            eventName: EventName.REFERRAL_EXTEND_TIME_BY_MY_STATUS_BUTTON_CLICKED,
            payload: { lockWeeks },
        })
    }, [])

    const handleClose = useCallback(() => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.MODAL_CLOSED,
            eventName: EventName.REFERRAL_EXTEND_TIME_BY_MY_STATUS_MODAL_CLOSED,
        })
        dispatch(closeModalExtendTimeByMyStatus())
        setLockerInfoPreview(INIT_PERP_INFO_PREVIEW)
    }, [closeModalExtendTimeByMyStatus, dispatch, setLockerInfoPreview])

    return (
        <Portal>
            <ChakraModal motionPreset={motion} onClose={handleClose} size={size} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent
                    bg="perp.background.background"
                    color="perp.text.primary"
                    boxShadow="0px 20px 20px 0px rgba(0, 0, 0, 0.15)"
                >
                    <Header onCloseButtonClick={handleClose}>Longer Lock Time Required</Header>
                    <ModalBody>
                        <VStack spacing={6} align="stretch">
                            <Text variant={"body2"}>Extend your Lock Time.</Text>
                            <LockerExtendTime
                                onClick={handleClick}
                                onFinished={handleClose}
                                onChange={setLockerInfoPreview}
                            />
                            <LockerInfoPreview {...lockerInfoPreview} />
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </ChakraModal>
        </Portal>
    )
}
