import { menuAnatomy as parts } from "@chakra-ui/anatomy"
import type { PartsStyleFunction, SystemStyleFunction, SystemStyleObject } from "@chakra-ui/theme-tools"
import { mode } from "@chakra-ui/theme-tools"

const baseStyleList: SystemStyleFunction = props => {
    return {
        bg: "perp.background.background",
        boxShadow: mode("sm", "dark-lg")(props),
        color: "perp.text.primary",
        minW: "320px",
        py: "0",
        zIndex: 1,
        borderRadius: "16px",
        borderWidth: "1px",
        borderColor: "perp.background.border",
    }
}

const baseStyleItem: SystemStyleFunction = _props => {
    return {
        py: "0",
        px: "0",
        transitionProperty: "background",
        transitionDuration: "ultra-fast",
        transitionTimingFunction: "ease-in",
        _focus: {
            bg: "perp.background.button",
        },
        _active: {
            bg: "perp.background.card",
        },
        _expanded: {
            bg: "perp.background.button",
        },
        _disabled: {
            opacity: 0.4,
            cursor: "not-allowed",
        },
    }
}

const baseStyleGroupTitle: SystemStyleObject = {
    mx: 4,
    my: 2,
    fontWeight: "semibold",
    fontSize: "sm",
}

const baseStyleCommand: SystemStyleObject = {
    opacity: 0.6,
}

const baseStyleDivider: SystemStyleObject = {
    border: 0,
    borderBottom: "1px solid",
    borderColor: "inherit",
    my: "0.5rem",
    opacity: 0.6,
}

const baseStyleButton: SystemStyleObject = {
    transitionProperty: "common",
    transitionDuration: "normal",
}

const baseStyle: PartsStyleFunction<typeof parts> = props => ({
    button: baseStyleButton,
    list: baseStyleList(props),
    item: baseStyleItem(props),
    groupTitle: baseStyleGroupTitle,
    command: baseStyleCommand,
    divider: baseStyleDivider,
})

export const Menu = {
    parts: parts.keys,
    baseStyle,
}
