import { METADATA } from "constants/env"

import { ContractClientFactory, contractClientFactory } from "./ContractClientFactory"

class RewardDelegateClient {
    private readonly contractClientFactory: ContractClientFactory
    private readonly contractAddress: string

    constructor(contractClientFactory: ContractClientFactory, contractAddress: string) {
        this.contractClientFactory = contractClientFactory
        this.contractAddress = contractAddress
    }

    async getBeneficiaryAndQualifiedMultiplier(
        account: string,
    ): Promise<{ beneficiary: string; qualifiedMultiplier: number }> {
        const contract = this.contractClientFactory.getRewardDelegate(this.contractAddress)
        const [beneficiary, qualifiedMultiplier] = await contract.getBeneficiaryAndQualifiedMultiplier(account)
        return { beneficiary, qualifiedMultiplier: qualifiedMultiplier.toNumber() }
    }
}

const { VOTING_ESCROW } = METADATA
export const vePerpReferralRewardDelegateClient = new RewardDelegateClient(
    contractClientFactory,
    // @ts-ignore // TODO: remove ts ignore when optimism METADATA supports VePERPReferralRewardDelegate contract
    VOTING_ESCROW.contracts.vePERPReferralRewardDelegate.address,
)
