import { ContractReceipt, ContractTransaction } from "ethers"
import { DependencyList, useCallback } from "react"
import { store } from "../store"
import { walletSlice } from "../modules/wallet/slice"

type TransactionCallback = (...args: any[]) => Promise<ContractTransaction>

function useTxCallback<T extends TransactionCallback>(fn: T, deps: DependencyList = []) {
    const callback = useCallback(
        async (...args: Parameters<T>) => {
            let receipt: ContractReceipt
            try {
                store.dispatch(walletSlice.actions.setTxLoading(true))
                const contractTransaction = await fn(...args)
                receipt = await contractTransaction.wait()
            } catch (error) {
                throw error
            } finally {
                store.dispatch(walletSlice.actions.setTxLoading(false))
            }
            return receipt
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [...deps],
    )

    return callback
}

export { useTxCallback }
