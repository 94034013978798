import React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

function ChevronLeftIcon({ ...props }: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" boxSize="24px" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.46967 12.5303L13.4697 18.5303L14.5303 17.4696L9.06066 11.9999L14.5303 6.53027L13.4697 5.46961L7.46967 11.4696C7.17678 11.7625 7.17678 12.2374 7.46967 12.5303Z"
                fill="currentColor"
            />
        </Icon>
    )
}

export { ChevronLeftIcon }
