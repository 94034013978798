import { Modal as ChakraModal, ModalBody, ModalContent, ModalOverlay, VStack } from "@chakra-ui/react"
import { DEFAULT_WEEKS_MIN } from "components/Locker/constants"
import { Header } from "components/Modal/Header"
import { EventGroup, EventName, EventTypeInteraction } from "modules/analytics"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { analyticsClient } from "services/AnalyticsClient"
import { useAppSelector } from "store"

import { useLazyRiverVePerpInfoPreview } from "../hook/useLazyRiverVePerpInfoPreview"
import { lazyRiverSlice } from "../slice"
import { LazyRiverLockerInfo } from "./LazyRiverLockerInfo"
import { LazyRiverLockerWarning } from "./LazyRiverLockerWarning"
import { ModalWidgetExtendTime } from "./ModalWidgetExtendTime"

/**
 * NOTE: This component will open when the user is trying to claim rewards
 * but whose PERP locked time is smaller than min-lock-duration.
 */

const INIT_PERP_INFO_PREVIEW = { lockWeeks: DEFAULT_WEEKS_MIN }

export function ModalExtendTime() {
    const { isOpen, size, motion } = useAppSelector(state => state.lazyRiver.modal.extendTime)

    const dispatch = useDispatch()

    const { info, handleValueChange } = useLazyRiverVePerpInfoPreview(INIT_PERP_INFO_PREVIEW)

    const { closeModalExtendTime } = lazyRiverSlice.actions
    const handleClose = useCallback(() => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.MODAL_CLOSED,
            eventName: EventName.EMISSION_EXTEND_TIME_MODAL_CLOSED,
        })
        dispatch(closeModalExtendTime())
        handleValueChange(INIT_PERP_INFO_PREVIEW)
    }, [closeModalExtendTime, dispatch, handleValueChange])

    return (
        <ChakraModal motionPreset={motion} onClose={handleClose} size={size} isOpen={isOpen} isCentered>
            <ModalOverlay />
            <ModalContent
                bg="perp.background.background"
                color="perp.text.primary"
                boxShadow="0px 20px 20px 0px rgba(0, 0, 0, 0.15)"
            >
                <Header onCloseButtonClick={handleClose}>PERP Locker</Header>
                <ModalBody>
                    <VStack spacing={6} align="stretch">
                        <LazyRiverLockerWarning />
                        <ModalWidgetExtendTime onChange={handleValueChange} onFinished={handleClose} />
                        <LazyRiverLockerInfo
                            lockedPerpAmount={info.lockedPerpAmount}
                            vePerpAmount={info.vePerpAmount}
                            eligibleWeeks={info.eligibleWeeks}
                            lastValidTimestamp={info.lastValidTimestamp}
                        />
                    </VStack>
                </ModalBody>
            </ModalContent>
        </ChakraModal>
    )
}
