/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type { IvePERP, IvePERPInterface } from "../IvePERP";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "timestamp",
        type: "uint256",
      },
    ],
    name: "balanceOfUnweighted",
    outputs: [
      {
        internalType: "uint256",
        name: "unweightedVotingPower",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "deposit_for",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "epoch",
    outputs: [
      {
        internalType: "uint256",
        name: "currentEpoch",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "locked__end",
    outputs: [
      {
        internalType: "uint256",
        name: "userLockEndTimestamp",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "epoch",
        type: "uint256",
      },
    ],
    name: "point_history",
    outputs: [
      {
        components: [
          {
            internalType: "int128",
            name: "bias",
            type: "int128",
          },
          {
            internalType: "int128",
            name: "slope",
            type: "int128",
          },
          {
            internalType: "uint256",
            name: "ts",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "blk",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "perp_amt",
            type: "uint256",
          },
        ],
        internalType: "struct IvePERP.Point",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "timestamp",
        type: "uint256",
      },
    ],
    name: "totalSupplyUnweighted",
    outputs: [
      {
        internalType: "uint256",
        name: "unweightedTotalVotingPower",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class IvePERP__factory {
  static readonly abi = _abi;
  static createInterface(): IvePERPInterface {
    return new utils.Interface(_abi) as IvePERPInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IvePERP {
    return new Contract(address, _abi, signerOrProvider) as IvePERP;
  }
}
