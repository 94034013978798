import React, { useState } from "react"
import { Box, Grid, Flex, Image, Text } from "@chakra-ui/react"
import { PerpNumberInput, PerpNumberInputProps } from "../PerpNumberInput"
import tokenPerpSvg from "../../assets/images/token-perp.svg"
import Big from "big.js"
import { formatNumber } from "../../utils/format"

interface PerpTokenAmountInputProps extends PerpNumberInputProps {
    isInvalid?: boolean
    tokenBalance?: Big
    valueInUSD?: Big
}

function PerpTokenAmountInput({
    isInvalid,
    tokenBalance,
    valueInUSD,
    value,
    precision,
    onChange,
}: PerpTokenAmountInputProps) {
    const handleClickMax = () => {
        if (tokenBalance && !isNaN(tokenBalance.toNumber())) {
            onChange(tokenBalance.toString(), tokenBalance.toNumber())
        }
    }

    const [isInputFocused, setIsInputFocused] = useState(false)
    const borderColor = isInvalid ? "perp.support.error" : isInputFocused ? "perp.text.primary" : "transparent"

    return (
        <Box>
            <Flex
                bgColor="perp.background.input"
                borderRadius="12px"
                color="perp.text.primary"
                p="12px"
                h="100%"
                w="100%"
                flexDir="column"
                justifyContent="space-between"
                border="1px solid"
                borderColor={borderColor}
                style={{ transition: "border-color 0.3s ease-in-out" }}
                _hover={{ borderColor: "perp.background.border" }}
            >
                <Grid gap="8px" templateColumns="auto 1fr">
                    <Flex bgColor="perp.background.background" p="8px" borderRadius="12px">
                        <Image w="24px" h="24px" src={tokenPerpSvg} />
                        <Text fontSize="16px" ml="4px">
                            PERP
                        </Text>
                    </Flex>
                    <PerpNumberInput
                        value={value}
                        precision={precision}
                        onFocus={() => setIsInputFocused(true)}
                        onBlur={() => setIsInputFocused(false)}
                        onChange={onChange}
                        // inputFieldProps
                        fontWeight="medium"
                        fontSize="24px"
                        textAlign="right"
                        placeholder="0.00"
                    />
                </Grid>
                <Grid mt="12px" justifyContent="space-between" templateColumns="auto auto">
                    <Flex>
                        <Box color="perp.text.secondary">
                            Balance: {tokenBalance ? formatNumber(tokenBalance) : "0.0"}
                        </Box>
                        <Box color="perp.primary.default" ml="4px" cursor="pointer" onClick={handleClickMax}>
                            MAX
                        </Box>
                    </Flex>
                    <Box color="perp.text.secondary">
                        ≈ {valueInUSD ? "$" + formatNumber(valueInUSD).toString() : "$0.0"}
                    </Box>
                </Grid>
            </Flex>
            {isInvalid && (
                <Text color="perp.support.error" pt="8px">
                    You don't have enough PERP
                </Text>
            )}
        </Box>
    )
}

export { PerpTokenAmountInput }
