import { Checkbox, Flex, Text, Tooltip } from "@chakra-ui/react"
import React from "react"

interface SelectAllProps {
    isChecked: boolean
    onChange: () => void
}

function SelectAll({ isChecked, onChange }: SelectAllProps) {
    return (
        <Checkbox colorScheme="perp.primary.default" isChecked={isChecked} onChange={onChange}>
            <Tooltip label={"10 week limit prevents transaction failures due to exceeding gas limit."}>
                <Flex>
                    <Text>Select max&nbsp;</Text>
                    <Text>(up to 10 weeks)</Text>
                </Flex>
            </Tooltip>
        </Checkbox>
    )
}

export { SelectAll }
