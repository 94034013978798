import { Button, Modal as ChakraModal, ModalBody, ModalContent, ModalOverlay, Portal, Text } from "@chakra-ui/react"
import { Header } from "components/Modal/Header"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useAppSelector } from "store"

import { liquidityMiningSlice } from "../slice"

/**
 * NOTE: This component will open when the user is trying to claim rewards
 * but whose PERP locked time is invalid.
 */
function ModalExpired() {
    const { isOpen, size, motion } = useAppSelector(state => state.liquidityMining.modal.expired)

    const dispatch = useDispatch()
    const { closeModalExpired } = liquidityMiningSlice.actions

    const handleClose = useCallback(() => {
        dispatch(closeModalExpired())
    }, [closeModalExpired, dispatch])

    const navigate = useNavigate()
    const onClickGoHome = useCallback(() => {
        handleClose()
        navigate("/")
    }, [navigate, handleClose])

    return (
        <Portal>
            <ChakraModal motionPreset={motion} onClose={handleClose} size={size} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent
                    bg="perp.background.background"
                    color="perp.text.primary"
                    boxShadow="0px 20px 20px 0px rgba(0, 0, 0, 0.15)"
                >
                    <Header onCloseButtonClick={handleClose}>Unlock First</Header>
                    <ModalBody>
                        <Text>
                            Your Locked PERP is valid end, please go to home page and unlock it first, then come back to
                            claim your rewards.
                        </Text>
                        <Button variant="green" w="100%" mt="16px" onClick={onClickGoHome}>
                            Go Home
                        </Button>
                    </ModalBody>
                </ModalContent>
            </ChakraModal>
        </Portal>
    )
}

export { ModalExpired }
