import { Box, Button } from "@chakra-ui/react"
import Big from "big.js"
import { ConnectKitButton } from "connectkit"
import { EPOCH, NOTIFICATION_DURATION, POLLING_PERIOD } from "constants/config"
import { analyticsTx } from "modules/analytics"
import { IStatus, notificationSlice } from "modules/notification/slice"
import { useVePerpCreateLockMutation } from "modules/vePerp"
import React, { ReactNode, useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import { useGetServerTimestampQuery } from "services/AWSClient/flow"
import { useCoingeckoPriceForPERPQuery } from "services/CoingeckoClient/flow"
import { str2Big } from "utils"

import { useLockerSlider } from "../../hooks"
import { Erc20PERPContainer } from "../../hooks/containers/useErc20PERPContainer"
import { PerpWalletConnectorContainer } from "../../hooks/containers/usePerpWalletConnectorContainer"
import { useAppSelector } from "../../store"
import { getEndAt } from "../../utils/time"
import { PerpTokenAmountInput } from "../PerpTokenAmountInput"
import { LockerSlider } from "./LockerSlider"

export interface CreateLockData {
    lockAmount: Big
    lockWeeks: number
}

export interface IOverrides {
    minStartWeek: number
}

export type LockerCreateLockProps = {
    onChange?: (data: CreateLockData) => void
    onClick?: (data: CreateLockData) => void
    onSucceed?: () => void
    onFinished?: () => void
    successMsg?: string | ReactNode
    failedMsg?: string | ReactNode
    overrides?: IOverrides
}

export function LockerCreateLock({
    onChange,
    onClick,
    onSucceed = () => {},
    onFinished = () => {},
    successMsg = "You have locked PERP successfully.",
    failedMsg = "Lock PERP failed.",
    overrides,
}: LockerCreateLockProps) {
    // NOTE: redux
    const dispatch = useDispatch()
    const { open } = notificationSlice.actions

    const { data } = useGetServerTimestampQuery()
    const timestamp = data?.timestamp

    const { data: pricePerpData } = useCoingeckoPriceForPERPQuery(undefined, { pollingInterval: POLLING_PERIOD.MEDIUM })
    const perpPrice = pricePerpData?.price

    // NOTE: container & provider
    const { account } = PerpWalletConnectorContainer.useContainer()
    const { isTxLoading } = useAppSelector(state => state.wallet)
    const [createLock] = useVePerpCreateLockMutation()

    const { allowance, balance, approve } = Erc20PERPContainer.useContainer()

    // NOTE: states
    const { week, setWeek, from, to } = useLockerSlider(undefined, overrides?.minStartWeek)
    const [amount, setAmount] = useState<string>("")

    // NOTE: derivative states
    const amountBig = str2Big(amount) || Big(0)
    const valueInUSD = perpPrice ? amountBig.times(perpPrice) : undefined

    // NOTE: handlers
    const handleAmountChange = useCallback(
        (amountNext: string) => {
            setAmount(amountNext)
            const amountNextBig = str2Big(amountNext) || Big(0)
            onChange?.({ lockAmount: amountNextBig, lockWeeks: week })
        },
        [setAmount, onChange, week],
    )

    const handleLockTimeChange = useCallback(
        (weekNext: number) => {
            setWeek(weekNext)
            onChange?.({ lockAmount: amountBig, lockWeeks: weekNext })
        },
        [amountBig, onChange, setWeek],
    )

    const handleClickLock = useCallback(async () => {
        onClick?.({ lockAmount: amountBig, lockWeeks: week })
        if (!timestamp) {
            return
        }
        const endAt = getEndAt(timestamp, week, EPOCH)
        const result = await createLock([amountBig, Big(endAt)])
        if ("error" in result) {
            analyticsTx.vePerp.createLock.failed(result.error)
            dispatch(
                open({
                    status: IStatus.error,
                    description: failedMsg,
                    duration: NOTIFICATION_DURATION.SHORT,
                }),
            )
        } else {
            analyticsTx.vePerp.createLock.succeeded(result.data)
            dispatch(
                open({
                    status: IStatus.success,
                    description: successMsg,
                    duration: NOTIFICATION_DURATION.SHORT,
                }),
            )
            onSucceed()
        }
        onFinished()
    }, [onClick, amountBig, week, timestamp, createLock, onFinished, dispatch, open, failedMsg, successMsg, onSucceed])

    // NOTE: ui conditions
    const isLoginNeeded = !account
    const isLoading = !isLoginNeeded && (allowance === undefined || balance === undefined || isTxLoading)
    const isApprovalNeeded = allowance && (allowance.lte(Big(0)) || allowance.lt(amountBig))
    const isDisabledToCreateLock = amountBig.lte(0)

    return (
        <Box color="perp.text.primary">
            <Box minH="96px" mt="16px">
                <PerpTokenAmountInput
                    value={amount}
                    precision={18}
                    onChange={handleAmountChange}
                    tokenBalance={balance}
                    valueInUSD={valueInUSD}
                />
            </Box>
            <Box mt="16px">
                <LockerSlider from={from} to={to} step={1} onChange={handleLockTimeChange} value={week} />
            </Box>
            <Box mt="24px">
                {isLoginNeeded ? (
                    <ConnectKitButton />
                ) : (
                    <Button
                        w="100%"
                        size="lg"
                        isLoading={isLoading}
                        onClick={isApprovalNeeded ? approve : handleClickLock}
                        disabled={isLoading || (isApprovalNeeded ? false : isDisabledToCreateLock)}
                        loadingText="Waiting for transaction"
                    >
                        {isApprovalNeeded ? "Approve" : "Lock PERP"}
                    </Button>
                )}
            </Box>
        </Box>
    )
}
