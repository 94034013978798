import { Center, Heading, Link, VStack, Text } from "@chakra-ui/react"
import React from "react"

export function ErrorView() {
    return (
        <Center>
            <VStack>
                <Heading>Something went wrong.</Heading>
                <Text>
                    Please visit our{" "}
                    <Link isExternal href="https://discord.com/servers/perpetual-protocol-687397941383659579">
                        Discord
                    </Link>
                    for more info.
                </Text>
            </VStack>
        </Center>
    )
}
