import Big from "big.js"
import { useCallback, useEffect, useMemo, useState } from "react"

import { ClaimableRewardsItem } from "../../../services/AppSyncClient"
import { Selected } from "../type"

function useSelectable(items: ClaimableRewardsItem[]) {
    const [selected, setSelected] = useState<Selected>({})
    const [isSelectedAll, setIsSelectedAll] = useState(false)
    const [selectedLength, setSelectedLength] = useState<number>(0)

    useEffect(() => {
        if (items.length !== 0) {
            const defaultSelected = getDefaultSelected(items)
            setSelected(defaultSelected)
            setIsSelectedAll(true)
            setSelectedLength(Object.keys(defaultSelected).length)
        }
    }, [items])

    const onSelectChange = useCallback(
        (index: number) => {
            const item = selected[index]
            if (item) {
                // NOTE: click checked item
                setSelected(prev => {
                    const newSelected = { ...prev }
                    delete newSelected[index]
                    setSelectedLength(Object.keys(newSelected).length)
                    return newSelected
                })
                if (isSelectedAll) {
                    setIsSelectedAll(false)
                }
            } else {
                // NOTE: click unchecked item
                // const selectedLength = Object.keys(selected).length
                const cannotAdd = selectedLength === 10
                const willHitUpperLimit = selectedLength === 9 || selectedLength === items.length - 1
                if (cannotAdd) return
                setSelected(prev => {
                    const newSelected = { ...prev }
                    newSelected[index] = items[index]
                    setSelectedLength(Object.keys(newSelected).length)
                    return newSelected
                })
                if (willHitUpperLimit) setIsSelectedAll(true)
            }
        },
        [isSelectedAll, items, selected, selectedLength],
    )

    const onSelectAll = useCallback(() => {
        if (isSelectedAll) {
            setIsSelectedAll(false)
            setSelected({})
            setSelectedLength(0)
        } else {
            setIsSelectedAll(true)
            setSelected(prevSelected => getUpperLimitSelected(prevSelected, items))
            setSelectedLength(10)
        }
    }, [isSelectedAll, items])

    const selectedAmount = useMemo(() => getSelectedAmount(selected), [selected])

    return {
        onSelectChange,
        onSelectAll,
        selected,
        selectedAmount,
        isSelectedAll,
        selectedLength,
    }
}

export { useSelectable }

function getDefaultSelected(items: ClaimableRewardsItem[]) {
    if (items.length === 0) return {}
    const selected: Selected = {}
    const maxLength = items.length < 10 ? items.length : 10
    for (let i = 0; i < maxLength; i++) {
        selected[i] = items[i]
    }
    return selected
}

function getUpperLimitSelected(selected: Selected, items: ClaimableRewardsItem[]) {
    const selectedArr = Object.entries(selected)
    const selectedLength = selectedArr.length
    const deviation = 10 - selectedLength
    if (deviation <= 0) return selected
    const result: Selected = { ...selected }
    for (let i = 0, counter = 0; i < items.length && counter < deviation; i++) {
        if (!result[i]) {
            result[i] = items[i]
            counter++
        }
    }
    return result
}

function getSelectedAmount(selected: Selected): Big {
    return Object.values(selected).reduce((acc: Big, curr) => acc.add(curr.balance), Big(0))
}
