import { createApi } from "@reduxjs/toolkit/query/react"
import { POLLING_PERIOD } from "constants/config"
import { bugsnagClient } from "services/BugsnagClient"

import { awsClient, IGeoBlockInfo } from "./"

interface IServerTimestamp {
    timestamp: number
}

async function serverTimestampQuery() {
    try {
        const timestamp = await awsClient.getServerTimestamp()
        return { data: { timestamp } }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}

async function serverTimestampDataCache(args: any, api: any) {
    const { updateCachedData, cacheDataLoaded, cacheEntryRemoved } = api
    await cacheDataLoaded
    let timer
    try {
        timer = setInterval(async () => {
            const timestamp = await awsClient.getServerTimestamp()
            updateCachedData((state: IServerTimestamp) => {
                state.timestamp = timestamp
            })
        }, POLLING_PERIOD.MEDIUM)
        await cacheEntryRemoved
        clearInterval(timer)
    } catch (error) {
        bugsnagClient.sendError(error as Error)
    }
}

async function geoBlockInfoQuery() {
    try {
        const geoBlockInfo = await awsClient.getGeoBlockInfo()
        return { data: geoBlockInfo }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}

export const awsClientQueryApi = createApi({
    reducerPath: "awsClient",
    baseQuery: () => ({ data: undefined }),
    endpoints: builder => ({
        getServerTimestamp: builder.query<IServerTimestamp, void>({
            queryFn: serverTimestampQuery,
            onCacheEntryAdded: serverTimestampDataCache,
        }),
        getGeoBlockInfo: builder.query<IGeoBlockInfo, void>({
            queryFn: geoBlockInfoQuery,
        }),
    }),
})

export const { useGetServerTimestampQuery, useGetGeoBlockInfoQuery } = awsClientQueryApi
