import { TransactionReceipt } from "@ethersproject/providers"
import { createApi } from "@reduxjs/toolkit/dist/query/react"
import Big from "big.js"
import { EPOCH } from "constants/config"
import { usdcFeeDistributorClient } from "contracts/FeeDistributorClient"
import { bugsnagClient } from "services/BugsnagClient"
import { theGraphClient } from "services/TheGraphClient"
import { bigNum2Big, createMutation, getCalibratedTime, getTimestampSlots } from "utils"

export interface IUSDCSurplus {
    totalSurplus: Big
}

export interface IUSDCSurplusArg {
    timestamp: number
}

export interface IUSDCFeeDistributorReward {
    totalReward: Big
}

export type IUSDCFeeDistributorRewards = Record<number, Big>

export interface IUSDCFeeDistributorRewardArg {
    timestamp: number
}

export interface IUSDCFeeDistributorRewardsArg {
    startTime: number
    endTime: number
}

export interface IUSDCFeeDistributorClaimable {
    amount: Big
}

export interface IUSDCFeeDistributorClaimableArgs {
    account: string
}

export interface IUSDCFeeDistributorInfo {
    lastTokenTime: number
}

export const usdcFeeDistributorQueryApi = createApi({
    reducerPath: "usdcFeeDistributorQuery",
    baseQuery: () => ({ data: undefined }),
    endpoints: builder => ({
        usdcSurplus: builder.query<IUSDCSurplus, IUSDCSurplusArg>({
            queryFn: usdcSurplusQuery,
        }),
        usdcFeeDistributorReward: builder.query<IUSDCFeeDistributorReward, IUSDCFeeDistributorRewardArg>({
            queryFn: usdcFeeDistributorRewardQuery,
        }),
        usdcFeeDistributorRewards: builder.query<IUSDCFeeDistributorRewards, IUSDCFeeDistributorRewardsArg>({
            queryFn: usdcFeeDistributorRewardsQuery,
        }),
        usdcFeeDistributorClaimable: builder.query<IUSDCFeeDistributorClaimable, IUSDCFeeDistributorClaimableArgs>({
            queryFn: usdcFeeDistributorClaimableQuery,
        }),
        usdcFeeDistributorInfo: builder.query<IUSDCFeeDistributorInfo, void>({
            queryFn: usdcFeeDistributorInfoQuery,
        }),
    }),
})

export const usdcFeeDistributorMutationApi = createApi({
    reducerPath: "usdcFeeDistributorMutation",
    baseQuery: () => ({ data: undefined }),
    endpoints: builder => ({
        usdcFeeDistributorClaim: builder.mutation<TransactionReceipt, void>({
            // @ts-ignore TODO: fix createMutation args typing issue
            queryFn: createMutation(() => usdcFeeDistributorClient.getClaimTx()),
        }),
    }),
})

export const {
    useUsdcSurplusQuery,
    useUsdcFeeDistributorClaimableQuery,
    useUsdcFeeDistributorRewardQuery,
    useUsdcFeeDistributorRewardsQuery,
    useUsdcFeeDistributorInfoQuery,
} = usdcFeeDistributorQueryApi

export const { useUsdcFeeDistributorClaimMutation } = usdcFeeDistributorMutationApi

// The information on the graph is only estimated. It provides an approximation.
// The contract may have received the fee but hasn't included it in the reward yet.
// This delay occurs because the check_point function hasn't been called.
// There can be a time difference between check_point and fee distribution.
// The check_point call necessitates a 24-hour cooling time.
// Obtaining the estimated information for the current week is beneficial.
// userReward = totalSurplus * 0.75
// treasury = totalSurplus * 0.25
async function usdcSurplusQuery(args: IUSDCSurplusArg) {
    try {
        const { timestamp } = args
        const startTime = getCalibratedTime(timestamp, EPOCH)
        const endTime = startTime + EPOCH
        const feeDistributedData = await theGraphClient.getFeeDistributeds(startTime, endTime)

        return {
            data: {
                totalSurplus: Big(feeDistributedData.reduce((acc, curr) => acc + Number(curr.surplus), 0)),
            },
        }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}

async function usdcFeeDistributorRewardQuery(args: IUSDCFeeDistributorRewardArg) {
    try {
        const { timestamp } = args
        const tokenAmount = await usdcFeeDistributorClient.getTokensPerWeek(timestamp)
        return {
            data: {
                totalReward: bigNum2Big(tokenAmount, 6),
            },
        }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}

async function usdcFeeDistributorRewardsQuery(args: IUSDCFeeDistributorRewardsArg) {
    try {
        const { startTime, endTime } = args
        const timestampSlots = getTimestampSlots(startTime, endTime)
        const usdcFeeDistributorRewards: IUSDCFeeDistributorRewards = {}
        await Promise.all(
            timestampSlots.map(async timestamp => {
                const tokenAmount = await usdcFeeDistributorClient.getTokensPerWeek(timestamp)
                usdcFeeDistributorRewards[timestamp] = bigNum2Big(
                    tokenAmount,
                    usdcFeeDistributorClient.getRewardDecimals(),
                )
            }),
        )
        return {
            data: {
                ...usdcFeeDistributorRewards,
            },
        }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}

async function usdcFeeDistributorClaimableQuery(args: IUSDCFeeDistributorClaimableArgs) {
    try {
        const { account } = args
        const amount = await usdcFeeDistributorClient.getClaimable(account)
        return { data: { amount: bigNum2Big(amount, 6) } }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}

async function usdcFeeDistributorInfoQuery() {
    try {
        const lastTokenTime = (await usdcFeeDistributorClient.getLastTokenTime()).toNumber()
        return { data: { lastTokenTime } }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}
