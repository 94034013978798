import { Icon, IconProps } from "@chakra-ui/react"
import React from "react"

export function BNBIcon({ ...props }: IconProps) {
    return (
        <Icon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 0C24.8372 0 32 7.16282 32 16C32 24.8372 24.8372 32 16 32C7.16282 32 0 24.8372 0 16C0 7.16282 7.16282 0 16 0Z"
                fill="#F0B90B"
            />
            <g style={{ mixBlendMode: "multiply" }} opacity="0.8">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.7051 15.123L21.6936 12.6461H21.7025L19.5987 11.4077L15.9936 13.5333L12.4102 11.4077L10.3077 12.6461V15.123L13.9128 17.2384V21.4794L16.0064 22.7064L18.1 21.4794V17.2384L21.7051 15.123ZM15.9949 5.06281L10.3064 8.41664L12.4 9.6551L15.9949 7.52947L19.6 9.6551L21.6936 8.41664L15.9949 5.06281ZM8.80514 20.2308L8.7936 16L6.70129 14.7628V21.4808L12.4 24.8231V22.3462L8.80514 20.2308ZM8.7936 14.2346V11.7692L10.8974 10.5308L8.7936 9.2923L6.70001 10.5308V12.9961L8.7936 14.2346ZM15.9949 9.2923L13.9013 10.5308L15.9949 11.7692L18.0987 10.5308L15.9949 9.2923ZM12.4 18.1154L10.3064 16.8769V19.3538L12.4 20.5808V18.1154ZM15.9949 24.4718L13.9013 23.2333V25.6987L15.9949 26.9372L18.0987 25.6987V23.2333L15.9949 24.4718ZM23.1949 9.2923L21.1013 10.5308L23.1949 11.7692V14.2346L25.2987 12.9961V10.5308L23.1949 9.2923ZM25.3 14.7615L23.2064 16L23.1949 20.2308L19.6013 22.3449V24.8218L25.3 21.4795V14.7615ZM21.6936 19.3539L19.6 20.5808V18.1154L21.6936 16.877V19.3539Z"
                    fill="#141319"
                />
            </g>
        </Icon>
    )
}
