import React from "react"
import { Statuses } from "../PerpAlert"
import { ContentSwitchNetwork } from "./ContentSwitchNetwork"

const BANNER_CONTENTS = {
    DEFAULT: <></>,
    SWITCH_NETWORK: <ContentSwitchNetwork />,
}

const BANNER_STATUSES = {
    INFO: "info" as Statuses,
    SUCCESS: "success" as Statuses,
    ERROR: "error" as Statuses,
}

type BannerContents = keyof typeof BANNER_CONTENTS
type BannerStatuses = keyof typeof BANNER_STATUSES

export { BANNER_CONTENTS, BANNER_STATUSES }
export type { BannerContents, BannerStatuses }
