import Big from "big.js"
import { ClaimableRewardsItem } from "services/AppSyncClient"

import { Selected } from "./type"

export class Claim {
    readonly week: number
    readonly balance: Big
    readonly merkleProof: string[]

    constructor(week: number, balance: Big, merkleProof: string[]) {
        this.week = week
        this.balance = balance
        this.merkleProof = merkleProof
    }

    static getClaimsBySelected(selected: Selected): Claim[] {
        return Object.values(selected).map(
            (item: ClaimableRewardsItem) => new Claim(item.week, item.balance, [...item.merkleProof]),
        )
    }
}
