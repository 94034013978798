import { TransactionReceipt } from "@ethersproject/providers"
import { analyticsClient } from "services/AnalyticsClient"

import { EventGroup, EventTypeContract } from "./types"

export function analyticsTxFailed(error: unknown, nameRejected: string, nameFailed: string) {
    if ((error as { code: number }).code === 4001) {
        analyticsClient.track({
            eventGroup: EventGroup.CONTRACT,
            eventType: EventTypeContract.TX_REJECTED,
            eventName: nameRejected,
        })
    } else {
        analyticsClient.track({
            eventGroup: EventGroup.CONTRACT,
            eventType: EventTypeContract.TX_FAILED,
            eventName: nameFailed,
        })
    }
}

export function analyticsTxSucceeded(nameSucceeded: string, receipt: TransactionReceipt) {
    analyticsClient.track({
        eventGroup: EventGroup.CONTRACT,
        eventType: EventTypeContract.TX_SUCCEEDED,
        eventName: nameSucceeded,
        receipt,
    })
}
