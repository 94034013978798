import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Motion, Size } from "components/Modal/type"
import { ClaimableRewards, ClaimableRewardsItem } from "services/AppSyncClient"

interface IModalState {
    isOpen: boolean
    size: Size
    motion: Motion
}

interface LiquidityMiningState {
    isLiquidityMiningLoading: boolean
    modal: {
        createLock: IModalState
        expired: IModalState
        extendTime: IModalState
        claim: IModalState & { items: ClaimableRewardsItem[] }
        claimOp: IModalState & { items: ClaimableRewardsItem[] }
    }
    claimableRewards?: ClaimableRewards
}

const initialState: LiquidityMiningState = {
    isLiquidityMiningLoading: false,
    modal: {
        createLock: {
            isOpen: false,
            size: Size.FULL,
            motion: Motion.SLIDE_IN_BOTTOM,
        },
        expired: {
            isOpen: false,
            size: Size.FULL,
            motion: Motion.SLIDE_IN_BOTTOM,
        },
        extendTime: {
            isOpen: false,
            size: Size.FULL,
            motion: Motion.SLIDE_IN_BOTTOM,
        },
        claim: {
            isOpen: false,
            size: Size.FULL,
            motion: Motion.SLIDE_IN_BOTTOM,
            items: [],
        },
        claimOp: {
            isOpen: false,
            size: Size.FULL,
            motion: Motion.SLIDE_IN_BOTTOM,
            items: [],
        },
    },
}

export const liquidityMiningSlice = createSlice({
    name: "liquidityMining",
    initialState,
    reducers: {
        openModalCreateLock(state, action: PayloadAction<{ size: Size; motion: Motion }>) {
            state.modal.createLock.isOpen = true
            state.modal.createLock.size = action.payload.size
            state.modal.createLock.motion = action.payload.motion
        },
        closeModalCreateLock(state) {
            state.modal.createLock.isOpen = false
        },
        openModalExpired(state, action: PayloadAction<{ size: Size; motion: Motion }>) {
            state.modal.expired.isOpen = true
            state.modal.expired.size = action.payload.size
            state.modal.expired.motion = action.payload.motion
        },
        closeModalExpired(state) {
            state.modal.expired.isOpen = false
        },
        openModalExtendTime(state, action: PayloadAction<{ size: Size; motion: Motion }>) {
            state.modal.extendTime.isOpen = true
            state.modal.extendTime.size = action.payload.size
            state.modal.extendTime.motion = action.payload.motion
        },
        closeModalExtendTime(state) {
            state.modal.extendTime.isOpen = false
        },
        openModalClaim(state, action: PayloadAction<{ size: Size; motion: Motion; items: ClaimableRewardsItem[] }>) {
            state.modal.claim.isOpen = true
            state.modal.claim.size = action.payload.size
            state.modal.claim.motion = action.payload.motion
            state.modal.claim.items = action.payload.items
        },
        closeModalClaim(state) {
            state.modal.claim.isOpen = false
            state.modal.claim.items = initialState.modal.claim.items
        },
        openModalClaimOp(state, action: PayloadAction<{ size: Size; motion: Motion; items: ClaimableRewardsItem[] }>) {
            state.modal.claimOp.isOpen = true
            state.modal.claimOp.size = action.payload.size
            state.modal.claimOp.motion = action.payload.motion
            state.modal.claimOp.items = action.payload.items
        },
        closeModalClaimOp(state) {
            state.modal.claimOp.isOpen = false
            state.modal.claimOp.items = initialState.modal.claim.items
        },
    },
})
