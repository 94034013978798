import React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

function PERPIcon({ ...props }: IconProps) {
    return (
        <Icon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_2_4118)">
                <path
                    d="M31.5195 19.8705C29.3825 28.442 20.701 33.6585 12.1285 31.521C3.5595 29.384 -1.657 20.702 0.481 12.131C2.617 3.55852 11.2985 -1.65848 19.8685 0.478523C28.4405 2.61552 33.6565 11.2985 31.5195 19.8705Z"
                    fill="#3CEAAA"
                />
                <g style={{ mixBlendMode: "multiply" }} opacity="0.8">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.811 8.84395C14.8029 7.87872 13.1901 7.87872 12.1819 8.84395L7.77847 13.0601C6.74051 14.0539 6.74051 15.6872 7.77847 16.681L14.9543 23.5515C14.9633 23.561 14.9724 23.5704 14.9817 23.5797C15.2634 23.8614 15.6362 24.0026 16.0092 24.0024C16.3842 24.0034 16.7592 23.8622 17.0423 23.5797C17.0645 23.5575 17.0858 23.5349 17.1062 23.5117L24.2234 16.681C25.2589 15.6872 25.2589 14.0538 24.2234 13.06L21.8424 10.7749C20.8367 9.80967 19.2278 9.80968 18.2221 10.7749L18.025 10.9638L15.811 8.84395ZM18.0669 14.8752C18.7244 14.2457 19.3755 13.6099 20.0322 12.9796L22.0025 14.8705L20.0322 16.7615L18.0669 14.8752ZM15.9674 12.9357L11.9797 16.7615L10.0047 14.8705L13.9965 11.0486L15.9674 12.9357ZM16.0151 16.8398L17.9786 18.7324L16.0108 20.621L14.0383 18.7325L16.0151 16.8398Z"
                        fill="#141319"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_2_4118">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </Icon>
    )
}

export { PERPIcon }
