import { Box, Modal as ChakraModal, ModalBody, ModalContent, ModalOverlay, Portal, Text } from "@chakra-ui/react"
import { Header } from "components/Modal/Header"
import { ModalWidgetExtendTime } from "modules/referral"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { useAppSelector } from "store"

import { liquidityMiningSlice } from "../slice"

/**
 * NOTE: This component will open when the user is trying to claim rewards
 * but whose PERP locked time is smaller than min-lock-duration.
 */

function ModalExtendTime() {
    const { minRequestDate, minRequestDateAndLockValidDateDiffInWeeks } = {
        minRequestDate: 0,
        minRequestDateAndLockValidDateDiffInWeeks: 0,
    }

    const { isOpen, size, motion } = useAppSelector(state => state.liquidityMining.modal.extendTime)

    const dispatch = useDispatch()
    const { closeModalExtendTime } = liquidityMiningSlice.actions

    const handleClose = useCallback(() => {
        dispatch(closeModalExtendTime())
    }, [closeModalExtendTime, dispatch])

    return (
        <Portal>
            <ChakraModal motionPreset={motion} onClose={handleClose} size={size} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent
                    bg="perp.background.background"
                    color="perp.text.primary"
                    boxShadow="0px 20px 20px 0px rgba(0, 0, 0, 0.15)"
                >
                    <Header onCloseButtonClick={handleClose}>Lock Before Claim</Header>
                    <ModalBody>
                        <Text>
                            You need to lock PERP for at least{" "}
                            {minRequestDateAndLockValidDateDiffInWeeks
                                ? minRequestDateAndLockValidDateDiffInWeeks
                                : "--"}{" "}
                            more weeks. The new valid date will be {minRequestDate ? minRequestDate : "--"}.
                        </Text>
                        <Box mt="16px">
                            <ModalWidgetExtendTime onFinished={handleClose} />
                        </Box>
                    </ModalBody>
                </ModalContent>
            </ChakraModal>
        </Portal>
    )
}

export { ModalExtendTime }
