import { Icon, IconProps } from "@chakra-ui/react"
import React from "react"

export function FLOWIcon({ ...props }: IconProps) {
    return (
        <Icon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                fill="#4BEAA7"
            />
            <g style={{ mixBlendMode: "multiply" }} opacity="0.8">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.76 11.76H24V7.99998H19.76C16.9066 7.99998 14.5866 10.32 14.5866 13.1733V13.6533H18.3466V13.1733C18.3466 12.4 18.9866 11.76 19.76 11.76ZM13.1733 20.24C13.9467 20.24 14.5867 19.6 14.5867 18.8267V17.4134L18.3466 17.4134H18.3466V13.6534H22.1066V17.4134L18.3467 17.4134V18.8267C18.3467 21.68 16.0267 24 13.1733 24C10.32 24 8 21.68 8 18.8267C8 15.9734 10.32 13.6534 13.1733 13.6534H14.5866V17.4134L13.1733 17.4134C12.4 17.4134 11.76 18.0534 11.76 18.8267C11.76 19.6 12.4 20.24 13.1733 20.24Z"
                    fill="#141319"
                />
            </g>
        </Icon>
    )
}
