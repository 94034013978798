export class CoingeckoClient {
    async getPrice(id: string) {
        const url = this.getUrl(id)
        return await fetch(url)
            .then(res => res.json())
            .then(data => data[id].usd)
    }

    private getUrl(id: string) {
        return `https://api.coingecko.com/api/v3/simple/price?ids=${id}&vs_currencies=usd`
    }
}

export const coingeckoClient = new CoingeckoClient()
