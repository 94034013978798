import { Motion, Size } from "components/Modal/type"
import { EPOCH } from "constants/config"
import { PerpWalletConnectorContainer } from "hooks/containers/usePerpWalletConnectorContainer"
import { VePerpByUserContainer } from "hooks/containers/useVePerpByUserContainer"
import { useIsMobile } from "hooks/useDevice"
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { useGetServerTimestampQuery } from "services/AWSClient/flow"

import { useLMRewardsQuery } from "../flow"
import { liquidityMiningSlice } from "../slice"

export function useLMClaim() {
    const dispatch = useDispatch()
    const { account } = PerpWalletConnectorContainer.useContainer()
    const { data: LMRewardsData } = useLMRewardsQuery({ account: account! }, { skip: !account })
    const { data: serverTimestampData } = useGetServerTimestampQuery()
    const timestamp = serverTimestampData?.timestamp

    const { hasLockedAlready, hasExpired, lockedPerpTokenValidDateInSec } = VePerpByUserContainer.useContainer()
    const isMobile = useIsMobile()

    const onLMPerpClaim = useCallback(() => {
        if (!timestamp || !LMRewardsData?.items || !LMRewardsData?.minLockDuration) {
            return
        }
        const fnName = getClaimClickFnName(
            timestamp,
            hasExpired,
            hasLockedAlready,
            LMRewardsData.minLockDuration,
            lockedPerpTokenValidDateInSec,
        )

        const size = isMobile ? Size.FULL : Size.MD
        const motion = Motion.SLIDE_IN_BOTTOM
        dispatch(
            fnName === "openModalClaim"
                ? liquidityMiningSlice.actions[fnName]({
                      size,
                      motion,
                      items: LMRewardsData.items,
                  })
                : liquidityMiningSlice.actions[fnName]({ size, motion }),
        )
    }, [
        LMRewardsData?.items,
        LMRewardsData?.minLockDuration,
        dispatch,
        hasExpired,
        hasLockedAlready,
        isMobile,
        lockedPerpTokenValidDateInSec,
        timestamp,
    ])

    const onLMOPClaim = useCallback(() => {
        if (!LMRewardsData?.itemsOp) {
            return
        }
        const size = isMobile ? Size.FULL : Size.MD
        const motion = Motion.SLIDE_IN_BOTTOM
        dispatch(liquidityMiningSlice.actions.openModalClaimOp({ size, motion, items: LMRewardsData.itemsOp }))
    }, [LMRewardsData?.itemsOp, dispatch, isMobile])

    return {
        onLMPerpClaim,
        onLMOPClaim,
    }
}

// TODO: use xstate
export function getClaimClickFnName(
    currentTime: number,
    hasExpired: boolean,
    hasLockedAlready?: boolean,
    minLockDuration?: number,
    validDateInSec?: number,
) {
    const epochStartTime = Math.floor(currentTime / EPOCH) * EPOCH
    const isClaimable = minLockDuration && validDateInSec && validDateInSec >= epochStartTime + minLockDuration
    if (hasLockedAlready) {
        if (hasExpired) {
            // NOTE: expired
            return "openModalExpired"
        } else if (isClaimable) {
            // NOTE: claimable
            return "openModalClaim"
        } else {
            // NOTE: need to extend lock time
            return "openModalExtendTime"
        }
    } else {
        // NOTE: no lock
        return "openModalCreateLock"
    }
}
