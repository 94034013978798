import { Modal as ChakraModal, ModalBody, ModalContent, ModalOverlay, Portal, Text } from "@chakra-ui/react"
import { LockerCreateLock } from "components/Locker/LockerCreateLock"
import { Header } from "components/Modal/Header"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { useAppSelector } from "store"

import { liquidityMiningSlice } from "../slice"

/**
 * NOTE: This component will open when the user is trying to claim rewards
 * but who doesn't have any PERP locked.
 */
export function ModalCreateLock() {
    const { minLockDurationInWeeks } = { minLockDurationInWeeks: 0 }
    const { isOpen, size, motion } = useAppSelector(state => state.liquidityMining.modal.createLock)

    const dispatch = useDispatch()
    const { closeModalCreateLock } = liquidityMiningSlice.actions

    const handleClose = useCallback(() => {
        dispatch(closeModalCreateLock())
    }, [closeModalCreateLock, dispatch])

    return (
        <Portal>
            <ChakraModal motionPreset={motion} onClose={handleClose} size={size} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent
                    bg="perp.background.background"
                    color="perp.text.primary"
                    boxShadow="0px 20px 20px 0px rgba(0, 0, 0, 0.15)"
                >
                    <Header onCloseButtonClick={handleClose}>Lock Before Claim</Header>
                    <ModalBody>
                        <Text>
                            You need to lock some PERP for {minLockDurationInWeeks} weeks. Your rewards will credit to
                            the locked PERP. If you don’t have any PERP please click here
                        </Text>
                        <LockerCreateLock onFinished={handleClose} />
                    </ModalBody>
                </ModalContent>
            </ChakraModal>
        </Portal>
    )
}
