export enum Size {
    XS = "xs",
    SM = "sm",
    MD = "md",
    LG = "lg",
    XL = "xl",
    FULL = "full",
}

export enum Motion {
    SLIDE_IN_BOTTOM = "slideInBottom",
    SLIDE_IN_RIGHT = "slideInRight",
    SCALE = "scale",
    NONE = "none",
}
