import { Alert, AlertIcon, Box, Grid, Heading } from "@chakra-ui/react"
import { PERPIcon } from "components/Icons/Tokens/PERPIcon"
import { USDCIcon } from "components/Icons/Tokens/USDCIcon"
import React from "react"

import { useVePERPEmissionFeeDistributorClaim } from "modules/vePERPEmissionFeeDistributor"
import { useUsdcFeeDistributorClaim } from "modules/usdcFeeDistributor"
import { useVePERPDistributorClaim } from "modules/vePerpDistributor"
import { LazyRiverClaimCard } from "./LazyRiverClaimCard"
import { LockedPERPIcon } from "components/Icons/Tokens/LockedPERPIcon"

export function LazyRiverControllerPanel() {
    const {
        data: vePERPEmissionData,
        isLoading: vePERPEmissionIsLoading,
        handleClaim: vePERPEmissionHandleClaim,
    } = useVePERPEmissionFeeDistributorClaim()
    const {
        data: usdcFeeDistributorData,
        isLoading: usdcFeeDistributorIsLoading,
        handleClaim: usdcFeeDistributorHandleClaim,
    } = useUsdcFeeDistributorClaim()
    const {
        data: vePERPDistributorData,
        isLoading: vePERPDistributorIsLoading,
        handleClaim: vePERPDistributorHandleClaim,
        error: vePERPDistributorError,
    } = useVePERPDistributorClaim()

    return (
        <Grid
            maxW="375px"
            gap="16px"
            color="perp.text.primary"
            bgColor="perp.background.background"
            border="1px solid"
            borderRadius={12}
            borderColor="perp.background.border"
            px={6}
            py={{ base: 6, lg: 8 }}
            boxShadow="0px 20px 20px 0px rgba(0, 0, 0, 0.15)"
            style={{ position: "sticky", top: "120px" }}
        >
            <Heading variant="h4">Your Rewards</Heading>
            <Box bg="perp.background.card" borderRadius="12px" p="16px" alignContent="center">
                <LazyRiverClaimCard
                    icon={<USDCIcon boxSize="48px" />}
                    title="USDC"
                    amount={usdcFeeDistributorData?.amount}
                    onClick={usdcFeeDistributorHandleClaim}
                    isLoading={usdcFeeDistributorIsLoading}
                />
            </Box>
            <Box bg="perp.background.card" borderRadius="12px" p="16px" alignContent="center">
                <LazyRiverClaimCard
                    icon={<LockedPERPIcon boxSize="48px" />}
                    title="Locked PERP"
                    amount={vePERPDistributorData?.amount}
                    onClick={vePERPDistributorHandleClaim}
                    isLoading={vePERPDistributorIsLoading}
                />
                <LockedPERPAlert error={vePERPDistributorError as Error} />
            </Box>
            <Box bg="perp.background.card" borderRadius="12px" p="16px" alignContent="center">
                <LazyRiverClaimCard
                    icon={<PERPIcon boxSize="48px" />}
                    title="PERP (Lazy River 1.0)"
                    amount={vePERPEmissionData?.amount}
                    onClick={vePERPEmissionHandleClaim}
                    isLoading={vePERPEmissionIsLoading}
                />
            </Box>
        </Grid>
    )
}

export interface VeTokenDistributorError {
    error: Error
}

export function LockedPERPAlert({ error: veTokenDistributorError }: VeTokenDistributorError) {
    let errorMessage = ""
    if (veTokenDistributorError?.message.includes("Cannot add to expired lock")) {
        errorMessage = "Locked PERP expired. Please unlock and re-lock to claim Locked PERP rewards."
    } else if (veTokenDistributorError?.message.includes("No existing lock found")) {
        errorMessage = "You don't have any Locked PERP. Lock some PERP to claim rewards."
    }
    return (
        <Alert borderRadius={"xl"} mt={4} fontSize={"xs"} status="warning" display={errorMessage ? "flex" : "none"}>
            <AlertIcon />
            {errorMessage}
        </Alert>
    )
}
