import { Box, Flex, Grid, Text } from "@chakra-ui/react"
import Big from "big.js"
import { OPIcon } from "components/Icons/Tokens/OPIcon"
import { PERPIcon } from "components/Icons/Tokens/PERPIcon"
import { useIsMobile } from "hooks/useDevice"
import React from "react"
import { formatNumber } from "utils/format"

import { useMakerMarketsQuery } from "../flow"
import { IParsedMakerMarket, selectorMakerMarkets } from "../selector"
import { LiquidityMiningAprTooltip } from "./LiquidityMiningAprTooltip"
import { LiquidityMiningIconFinder } from "./LiquidityMiningIconFinder"

export function LiquidityMiningMyPoolRewards({ account }: { account: string }) {
    const { data: makerMarketsData } = useMakerMarketsQuery({ account }, { selectFromResult: selectorMakerMarkets })
    const isMobile = useIsMobile()

    return (
        <Box mt="96px">
            <Text fontWeight="bold" fontSize="24px" color="perp.text.primary">
                My Pool Rewards
            </Text>
            <Grid
                mt="48px"
                px="24px"
                fontSize="14px"
                color="perp.text.secondary"
                templateColumns="1fr 95px"
                justifyContent="space-between"
            >
                {isMobile ? (
                    <>
                        <Grid templateColumns="1fr 1fr">
                            <Text>Net Value</Text>
                            <Text>OP & PERP </Text>
                        </Grid>
                        <Text textAlign="end">APR</Text>
                    </>
                ) : (
                    <>
                        <Grid templateColumns="1fr 1fr 1fr">
                            <Text>Net Value</Text>
                            <Text>Est. PERP</Text>
                            <Text>Est. OP</Text>
                        </Grid>

                        <Text textAlign="end">APR</Text>
                    </>
                )}
            </Grid>
            <Grid gap="8px" mt="8px">
                {makerMarketsData &&
                    makerMarketsData.map(item => {
                        return <MyPoolRewardCard key={item.marketSymbol} data={item} />
                    })}
            </Grid>
        </Box>
    )
}

function MyPoolRewardCard({ data }: { data: IParsedMakerMarket }) {
    const isMobile = useIsMobile()

    return (
        <Grid
            p="24px"
            templateColumns="1fr 95px"
            justifyContent="space-between"
            bgColor="perp.background.background"
            borderRadius="16px"
        >
            {isMobile ? (
                <>
                    <Grid templateColumns="1fr 1fr 1fr">
                        <Flex alignItems="center">
                            <LiquidityMiningIconFinder symbol={data.baseSymbol} boxSize="40px" />
                            <Grid pl="12px">
                                <Text color="perp.text.primary" fontSize="16px">
                                    {data.base}
                                </Text>
                                <Text color="perp.text.secondary" fontSize="12px">
                                    ${data.netValue}
                                </Text>
                            </Grid>
                        </Flex>
                        <Grid>
                            <Flex alignItems="center">
                                <PERPIcon boxSize="16px" />
                                <Text color="perp.text.primary" fontSize="16px" pl="4px">
                                    {data.estimatedRewardAmount}
                                </Text>
                            </Flex>
                            <Flex alignItems="center">
                                <OPIcon boxSize="16px" />
                                <Text color="perp.text.primary" fontSize="16px" pl="4px">
                                    {data.estimatedRewardOpAmount}
                                </Text>
                            </Flex>
                        </Grid>
                    </Grid>

                    <LiquidityMiningAprTooltip
                        triggerEl={
                            <Flex fontSize="20px" alignItems="center" color="perp.primary.default">
                                <Text w="100%" textAlign="end">
                                    {formatNumber(Big(data.aprTotal))}%
                                </Text>
                            </Flex>
                        }
                        baseAprText={<Text color="perp.primary.default">{formatNumber(Big(data.baseApr))}%</Text>}
                        rewardAprText={<Text color="perp.primary.default">{formatNumber(Big(data.rewardApr))}%</Text>}
                    />
                </>
            ) : (
                <>
                    <Grid templateColumns="1fr 1fr 1fr">
                        <Flex alignItems="center">
                            <LiquidityMiningIconFinder symbol={data.baseSymbol} boxSize="40px" />
                            <Grid pl="12px">
                                <Text color="perp.text.primary" fontSize="16px">
                                    {data.base}
                                </Text>
                                <Text color="perp.text.secondary" fontSize="12px">
                                    ${data.netValue}
                                </Text>
                            </Grid>
                        </Flex>
                        <Grid>
                            <Flex alignItems="center">
                                <Text color="perp.text.primary" fontSize="16px" pr="4px">
                                    {data.estimatedRewardAmount}
                                </Text>
                                <PERPIcon boxSize="16px" />
                            </Flex>
                            <Text color="perp.text.secondary" fontSize="12px">
                                ${data.estimatedRewardUsd}
                            </Text>
                        </Grid>
                        <Grid>
                            <Flex alignItems="center">
                                <Text color="perp.text.primary" fontSize="16px" pr="4px">
                                    {data.estimatedRewardOpAmount}
                                </Text>
                                <OPIcon boxSize="16px" />
                            </Flex>
                            <Text color="perp.text.secondary" fontSize="12px">
                                ${data.estimatedRewardOpUsd}
                            </Text>
                        </Grid>
                    </Grid>

                    <LiquidityMiningAprTooltip
                        triggerEl={
                            <Flex fontSize="20px" alignItems="center" color="perp.primary.default">
                                <Text w="100%" textAlign="end">
                                    {formatNumber(Big(data.aprTotal))}%
                                </Text>
                            </Flex>
                        }
                        baseAprText={<Text color="perp.primary.default">{formatNumber(Big(data.baseApr))}%</Text>}
                        rewardAprText={<Text color="perp.primary.default">{formatNumber(Big(data.rewardApr))}%</Text>}
                    />
                </>
            )}
        </Grid>
    )
}
