import { Motion, Size } from "components/Modal/type"
import { EPOCH } from "constants/config"
import { PerpWalletConnectorContainer } from "hooks/containers/usePerpWalletConnectorContainer"
import { VePerpByUserContainer } from "hooks/containers/useVePerpByUserContainer"
import { useIsMobile } from "hooks/useDevice"
import { EventGroup, EventName, EventTypeInteraction } from "modules/analytics"
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { analyticsClient } from "services/AnalyticsClient"
import { useGetServerTimestampQuery } from "services/AWSClient/flow"

import { useReferralRewardsQuery } from "../flow"
import { referralSlice } from "../slice"

enum OpenModalFnName {
    CLAIM = "openModalClaim",
    CREATE_LOCK = "openModalCreateLock",
    EXTEND_TIME = "openModalExtendTime",
    EXPIRED = "openModalExpired",
}

export function useReferralClaim() {
    const dispatch = useDispatch()
    const { account } = PerpWalletConnectorContainer.useContainer()
    const { data: referralRewardsData } = useReferralRewardsQuery({ account: account! }, { skip: !account })
    const { data: serverTimestampData } = useGetServerTimestampQuery()
    const timestamp = serverTimestampData?.timestamp

    const { hasLockedAlready, hasExpired, lockedPerpTokenValidDateInSec } = VePerpByUserContainer.useContainer()
    const isMobile = useIsMobile()

    const onReferralClaim = useCallback(() => {
        if (!timestamp || !referralRewardsData?.items || !referralRewardsData?.minLockDuration) {
            return
        }
        const fnName = getClaimClickFnName(
            timestamp,
            hasExpired,
            hasLockedAlready,
            referralRewardsData.minLockDuration,
            lockedPerpTokenValidDateInSec,
        )

        const size = isMobile ? Size.FULL : Size.MD
        const motion = Motion.SLIDE_IN_BOTTOM
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.MODAL_OPENED,
            eventName: getEventName(fnName),
        })
        dispatch(
            fnName === OpenModalFnName.CLAIM
                ? referralSlice.actions[fnName]({
                      size,
                      motion,
                      items: referralRewardsData.items,
                  })
                : referralSlice.actions[fnName]({ size, motion }),
        )
    }, [
        dispatch,
        hasExpired,
        hasLockedAlready,
        isMobile,
        lockedPerpTokenValidDateInSec,
        referralRewardsData?.items,
        referralRewardsData?.minLockDuration,
        timestamp,
    ])

    return {
        onReferralClaim,
    }
}

// TODO: use xstate
export function getClaimClickFnName(
    currentTime: number,
    hasExpired: boolean,
    hasLockedAlready?: boolean,
    minLockDuration?: number,
    validDateInSec?: number,
) {
    const epochStartTime = Math.floor(currentTime / EPOCH) * EPOCH
    const isClaimable = minLockDuration && validDateInSec && validDateInSec >= epochStartTime + minLockDuration
    if (hasLockedAlready) {
        if (hasExpired) {
            // NOTE: expired
            return OpenModalFnName.EXPIRED
        } else if (isClaimable) {
            // NOTE: claimable
            return OpenModalFnName.CLAIM
        } else {
            // NOTE: need to extend lock time
            return OpenModalFnName.EXTEND_TIME
        }
    } else {
        // NOTE: no lock
        return OpenModalFnName.CREATE_LOCK
    }
}

function getEventName(fnName: string) {
    switch (fnName) {
        case OpenModalFnName.CLAIM: {
            return EventName.REFERRAL_CLAIM_MODAL_OPENED
        }
        case OpenModalFnName.CREATE_LOCK: {
            return EventName.REFERRAL_CREATE_LOCK_MODAL_OPENED
        }
        case OpenModalFnName.EXTEND_TIME: {
            return EventName.REFERRAL_EXTEND_TIME_MODAL_OPENED
        }
        case OpenModalFnName.EXPIRED: {
            return EventName.REFERRAL_EXPIRED_MODAL_OPENED
        }
        default: {
            return ""
        }
    }
}
