import { createApi } from "@reduxjs/toolkit/query/react"
import { bugsnagClient } from "services/BugsnagClient"

import { coingeckoClient } from "./"

// NOTE: ResultType
interface ICoingeckoPriceData {
    price: number
}

enum TOKEN_LIST {
    PERP = "perpetual-protocol",
    OP = "optimism",
}

async function pricePERPQuery() {
    try {
        const price = await coingeckoClient.getPrice(TOKEN_LIST.PERP)
        return { data: { price } }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}
async function priceOPQuery() {
    try {
        const price = await coingeckoClient.getPrice(TOKEN_LIST.OP)
        return { data: { price } }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}
export const coingeckoClientQueryApi = createApi({
    reducerPath: "coingeckoClient",
    baseQuery: () => ({ data: undefined }),
    endpoints: builder => ({
        coingeckoPriceForPERP: builder.query<ICoingeckoPriceData, void>({
            queryFn: pricePERPQuery,
        }),
        coingeckoPriceForOP: builder.query<ICoingeckoPriceData, void>({
            queryFn: priceOPQuery,
        }),
    }),
})

export const { useCoingeckoPriceForPERPQuery, useCoingeckoPriceForOPQuery } = coingeckoClientQueryApi
