import { METADATA } from "constants/env"
import { CallOverrides } from "ethers"
import { rpcManager, RPCManager } from "lib/RPCManager"
import { bigNum2Big } from "../utils/number"
import { ContractClientFactory, contractClientFactory } from "./ContractClientFactory"

export class ERC20Client {
    private readonly contractClientFactory: ContractClientFactory
    private readonly contractAddress: string
    private readonly rpcManager: RPCManager
    private readonly decimals: number

    constructor(
        contractClientFactory: ContractClientFactory,
        contractAddress: string,
        rpcManager: RPCManager,
        decimals: number,
    ) {
        this.contractClientFactory = contractClientFactory
        this.contractAddress = contractAddress
        this.rpcManager = rpcManager
        this.decimals = decimals
    }

    getContract() {
        return this.contractClientFactory.getERC20(this.contractAddress)
    }

    async balanceOf(address: string, overrides: CallOverrides = {}) {
        const contract = this.getContract()
        return bigNum2Big(await contract.balanceOf(address, overrides), this.decimals)
    }

    async balanceOfAt(address: string, t: number) {
        const { exactBlockNumber, nextBlockNumber } = await rpcManager.getBlockTagAt(t)
        const blockTag = exactBlockNumber || nextBlockNumber
        return await this.balanceOf(address, { blockTag })
    }
}

export const usdcClient = new ERC20Client(contractClientFactory, METADATA.CORE.externalContracts.USDC, rpcManager, 6)
export const perpClient = new ERC20Client(
    contractClientFactory,
    METADATA.VOTING_ESCROW.externalContracts.PERP,
    rpcManager,
    18,
)
