import { Box } from "@chakra-ui/react"
import React from "react"
import { PerpAlert } from "../PerpAlert"
import { BannerContainer } from "../../hooks/containers/useBannerContainer"
import { BANNER_CONTENTS, BANNER_STATUSES } from "./constants"

function Banner() {
    const { isOpen, status = "INFO", bannerContent = "DEFAULT" } = BannerContainer.useContainer()
    const alertStatus = BANNER_STATUSES[status]
    const alertContent = BANNER_CONTENTS[bannerContent]

    return (
        <PerpAlert
            isOpen={isOpen}
            hasClose={false}
            status={alertStatus}
            description={<Box display="flex">{alertContent}</Box>}
        />
    )
}

export { Banner }
