import { Button } from "./button"
import { Checkbox } from "./checkbox"
import { CloseButton } from "./closeButton"
import { Heading } from "./heading"
import { Input } from "./input"
import { Menu } from "./menu"
import { Modal } from "./modal"
import { Skeleton } from "./skeleton"
import { Slider } from "./slider"
import { Text } from "./text"

export const components = {
    Slider,
    Button,
    CloseButton,
    Skeleton,
    Menu,
    Modal,
    Checkbox,
    Heading,
    Text,
    Input,
}
