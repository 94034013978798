import { Box, Button, Flex, Skeleton, Text } from "@chakra-ui/react"
import { PlusIcon } from "components/Icons/PlusIcon"
import { Motion, Size } from "components/Modal/type"
import { NOTIFICATION_DURATION } from "constants/config"
import { PerpWalletConnectorContainer } from "hooks/containers/usePerpWalletConnectorContainer"
import { useIsMobile } from "hooks/useDevice"
import { analyticsTx, EventGroup, EventName, EventTypeInteraction } from "modules/analytics"
import { IStatus, notificationSlice } from "modules/notification"
import { referralSlice } from "modules/referral/slice"
import { useVePerpInfo, useVePerpRedeemMutation } from "modules/vePerp"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { analyticsClient } from "services/AnalyticsClient"
import { useGetServerTimestampQuery } from "services/AWSClient/flow"
import { useAppSelector } from "store"

import { useReferralDelegateInfoQuery } from "../../flow"
import { getEligibleWeeks } from "../../util"
import { ModalCreateLock } from "./ModalCreateLock"
import { ModalExtendTime } from "./ModalExtendTime"
import { ModalIncreaseAmount } from "./ModalIncreaseAmount"

export function ReferralStatusFooter() {
    const dispatch = useDispatch()
    const { isTxLoading } = useAppSelector(state => state.wallet)
    const { open } = notificationSlice.actions
    const { isLoading, hasLockedAlready, hasExpired } = useVePerpInfo()
    const [redeem] = useVePerpRedeemMutation()

    // NOTE: modal states
    const isMobile = useIsMobile()
    const size = isMobile ? Size.FULL : Size.MD
    const motion = Motion.SLIDE_IN_BOTTOM

    const { account } = PerpWalletConnectorContainer.useContainer()
    const { lockedEndTimestamp, lockedPerpTokenAmount } = useVePerpInfo()
    const { data: delegateInfo } = useReferralDelegateInfoQuery({ account: account! }, { skip: !account })
    const { data: serverTimeData } = useGetServerTimestampQuery()
    const timestamp = serverTimeData?.timestamp
    const qualifiedMultiplier = delegateInfo?.qualifiedMultiplier
    const eligibleWeeks =
        timestamp && qualifiedMultiplier !== undefined && lockedEndTimestamp && lockedPerpTokenAmount?.gt(0)
            ? getEligibleWeeks(10, qualifiedMultiplier, timestamp, lockedEndTimestamp, lockedPerpTokenAmount)
            : undefined

    // NOTE: handlers
    const handleLock = useCallback(async () => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.MODAL_OPENED,
            eventName: EventName.REFERRAL_CREATE_LOCK_BY_MY_STATUS_MODAL_OPENED,
        })
        dispatch(referralSlice.actions.openModalCreateLockByMyStatus({ size, motion }))
    }, [dispatch, motion, size])

    const handleExtend = useCallback(async () => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.MODAL_OPENED,
            eventName: EventName.REFERRAL_EXTEND_TIME_BY_MY_STATUS_MODAL_OPENED,
        })
        dispatch(referralSlice.actions.openModalExtendTimeByMyStatus({ size, motion }))
    }, [dispatch, motion, size])

    const handleIncrease = useCallback(async () => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.MODAL_OPENED,
            eventName: EventName.REFERRAL_INCREASE_AMOUNT_BY_MY_STATUS_MODAL_OPENED,
        })
        dispatch(referralSlice.actions.openModalIncreaseAmountByMyStatus({ size, motion }))
    }, [dispatch, motion, size])

    const handleUnlock = useCallback(async () => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.BUTTON_CLICKED,
            eventName: EventName.REFERRAL_UNLOCK_BY_MY_STATUS_BUTTON_CLICKED,
        })
        const result = await redeem()
        if ("error" in result) {
            analyticsTx.vePerp.withdraw.failed(result.error)
            dispatch(
                open({
                    status: IStatus.error,
                    description: "PERP unlock failed.",
                    duration: NOTIFICATION_DURATION.SHORT,
                }),
            )
        } else {
            analyticsTx.vePerp.withdraw.succeeded(result.data)
            dispatch(
                open({
                    status: IStatus.success,
                    description: "PERP unlocked successfully. Please continue to claim your rewards.",
                    duration: NOTIFICATION_DURATION.SHORT,
                }),
            )
        }
    }, [dispatch, open, redeem])

    return (
        <Skeleton isLoaded={!isLoading}>
            <Box bg="perp.background.background" borderRadius="12px" p="12px" pl="24px" mt="16px">
                {!hasLockedAlready && (
                    <Flex alignItems="center" justifyContent="space-between">
                        <Text>Lock PERP to receive weekly rewards.</Text>
                        <Button onClick={handleLock}>+ Lock PERP</Button>
                    </Flex>
                )}
                {hasLockedAlready && !hasExpired && (
                    <Flex alignItems="center" justifyContent="space-between">
                        <Text>
                            Eligible for the next{" "}
                            <Text as="span" color="perp.primary.default">
                                {eligibleWeeks}
                            </Text>{" "}
                            weeks.
                        </Text>
                        <Flex gap="8px">
                            <Button onClick={handleExtend}>
                                <PlusIcon boxSize="14px" mr="4px" />
                                Extend
                            </Button>
                            <Button onClick={handleIncrease}>
                                <PlusIcon boxSize="14px" mr="4px" />
                                Increase
                            </Button>
                        </Flex>
                    </Flex>
                )}
                {hasLockedAlready && hasExpired && (
                    <Flex alignItems="center" justifyContent="space-between">
                        <Text>Your Lock Time has ended.</Text>
                        <Button onClick={handleUnlock} isLoading={isTxLoading} disabled={isTxLoading}>
                            Unlock PERP
                        </Button>
                    </Flex>
                )}
            </Box>
            <ModalCreateLock />
            <ModalExtendTime />
            <ModalIncreaseAmount />
        </Skeleton>
    )
}
