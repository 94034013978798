import { Box, Flex, Grid, Text } from "@chakra-ui/react"
import Big from "big.js"
import { OPIcon } from "components/Icons/Tokens/OPIcon"
import { PERPIcon } from "components/Icons/Tokens/PERPIcon"
import { useIsMobile } from "hooks/useDevice"
import React from "react"
import { formatNumber } from "utils/format"

import { useDefaultMarketsQuery } from "../flow"
import { selectorDefaultMarkets } from "../selector"
import IParsedDefaultMarket from "../selector/selectorDefaultMarkets"
import { LiquidityMiningAprTooltip } from "./LiquidityMiningAprTooltip"
import { LiquidityMiningIconFinder } from "./LiquidityMiningIconFinder"

function LiquidityMiningPoolRewards() {
    const { data: defaultMarketsData } = useDefaultMarketsQuery(undefined, { selectFromResult: selectorDefaultMarkets })
    const isMobile = useIsMobile()
    return (
        <Box mt="96px">
            <Text fontWeight="bold" fontSize="24px" color="perp.text.primary">
                Pool Rewards
            </Text>
            <Grid
                mt="48px"
                px="24px"
                fontSize="14px"
                color="perp.text.secondary"
                templateColumns="1fr 95px"
                justifyContent="space-between"
            >
                {isMobile ? (
                    <>
                        <Grid templateColumns="1fr 1fr">
                            <Text>Pools</Text>
                            <Text>OP & PERP </Text>
                        </Grid>
                        <Text textAlign="end">APR</Text>
                    </>
                ) : (
                    <>
                        <Grid templateColumns="1fr 1fr 1fr">
                            <Text>Pools</Text>
                            <Text>Total PERP</Text>
                            <Text>Total OP</Text>
                        </Grid>
                        <Text textAlign="end">Pool APR</Text>
                    </>
                )}
            </Grid>
            <Grid gap="8px" mt="8px">
                {defaultMarketsData &&
                    defaultMarketsData.map(item => {
                        return <PoolRewardCard key={item.marketSymbol} data={item} />
                    })}
            </Grid>
        </Box>
    )
}

function PoolRewardCard({ data }: { data: IParsedDefaultMarket }) {
    const isMobile = useIsMobile()
    return (
        <Grid
            p="24px"
            templateColumns="1fr 95px"
            justifyContent="space-between"
            bgColor="perp.background.background"
            borderRadius="16px"
        >
            {isMobile ? (
                <>
                    <Grid templateColumns="1fr 1fr">
                        <Flex alignItems="center">
                            <LiquidityMiningIconFinder symbol={data.baseSymbol} boxSize="32px" />
                            <Grid pl="8px">
                                <Text color="perp.text.primary" fontSize="14px">
                                    {data.base}
                                </Text>
                                <Text color="perp.text.secondary" fontSize="12px">
                                    {data.baseFullName}
                                </Text>
                            </Grid>
                        </Flex>
                        <Grid>
                            <Flex alignItems="center">
                                <PERPIcon boxSize="16px" />
                                <Text color="perp.text.primary" fontSize="16px" pl="4px">
                                    {data.rewardCapWeeklyPerp}
                                </Text>
                            </Flex>
                            <Flex alignItems="center">
                                <OPIcon boxSize="16px" />
                                <Text color="perp.text.primary" fontSize="16px" pl="4px">
                                    {data.rewardCapWeeklyOp}
                                </Text>
                            </Flex>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid templateColumns="1fr 1fr 1fr">
                        <Flex alignItems="center">
                            <LiquidityMiningIconFinder symbol={data.baseSymbol} boxSize="40px" />
                            <Grid pl="12px">
                                <Text color="perp.text.primary" fontSize="16px">
                                    {data.base}
                                </Text>
                                <Text color="perp.text.secondary" fontSize="12px">
                                    {data.baseFullName}
                                </Text>
                            </Grid>
                        </Flex>
                        <Grid>
                            <Flex alignItems="center">
                                <Text color="perp.text.primary" fontSize="16px" pr="4px">
                                    {data.rewardCapWeeklyPerp}
                                </Text>
                                <PERPIcon boxSize="16px" />
                            </Flex>
                            <Text color="perp.text.secondary" fontSize="12px">
                                {data.rewardCapWeeklyPerp}
                            </Text>
                        </Grid>
                        <Grid>
                            <Flex alignItems="center">
                                <Text color="perp.text.primary" fontSize="16px" pr="4px">
                                    {data.rewardCapWeeklyOp}
                                </Text>
                                <OPIcon boxSize="16px" />
                            </Flex>
                            <Text color="perp.text.secondary" fontSize="12px">
                                {data.rewardCapWeeklyOp}
                            </Text>
                        </Grid>
                    </Grid>
                </>
            )}

            <LiquidityMiningAprTooltip
                triggerEl={
                    <Flex fontSize="20px" alignItems="center">
                        <Text>{formatNumber(Big(data.lowerAprTotal), { maximumFractionDigits: 1 })}%</Text>-
                        <Text>{formatNumber(Big(data.upperAprTotal), { maximumFractionDigits: 1 })}%</Text>
                    </Flex>
                }
                baseAprText={
                    <Text color="perp.primary.default">
                        {formatNumber(Big(data.lowerBaseApr))}%-{formatNumber(Big(data.upperBaseApr))}%
                    </Text>
                }
                rewardAprText={
                    <Text color="perp.primary.default">
                        {formatNumber(Big(data.lowerRewardApr))}%-{formatNumber(Big(data.upperRewardApr))}%
                    </Text>
                }
            />
        </Grid>
    )
}

export { LiquidityMiningPoolRewards }
