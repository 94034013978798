import { NOTIFICATION_DURATION } from "constants/config"
import { PerpWalletConnectorContainer } from "hooks/containers/usePerpWalletConnectorContainer"
import { analyticsTx, EventGroup, EventName, EventTypeInteraction } from "modules/analytics"
import { IStatus, notificationSlice } from "modules/notification"
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { analyticsClient } from "services/AnalyticsClient"
import { AppDispatch } from "store"

import { useVePERPEmissionFeeDistributorClaimMutation, useVePERPEmissionFeeDistributorClaimableQuery } from "../flow"

export function useVePERPEmissionFeeDistributorClaim() {
    const dispatch = useDispatch<AppDispatch>()
    const { account } = PerpWalletConnectorContainer.useContainer()
    const { data, isLoading, refetch } = useVePERPEmissionFeeDistributorClaimableQuery(
        { account: account! },
        { skip: !account },
    )
    const [vePERPEmissionFeeDistributorClaim] = useVePERPEmissionFeeDistributorClaimMutation()
    const { open } = notificationSlice.actions

    const handleClaim = useCallback(async () => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.BUTTON_CLICKED,
            eventName: EventName.EMISSION_CLAIM_BUTTON_CLICKED,
        })
        const result = await vePERPEmissionFeeDistributorClaim()
        if ("error" in result) {
            analyticsTx.feeDistributor.claim.failed(result.error)
            dispatch(
                open({
                    status: IStatus.error,
                    description: "Claim rewards failed.",
                    duration: NOTIFICATION_DURATION.SHORT,
                }),
            )
        } else {
            analyticsTx.feeDistributor.claim.succeeded(result.data)
            refetch()
            dispatch(
                open({
                    status: IStatus.success,
                    description: "PERP rewards successfully claimed! Please check your wallet.",
                }),
            )
        }
    }, [dispatch, vePERPEmissionFeeDistributorClaim, open, refetch])

    return {
        data,
        isLoading,
        handleClaim,
    }
}
