import React from "react"
import { Grid } from "@chakra-ui/react"
import { EcosystemMap } from "./EcosystemMap"

function Footer() {
    return (
        <Grid mb={["69px"]} pt="80px" pb="40px" gap="80px">
            <EcosystemMap />
        </Grid>
    )
}

export { Footer }
