import { Buffer } from "buffer"
import { ErrorView } from "components/ErrorView"
import { ConnectKitProvider } from "connectkit"
import React, { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { analyticsClient } from "services/AnalyticsClient"
import { bugsnagClient } from "services/BugsnagClient"
import { CmsProvider } from "services/CMSClient/CmsProvider"
import { wagmi } from "services/Wagmi"
import { WagmiConfig } from "wagmi"

import { ProviderRegister } from "./components/ProviderRegister.tsx"
import { BannerContainer } from "./hooks/containers/useBannerContainer"
import { ContractsContainer } from "./hooks/containers/useContractsContainer"
import { Erc20PERPContainer } from "./hooks/containers/useErc20PERPContainer"
import { PerpWalletConnectorContainer } from "./hooks/containers/usePerpWalletConnectorContainer"
import { VePerpByUserContainer } from "./hooks/containers/useVePerpByUserContainer"
import { App } from "./pages/App"
import { store } from "./store"
import { ThemeProvider } from "./theme"

// NOTE: for [Wallet Connect, Coinbase Wallet]
window.Buffer = window.Buffer || Buffer

// NOTE: init app services
analyticsClient.init()
bugsnagClient.init()
const ErrorBoundary = bugsnagClient.createErrorBoundary()

const providers = [
    { component: Provider, props: { store } },
    { component: BrowserRouter },
    { component: ThemeProvider },
    { component: BannerContainer.Provider },
    { component: WagmiConfig, props: { client: wagmi.getClient() } },
    { component: ConnectKitProvider, props: { mode: "dark", walletConnectName: "WalletConnect" } },
    { component: PerpWalletConnectorContainer.Provider },
    { component: ContractsContainer.Provider },
    { component: VePerpByUserContainer.Provider },
    { component: Erc20PERPContainer.Provider },
    { component: CmsProvider },
]

const container = document.getElementById("root") as HTMLElement
const root = createRoot(container)

root.render(
    <StrictMode>
        <ErrorBoundary FallbackComponent={ErrorView}>
            <ProviderRegister providers={providers}>
                <App />
            </ProviderRegister>
        </ErrorBoundary>
    </StrictMode>,
)
