import React, { ReactNode } from "react"

function ProviderRegister({ providers, children }: { providers: any[]; children: ReactNode }) {
    return providers.reduceRight((children, provider) => {
        const Provider = provider.component || provider
        const props = provider.props || {}
        return <Provider {...props}>{children}</Provider>
    }, children)
}

export { ProviderRegister }
