import { METADATA } from "constants/env"

import { ContractClientFactory, contractClientFactory } from "./ContractClientFactory"

class PerpReferrerClient {
    private readonly contractClientFactory: ContractClientFactory
    private readonly contractAddress: string

    constructor(contractClientFactory: ContractClientFactory, contractAddress: string) {
        this.contractClientFactory = contractClientFactory
        this.contractAddress = contractAddress
    }

    async getReferralCodeByPartnerAddress(address: string): Promise<string> {
        const contract = this.contractClientFactory.getPerpReferrer(this.contractAddress)
        return await contract.getReferralCodeByPartnerAddress(address)
    }

    async getRefereeCodeByTraderAddress(address: string): Promise<string> {
        const contract = this.contractClientFactory.getPerpReferrer(this.contractAddress)
        return await contract.getRefereeCodeByTraderAddress(address)
    }

    getCreateReferralCodeTx(code: string) {
        const contract = this.contractClientFactory.getPerpReferrer(this.contractAddress)
        return this.contractClientFactory.getTx(contract, "createReferralCode", [code])
    }

    getSetReferralCodeTx(code: string) {
        const contract = this.contractClientFactory.getPerpReferrer(this.contractAddress)
        return this.contractClientFactory.getTx(contract, "setReferralCode", [code])
    }
}

export const perpReferrerClient = new PerpReferrerClient(
    contractClientFactory,
    METADATA.PERIPHERY.externalContracts.PerpetualProtocolReferrer,
)
