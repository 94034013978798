import { InfoIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, HStack, Skeleton, Text } from "@chakra-ui/react"
import { Motion, Size } from "components/Modal/type"
import { PerpWalletConnectorContainer } from "hooks/containers/usePerpWalletConnectorContainer"
import { EventGroup, EventName, EventTypeInteraction } from "modules/analytics"
import { lazyRiverSlice } from "modules/lazyRiver/slice"
import { useVePerpInfo, useVePerpRedeemMutation } from "modules/vePerp"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { analyticsClient } from "services/AnalyticsClient"
import { ModalCreateLock } from "../ModalCreateLock"
import { ModalExtendTime } from "../ModalExtendTime"
import { ModalIncreaseAmount } from "../ModalIncreaseAmount"

function Footer() {
    const { weeksToExpiration, isLoading, hasExpired, hasLockedAlready } = useVePerpInfo()
    const { account } = PerpWalletConnectorContainer.useContainer()
    const eligibleWeeks = weeksToExpiration && weeksToExpiration - 1

    // NOTE: modal handler
    const dispatch = useDispatch()
    const [redeem] = useVePerpRedeemMutation()
    const handleCreateLock = useCallback(() => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.MODAL_OPENED,
            eventName: EventName.EMISSION_CREATE_LOCK_MODAL_OPENED,
        })
        dispatch(lazyRiverSlice.actions.openModalCreateLock({ size: Size.MD, motion: Motion.SLIDE_IN_BOTTOM }))
    }, [dispatch])
    const handleExtendTime = useCallback(() => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.MODAL_OPENED,
            eventName: EventName.EMISSION_EXTEND_TIME_MODAL_OPENED,
        })
        dispatch(lazyRiverSlice.actions.openModalExtendTime({ size: Size.MD, motion: Motion.SLIDE_IN_BOTTOM }))
    }, [dispatch])
    const handleIncreaseAmount = useCallback(() => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.MODAL_OPENED,
            eventName: EventName.EMISSION_INCREASE_AMOUNT_MODAL_OPENED,
        })
        dispatch(lazyRiverSlice.actions.openModalIncreaseAmount({ size: Size.MD, motion: Motion.SLIDE_IN_BOTTOM }))
    }, [dispatch])
    const handleUnlock = useCallback(() => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.BUTTON_CLICKED,
            eventName: EventName.EMISSION_UNLOCK_BUTTON_CLICKED,
        })
        redeem()
    }, [redeem])

    return (
        <Box>
            {/* Helper box for lock or extend */}
            {account && (
                <Skeleton isLoaded={!isLoading}>
                    <Box bg="perp.background.selected" borderRadius="12px" p="16px" alignContent="center">
                        {hasExpired && (
                            <Flex flexDirection={["column", "row"]} alignItems={["stretch", "center"]} gap="16px">
                                <HStack spacing={2} w="100%">
                                    <InfoIcon boxSize={"24px"} color={"perp.primary.default"} />
                                    <Text fontSize="14px">Your lock time is up. Please unlock and re-lock again.</Text>
                                </HStack>
                                <Flex justifyContent={"space-between"} gap={"8px"}>
                                    <Button width={"100%"} onClick={handleUnlock}>
                                        Unlock
                                    </Button>
                                </Flex>
                            </Flex>
                        )}
                        {!hasExpired && !hasLockedAlready && (
                            <Flex flexDirection={["column", "row"]} alignItems={["stretch", "center"]} gap="16px">
                                <HStack spacing={2} w="100%">
                                    <InfoIcon boxSize={"14px"} color={"perp.primary.default"} />
                                    <Text fontSize="14px">Lock PERP to receive weekly rewards.</Text>
                                </HStack>
                                <Flex justifyContent={"space-between"} gap={"8px"}>
                                    <Button width={"100%"} onClick={handleCreateLock}>
                                        + Lock PERP
                                    </Button>
                                </Flex>
                            </Flex>
                        )}
                        {!hasExpired && hasLockedAlready && eligibleWeeks === 0 && (
                            <Flex flexDirection={["column", "row"]} alignItems={["stretch", "center"]} gap="16px">
                                <HStack spacing={2} w="100%">
                                    <InfoIcon boxSize={"14px"} color={"perp.primary.default"} />
                                    <Text fontSize="14px">
                                        Eligible for the next{" "}
                                        <Text as="span" color={"perp.primary.default"}>
                                            {eligibleWeeks}
                                        </Text>{" "}
                                        weeks.
                                    </Text>
                                </HStack>
                                <Flex justifyContent={"space-between"} gap={"8px"}>
                                    <Button width={"100%"} onClick={handleExtendTime}>
                                        + Extend
                                    </Button>
                                    <Button width={"100%"} isDisabled>
                                        + Increase
                                    </Button>
                                </Flex>
                            </Flex>
                        )}
                        {!hasExpired && hasLockedAlready && eligibleWeeks !== undefined && eligibleWeeks > 0 && (
                            <Flex flexDirection={["column", "row"]} alignItems={["stretch", "center"]} gap="16px">
                                <HStack spacing={2} w="100%">
                                    <InfoIcon boxSize={"14px"} color={"perp.primary.default"} />
                                    <Text fontSize="14px">
                                        Eligible for the next{" "}
                                        <Text as="span" color={"perp.primary.default"}>
                                            {eligibleWeeks}
                                        </Text>{" "}
                                        weeks.
                                    </Text>
                                </HStack>
                                <Flex justifyContent={"space-between"} gap={"8px"}>
                                    <Button width={"100%"} onClick={handleExtendTime}>
                                        + Extend
                                    </Button>
                                    <Button width={"100%"} onClick={handleIncreaseAmount}>
                                        + Increase
                                    </Button>
                                </Flex>
                            </Flex>
                        )}
                    </Box>
                </Skeleton>
            )}
            <ModalCreateLock />
            <ModalExtendTime />
            <ModalIncreaseAmount />
        </Box>
    )
}

export default Footer
