import { Box, Container, Flex, Heading, VStack } from "@chakra-ui/react"
import { useProgramDetail } from "hooks/useCMS"
import React from "react"

export function LazyRiverHeader() {
    const { data } = useProgramDetail("lazy-river")

    return (
        <Box
            color="perp.text.primary"
            pt={{ base: "96px", sm: "112px", lg: "144px" }}
            pb={{ base: "48px", sm: "64px", lg: "72px" }}
            backgroundImage={
                data &&
                data.program && {
                    base: data.program.banner.sm.url,
                    sm: data.program.banner.md.url,
                    xl: data.program.banner.lg.url,
                }
            }
            backgroundSize={{ base: "contain", sm: "cover", xl: "cover" }}
            backgroundPosition={{ base: "right bottom", sm: "right bottom", xl: "right" }}
            backgroundRepeat="no-repeat"
        >
            <Container maxW="1180px" h="100%" display="flex" pl="24px">
                <VStack alignItems={"flex-start"}>
                    <Box paddingBottom={4}>
                        <a style={{ color: "rgb(114, 249, 163)" }} href={"https://perp.com/staking"}>
                            perp.com/staking →
                        </a>
                    </Box>
                    <Flex alignItems="center">
                        <Heading variant={"h1"} borderLeft="3px solid" borderColor="perp.primary.default" pl="24px">
                            Lazy River 2.0
                        </Heading>
                    </Flex>
                </VStack>
            </Container>
        </Box>
    )
}
