import { QueryCacheLifecycleApi } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import { createApi } from "@reduxjs/toolkit/dist/query/react"
import Big from "big.js"
import { POLLING_PERIOD } from "constants/config"
import { usdcClient } from "contracts/ERC20Client"
import { insuranceFundClient } from "contracts/InsuranceFundClient"
import { vaultClient } from "contracts/VaultClient"
import { bugsnagClient } from "services/BugsnagClient"
import { TREASURY_DAO_ADDRESS } from "constants/config"
export interface IInsuranceFundMetadata {
    distributionThreshold: Big
    capacity: Big
    freeCollateralUSDC: Big
}

export interface IInsuranceFundTreasuryDAOAtArgs {
    weekStartTimestamp: number
}

export interface IInsuranceFundTreasuryDAOAt {
    usdcBalance: Big
}

export interface IInsuranceFundTreasuryDAO {
    usdcBalance: Big
}

export const insuranceFundQueryApi = createApi({
    reducerPath: "insuranceFundQuery",
    baseQuery: () => ({ data: undefined }),
    endpoints: builder => ({
        insuranceFundMetadata: builder.query<IInsuranceFundMetadata, void>({
            queryFn: insuranceFundMetadataQuery,
            onCacheEntryAdded: insuranceFundDataCache,
        }),
        insuranceFundTreasuryDAO: builder.query<IInsuranceFundTreasuryDAO, void>({
            queryFn: insuranceFundTreasuryDAOQuery,
            onCacheEntryAdded: insuranceFundTreasuryDAODataCache,
        }),
        insuranceFundTreasuryDAOAt: builder.query<IInsuranceFundTreasuryDAOAt, IInsuranceFundTreasuryDAOAtArgs>({
            queryFn: insuranceFundTreasuryDAOAtQuery,
        }),
    }),
})

export const { useInsuranceFundMetadataQuery, useInsuranceFundTreasuryDAOAtQuery, useInsuranceFundTreasuryDAOQuery } =
    insuranceFundQueryApi

async function insuranceFundMetadataQuery() {
    try {
        const [distributionThreshold, capacity, freeCollateralUSDC] = await Promise.all([
            insuranceFundClient.getDistributionThreshold(),
            insuranceFundClient.getInsuranceFundCapacity(),
            vaultClient.getFreeCollateralByToken(
                insuranceFundClient.getContract().address,
                usdcClient.getContract().address,
                6,
            ),
        ])
        return {
            data: {
                distributionThreshold,
                capacity,
                freeCollateralUSDC,
            },
        }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}

async function insuranceFundTreasuryDAOQuery() {
    try {
        const [usdcBalance] = await Promise.all([usdcClient.balanceOf(TREASURY_DAO_ADDRESS)])
        return {
            data: {
                usdcBalance,
            },
        }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}

async function insuranceFundTreasuryDAOAtQuery(args: IInsuranceFundTreasuryDAOAtArgs) {
    try {
        const { weekStartTimestamp } = args
        const [usdcBalance] = await Promise.all([usdcClient.balanceOfAt(TREASURY_DAO_ADDRESS, weekStartTimestamp)])
        return {
            data: {
                usdcBalance,
            },
        }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}

// NOTE: Query api
type IQueryApi = QueryCacheLifecycleApi<void, any, IInsuranceFundMetadata, "insuranceFundQuery">
async function insuranceFundDataCache(_: void, api: IQueryApi) {
    try {
        const { cacheDataLoaded, cacheEntryRemoved } = api
        await cacheDataLoaded
        const handlerInsuranceFundMetadata = getHandlerInsuranceFundMetadata(api)
        const timer = setInterval(handlerInsuranceFundMetadata, POLLING_PERIOD.SHORT)
        await cacheEntryRemoved
        clearInterval(timer)
    } catch (error) {
        bugsnagClient.sendError(error as Error)
    }
}

type IInsuranceFundTreasuryDAOQueryApi = QueryCacheLifecycleApi<
    void,
    any,
    IInsuranceFundTreasuryDAO,
    "insuranceFundQuery"
>
async function insuranceFundTreasuryDAODataCache(_: void, api: IInsuranceFundTreasuryDAOQueryApi) {
    try {
        const { cacheDataLoaded, cacheEntryRemoved } = api
        await cacheDataLoaded
        const handlerInsuranceFundTreasuryDAO = getHandlerInsuranceFundTreasuryDAO(api)
        const timer = setInterval(handlerInsuranceFundTreasuryDAO, POLLING_PERIOD.SHORT)
        await cacheEntryRemoved
        clearInterval(timer)
    } catch (error) {
        bugsnagClient.sendError(error as Error)
    }
}

function getHandlerInsuranceFundMetadata(api: IQueryApi) {
    return async () => {
        const { updateCachedData } = api
        const [distributionThreshold, capacity, freeCollateralUSDC] = await Promise.all([
            insuranceFundClient.getDistributionThreshold(),
            insuranceFundClient.getInsuranceFundCapacity(),
            vaultClient.getFreeCollateralByToken(
                insuranceFundClient.getContract().address,
                usdcClient.getContract().address,
                6,
            ),
        ])
        updateCachedData((state: IInsuranceFundMetadata) => {
            state.distributionThreshold = distributionThreshold
            state.capacity = capacity
            state.freeCollateralUSDC = freeCollateralUSDC
        })
    }
}

function getHandlerInsuranceFundTreasuryDAO(api: IInsuranceFundTreasuryDAOQueryApi) {
    return async () => {
        const { updateCachedData } = api
        const [usdcBalance] = await Promise.all([usdcClient.balanceOf(TREASURY_DAO_ADDRESS)])
        updateCachedData((state: IInsuranceFundTreasuryDAO) => {
            state.usdcBalance = usdcBalance
        })
    }
}
