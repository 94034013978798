/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type { IMerkleRedeem, IMerkleRedeemInterface } from "../IMerkleRedeem";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "_claimant",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "_balance",
        type: "uint256",
      },
    ],
    name: "Claimed",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_liquidityProvider",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_begin",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_end",
        type: "uint256",
      },
    ],
    name: "claimStatus",
    outputs: [
      {
        internalType: "bool[]",
        name: "",
        type: "bool[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_liquidityProvider",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_week",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_claimedBalance",
        type: "uint256",
      },
      {
        internalType: "bytes32[]",
        name: "_merkleProof",
        type: "bytes32[]",
      },
    ],
    name: "claimWeek",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_liquidityProvider",
        type: "address",
      },
      {
        components: [
          {
            internalType: "uint256",
            name: "week",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "balance",
            type: "uint256",
          },
          {
            internalType: "bytes32[]",
            name: "merkleProof",
            type: "bytes32[]",
          },
        ],
        internalType: "struct IMerkleRedeem.Claim[]",
        name: "claims",
        type: "tuple[]",
      },
    ],
    name: "claimWeeks",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getToken",
    outputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_begin",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_end",
        type: "uint256",
      },
    ],
    name: "merkleRoots",
    outputs: [
      {
        internalType: "bytes32[]",
        name: "",
        type: "bytes32[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_week",
        type: "uint256",
      },
      {
        internalType: "bytes32",
        name: "_merkleRoot",
        type: "bytes32",
      },
      {
        internalType: "uint256",
        name: "_totalAllocation",
        type: "uint256",
      },
    ],
    name: "seedAllocations",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_liquidityProvider",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_week",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_claimedBalance",
        type: "uint256",
      },
      {
        internalType: "bytes32[]",
        name: "_merkleProof",
        type: "bytes32[]",
      },
    ],
    name: "verifyClaim",
    outputs: [
      {
        internalType: "bool",
        name: "valid",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class IMerkleRedeem__factory {
  static readonly abi = _abi;
  static createInterface(): IMerkleRedeemInterface {
    return new utils.Interface(_abi) as IMerkleRedeemInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IMerkleRedeem {
    return new Contract(address, _abi, signerOrProvider) as IMerkleRedeem;
  }
}
