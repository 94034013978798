import { METADATA } from "constants/env"
import { ContractTransaction } from "ethers"
import { Claim } from "modules/claim"

import { big2BigNum, bigNum2Big } from "../utils/number"
import { ContractClientFactory, contractClientFactory } from "./ContractClientFactory"

class VePerpRewardDistributorClient {
    private readonly contractClientFactory: ContractClientFactory
    private readonly contractAddress: string

    constructor(contractClientFactory: ContractClientFactory, contractAddress: string) {
        this.contractClientFactory = contractClientFactory
        this.contractAddress = contractAddress
    }

    async getMinLockDuration(): Promise<number> {
        const contract = this.contractClientFactory.getVePERPRewardDistributor(this.contractAddress)
        const minLockDuration = await contract.getMinLockDuration()
        return bigNum2Big(minLockDuration, 0).toNumber()
    }

    getClaimWeekTx(liquidityProvider: string, claim: Claim) {
        const contract = this.contractClientFactory.getVePERPRewardDistributor(this.contractAddress)
        return this.contractClientFactory.getTx(contract, "claimWeek", [
            liquidityProvider,
            claim.week,
            big2BigNum(claim.balance),
            claim.merkleProof,
        ])
    }

    getClaimWeeksTx(liquidityProvider: string, claims: Claim[]): Promise<ContractTransaction> {
        const contract = this.contractClientFactory.getVePERPRewardDistributor(this.contractAddress)
        const parsedClaims = claims.map(claim => ({
            week: claim.week,
            balance: big2BigNum(claim.balance),
            merkleProof: claim.merkleProof,
        }))
        return this.contractClientFactory.getTx(contract, "claimWeeks", [liquidityProvider, parsedClaims])
    }
}

const { LIQUIDITY_MINING } = METADATA
export const referralDistributorClient = new VePerpRewardDistributorClient(
    contractClientFactory,
    LIQUIDITY_MINING.contracts.vePERPReferralRewardDistributor.address,
)
export const LMDistributorClient = new VePerpRewardDistributorClient(
    contractClientFactory,
    // @ts-ignore // TODO: remove ts ignore when optimism METADATA supports LM contract
    LIQUIDITY_MINING.contracts.vePERPLiquidityMining?.address || "",
)
