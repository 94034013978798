import { Modal as ChakraModal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Portal } from "@chakra-ui/react"
import { CreateLockData, LockerCreateLock } from "components/Locker/LockerCreateLock"
import { Header } from "components/Modal/Header"
import { EventGroup, EventName, EventTypeInteraction } from "modules/analytics"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { analyticsClient } from "services/AnalyticsClient"
import { useAppSelector } from "store"

import { homeSlice } from "../slice"

function ModalCreateLock() {
    const { isOpen, size, motion } = useAppSelector(state => state.home.modal.createLock)

    const dispatch = useDispatch()
    const { closeModalCreateLock } = homeSlice.actions

    const handleClick = useCallback(({ lockAmount, lockWeeks }: CreateLockData) => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.BUTTON_CLICKED,
            eventName: EventName.HOME_CREATE_LOCK_BUTTON_CLICKED,
            payload: { lockAmount: lockAmount.toString(), lockWeeks },
        })
    }, [])

    const handleClose = useCallback(() => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.MODAL_CLOSED,
            eventName: EventName.HOME_CREATE_LOCK_MODAL_CLOSED,
        })
        dispatch(closeModalCreateLock())
    }, [closeModalCreateLock, dispatch])

    return (
        <Portal>
            <ChakraModal onClose={handleClose} motionPreset={motion} size={size} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent
                    bg="perp.background.background"
                    color="perp.text.primary"
                    boxShadow="0px 20px 20px 0px rgba(0, 0, 0, 0.15)"
                >
                    <Header onCloseButtonClick={handleClose}>Lock PERP</Header>
                    <ModalBody>
                        <LockerCreateLock onClick={handleClick} onFinished={handleClose} />
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </ChakraModal>
        </Portal>
    )
}

export { ModalCreateLock }
