import { useState } from "react"
import { DEFAULT_WEEKS_MAX, DEFAULT_WEEKS_MIN } from "./constants"

export function useLockerSlider(weeksToExpiration?: number, minStartWeek?: number) {
    let to = DEFAULT_WEEKS_MAX
    if (weeksToExpiration) {
        to = DEFAULT_WEEKS_MAX - weeksToExpiration
        if (to < 0) {
            to = 0
        }
    }
    const isDisabled = to === 0
    const from = minStartWeek && minStartWeek > 0 && minStartWeek < to ? minStartWeek : DEFAULT_WEEKS_MIN
    const [week, setWeek] = useState<number>(from)

    return {
        setWeek,
        isDisabled,
        week: isDisabled ? 0 : week,
        from: isDisabled ? 0 : from,
        to: isDisabled ? 1 : to,
    }
}
