import { TransactionReceipt } from "@ethersproject/providers"
import { createApi } from "@reduxjs/toolkit/dist/query/react"
import Big from "big.js"
import { IS_PRODUCTION } from "constants/env"
import { vePERPDistributorClient } from "contracts/VeTokenDistributorClient"
import { awsClient } from "services/AWSClient"
import { bugsnagClient } from "services/BugsnagClient"
import { bigNum2Big, createMutation, getTimestampSlots } from "utils"

export interface IVePERPDistributorLastReward {
    weekStart: number
    weekEnd: number
    totalReward: Big
}

export interface IVePERPDistributorClaimable {
    amount?: Big
    hasError?: boolean
    errorMessage?: string
}

interface IVePERPDistributorClaimableArgs {
    account: string
}

export interface IVePERPFeeDistributorRewardsArgs {
    startTime: number
    endTime: number
}

export type IVePERPFeeDistributorRewards = Record<number, Big>

export const vePERPDistributorQueryApi = createApi({
    reducerPath: "vePERPDistributorQuery",
    baseQuery: () => ({ data: undefined }),
    endpoints: builder => ({
        vePERPDistributorLastReward: builder.query<IVePERPDistributorLastReward, void>({
            queryFn: vePERPDistributorLastRewardQuery,
        }),
        vePERPDistributorRewards: builder.query<IVePERPFeeDistributorRewards, IVePERPFeeDistributorRewardsArgs>({
            queryFn: vePERPDistributorRewardsQuery,
        }),
        vePERPDistributorClaimable: builder.query<IVePERPDistributorClaimable, IVePERPDistributorClaimableArgs>({
            queryFn: vePERPDistributorClaimableQuery,
        }),
    }),
})

export const vePERPDistributorMutationApi = createApi({
    reducerPath: "vePERPDistributorMutation",
    baseQuery: () => ({ data: undefined }),
    endpoints: builder => ({
        vePERPDistributorClaim: builder.mutation<TransactionReceipt, void>({
            // @ts-ignore TODO: fix createMutation args typing issue
            queryFn: createMutation(() => vePERPDistributorClient.getClaimTx()),
        }),
    }),
})

export const {
    useVePERPDistributorLastRewardQuery,
    useVePERPDistributorClaimableQuery,
    useVePERPDistributorRewardsQuery,
} = vePERPDistributorQueryApi

export const { useVePERPDistributorClaimMutation } = vePERPDistributorMutationApi

async function vePERPDistributorLastRewardQuery() {
    try {
        const rewards = await awsClient.getVePERPDistributorRewardsSnapshot(IS_PRODUCTION)
        const lastReward = rewards[Object.keys(rewards)[Object.keys(rewards).length - 1]]
        const data = {
            weekStart: lastReward.weekStart,
            weekEnd: lastReward.weekEnd,
            totalReward: Big(lastReward.totalReward),
        }
        return { data }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}

async function vePERPDistributorRewardsQuery(args: IVePERPFeeDistributorRewardsArgs) {
    try {
        const { startTime, endTime } = args
        const timestampSlots = getTimestampSlots(startTime, endTime)
        const vePERPFeeDistributorRewards: IVePERPFeeDistributorRewards = {}
        await Promise.all(
            timestampSlots.map(async timestamp => {
                const tokenAmount = await vePERPDistributorClient.getTokensPerWeek(timestamp)
                vePERPFeeDistributorRewards[timestamp] = bigNum2Big(
                    tokenAmount,
                    vePERPDistributorClient.getRewardDecimals(),
                )
            }),
        )
        return {
            data: {
                ...vePERPFeeDistributorRewards,
            },
        }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}

async function vePERPDistributorClaimableQuery(args: IVePERPDistributorClaimableArgs) {
    try {
        const { account } = args
        const amount = await vePERPDistributorClient.getClaimable(account)
        return { data: { amount: bigNum2Big(amount) } }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}
