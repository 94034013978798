import { Icon, IconProps } from "@chakra-ui/react"
import React from "react"

export function NEARIcon({ ...props }: IconProps) {
    return (
        <Icon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                fill="white"
            />
            <g style={{ mixBlendMode: "multiply" }} opacity="0.8">
                <path
                    d="M20.2186 9.71556L17.2974 14.0556C17.0955 14.3511 17.4839 14.7089 17.7636 14.46L20.6382 11.9556C20.7159 11.8933 20.8246 11.94 20.8246 12.0489V19.8733C20.8246 19.9822 20.6848 20.0289 20.6226 19.9511L11.9212 9.52889C11.6415 9.18667 11.2375 9 10.7869 9H10.4761C9.66815 9 9 9.66889 9 10.4933V21.5067C9 22.3311 9.66815 23 10.4917 23C11.0044 23 11.4861 22.7356 11.7658 22.2844L14.687 17.9444C14.889 17.6489 14.5006 17.2911 14.2209 17.54L11.3463 20.0289C11.2686 20.0911 11.1598 20.0444 11.1598 19.9356V12.1267C11.1598 12.0178 11.2997 11.9711 11.3618 12.0489L20.0633 22.4711C20.343 22.8133 20.7625 23 21.1976 23H21.5083C22.3319 23 23 22.3311 23 21.5067V10.4933C23 9.66889 22.3319 9 21.5083 9C20.98 9 20.4983 9.26444 20.2186 9.71556Z"
                    fill="#141319"
                />
            </g>
        </Icon>
    )
}
