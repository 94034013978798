import { Contract, ethers } from "ethers"
import { rpcManager, RPCManager } from "lib/RPCManager"
import { big2BigNum, bigNum2Big } from "utils/number"

import { PerpetualProtocolReferrerABI } from "./abi/PerpetualProtocolReferrerABI"
import {
    FeeDistributor,
    FeeDistributor__factory,
    PerpLiquidityMining,
    PerpLiquidityMining__factory,
    RewardDelegate,
    RewardDelegate__factory,
    VePERP,
    VePERP__factory,
    VePERPRewardDistributor,
    VePERPRewardDistributor__factory,
    InsuranceFund,
    InsuranceFund__factory,
    Vault,
    Vault__factory,
    VeTokenDistributor,
    VeTokenDistributor__factory,
    IERC20,
    IERC20__factory,
} from "./type"

export class ContractClientFactory {
    private rpcManager: RPCManager

    constructor(rpcManager: RPCManager) {
        this.rpcManager = rpcManager
    }

    getVePERP(address: string): VePERP {
        return VePERP__factory.connect(address, this.rpcManager.getProviderOrSigner())
    }

    // NOTE: VePERPRewardDistributor is for vePERP reward distribution.
    getVePERPRewardDistributor(address: string): VePERPRewardDistributor {
        return VePERPRewardDistributor__factory.connect(address, this.rpcManager.getProviderOrSigner())
    }

    // NOTE: PerpLiquidityMining is for non-vePERP reward distribution, ex. OP reward.
    getPerpLiquidityMining(address: string): PerpLiquidityMining {
        return PerpLiquidityMining__factory.connect(
            // @ts-ignore - This is a temporary workaround for the missing address in the metadata
            address,
            this.rpcManager.getProviderOrSigner(),
        )
    }

    // NOTE: VePERPFeeDistributor is for PERP reward distribution.
    getFeeDistributor(address: string): FeeDistributor {
        return FeeDistributor__factory.connect(address, this.rpcManager.getProviderOrSigner())
    }

    getPerpReferrer(address: string): Contract {
        return new ethers.Contract(address, PerpetualProtocolReferrerABI, this.rpcManager.getProviderOrSigner())
    }

    getRewardDelegate(address: string): RewardDelegate {
        return RewardDelegate__factory.connect(address, this.rpcManager.getProviderOrSigner())
    }

    getInsuranceFund(address: string): InsuranceFund {
        return InsuranceFund__factory.connect(address, this.rpcManager.getProviderOrSigner())
    }

    getVault(address: string): Vault {
        return Vault__factory.connect(address, this.rpcManager.getProviderOrSigner())
    }

    getVeTokenDistributor(address: string): VeTokenDistributor {
        return VeTokenDistributor__factory.connect(address, this.rpcManager.getProviderOrSigner())
    }

    getERC20(address: string): IERC20 {
        return IERC20__factory.connect(address, this.rpcManager.getProviderOrSigner())
    }

    async getTx(contract: Contract, fn: string, args?: any[]) {
        const gasLimitOrigin = args ? await contract.estimateGas[fn](...args) : await contract.estimateGas[fn]()
        const gasLimit = big2BigNum(bigNum2Big(gasLimitOrigin, 0).mul(1.5).round(), 0)
        return args ? contract[fn](...args, { gasLimit }) : contract[fn]({ gasLimit })
    }
}

export const contractClientFactory = new ContractClientFactory(rpcManager)
