import { Icon, IconProps } from "@chakra-ui/react"
import React from "react"

export function SANDIcon({ ...props }: IconProps) {
    return (
        <Icon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                fill="#019BF6"
            />
            <g style={{ mixBlendMode: "multiply" }} opacity="0.8">
                <path
                    d="M11.5 8.05926L10 9.65926V16L11.5 17.6H17.4444V20.8H14.5V19.2H10V22.4L11.5 24H20.4444L21.9444 22.4V16L20.4444 14.4H14.5V11.2H17.5V12.8H22V9.6L20.5 8H11.5V8.05926Z"
                    fill="#141319"
                />
            </g>
        </Icon>
    )
}
