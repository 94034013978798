import {
    Box,
    Button,
    Center,
    Flex,
    Heading,
    HStack,
    IconButton,
    Image,
    Show,
    Spacer,
    Text,
    Tooltip,
    useBreakpointValue,
    VStack,
} from "@chakra-ui/react"
import iconPlusBlackSvg from "assets/icons/icon-plus-black.svg"
import { BigPlusIcon } from "components/Icons/BigPlusIcon"
import { Motion, Size } from "components/Modal/type"
import { MotionBox, MotionFlex, MotionStack } from "components/Motion"
import { useModal } from "connectkit"
import { NOTIFICATION_DURATION } from "constants/config"
import { analyticsTx, EventGroup, EventName, EventTypeInteraction } from "modules/analytics"
import { IStatus, notificationSlice } from "modules/notification/slice"
import React, { useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import { useLocalStorage } from "react-use"
import { analyticsClient } from "services/AnalyticsClient"

import { PerpWalletConnectorContainer } from "../../../hooks/containers/usePerpWalletConnectorContainer"
import { useIsMobile } from "../../../hooks/useDevice"
import { useSupportedChain } from "../../../hooks/useSupportedChain"
import { switchNetworkForInjectedProvider } from "../../../utils/switchNetwork"
import { getDayHourMinSec } from "../../../utils/time"
import { useVePerpRedeemMutation } from "../../vePerp/flow"
import { useVePerpInfo } from "../../vePerp/hooks/useVePerpInfo"
import { homeSlice } from "../slice"

function isSupportSwitchNetwork(walletId: string) {
    return walletId === "METAMASK_WALLET"
}

function HeroInit() {
    const isMobile = useIsMobile()
    const { account } = PerpWalletConnectorContainer.useContainer()
    const { isLoading } = useVePerpInfo()

    const dispatch = useDispatch()
    const { openModalCreateLock } = homeSlice.actions

    const { setOpen } = useModal()
    const handleClick = useCallback(() => {
        if (account) {
            analyticsClient.track({
                eventGroup: EventGroup.INTERACTION,
                eventName: EventName.HOME_CREATE_LOCK_MODAL_OPENED,
                eventType: EventTypeInteraction.MODAL_OPENED,
            })
            dispatch(openModalCreateLock({ size: isMobile ? Size.FULL : Size.MD, motion: Motion.SLIDE_IN_BOTTOM }))
        } else {
            setOpen(true)
        }
    }, [setOpen, account, dispatch, isMobile, openModalCreateLock])

    const { isAccountOnSupportedChain, id } = useSupportedChain()
    const [walletId, _setWalletId] = useLocalStorage("wallet-id", "")

    const handleSwitchButtonClick = useCallback(() => {
        if (walletId && isSupportSwitchNetwork(walletId)) {
            switchNetworkForInjectedProvider(id)
        }
    }, [id, walletId])

    const headingVariant = useBreakpointValue({ base: "h2", sm: "h1" })
    return (
        <MotionFlex
            pb="60px"
            pt="132px"
            color="perp.text.primary"
            bgColor="perp.background.background"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            backgroundImage={{
                base: "url('/images/home/sm.png')",
                md: "url('/images/home/md.png')",
                lg: "url('/images/home/lg.png')",
            }}
            backgroundSize={{ base: "contain", md: "120%", lg: "cover" }}
            backgroundPosition={{ base: "bottom", md: "center", lg: "bottom" }}
            backgroundRepeat="no-repeat"
        >
            <Heading maxWidth={{ md: "550px" }} px={6} variant={headingVariant} style={{ textAlign: "center" }}>
                Lock{" "}
                <Text color="perp.primary.default" style={{ display: "inline-block" }}>
                    PERP
                </Text>{" "}
                and enjoy protocol benefits!
            </Heading>

            <Box
                visibility={isLoading ? "hidden" : "visible"}
                as="button"
                mt="32px"
                onClick={
                    !isAccountOnSupportedChain && isSupportSwitchNetwork(walletId || "")
                        ? handleSwitchButtonClick
                        : handleClick
                }
                bg="#3CEAAA"
                borderRadius="12px"
                w="156px"
                height="56px"
                lineHeight="16px"
            >
                <Center>
                    <Flex>
                        <Image h="13.5px" w="13.5px" src={iconPlusBlackSvg} mr="10px" fill="black" />
                        <Text fontSize="16px" color="black">
                            Lock PERP
                        </Text>
                    </Flex>
                </Center>
            </Box>
        </MotionFlex>
    )
}

function HeroLocked() {
    const isMobile = useIsMobile()
    const [redeem] = useVePerpRedeemMutation()

    const dispatch = useDispatch()
    const { openModalIncreaseAmount, openModalExtendTime } = homeSlice.actions
    const { open } = notificationSlice.actions

    const handleIncreaseAmountClick = useCallback(() => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventName: EventName.HOME_INCREASE_AMOUNT_MODAL_OPENED,
            eventType: EventTypeInteraction.MODAL_OPENED,
        })
        dispatch(openModalIncreaseAmount({ size: isMobile ? Size.FULL : Size.MD, motion: Motion.SLIDE_IN_BOTTOM }))
    }, [dispatch, openModalIncreaseAmount, isMobile])

    const handleIncreaseLockTimeClick = useCallback(() => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventName: EventName.HOME_EXTEND_TIME_MODAL_OPENED,
            eventType: EventTypeInteraction.MODAL_OPENED,
        })
        dispatch(openModalExtendTime({ size: isMobile ? Size.FULL : Size.MD, motion: Motion.SLIDE_IN_BOTTOM }))
    }, [dispatch, openModalExtendTime, isMobile])

    const handleUnlock = useCallback(async () => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventName: EventName.HOME_UNLOCK_CLICKED,
            eventType: EventTypeInteraction.BUTTON_CLICKED,
        })
        const result = await redeem()
        if ("error" in result) {
            analyticsTx.vePerp.withdraw.failed(result.error)
            dispatch(
                open({
                    status: IStatus.error,
                    description: "PERP unlock failed.",
                    duration: NOTIFICATION_DURATION.SHORT,
                }),
            )
        } else {
            analyticsTx.vePerp.withdraw.succeeded(result.data)
            dispatch(
                open({
                    status: IStatus.success,
                    description: "PERP unlocked successfully. Please check the balance in your wallet.",
                    duration: NOTIFICATION_DURATION.SHORT,
                }),
            )
        }
    }, [dispatch, open, redeem])

    const {
        hasExpired,
        lockedPerpTokenAmount,
        lockedPerpTokenValidDate,
        daysToExpiration,
        secsToExpiration,
        vePerpAmountUnweighted,
    } = useVePerpInfo()

    const weeks = daysToExpiration ? Math.floor(daysToExpiration / 7) : "-"
    const days = daysToExpiration ? daysToExpiration % 7 : "-"
    const hintTime = getDayHourMinSec(secsToExpiration || 0)

    const titleVariant = useBreakpointValue({ base: "h2", sm: "h1" })
    const subtitleVariant = useBreakpointValue({ base: "sub2", sm: "h4" })

    const [showingVePERP, setShowingVePerp] = useState(false)

    return (
        <MotionStack
            zIndex={2}
            position={"relative"}
            pt={{ base: "96px", sm: "112px", lg: "144px" }}
            pb={{ base: "24px", sm: "40px", lg: "72px" }}
            justify={{ base: "flex-start", lg: "center" }}
            alignItems={{ base: "stretch", lg: "center" }}
            width={{ base: "100%", lg: "auto" }}
            direction={{ base: "column", lg: "row" }}
            spacing={{ base: "24px", lg: "40px", xl: "80px" }}
            bgImage="url('images/home/heroLockedBg.svg')"
            bgRepeat={"repeat-x"}
            bgPosition="center"
            bgSize={"contain"}
        >
            {/* Section 1 */}
            <HStack px={"24px"} spacing={{ base: 0, md: "40px" }}>
                {/* stats */}
                <VStack spacing={{ base: 1, lg: 2 }} align={"stretch"}>
                    <Heading variant={titleVariant}>
                        {showingVePERP ? vePerpAmountUnweighted?.toFixed(2) : lockedPerpTokenAmount?.toFixed(2)}
                    </Heading>
                    <Flex alignItems="center" fontSize="20px" gap="16px">
                        <Text
                            as="button"
                            fontWeight={500}
                            color={showingVePERP ? "perp.text.secondary" : "inherit"}
                            onClick={() => setShowingVePerp(false)}
                        >
                            Locked PERP
                        </Text>
                        <Text fontWeight={500}>|</Text>
                        <Text
                            as="button"
                            fontWeight={500}
                            color={!showingVePERP ? "perp.text.secondary" : "inherit"}
                            onClick={() => setShowingVePerp(true)}
                        >
                            vePERP
                        </Text>
                    </Flex>
                </VStack>
                <Show below="lg">
                    <Spacer />
                </Show>
                {!hasExpired && (
                    <Tooltip label="Lock more PERP" hasArrow offset={[0, 16]}>
                        <IconButton
                            onClick={handleIncreaseAmountClick}
                            borderRadius={"16px"}
                            boxSize={["56px", "88px"]}
                            aria-label={"lock perp"}
                            bg="perp.background.card"
                            _hover={{ background: "perp.background.border" }}
                            icon={<BigPlusIcon boxSize={["36px", "56px"]} />}
                        />
                    </Tooltip>
                )}
            </HStack>
            {/* Section 2 */}
            <HStack px={"24px"} spacing={{ base: 0, md: "40px" }}>
                {/* stats */}
                <VStack spacing={{ base: 1, lg: 2 }} align={"stretch"}>
                    <Heading variant={titleVariant}>{hasExpired ? "Ended" : lockedPerpTokenValidDate}</Heading>
                    <Heading variant={subtitleVariant}>
                        Locked Through{" "}
                        {!hasExpired && (
                            <>
                                <Tooltip label={`${hintTime[0]}d ${hintTime[1]}h ${hintTime[2]}m ${hintTime[3]}s`}>
                                    <Box as="span" color="#8D8D93">
                                        ({weeks}w{days}d)
                                    </Box>
                                </Tooltip>
                            </>
                        )}
                    </Heading>
                </VStack>
                <Show below="lg">
                    <Spacer />
                </Show>

                {!hasExpired && (
                    <Tooltip label="Extend lock time" hasArrow offset={[0, 16]}>
                        <IconButton
                            onClick={handleIncreaseLockTimeClick}
                            borderRadius={"16px"}
                            boxSize={["56px", "88px"]}
                            aria-label={"lock perp"}
                            bg="perp.background.card"
                            _hover={{ background: "perp.background.border" }}
                            icon={<BigPlusIcon boxSize={["36px", "56px"]} />}
                        />
                    </Tooltip>
                )}

                {hasExpired && (
                    <Box display={{ base: "none", sm: "block", md: "block", lg: "none" }}>
                        <Button w="100%" onClick={handleUnlock}>
                            Unlock PERP
                        </Button>
                    </Box>
                )}
            </HStack>

            {/* Section 3 */}
            {hasExpired && (
                <Box px={4} display={{ base: "block", sm: "none", md: "none", lg: "block" }}>
                    <Button w="100%" onClick={handleUnlock}>
                        Unlock PERP
                    </Button>
                </Box>
            )}
        </MotionStack>
    )
}

export function Hero() {
    const { account } = PerpWalletConnectorContainer.useContainer()
    const { hasExpired, hasLockedAlready } = useVePerpInfo()
    const Content = account && (hasExpired || hasLockedAlready) ? HeroLocked : HeroInit
    return (
        <MotionBox layout>
            <Content />
        </MotionBox>
    )
}
