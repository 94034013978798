import { Icon, IconProps } from "@chakra-ui/react"
import React from "react"

export function PlusIcon({ ...props }: IconProps) {
    return (
        <Icon width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.75 0.25H6.25V6.25H0.25V7.75H6.25V13.75H7.75V7.75H13.75V6.25H7.75V0.25Z"
                fill="black"
            />
        </Icon>
    )
}
