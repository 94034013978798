import type { SystemStyleFunction, SystemStyleObject } from "@chakra-ui/theme-tools"
import { cssVar } from "@chakra-ui/theme-tools"

const $size = cssVar("close-button-size")

const baseStyle: SystemStyleFunction = _props => {
    const hoverBg = `rgba(0, 0, 0, 0.4)`
    const activeBg = `rgba(0, 0, 0, 0.5)`
    const crossColor = `white`

    return {
        w: [$size.reference],
        h: [$size.reference],
        borderRadius: "4px",
        transitionProperty: "common",
        transitionDuration: "normal",
        _disabled: {
            opacity: 0.4,
            cursor: "not-allowed",
            boxShadow: "none",
        },
        color: crossColor,
        bg: "rgba(0, 0, 0, 0.3)",
        _hover: { bg: hoverBg },
        _active: { bg: activeBg },
        _focus: {
            boxShadow: "outline",
        },
    }
}

const sizes: Record<string, SystemStyleObject> = {
    lg: {
        [$size.variable]: "40px",
        fontSize: "16px",
    },
    md: {
        [$size.variable]: "32px",
        fontSize: "12px",
    },
    sm: {
        [$size.variable]: "24px",
        fontSize: "10px",
    },
}

const defaultProps = {
    size: "md",
}

export const CloseButton = {
    baseStyle,
    sizes,
    defaultProps,
}
