import { METADATA } from "constants/env"

import { ContractClientFactory, contractClientFactory } from "./ContractClientFactory"

class FeeDistributorClient {
    private readonly contractClientFactory: ContractClientFactory
    private readonly contractAddress: string
    private readonly rewardDecimals: number

    constructor(contractClientFactory: ContractClientFactory, contractAddress: string, rewardDecimals: number) {
        this.contractClientFactory = contractClientFactory
        this.contractAddress = contractAddress
        this.rewardDecimals = rewardDecimals
    }

    async getClaimable(address: string) {
        const contract = this.contractClientFactory.getFeeDistributor(this.contractAddress)
        return await contract.callStatic["claim(address)"](address)
    }

    async getTokensPerWeek(timestamp: number) {
        const contract = this.contractClientFactory.getFeeDistributor(this.contractAddress)
        return await contract.tokens_per_week(timestamp)
    }

    async getLastTokenTime() {
        const contract = this.contractClientFactory.getFeeDistributor(this.contractAddress)
        return await contract.last_token_time()
    }

    getClaimTx() {
        const contract = this.contractClientFactory.getFeeDistributor(this.contractAddress)
        return this.contractClientFactory.getTx(contract, "claim()")
    }

    getRewardDecimals() {
        return this.rewardDecimals
    }
}

export const vePERPEmissionFeeDistributorClient = new FeeDistributorClient(
    contractClientFactory,
    METADATA.VOTING_ESCROW.contracts.vePERPEmissionFeeDistributor.address,
    18,
)

export const usdcFeeDistributorClient = new FeeDistributorClient(
    contractClientFactory,
    // @ts-ignore TODO: remove ts-ignore when optimism support USDCFeeDistributor contract
    METADATA.VOTING_ESCROW.contracts.USDCFeeDistributor.address,
    6,
)
