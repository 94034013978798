import { TransactionReceipt } from "@ethersproject/providers"
import { walletSlice } from "modules/wallet"
import { bugsnagClient } from "services/BugsnagClient"

export function createMutation(fn: (...args: any[]) => Promise<any>) {
    return async (args: any[], { dispatch }: any) => {
        dispatch(walletSlice.actions.setTxLoading(true))
        try {
            const tx = args ? await fn(...args) : await fn()
            const receipt: TransactionReceipt = await tx.wait()
            return {
                data: receipt,
            }
        } catch (error) {
            bugsnagClient.sendError(error as Error)
            return {
                error,
            }
        } finally {
            dispatch(walletSlice.actions.setTxLoading(false))
        }
    }
}
