import Big from "big.js"
import { METADATA } from "constants/env"
import { RPCManager, rpcManager } from "lib/RPCManager"
import { big2BigNum, bigNum2Big } from "../utils/number"
import { ContractClientFactory, contractClientFactory } from "./ContractClientFactory"

export class VePerpClient {
    private readonly contractClientFactory: ContractClientFactory
    private readonly contractAddress: string
    private readonly rpcManager: RPCManager

    constructor(contractClientFactory: ContractClientFactory, contractAddress: string, rpcManager: RPCManager) {
        this.contractClientFactory = contractClientFactory
        this.contractAddress = contractAddress
        this.rpcManager = rpcManager
    }

    getContract() {
        return this.contractClientFactory.getVePERP(this.contractAddress)
    }

    getCreateLockTx(value: Big, unlockTime: Big) {
        const contract = this.contractClientFactory.getVePERP(this.contractAddress)
        return this.contractClientFactory.getTx(contract, "create_lock", [big2BigNum(value), big2BigNum(unlockTime, 0)])
    }

    getIncreaseAmountTx(value: Big) {
        const contract = this.contractClientFactory.getVePERP(this.contractAddress)
        return this.contractClientFactory.getTx(contract, "increase_amount", [big2BigNum(value)])
    }

    getIncreaseUnlockTimeTx(unlockTime: Big) {
        const contract = this.contractClientFactory.getVePERP(this.contractAddress)
        return this.contractClientFactory.getTx(contract, "increase_unlock_time", [big2BigNum(unlockTime, 0)])
    }

    getRedeemTx() {
        const contract = this.contractClientFactory.getVePERP(this.contractAddress)
        return this.contractClientFactory.getTx(contract, "withdraw")
    }

    async getTotalLockedPerpAmount(): Promise<Big> {
        const contract = this.contractClientFactory.getVePERP(this.contractAddress)
        return bigNum2Big(await contract.totalPERPSupply())
    }

    async getTotalLockedPerpAmountAt(t: number): Promise<Big> {
        const { exactBlockNumber, nextBlockNumber } = await rpcManager.getBlockTagAt(t)
        const blockTag = exactBlockNumber || nextBlockNumber
        const contract = this.contractClientFactory.getVePERP(this.contractAddress)
        return bigNum2Big(await contract.totalPERPSupplyAt(blockTag))
    }

    async getTotalUnweightedVePerpAmount(): Promise<Big> {
        const contract = this.contractClientFactory.getVePERP(this.contractAddress)
        return bigNum2Big(await contract["totalSupply()"]())
    }

    async getTotalUnweightedVePerpAmountAt(t: number): Promise<Big> {
        const contract = this.contractClientFactory.getVePERP(this.contractAddress)
        return bigNum2Big(await contract["totalSupply(uint256)"](t))
    }

    async getEmergencyUnlockActive(): Promise<boolean> {
        const contract = this.contractClientFactory.getVePERP(this.contractAddress)
        return await contract.emergencyUnlockActive()
    }

    async getLocked(account: string): Promise<{ amount: Big; end: Big }> {
        const contract = this.contractClientFactory.getVePERP(this.contractAddress)
        const locked = await contract.locked(account)
        return {
            amount: bigNum2Big(locked.amount),
            end: bigNum2Big(locked.end, 0),
        }
    }

    async getLockedAt(account: string, t: number): Promise<{ amount: Big; end: Big }> {
        const { exactBlockNumber, nextBlockNumber } = await rpcManager.getBlockTagAt(t)
        const blockTag = exactBlockNumber || nextBlockNumber
        const contract = this.contractClientFactory.getVePERP(this.contractAddress)
        const locked = await contract.locked(account, { blockTag })
        return {
            amount: bigNum2Big(locked.amount),
            end: bigNum2Big(locked.end, 0),
        }
    }

    async getBalanceOfWeighted(account: string): Promise<Big> {
        const contract = this.contractClientFactory.getVePERP(this.contractAddress)
        return bigNum2Big(await contract["balanceOfWeighted(address)"](account))
    }

    async getBalanceOf(account: string): Promise<Big> {
        const contract = this.contractClientFactory.getVePERP(this.contractAddress)
        return bigNum2Big(await contract["balanceOf(address)"](account))
    }

    async getBalanceOfAt(account: string, t: number): Promise<Big> {
        const contract = this.contractClientFactory.getVePERP(this.contractAddress)
        return bigNum2Big(await contract["balanceOf(address,uint256)"](account, t))
    }

    static getCountDownToExpiration(endDateTimestamp: number, now: number) {
        const _secs = endDateTimestamp - now
        const _days = Math.ceil(_secs / 86400)
        const _weeks = Math.ceil(_secs / (86400 * 7))

        return {
            secs: _secs <= 0 ? 0 : _secs,
            days: _days <= 0 ? 0 : _days,
            weeks: _weeks <= 0 ? 0 : _weeks,
        }
    }

    static getVePerpAmount(lockedPerpAmount: Big, endDateTimestamp: number, now: number) {
        const { secs } = VePerpClient.getCountDownToExpiration(endDateTimestamp, now)
        return lockedPerpAmount.mul(secs / (86400 * 7 * 52))
    }

    static getVePerpEstimatedTime(validDate: number, lockedPerpAmount: Big, targetVePerpAmount: Big) {
        // NOTE: targetVePerpAmount = lockedPerpAmount * ((validDate - x) / (86400 * 7 * 52))
        // NOTE: round down to make sure the vePerp amount at the estimated time will greater than the target amount
        if (lockedPerpAmount.eq(0)) {
            return Big(0)
        }
        return targetVePerpAmount
            .mul(86400 * 7 * 52)
            .div(lockedPerpAmount)
            .minus(validDate)
            .mul(-1)
            .round(0, 0)
    }
}

export const vePerpClient = new VePerpClient(
    contractClientFactory,
    METADATA.VOTING_ESCROW.contracts.vePERP.address,
    rpcManager,
)
