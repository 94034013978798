import { IS_STAGING } from "../constants/env"

enum ChainId {
    OPTIMISTIC_ETHEREUM = 10,
    OPTIMISTIC_ETHEREUM_TESTNET_GOERLI = 420,
}

enum ChainName {
    OPTIMISTIC_ETHEREUM = "Optimistic Ethereum",
    OPTIMISTIC_ETHEREUM_TESTNET_GOERLI = "Optimistic Ethereum Testnet Goerli",
}

type UrlBuilder = (link: string, data: string, type: "transaction" | "token" | "address" | "block") => string

interface Chains {
    [chainId: number]: {
        id: number
        name: ChainName
        alias: string
        link: string
        urlBuilder: (chainName: string, data: string, type: "transaction" | "token" | "address" | "block") => string
    }
}

const explorers: Record<string, UrlBuilder> = {
    etherscan: (link: string, data: string, type: "transaction" | "token" | "address" | "block") => {
        switch (type) {
            case "transaction":
                return `${link}/tx/${data}`
            default:
                return `${link}/${type}/${data}`
        }
    },
    blockscout: (link: string, data: string, type: "transaction" | "token" | "address" | "block") => {
        switch (type) {
            case "transaction":
                return `${link}/tx/${data}`
            case "token":
                return `${link}/tokens/${data}`
            default:
                return `${link}/${type}/${data}`
        }
    },
}

const CHAIN_INFO_MAP: Chains = {
    [ChainId.OPTIMISTIC_ETHEREUM]: {
        id: ChainId.OPTIMISTIC_ETHEREUM,
        name: ChainName.OPTIMISTIC_ETHEREUM,
        alias: "Optimism",
        link: "https://optimistic.etherscan.io",
        urlBuilder: explorers.etherscan,
    },
    [ChainId.OPTIMISTIC_ETHEREUM_TESTNET_GOERLI]: {
        id: ChainId.OPTIMISTIC_ETHEREUM_TESTNET_GOERLI,
        name: ChainName.OPTIMISTIC_ETHEREUM_TESTNET_GOERLI,
        alias: "Optimism Goerli",
        link: "https://goerli-optimistic.etherscan.io",
        urlBuilder: explorers.etherscan,
    },
}

function getSupportedChainId() {
    const chainId = IS_STAGING ? ChainId.OPTIMISTIC_ETHEREUM_TESTNET_GOERLI : ChainId.OPTIMISTIC_ETHEREUM
    return chainId
}

function getSupportedChainInfo() {
    const chainId = getSupportedChainId()
    const chainInfo = CHAIN_INFO_MAP[chainId]
    return chainInfo
}

export { getSupportedChainId, getSupportedChainInfo, ChainId }
