/**
 * NOTE:
 * CONTRACT: For things that happens on-chain.
 * INTERACTION: For things that user does actively to the user interface.
 * UI_FEEDBACK: For changes on the user interface passively.
 */
export enum EventGroup {
    CONTRACT = "CONTRACT",
    INTERACTION = "INTERACTION",
    UI_FEEDBACK = "UI_FEEDBACK",
}

export enum EventTypeContract {
    TX_SENT = "TX_SENT",
    TX_SUCCEEDED = "TX_SUCCEEDED",
    TX_REJECTED = "TX_REJECTED",
    TX_FAILED = "TX_FAILED",
}

export enum EventTypeInteraction {
    BUTTON_CLICKED = "BUTTON_CLICKED",
    INPUT_FOCUSED = "INPUT_FOCUSED",
    INPUT_UNFOCUSED = "INPUT_UNFOCUSED",
    FORM_SUBMITTED = "FORM_SUBMITTED",
    TAB_FOCUSED = "TAB_FOCUSED",
    RADIO_GROUP_SELECTED = "RADIO_GROUP_SELECTED",
    MODAL_OPENED = "MODAL_OPENED",
    MODAL_CLOSED = "MODAL_CLOSED",
    LINK_CLICKED = "LINK_CLICKED",
    MENU_ITEM_CLICKED = "MENU_ITEM_CLICKED",
    SLIDER_CHANGED_ENDED = "SLIDER_CHANGED_ENDED",
    OTHERS = "OTHERS",
}

export enum EventTypeUIFeedback {
    SUCCESS_DISPLAYED = "SUCCESS_DISPLAYED",
    WARNING_DISPLAYED = "WARNING_DISPLAYED",
    ERROR_DISPLAYED = "ERROR_DISPLAYED",
    INFO_DISPLAYED = "INFO_DISPLAYED",
}

// NOTE: naming [module name]_[action]
export enum EventName {
    // WALLET
    WALLET_CONNECT_MODAL_OPENED = "WALLET_CONNECT_MODAL_OPENED",
    WALLET_CONNECT_MODAL_CLOSED = "WALLET_CONNECT_MODAL_CLOSED",
    WALLET_BUTTON_CLICKED = "WALLET_BUTTON_CLICKED",
    // HOME
    HOME_PROGRAM_CARD_CLICKED = "HOME_PROGRAM_CARD_CLICKED",
    HOME_RELATED_CARD_CLICKED = "HOME_RELATED_CARD_CLICKED",
    HOME_UNLOCK_CLICKED = "HOME_UNLOCK_CLICKED",
    HOME_CREATE_LOCK_MODAL_OPENED = "HOME_CREATE_LOCK_MODAL_OPENED",
    HOME_CREATE_LOCK_MODAL_CLOSED = "HOME_CREATE_LOCK_MODAL_CLOSED",
    HOME_CREATE_LOCK_BUTTON_CLICKED = "HOME_CREATE_LOCK_BUTTON_CLICKED",
    HOME_INCREASE_AMOUNT_MODAL_OPENED = "HOME_INCREASE_AMOUNT_MODAL_OPENED",
    HOME_INCREASE_AMOUNT_MODAL_CLOSED = "HOME_INCREASE_AMOUNT_MODAL_CLOSED",
    HOME_INCREASE_AMOUNT_BUTTON_CLICKED = "HOME_INCREASE_AMOUNT_BUTTON_CLICKED",
    HOME_EXTEND_TIME_MODAL_OPENED = "HOME_EXTEND_TIME_MODAL_OPENED",
    HOME_EXTEND_TIME_MODAL_CLOSED = "HOME_EXTEND_TIME_MODAL_CLOSED",
    HOME_EXTEND_TIME_BUTTON_CLICKED = "HOME_EXTEND_TIME_BUTTON_CLICKED",
    // REFERRAL
    REFERRAL_CREATE_LOCK_MODAL_OPENED = "REFERRAL_CREATE_LOCK_MODAL_OPENED",
    REFERRAL_CREATE_LOCK_MODAL_CLOSED = "REFERRAL_CREATE_LOCK_MODAL_CLOSED",
    REFERRAL_CREATE_LOCK_BUTTON_CLICKED = "REFERRAL_CREATE_LOCK_BUTTON_CLICKED",
    REFERRAL_EXPIRED_MODAL_OPENED = "REFERRAL_EXPIRED_MODAL_OPENED",
    REFERRAL_EXPIRED_MODAL_CLOSED = "REFERRAL_EXPIRED_MODAL_CLOSED",
    REFERRAL_UNLOCK_BUTTON_CLICKED = "REFERRAL_UNLOCK_BUTTON_CLICKED",
    REFERRAL_EXTEND_TIME_MODAL_OPENED = "REFERRAL_EXTEND_TIME_MODAL_OPENED",
    REFERRAL_EXTEND_TIME_MODAL_CLOSED = "REFERRAL_EXTEND_TIME_MODAL_CLOSED",
    REFERRAL_EXTEND_TIME_BUTTON_CLICKED = "REFERRAL_EXTEND_TIME_BUTTON_CLICKED",
    REFERRAL_CLAIM_MODAL_OPENED = "REFERRAL_CLAIM_MODAL_OPENED",
    REFERRAL_CLAIM_MODAL_CLOSED = "REFERRAL_CLAIM_MODAL_CLOSED",
    REFERRAL_CLAIM_BUTTON_CLICKED = "REFERRAL_CLAIM_BUTTON_CLICKED",
    REFERRAL_CREATE_CODE_BUTTON_CLICKED = "REFERRAL_CREATE_CODE_BUTTON_CLICKED",
    REFERRAL_SET_CODE_BUTTON_CLICKED = "REFERRAL_SET_CODE_BUTTON_CLICKED",
    REFERRAL_CREATE_LOCK_BY_MY_STATUS_MODAL_OPENED = "REFERRAL_CREATE_LOCK_BY_MY_STATUS_MODAL_OPENED",
    REFERRAL_CREATE_LOCK_BY_MY_STATUS_MODAL_CLOSED = "REFERRAL_CREATE_LOCK_BY_MY_STATUS_MODAL_CLOSED",
    REFERRAL_CREATE_LOCK_BY_MY_STATUS_BUTTON_CLICKED = "REFERRAL_CREATE_LOCK_BY_MY_STATUS_BUTTON_CLICKED",
    REFERRAL_EXTEND_TIME_BY_MY_STATUS_MODAL_OPENED = "REFERRAL_EXTEND_TIME_BY_MY_STATUS_MODAL_OPENED",
    REFERRAL_EXTEND_TIME_BY_MY_STATUS_MODAL_CLOSED = "REFERRAL_EXTEND_TIME_BY_MY_STATUS_MODAL_CLOSED",
    REFERRAL_EXTEND_TIME_BY_MY_STATUS_BUTTON_CLICKED = "REFERRAL_EXTEND_TIME_BY_MY_STATUS_BUTTON_CLICKED",
    REFERRAL_INCREASE_AMOUNT_BY_MY_STATUS_MODAL_OPENED = "REFERRAL_INCREASE_AMOUNT_BY_MY_STATUS_MODAL_OPENED",
    REFERRAL_INCREASE_AMOUNT_BY_MY_STATUS_MODAL_CLOSED = "REFERRAL_INCREASE_AMOUNT_BY_MY_STATUS_MODAL_CLOSED",
    REFERRAL_INCREASE_AMOUNT_BY_MY_STATUS_BUTTON_CLICKED = "REFERRAL_INCREASE_AMOUNT_BY_MY_STATUS_BUTTON_CLICKED",
    REFERRAL_UNLOCK_BY_MY_STATUS_BUTTON_CLICKED = "REFERRAL_UNLOCK_BY_MY_STATUS_BUTTON_CLICKED",
    // EMISSION
    EMISSION_CLAIM_BUTTON_CLICKED = "EMISSION_CLAIM_BUTTON_CLICKED",
    EMISSION_CREATE_LOCK_MODAL_OPENED = "EMISSION_CREATE_LOCK_MODAL_OPENED",
    EMISSION_CREATE_LOCK_MODAL_CLOSED = "EMISSION_CREATE_LOCK_MODAL_CLOSED",
    EMISSION_CREATE_LOCK_BUTTON_CLICKED = "EMISSION_CREATE_LOCK_BUTTON_CLICKED",
    EMISSION_INCREASE_AMOUNT_MODAL_OPENED = "EMISSION_INCREASE_AMOUNT_MODAL_OPENED",
    EMISSION_INCREASE_AMOUNT_MODAL_CLOSED = "EMISSION_INCREASE_AMOUNT_MODAL_CLOSED",
    EMISSION_INCREASE_AMOUNT_BUTTON_CLICKED = "EMISSION_INCREASE_AMOUNT_BUTTON_CLICKED",
    EMISSION_EXTEND_TIME_MODAL_OPENED = "EMISSION_EXTEND_TIME_MODAL_OPENED",
    EMISSION_EXTEND_TIME_MODAL_CLOSED = "EMISSION_EXTEND_TIME_MODAL_CLOSED",
    EMISSION_EXTEND_TIME_BUTTON_CLICKED = "EMISSION_EXTEND_TIME_BUTTON_CLICKED",
    EMISSION_UNLOCK_BUTTON_CLICKED = "EMISSION_UNLOCK_BUTTON_CLICKED",
    // USDC_FEE_DISTRIBUTOR
    USDC_FEE_DISTRIBUTOR_CLAIM_BUTTON_CLICKED = "USDC_FEE_DISTRIBUTOR_CLAIM_BUTTON_CLICKED",
    // VEPERP_DISTRIBUTOR
    VEPERP_DISTRIBUTOR_CLAIM_BUTTON_CLICKED = "VEPERP_DISTRIBUTOR_CLAIM_BUTTON_CLICKED",
}

// NOTE: naming TX_[contract name]_[function]
export enum EventNameTx {
    // VEPERP
    TX_REJECTED_VEPERP_WITHDRAW = "TX_REJECTED_VEPERP_WITHDRAW",
    TX_SUCCEEDED_VEPERP_WITHDRAW = "TX_SUCCEEDED_VEPERP_WITHDRAW",
    TX_FAILED_VEPERP_WITHDRAW = "TX_FAILED_VEPERP_WITHDRAW",
    TX_REJECTED_VEPERP_CREATE_LOCK = "TX_REJECTED_VEPERP_CREATE_LOCK",
    TX_SUCCEEDED_VEPERP_CREATE_LOCK = "TX_SUCCEEDED_VEPERP_CREATE_LOCK",
    TX_FAILED_VEPERP_CREATE_LOCK = "TX_FAILED_VEPERP_CREATE_LOCK",
    TX_REJECTED_VEPERP_INCREASE_AMOUNT = "TX_REJECTED_VEPERP_INCREASE_AMOUNT",
    TX_SUCCEEDED_VEPERP_INCREASE_AMOUNT = "TX_SUCCEEDED_VEPERP_INCREASE_AMOUNT",
    TX_FAILED_VEPERP_INCREASE_AMOUNT = "TX_FAILED_VEPERP_INCREASE_AMOUNT",
    TX_REJECTED_VEPERP_INCREASE_UNLOCK_TIME = "TX_REJECTED_VEPERP_INCREASE_UNLOCK_TIME",
    TX_SUCCEEDED_VEPERP_INCREASE_UNLOCK_TIME = "TX_SUCCEEDED_VEPERP_INCREASE_UNLOCK_TIME",
    TX_FAILED_VEPERP_INCREASE_UNLOCK_TIME = "TX_FAILED_VEPERP_INCREASE_UNLOCK_TIME",
    // FEE DISTRIBUTOR
    TX_REJECTED_FEE_DISTRIBUTOR_CLAIM = "TX_REJECTED_FEE_DISTRIBUTOR_CLAIM",
    TX_SUCCEEDED_FEE_DISTRIBUTOR_CLAIM = "TX_SUCCEEDED_FEE_DISTRIBUTOR_CLAIM",
    TX_FAILED_FEE_DISTRIBUTOR_CLAIM = "TX_FAILED_FEE_DISTRIBUTOR_CLAIM",
    // ERC20
    TX_REJECTED_ERC20_APPROVE = "TX_REJECTED_ERC20_APPROVE",
    TX_FAILED_ERC20_APPROVE = "TX_FAILED_ERC20_APPROVE",
    TX_SUCCEEDED_ERC20_APPROVE = "TX_SUCCEEDED_ERC20_APPROVE",
    // VEPERP REWARD DISTRIBUTOR
    TX_REJECTED_VEPERP_REWARD_DISTRIBUTOR_CLAIM_WEEKS = "TX_REJECTED_VEPERP_REWARD_DISTRIBUTOR_CLAIM_WEEKS",
    TX_FAILED_VEPERP_REWARD_DISTRIBUTOR_CLAIM_WEEKS = "TX_FAILED_VEPERP_REWARD_DISTRIBUTOR_CLAIM_WEEKS",
    TX_SUCCEEDED_VEPERP_REWARD_DISTRIBUTOR_CLAIM_WEEKS = "TX_SUCCEEDED_VEPERP_REWARD_DISTRIBUTOR_CLAIM_WEEKS",
    // PERP LIQUIDITY MINING
    TX_REJECTED_PERP_LIQUIDITY_MINING_CLAIM_WEEKS = "TX_REJECTED_PERP_LIQUIDITY_MINING_CLAIM_WEEKS",
    TX_FAILED_PERP_LIQUIDITY_MINING_CLAIM_WEEKS = "TX_FAILED_PERP_LIQUIDITY_MINING_CLAIM_WEEKS",
    TX_SUCCEEDED_PERP_LIQUIDITY_MINING_CLAIM_WEEKS = "TX_SUCCEEDED_PERP_LIQUIDITY_MINING_CLAIM_WEEKS",
    // PERP REFERRAL
    TX_REJECTED_PERP_REFERRAL_CREATE_REFERRAL_CODE = "TX_REJECTED_PERP_REFERRAL_CREATE_REFERRAL_CODE",
    TX_FAILED_PERP_REFERRAL_CREATE_REFERRAL_CODE = "TX_FAILED_PERP_REFERRAL_CREATE_REFERRAL_CODE",
    TX_SUCCEEDED_PERP_REFERRAL_CREATE_REFERRAL_CODE = "TX_SUCCEEDED_PERP_REFERRAL_CREATE_REFERRAL_CODE",
    TX_REJECTED_PERP_REFERRAL_SET_REFERRAL_CODE = "TX_REJECTED_PERP_REFERRAL_SET_REFERRAL_CODE",
    TX_FAILED_PERP_REFERRAL_SET_REFERRAL_CODE = "TX_FAILED_PERP_REFERRAL_SET_REFERRAL_CODE",
    TX_SUCCEEDED_PERP_REFERRAL_SET_REFERRAL_CODE = "TX_SUCCEEDED_PERP_REFERRAL_SET_REFERRAL_CODE",
}
