import { MakerMarket } from "services/AppSyncClient"

import { getVSymbolFullName } from "../util"

export function selectorMakerMarkets(result: { data?: MakerMarket[] }) {
    if (!result.data) {
        return {}
    }
    const parsedItems: IParsedMakerMarket[] = result.data.map(item => ({
        ...item,
        rewardApr: item.rewardApr + item.rewardOpApr,
        rewardPerpApr: item.rewardApr,
        aprTotal: item.baseApr + item.rewardApr + item.rewardOpApr,
        base: item.baseSymbol.substring(1),
        quote: item.quoteSymbol.substring(1),
        baseFullName: getVSymbolFullName(item.baseSymbol),
    }))
    return {
        data: parsedItems,
    }
}

export interface IParsedMakerMarket extends MakerMarket {
    rewardApr: number
    rewardPerpApr: number
    aprTotal: number
    base: string
    quote: string
    baseFullName: string
}
