// AI assisted geoblock container
// Usaage: <GeoblockContent>blah</GeoblockContent>

// TODO: migrate to Zustand store in the future. As seen in perp-kantaban-ui

import { useEffect, useState } from "react"
import { Box, Center, Link, Text } from "@chakra-ui/react"

import fetch from "cross-fetch"
import React from "react"
import { useKonami } from "react-konami-code"
import { motion } from "framer-motion"
import { Globe2 } from "lucide-react"

export function GeoblockContent({ children }: { children: React.ReactNode }) {
    // geoblock setting
    const [isBlocked, setIsBlocked] = useState(localStorage.getItem("isBlocked") !== "false")
    // konami code setting
    const [usedCode] = useState(localStorage.getItem("usedCode") === "true")
    // country code
    const [countryCode, setCountryCode] = useState("")

    useEffect(() => {
        async function fetchGeoblock() {
            try {
                const response = await fetch("https://api.perp.exchange/geolocation-block")
                const data = await response.json()
                setCountryCode(data.countryCode)
                setIsBlocked(data.isBlocked)
                // remember your geoblock setting to prevent page flash
                localStorage.setItem("isBlocked", data.isBlocked)
            } catch (error) {
                console.error(error)
            }
        }

        if (usedCode) {
            // If you have entered code, then un-block
            localStorage.setItem("isBlocked", "false")
            setIsBlocked(false)
        } else {
            // If you don't have konami code, geo-block normally
            fetchGeoblock()
        }
    }, [usedCode])

    // if you enter konami code then un-block
    function bypass() {
        localStorage.setItem("usedCode", "true")
        localStorage.setItem("isBlocked", "false")
        setIsBlocked(false)
    }
    useKonami(bypass, { code: [86, 69, 80, 69, 82, 80] })
    // code is: `veperp`

    if (isBlocked) {
        return (
            <Center height={"100vh"}>
                <Box
                    maxWidth={"560px"}
                    bg="white"
                    color={"black"}
                    borderRadius={"2xl"}
                    padding="32px"
                    display="flex"
                    flexDirection="column"
                    gap={8}
                >
                    <Box>
                        <motion.div
                            style={{
                                width: "48px",
                                height: "48px",
                                display: "inline-block",
                            }}
                            initial={{ rotate: 0, color: "#F46CBA" }}
                            animate={{
                                rotate: 360,
                                color: ["#F46CBA", "#6C7AF4", "#F46CBA"],
                            }}
                            transition={{
                                repeat: Infinity,
                                duration: 6,
                                type: "tween",
                                ease: "linear",
                            }}
                        >
                            <Globe2 name="globe-2" size={48} />
                        </motion.div>
                    </Box>
                    <Text fontWeight="medium" fontSize="2xl">
                        Service Not Available in Your Region <sup style={{ opacity: 0.2 }}>{countryCode}</sup>
                    </Text>
                    <Text fontSize="lg">
                        Sorry! For compliance reasons, this service is not accessible in your area. Use of VPN, Tor,
                        proxies or other means to circumvent this restriction is a violation of our{" "}
                        <Link
                            color={"blue"}
                            as="a"
                            rel="noreferrer"
                            target="_blank"
                            href="https://support.perp.com/hc/en-us/articles/7529352712729"
                        >
                            Terms of Service
                        </Link>
                        .<br />
                        <br />
                        For help, please open a ticket via{" "}
                        <Link color={"blue"} as="a" rel="noreferrer" target="_blank" href="https://t.me/perpSupBot">
                            Telegram
                        </Link>{" "}
                        or{" "}
                        <Link color={"blue"} as="a" rel="noreferrer" target="_blank" href="https://discord.perp.fi/">
                            Discord
                        </Link>
                        .
                    </Text>
                </Box>
            </Center>
        )
    }

    return <div>{children}</div>
}
