import { BaseProvider } from "@ethersproject/providers"
import { RPC_URL } from "constants/env"
import { getDefaultProvider, Signer } from "ethers"

export class RPCManager {
    private signer?: Signer
    private provider?: BaseProvider
    private defaultRpcUrl: string

    constructor(defaultRpcUrl: string) {
        this.defaultRpcUrl = defaultRpcUrl
    }

    getProviderOrSigner(): BaseProvider | Signer {
        return this.signer ? this.signer : this.getBaseProvider()
    }

    getBaseProvider(): BaseProvider {
        if (!this.provider) {
            this.provider = this.createProvider()
        }
        return this.provider
    }

    setSigner(signer?: Signer) {
        this.provider = undefined
        this.signer = signer
    }

    async getBlockTagAt(timeInSec: number) {
        const provider = this.getBaseProvider()
        const maxBlockNumber = await provider.getBlockNumber()
        let left = 0
        let right = maxBlockNumber
        let mid = Math.floor((left + right) / 2)
        let midBlock = await provider.getBlock(mid)
        let foundExactBlock = false
        while (left <= right) {
            if (midBlock.timestamp === timeInSec) {
                foundExactBlock = true
                break
            } else if (midBlock.timestamp > timeInSec) {
                right = mid - 1
            } else {
                left = mid + 1
            }
            mid = Math.floor((left + right) / 2)
            midBlock = await provider.getBlock(mid)
        }
        let previousBlockNumber
        let previousBlock
        let nextBlockNumber
        let nextBlock
        if (foundExactBlock) {
            previousBlockNumber = mid > 0 ? mid - 1 : 0
            nextBlockNumber = mid === maxBlockNumber ? mid : mid + 1
        } else {
            previousBlockNumber = right < 0 ? 0 : right
            nextBlockNumber = left > maxBlockNumber ? maxBlockNumber : left
        }
        ;[previousBlock, nextBlock] = await Promise.all([
            provider.getBlock(previousBlockNumber),
            provider.getBlock(nextBlockNumber),
        ])
        return {
            exactBlockNumber: foundExactBlock ? mid : undefined,
            exactBlock: foundExactBlock ? midBlock : undefined,
            previousBlockNumber,
            previousBlock,
            nextBlockNumber,
            nextBlock,
        }
    }

    // noinspection JSMethodCanBeStatic
    private createProvider(): BaseProvider {
        return getDefaultProvider(this.defaultRpcUrl)
    }
}

export const rpcManager = new RPCManager(RPC_URL)
