import { Flex, Skeleton, Text } from "@chakra-ui/react"
import Big from "big.js"
import { VePERPIcon } from "components/Icons/Tokens/VePERPIcon"
import { NUMBER_PLACE_HOLDER } from "constants/strings"
import { PerpWalletConnectorContainer } from "hooks/containers/usePerpWalletConnectorContainer"
import React from "react"
import { formatNumber } from "utils"

interface IVePerpCardMyProps {
    data: {
        myVePerp?: Big
        totalVePerp?: Big
    }
}

export function VePerpCardMy({ data: { myVePerp, totalVePerp } }: IVePerpCardMyProps) {
    const { account } = PerpWalletConnectorContainer.useContainer()
    const vePerpPercentageStr =
        (myVePerp && totalVePerp && totalVePerp.gt(0) && ` (${formatNumber(myVePerp.div(totalVePerp).mul(100))}%)`) ||
        " (0%)"

    return (
        <Flex
            bg="perp.background.card"
            borderRadius="12px"
            p="16px"
            alignContent="center"
            alignItems={"center"}
            flexGrow={1}
        >
            <Text flex={1} fontSize="14px" color="perp.text.secondary">
                {"My vePERP"}
            </Text>
            <Flex alignItems="center" gap="4px">
                <VePERPIcon boxSize="14px" />
                <Skeleton isLoaded={!account || (!!totalVePerp && !!myVePerp)}>
                    <Text fontSize="14px" color="perp.text.primary">
                        {(myVePerp && totalVePerp && `${formatNumber(myVePerp)}${vePerpPercentageStr}`) ||
                            NUMBER_PLACE_HOLDER}
                    </Text>
                </Skeleton>
            </Flex>
        </Flex>
    )
}
