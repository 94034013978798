import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Motion, Size } from "components/Modal/type"

const initialState = {
    modal: {
        increaseAmount: {
            isOpen: false,
            size: Size.FULL,
            motion: Motion.SLIDE_IN_BOTTOM,
        },
        extendTime: {
            isOpen: false,
            size: Size.FULL,
            motion: Motion.SLIDE_IN_BOTTOM,
        },
        createLock: {
            isOpen: false,
            size: Size.FULL,
            motion: Motion.SLIDE_IN_BOTTOM,
        },
    },
}

export const homeSlice = createSlice({
    name: "home",
    initialState,
    reducers: {
        openModalIncreaseAmount(state, action: PayloadAction<{ size: Size; motion: Motion }>) {
            state.modal.increaseAmount.isOpen = true
            state.modal.increaseAmount.size = action.payload.size
            state.modal.increaseAmount.motion = action.payload.motion
        },
        closeModalIncreaseAmount(state) {
            state.modal.increaseAmount.isOpen = false
        },
        openModalExtendTime(state, action: PayloadAction<{ size: Size; motion: Motion }>) {
            state.modal.extendTime.isOpen = true
            state.modal.extendTime.size = action.payload.size
            state.modal.extendTime.motion = action.payload.motion
        },
        closeModalExtendTime(state) {
            state.modal.extendTime.isOpen = false
        },
        openModalCreateLock(state, action: PayloadAction<{ size: Size; motion: Motion }>) {
            state.modal.createLock.isOpen = true
            state.modal.createLock.size = action.payload.size
            state.modal.createLock.motion = action.payload.motion
        },
        closeModalCreateLock(state) {
            state.modal.createLock.isOpen = false
        },
    },
})
