import { TransactionReceipt } from "@ethersproject/providers"
import { createApi } from "@reduxjs/toolkit/dist/query/react"
import Big from "big.js"
import { IS_PRODUCTION } from "constants/env"
import { perpReferrerClient } from "contracts/PerpReferrerClient"
import { vePerpReferralRewardDelegateClient } from "contracts/RewardDelegateClient"
import { referralDistributorClient } from "contracts/VePerpRewardDistributorClient"
import { rpcManager } from "lib/RPCManager"
import { Claim } from "modules/claim"
import { appSyncClient, ClaimableRewardsItem } from "services/AppSyncClient"
import { awsClient } from "services/AWSClient"
import { bugsnagClient } from "services/BugsnagClient"
import { theGraphClient } from "services/TheGraphClient"
import { createMutation } from "utils"

export interface IReferralRewards {
    items: ClaimableRewardsItem[]
    totalBalance: Big
    totalBalanceUsd: Big
    minLockDuration: number
}

interface IReferralRewardsArg {
    account: string
}

/**
 * NOTE: since referral v10 will continue to use the same snapshot endpoint,
 * the totalReferrerVolume, totalReferrerVePERPBalance, and totalReferrerReward need to be optional
 */
// TODO: separate the accumulatedTradingVolume into PERP module
export interface IReferralLastReward {
    week: number
    weekStart: number
    weekEnd: number
    totalReward: Big
    referrerMapUrl: string
    refereeMapUrl: string
    totalReferrerVolume?: Big
    totalReferrerVePERPBalance?: Big
    totalReferrerReward?: Big
    accumulatedTradingVolume: Big
}

interface IReferralRewardUserArg {
    week: number
    account: string
}

export interface IReferralRewardUser {
    referrerVolume: Big
    referrerReward: Big
    referrerBoostedReward: Big
    refereeFee: Big
    refereeReward: Big
}

export interface IReferralCode {
    referrer?: string
    referee?: string
}

export interface IReferralCodeArg {
    account: string
}

export interface ICurrentReward {
    tradingVolume: Big
}

// TODO: separate the accumulatedTradingVolume into PERP module
export interface IReferralCurrentReward {
    referrer: ICurrentReward
    referee: ICurrentReward
    accumulatedTradingVolume: Big
}

export interface IReferralCurrentRewardArg {
    account: string
    startTime: number
    endTime: number
    referralCode?: string
}

export interface IReferralDelegateInfo {
    beneficiary: string
    qualifiedMultiplier: number
}

export interface IReferralDelegateInfoArg {
    account: string
}

export const referralQueryApi = createApi({
    reducerPath: "referralQuery",
    baseQuery: () => ({ data: undefined }),
    endpoints: builder => ({
        referralRewards: builder.query<IReferralRewards, IReferralRewardsArg>({
            queryFn: referralRewardsQuery,
        }),
        referralLastReward: builder.query<IReferralLastReward, void>({
            queryFn: referralLastRewardQuery,
        }),
        referralCurrentReward: builder.query<IReferralCurrentReward, IReferralCurrentRewardArg>({
            queryFn: referralCurrentRewardQuery,
        }),
        referralRewardUserAt: builder.query<IReferralRewardUser, IReferralRewardUserArg>({
            queryFn: referralRewardUserAtQuery,
        }),
        referralCode: builder.query<IReferralCode, IReferralCodeArg>({
            queryFn: referralCodeQuery,
        }),
        referralDelegateInfo: builder.query<IReferralDelegateInfo, IReferralDelegateInfoArg>({
            queryFn: referralDelegateInfoQuery,
        }),
    }),
})

export const referralMutationApi = createApi({
    reducerPath: "referralMutation",
    baseQuery: () => ({ data: undefined }),
    endpoints: builder => ({
        referralClaimWeeks: builder.mutation<TransactionReceipt, [string, Claim[]]>({
            queryFn: createMutation((liquidityProvider: string, claims: Claim[]) =>
                referralDistributorClient.getClaimWeeksTx(liquidityProvider, claims),
            ),
        }),
        referralCreateCode: builder.mutation<TransactionReceipt, [string]>({
            queryFn: createMutation((code: string) => perpReferrerClient.getCreateReferralCodeTx(code)),
        }),
        referralSetCode: builder.mutation<TransactionReceipt, [string]>({
            queryFn: createMutation((code: string) => perpReferrerClient.getSetReferralCodeTx(code)),
        }),
    }),
})

export const {
    useReferralRewardsQuery,
    useReferralLastRewardQuery,
    useReferralCurrentRewardQuery,
    useReferralRewardUserAtQuery,
    useReferralCodeQuery,
    useReferralDelegateInfoQuery,
} = referralQueryApi
export const { useReferralClaimWeeksMutation, useReferralCreateCodeMutation, useReferralSetCodeMutation } =
    referralMutationApi

async function referralRewardsQuery(args: IReferralRewardsArg) {
    try {
        const { account } = args
        const [rewards, minLockDuration] = await Promise.all([
            appSyncClient.getReferralProgramClaimableRewards(account),
            referralDistributorClient.getMinLockDuration(),
        ])
        const data = {
            items: rewards.items,
            totalBalance: rewards.totalBalance,
            totalBalanceUsd: rewards.totalBalanceUsd,
            minLockDuration,
        }
        return { data }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}

async function referralLastRewardQuery() {
    try {
        const rewards = await awsClient.getReferralRewardsSnapshot(IS_PRODUCTION)
        const lastReward = rewards[Object.keys(rewards)[Object.keys(rewards).length - 1]]
        const weekEnd = lastReward.weekEnd
        const { exactBlockNumber, previousBlockNumber } = await rpcManager.getBlockTagAt(weekEnd)
        const blockTag = exactBlockNumber || previousBlockNumber
        const { tradingVolume } = await theGraphClient.getPERPProtocolDataAt(blockTag)
        const data = {
            week: lastReward.weekOrdinal,
            weekStart: lastReward.weekStart,
            weekEnd: lastReward.weekEnd,
            totalReward: Big(lastReward.totalReward),
            referrerMapUrl: lastReward.referrerMap,
            refereeMapUrl: lastReward.refereeMap,
            totalReferrerVolume: lastReward.totalReferrerVolume ? Big(lastReward.totalReferrerVolume) : undefined,
            totalReferrerVePERPBalance: lastReward.totalReferrerVePERPBalance
                ? Big(lastReward.totalReferrerVePERPBalance)
                : undefined,
            totalReferrerReward: lastReward.totalReferrerReward ? Big(lastReward.totalReferrerReward) : undefined,
            accumulatedTradingVolume: tradingVolume,
        }
        return { data }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}

async function referralCurrentRewardQuery({
    account,
    startTime,
    endTime,
    referralCode = "",
}: IReferralCurrentRewardArg) {
    try {
        const [referrerReward, refereeReward, { tradingVolume }] = await Promise.all([
            theGraphClient.getReferralCodeDayData(startTime, endTime, referralCode),
            theGraphClient.getRefereeDayData(account, startTime, endTime),
            theGraphClient.getPERPProtocol(),
        ])
        return {
            data: {
                referrer: referrerReward,
                referee: refereeReward,
                accumulatedTradingVolume: tradingVolume,
            },
        }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}

async function referralRewardUserAtQuery({ week, account }: IReferralRewardUserArg) {
    try {
        const data = await appSyncClient.getReferralRewardUser(account, week)
        return { data }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}

async function referralCodeQuery({ account }: IReferralCodeArg) {
    try {
        const [referrerCodeResult, refereeCodeResult] = await Promise.allSettled([
            perpReferrerClient.getReferralCodeByPartnerAddress(account),
            perpReferrerClient.getRefereeCodeByTraderAddress(account),
        ])

        // NOTE: only handle empty case, will throw all other errors
        let referrer
        if (referrerCodeResult.status === "rejected") {
            const isEmpty = JSON.stringify(referrerCodeResult.reason).includes(
                "execution reverted: Referral code doesn't exist",
            )
            if (!isEmpty) {
                throw referrerCodeResult.reason.error
            }
        } else {
            referrer = referrerCodeResult.value
        }

        let referee
        if (refereeCodeResult.status === "rejected") {
            const isEmpty = JSON.stringify(refereeCodeResult.reason).includes(
                "execution reverted: Trader doesn't have a referral code",
            )
            if (!isEmpty) {
                throw refereeCodeResult.reason.error
            }
        } else {
            referee = refereeCodeResult.value
        }

        return {
            data: { referrer, referee },
        }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}

async function referralDelegateInfoQuery({ account }: IReferralDelegateInfoArg) {
    try {
        const { beneficiary, qualifiedMultiplier } =
            await vePerpReferralRewardDelegateClient.getBeneficiaryAndQualifiedMultiplier(account)
        return {
            data: {
                beneficiary,
                qualifiedMultiplier,
            },
        }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}
