import { Text, VStack, SimpleGrid, Heading, Divider } from "@chakra-ui/react"
import { useVePerpInfo } from "modules/vePerp"
import React from "react"

export const CurrentVePerpWithRequirement = (props: { vePerpAmountRequiredAtSnapshot?: string }) => {
    const { vePerpAmountUnweighted } = useVePerpInfo()

    return (
        <VStack align="stretch" mt="12px" spacing={5} p="24px" bgColor="perp.background.background" borderRadius="16px">
            <SimpleGrid gap={[6, 0]} columns={[1, 2]}>
                <VStack w="100%" spacing={0} align={"flex-start"}>
                    <Heading>{vePerpAmountUnweighted ? vePerpAmountUnweighted.toFixed(2) : "—"}</Heading>
                    <Text>Current vePERP</Text>
                </VStack>
                <VStack w="100%" spacing={0} align={"flex-start"}>
                    <Heading>
                        {props.vePerpAmountRequiredAtSnapshot ? props.vePerpAmountRequiredAtSnapshot : "—"}
                    </Heading>
                    <Text>Req. snapshot amount </Text>
                </VStack>
            </SimpleGrid>
            <Divider />
            <Text variant={"body2"} color="perp.text.secondary">
                The amount of vePERP will decrease overtime while your locked PERP remains constant. You must meet the
                requirement <strong style={{ color: "rgb(200,200,200)" }}>at the time of the snapshot</strong> to be
                eligible for the rewards.
            </Text>
        </VStack>
    )
}
