import opGoerliCoreMetadataJson from "@perp/curie-deployments/optimism-goerli/core/metadata.json"
import opGoerliLiquidityMiningMetadataJson from "@perp/curie-deployments/optimism-goerli/liquidity-mining/metadata.json"
import opGoerliPeripheryMetadataJson from "@perp/curie-deployments/optimism-goerli/periphery/metadata.json"
import opGoerliVotingEscrowMetadataJson from "@perp/curie-deployments/optimism-goerli/voting-escrow/metadata.json"
import opCoreMetadataJson from "@perp/curie-deployments/optimism/core/metadata.json"
import opLiquidityMiningMetadataJson from "@perp/curie-deployments/optimism/liquidity-mining/metadata.json"
import opPeripheryMetadataJson from "@perp/curie-deployments/optimism/periphery/metadata.json"
import opVotingEscrowMetadataJson from "@perp/curie-deployments/optimism/voting-escrow/metadata.json"

const {
    NODE_ENV,
    REACT_APP_STAGE,
    REACT_APP_VERSION,
    REACT_APP_CURIE_DEPLOYMENTS_VERSION,
    REACT_APP_RPC_URL,
    REACT_APP_RPC_URL_HTTPS,
    REACT_APP_SUBGRAPH_URL,
    REACT_APP_SUBGRAPH_URL_PERP_V2,
    REACT_APP_BUGSNAG_API_KEY,
    REACT_APP_SEGMENT_API_KEY,
    REACT_APP_CMS_URL,
    REACT_APP_CMS_URL_AUTH,
    REACT_APP_APP_SYNC_API_URL_REFERRAL,
    REACT_APP_APP_SYNC_API_KEY_REFERRAL,
    REACT_APP_APP_SYNC_API_URL_LIQUIDITY_MINING,
    REACT_APP_APP_SYNC_API_KEY_LIQUIDITY_MINING,
} = process.env

export const IS_DEV = NODE_ENV === "development"
export const IS_STAGING = REACT_APP_STAGE === "staging"
export const IS_PRODUCTION = REACT_APP_STAGE === "production"

// NOTE: metadata
export const STAGE = REACT_APP_STAGE!
export const VERSION = REACT_APP_VERSION!
export const CURIE_DEPLOYMENTS_VERSION = REACT_APP_CURIE_DEPLOYMENTS_VERSION!

// NOTE: services
export const RPC_URL = REACT_APP_RPC_URL!
export const RPC_URL_HTTPS = REACT_APP_RPC_URL_HTTPS!
export const SUBGRAPH_URL = REACT_APP_SUBGRAPH_URL!
export const SUBGRAPH_URL_PERP_V2 = REACT_APP_SUBGRAPH_URL_PERP_V2!
export const BUGSNAG_API_KEY = REACT_APP_BUGSNAG_API_KEY!
export const SEGMENT_API_KEY = REACT_APP_SEGMENT_API_KEY!
export const CMS_URL = REACT_APP_CMS_URL!
export const CMS_URL_AUTH = REACT_APP_CMS_URL_AUTH!
export const APP_SYNC_API_URL_REFERRAL = REACT_APP_APP_SYNC_API_URL_REFERRAL!
export const APP_SYNC_API_KEY_REFERRAL = REACT_APP_APP_SYNC_API_KEY_REFERRAL!
export const APP_SYNC_API_URL_LIQUIDITY_MINING = REACT_APP_APP_SYNC_API_URL_LIQUIDITY_MINING!
export const APP_SYNC_API_KEY_LIQUIDITY_MINING = REACT_APP_APP_SYNC_API_KEY_LIQUIDITY_MINING!

// NOTE: contract metadata
export const METADATA = {
    VOTING_ESCROW: IS_PRODUCTION ? opVotingEscrowMetadataJson : opGoerliVotingEscrowMetadataJson,
    LIQUIDITY_MINING: IS_PRODUCTION ? opLiquidityMiningMetadataJson : opGoerliLiquidityMiningMetadataJson,
    PERIPHERY: IS_PRODUCTION ? opPeripheryMetadataJson : opGoerliPeripheryMetadataJson,
    CORE: IS_PRODUCTION ? opCoreMetadataJson : opGoerliCoreMetadataJson,
}
