import Big from "big.js"
import { useUsdcFeeDistributorRewardsQuery } from "modules/usdcFeeDistributor"
import { useVePERPDistributorRewardsQuery } from "modules/vePerpDistributor"
import { getTimestampSlots } from "utils"

interface IUseHistoryRewardsArgs {
    startTime: number
    endTime: number
}

export function useHistoryRewards({ startTime, endTime }: IUseHistoryRewardsArgs) {
    // TODO: implement multicall in the future
    const { data: usdcFeeRewardsData } = useUsdcFeeDistributorRewardsQuery({ startTime, endTime })
    const { data: vePERPRewardsData } = useVePERPDistributorRewardsQuery({ startTime, endTime })
    const reverseTimestampSlots = getTimestampSlots(startTime, endTime).reverse()

    const historyRewards = reverseTimestampSlots.map(timestamp => {
        return {
            timestamp,
            usdcReward: usdcFeeRewardsData?.[timestamp] ?? Big(0),
            vePERPReward: vePERPRewardsData?.[timestamp] ?? Big(0),
        }
    })
    return historyRewards
}
