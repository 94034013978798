import Big from "big.js"
import { BigNumber } from "ethers"

export function big2BigNum(val: Big, decimals: number = 18): BigNumber {
    return BigNumber.from(val.mul(new Big(10).pow(decimals)).toFixed(0))
}

export function bigNum2Big(val: BigNumber, decimals: number = 18): Big {
    return new Big(val.toString()).div(new Big(10).pow(decimals))
}

export function str2Big(val: string) {
    try {
        return Big(val)
    } catch (err) {}
}
