import { modalAnatomy as parts } from "@chakra-ui/anatomy"
import { mode } from "@chakra-ui/theme-tools"

import type {
    PartsStyleFunction,
    PartsStyleObject,
    SystemStyleFunction,
    SystemStyleObject,
} from "@chakra-ui/theme-tools"

const baseStyleOverlay: SystemStyleObject = {
    bg: "blackAlpha.700",
    zIndex: "modal",
}

const baseStyleDialogContainer: SystemStyleFunction = props => {
    const { isCentered, scrollBehavior } = props

    return {
        display: "flex",
        zIndex: "modal",
        justifyContent: "center",
        alignItems: isCentered ? "center" : "flex-start",
        overflow: scrollBehavior === "inside" ? "hidden" : "auto",
    }
}

const baseStyleDialog: SystemStyleFunction = props => {
    const { scrollBehavior } = props

    return {
        border: "1px solid",
        borderColor: "perp.background.border",
        borderRadius: "16px",
        bg: mode("white", "gray.700")(props),
        color: "inherit",
        my: "3.75rem",
        zIndex: "modal",
        maxH: scrollBehavior === "inside" ? "calc(100% - 7.5rem)" : undefined,
        boxShadow: mode("lg", "dark-lg")(props),
    }
}

const baseStyleHeader: SystemStyleObject = {
    px: 6,
    py: 4,
    fontSize: "xl",
    fontWeight: "semibold",
}

const baseStyleCloseButton: SystemStyleObject = {
    position: "absolute",
    top: 2,
    insetEnd: 3,
    _focus: {
        boxShadow: "none",
    },
}

const baseStyleBody: SystemStyleFunction = props => {
    const { scrollBehavior } = props
    return {
        p: "24px",
        flex: 1,
        overflow: scrollBehavior === "inside" ? "auto" : undefined,
    }
}

const baseStyleFooter: SystemStyleObject = {
    px: 6,
    py: 4,
}

const baseStyle: PartsStyleFunction<typeof parts> = props => ({
    overlay: baseStyleOverlay,
    dialogContainer: baseStyleDialogContainer(props),
    dialog: baseStyleDialog(props),
    header: baseStyleHeader,
    closeButton: baseStyleCloseButton,
    body: baseStyleBody(props),
    footer: baseStyleFooter,
})

/**
 * Since the `maxWidth` prop references theme.sizes internally,
 * we can leverage that to size our modals.
 */
function getSize(value: string): PartsStyleObject<typeof parts> {
    if (value === "full") {
        return {
            dialog: {
                maxW: "100vw",
                minH: "100vh",
                "@supports(min-height: -webkit-fill-available)": {
                    minH: "-webkit-fill-available",
                },
                my: 0,
                border: "initial",
                borderRadius: "initial",
            },
        }
    }
    return {
        dialog: { maxW: value },
    }
}

const sizes = {
    xs: getSize("xs"),
    sm: getSize("sm"),
    md: getSize("md"),
    lg: getSize("lg"),
    xl: getSize("xl"),
    "2xl": getSize("2xl"),
    "3xl": getSize("3xl"),
    "4xl": getSize("4xl"),
    "5xl": getSize("5xl"),
    "6xl": getSize("6xl"),
    full: getSize("full"),
}

const defaultProps = {
    size: "md",
}

export const Modal = {
    parts: parts.keys,
    baseStyle,
    sizes,
    defaultProps,
}
