import { METADATA } from "constants/env"
import { ContractTransaction } from "ethers"
import { Claim } from "modules/claim"

import { big2BigNum } from "../utils/number"
import { ContractClientFactory, contractClientFactory } from "./ContractClientFactory"

class PerpLiquidityMiningClient {
    private readonly contractClientFactory: ContractClientFactory
    private readonly contractAddress: string

    constructor(contractClientFactory: ContractClientFactory, contractAddress: string) {
        this.contractClientFactory = contractClientFactory
        this.contractAddress = contractAddress
    }

    getClaimWeekTx(liquidityProvider: string, claim: Claim) {
        const contract = this.contractClientFactory.getPerpLiquidityMining(this.contractAddress)
        return this.contractClientFactory.getTx(contract, "claimWeek", [
            liquidityProvider,
            claim.week,
            big2BigNum(claim.balance),
            claim.merkleProof,
        ])
    }

    getClaimWeeksTx(liquidityProvider: string, claims: Claim[]): Promise<ContractTransaction> {
        const contract = this.contractClientFactory.getPerpLiquidityMining(this.contractAddress)
        const parsedClaims = claims.map(claim => ({
            week: claim.week,
            balance: big2BigNum(claim.balance),
            merkleProof: claim.merkleProof,
        }))
        return this.contractClientFactory.getTx(contract, "claimWeeks", [liquidityProvider, parsedClaims])
    }
}

const { LIQUIDITY_MINING } = METADATA
export const opPerpLiquidityMiningClient = new PerpLiquidityMiningClient(
    contractClientFactory,
    LIQUIDITY_MINING.contracts.OpReward.address,
)
