import { Divider, Heading, SimpleGrid, Stack, Text, VStack } from "@chakra-ui/react"
import { USDCIcon } from "components/Icons/Tokens/USDCIcon"
import { useLastEpochTotalAPR } from "modules/lazyRiver/hook/useLastEpochTotalAPR"
import { useVePerpInfo } from "modules/vePerp"
import React from "react"
import { formatNumber } from "utils"
import Footer from "./Footer"

export function CurrentVePerp() {
    const { vePerpAmountUnweighted } = useVePerpInfo()
    const { daysToExpiration, lockedPerpTokenValidDate } = useVePerpInfo()
    const { lastEpochRewardInUsdcPerVePerp } = useLastEpochTotalAPR({ epochDeviation: 1 })
    return (
        <Stack
            align={"stretch"}
            mt="12px"
            spacing={5}
            p="24px"
            bgColor="perp.background.background"
            borderRadius="16px"
        >
            <Heading variant="h4" color="perp.text.primary">
                Your vePERP Status
            </Heading>{" "}
            <Divider />
            <SimpleGrid columns={[1, 2]} width="100%" spacing={8}>
                <VStack spacing={0} align={"flex-start"}>
                    <Heading variant={"h2"}>
                        {vePerpAmountUnweighted ? vePerpAmountUnweighted?.toFixed(2) : "—"}
                    </Heading>
                    <Heading variant="sub1">Current vePERP</Heading>
                </VStack>
                <VStack spacing={0} align={"flex-start"}>
                    <Heading variant={"h2"}>
                        {lockedPerpTokenValidDate ? lockedPerpTokenValidDate : "—"}{" "}
                        <Text lineHeight={1} fontSize={"md"} as="span" color="perp.text.secondary">
                            ({daysToExpiration ? daysToExpiration : "—"} Days)
                        </Text>
                    </Heading>
                    <Heading variant="sub1">Locked Through</Heading>
                </VStack>
            </SimpleGrid>
            <Divider />
            <Text variant={"body2"} color="perp.text.secondary">
                <Text color="perp.text.primary" fontWeight={"bold"} as="span">
                    <USDCIcon boxSize={"14px"} />{" "}
                    {lastEpochRewardInUsdcPerVePerp
                        ? formatNumber(lastEpochRewardInUsdcPerVePerp, { maximumFractionDigits: 6 })
                        : " — "}
                </Text>{" "}
                generated per vePERP last week. The amount of vePERP will decrease overtime while your locked PERP
                remains constant. You must have some vePERP{" "}
                <strong style={{ color: "rgb(200,200,200)" }}>at the time of the snapshot</strong> to be eligible for
                the rewards.
            </Text>
            <Footer />
        </Stack>
    )
}
