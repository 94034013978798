import React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

function LockedPERPIcon({ ...props }: IconProps) {
    return (
        <Icon width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_1130_3583)">
                <path
                    d="M31.9862 20.3705C29.8492 28.942 21.1677 34.1585 12.5952 32.021C4.02624 29.884 -1.19026 21.202 0.947736 12.631C3.08374 4.05852 11.7652 -1.15848 20.3352 0.978516C28.9072 3.11552 34.1232 11.7985 31.9862 20.3705Z"
                    fill="#03BCE4"
                />
                <path
                    opacity="0.8"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.2777 9.28275C15.2696 8.31751 13.6568 8.31751 12.6487 9.28275L8.24521 13.4989C7.20724 14.4927 7.20725 16.126 8.24521 17.1198L15.4211 23.9903C15.43 23.9998 15.4392 24.0092 15.4485 24.0185C15.7301 24.3002 16.1029 24.4414 16.476 24.4412C16.8509 24.4422 17.2259 24.301 17.5091 24.0185C17.5312 23.9963 17.5525 23.9737 17.5729 23.9505L24.6901 17.1198C25.7256 16.126 25.7256 14.4926 24.6901 13.4988L22.3092 11.2137C21.3034 10.2485 19.6945 10.2485 18.6888 11.2137L18.4917 11.4026L16.2777 9.28275ZM18.5337 15.314C19.1911 14.6845 19.8422 14.0487 20.499 13.4184L22.4692 15.3093L20.499 17.2002L18.5337 15.314ZM16.4342 13.3745L12.4464 17.2003L10.4715 15.3093L14.4632 11.4874L16.4342 13.3745ZM16.4818 17.2786L18.4453 19.1712L16.4775 21.0598L14.505 19.1712L16.4818 17.2786Z"
                    fill="#141319"
                />
                <path
                    d="M18.9706 25.5624C18.9706 22.0071 21.8528 19.1249 25.4082 19.1249H25.5624C29.1178 19.1249 32 22.0071 32 25.5624C32 29.1178 29.1178 32 25.5624 32H25.4082C21.8528 32 18.9706 29.1178 18.9706 25.5624Z"
                    fill="#B0EDFF"
                />
                <path
                    d="M28.9305 25.1985C28.9305 24.7255 28.5459 24.3409 28.0729 24.3409H27.6441V22.8107C27.6441 21.6285 26.6823 20.6666 25.5 20.6666C24.3178 20.6666 23.356 21.6285 23.356 22.8107V24.3409H22.9271C22.4542 24.3409 22.0695 24.7255 22.0695 25.1985V28.3853C22.0695 28.8582 22.4542 29.2429 22.9271 29.2429H28.0729C28.5459 29.2429 28.9305 28.8582 28.9305 28.3853V25.1985ZM24.2136 22.8107C24.2136 22.1014 24.7908 21.5243 25.5 21.5243C26.2093 21.5243 26.7865 22.1014 26.7865 22.8107V24.3409H24.2136V22.8107Z"
                    fill="#027692"
                />
            </g>
            <defs>
                <clipPath id="clip0_1130_3583">
                    <rect width="32" height="32" fill="white" transform="translate(0.466736 0.5)" />
                </clipPath>
            </defs>
        </Icon>
    )
}

export { LockedPERPIcon }
