import {
    Divider,
    Heading,
    ListIcon,
    ListItem,
    SimpleGrid,
    Skeleton,
    Text,
    UnorderedList,
    VStack,
} from "@chakra-ui/react"
import { PERPIcon } from "components/Icons/Tokens/PERPIcon"
import { PerpWalletConnectorContainer } from "hooks/containers/usePerpWalletConnectorContainer"
import { useEpochStatus } from "modules/lazyRiver/hook/useEpochStatus"
import { useLastEpochMyAPR } from "modules/lazyRiver/hook/useLastEpochMyAPR"
import { useLastEpochTotalAPR } from "modules/lazyRiver/hook/useLastEpochTotalAPR"
import { useLastTokenTimeDeviation } from "modules/lazyRiver/hook/useLastTokenTimeDeviation"
import React from "react"
import { formatNumber } from "utils"
import { RewardCardMy } from "./RewardCardMy"
import { RewardCardTotal } from "./RewardCardTotal"
import { VePerpCardMy } from "./VePerpCardMy"
import { VePerpCardTotal } from "./VePerpCardTotal"
import { VePERPIcon } from "components/Icons/Tokens/VePERPIcon"

export function LastWeekStatus() {
    const { account } = PerpWalletConnectorContainer.useContainer()
    const { epochDeviation, nextDistributionTimeUtcString, isNextCheckpointTimeAcrossNewEpoch } =
        useLastTokenTimeDeviation()

    const {
        totalUsdcFeeRewards: lastEpochTotalUsdcFeeRewards,
        totalVePerp: lastEpochTotalVePerp,
        myVePerp: lastEpochMyVePerp,
        myUsdcRewards: lastEpochMyUsdcRewards,
        isLoadingVePerpUserDataAt: lastEpochIsLoadingVePerpUserDataAt,
        startDateStr: lastEpochStartDateStr,
        endDateStr: lastEpochEndDateStr,
    } = useEpochStatus({
        epochDeviation,
    })
    const { lastEpochTotalApr } = useLastEpochTotalAPR({ epochDeviation })
    const { lastEpochMyApr } = useLastEpochMyAPR({ epochDeviation })

    return (
        <VStack
            spacing="16px"
            align="stretch"
            mt="12px"
            p="24px"
            bgColor="perp.background.background"
            borderRadius="16px"
        >
            <VStack align="flex-start" spacing={1}>
                <Heading variant="h4" color="perp.text.primary">
                    Last Week
                </Heading>
                <Text color="perp.text.secondary" variant={"body2"}>
                    {lastEpochStartDateStr} - {lastEpochEndDateStr}
                </Text>
            </VStack>

            <Divider />
            <SimpleGrid columns={[1, 2]} width="100%" spacing={8}>
                <VStack spacing={0} align={"flex-start"}>
                    <Skeleton isLoaded={!!lastEpochTotalApr}>
                        <Heading variant={"h2"}>{lastEpochTotalApr && formatNumber(lastEpochTotalApr)} %</Heading>
                    </Skeleton>
                    <Heading variant="sub1">APR - Last Week</Heading>
                </VStack>
                <VStack spacing={0} align={"flex-start"}>
                    <Skeleton isLoaded={!account || !!lastEpochMyApr}>
                        <Heading variant={"h2"}>
                            {account ? lastEpochMyApr && formatNumber(lastEpochMyApr) : "—"} %
                        </Heading>
                    </Skeleton>
                    <Heading variant="sub1">My APR - Last Week</Heading>
                </VStack>
            </SimpleGrid>
            <SimpleGrid columns={[1, 2]} width="100%" spacing={4}>
                <RewardCardTotal
                    data={{
                        totalUsdcRewards: lastEpochTotalUsdcFeeRewards,
                    }}
                />
                <RewardCardMy
                    data={{
                        isLoading: lastEpochIsLoadingVePerpUserDataAt,
                        myUsdcRewards: lastEpochMyUsdcRewards,
                    }}
                />
            </SimpleGrid>
            <SimpleGrid columns={[1, 2]} width="100%" spacing={4}>
                <VePerpCardTotal
                    data={{
                        totalVePerp: lastEpochTotalVePerp,
                    }}
                />
                <VePerpCardMy
                    data={{
                        myVePerp: lastEpochMyVePerp,
                        totalVePerp: lastEpochTotalVePerp,
                    }}
                />
            </SimpleGrid>
            <Skeleton isLoaded={true}>
                <UnorderedList spacing={1} color="perp.text.secondary" fontSize={"sm"}>
                    <ListItem>
                        <ListIcon as={PERPIcon} />
                        <strong>PERP</strong> Rewards from Lazy River 1.0 is replaced by Locked PERP and no longer
                        distributed.
                    </ListItem>
                    {isNextCheckpointTimeAcrossNewEpoch && (
                        <ListItem>
                            <strong>Next Distribution: </strong> {nextDistributionTimeUtcString}
                        </ListItem>
                    )}
                </UnorderedList>
                <UnorderedList spacing={1} color="perp.text.secondary" fontSize={"sm"}>
                    <ListItem>
                        <ListIcon as={VePERPIcon} />
                        <strong>LockedPERP</strong> Rewards from Lazy River 2.0 is no longer distributed{" "}
                        <a
                            style={{
                                color: "#72f9a3",
                            }}
                            href="https://support.perp.com/hc/en-us/articles/8939928349337"
                        >
                            (Learn more)
                        </a>
                        .
                    </ListItem>
                    {isNextCheckpointTimeAcrossNewEpoch && (
                        <ListItem>
                            <strong>Next Distribution: </strong> {nextDistributionTimeUtcString}
                        </ListItem>
                    )}
                </UnorderedList>
            </Skeleton>
        </VStack>
    )
}
