import { Divider, Heading, HStack, SimpleGrid, Stack, Text, VStack } from "@chakra-ui/react"
import { USDCIcon } from "components/Icons/Tokens/USDCIcon"
import { useProtocolRevenueEstimated } from "modules/lazyRiver/hook/useProtocolRevenueEstimated"
import React from "react"
import { History } from "./History"

export function UpcomingRewards() {
    const { revenue } = useProtocolRevenueEstimated()

    return (
        <Stack
            align={"stretch"}
            mt="12px"
            spacing={5}
            p="24px"
            bgColor="perp.background.background"
            borderRadius="16px"
        >
            <Heading variant="h4" color="perp.text.primary">
                Upcoming Rewards
            </Heading>
            <Divider />
            <SimpleGrid columns={[1]} width="100%" spacing={8}>
                <VStack spacing={0} align={"flex-start"}>
                    {/* If revenue is positive, then we will distribute 75% as USDC rewards */}
                    <Heading variant={"h2"}>
                        {revenue && revenue.gte(0) ? revenue.times(0.75)?.toFixed(3) : "—"}
                    </Heading>
                    <Heading variant="sub1">
                        <HStack spacing={1}>
                            <USDCIcon boxSize={"14px"} />
                            <span>Pending USDC Rewards</span>
                        </HStack>
                    </Heading>
                </VStack>
            </SimpleGrid>
            <Text variant={"body2"} color="perp.text.secondary">
                The pending rewards will be reset every Thursday around 0:00 UTC.
                <br /> The current Protocol Revenue is{" "}
                <Text color="perp.text.primary" fontWeight={"bold"} as="span">
                    <USDCIcon boxSize={"14px"} /> {revenue && revenue.gte(0) ? revenue?.toFixed(3) : "—"}
                </Text>
                . If the Protocol Revenue is negative then no rewards will be distributed.
            </Text>
            <Divider />
            <VStack align={"stretch"} spacing={4}>
                <Heading variant="h4" color="perp.text.primary">
                    Rewards History
                </Heading>
                <History />
            </VStack>
        </Stack>
    )
}
