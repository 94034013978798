import React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

function UpIcon({ ...props }: IconProps) {
    return (
        <Icon {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.46973 0.469613L0.469727 6.46961L1.53039 7.53027L7.00006 2.0606L12.4697 7.53027L13.5304 6.46961L7.53039 0.469613C7.23749 0.17672 6.76262 0.17672 6.46973 0.469613Z"
                fill="white"
            />
        </Icon>
    )
}

export { UpIcon }
