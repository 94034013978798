import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Signer } from "ethers"
import { rpcManager } from "lib/RPCManager"

interface WalletState {
    isTxLoading: boolean
    signer?: Signer
}

const initialState: WalletState = {
    isTxLoading: false,
}

export const walletSlice = createSlice({
    name: "wallet",
    initialState,
    reducers: {
        setSigner(state, action: PayloadAction<Signer | undefined>) {
            state.signer = action.payload
            rpcManager.setSigner(state.signer)
        },
        setTxLoading(state, action: PayloadAction<boolean>) {
            state.isTxLoading = action.payload
        },
    },
})
