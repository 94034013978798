import { METADATA } from "constants/env"
import { bigNum2Big } from "utils"
import { ContractClientFactory, contractClientFactory } from "./ContractClientFactory"

export class InsuranceFundClient {
    private readonly contractClientFactory: ContractClientFactory
    private readonly contractAddress: string

    constructor(contractClientFactory: ContractClientFactory, contractAddress: string) {
        this.contractClientFactory = contractClientFactory
        this.contractAddress = contractAddress
    }

    getContract() {
        return this.contractClientFactory.getInsuranceFund(this.contractAddress)
    }

    async getDistributionThreshold() {
        const contract = this.getContract()
        return bigNum2Big(await contract.getDistributionThreshold(), 6)
    }

    async getInsuranceFundCapacity() {
        const contract = this.getContract()
        return bigNum2Big(await contract.getInsuranceFundCapacity(), 6)
    }
}

export const insuranceFundClient = new InsuranceFundClient(
    contractClientFactory,
    // @ts-ignore TODO: remove ts-ignore when optimism support USDCFeeDistributor contract
    METADATA.CORE.contracts.InsuranceFund.address,
)
