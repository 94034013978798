import Big from "big.js"
import { EPOCH } from "constants/config"
import { VePerpClient } from "contracts/VePerpClient"
import { useVePerpInfo } from "modules/vePerp"
import { useMemo, useState } from "react"
import { useGetServerTimestampQuery } from "services/AWSClient/flow"
import { getEndAtWithCalibrated } from "utils"

import { LockerInfoPreviewProps } from "./LockerInfoPreview"

interface Lock {
    lockAmount: Big
    lockWeeks: number
}

export function useLockerInfoPreview(init?: Partial<Lock>) {
    const { data: serverTimestampData } = useGetServerTimestampQuery()
    const serverTimestamp = serverTimestampData?.timestamp

    const { lockedPerpTokenAmount, weeksToExpiration, isLoading } = useVePerpInfo()

    const [{ lockAmount, lockWeeks }, setLock] = useState<Lock>({
        lockAmount: init?.lockAmount ?? Big(0),
        lockWeeks: init?.lockWeeks ?? 0,
    })

    const setLockerInfoPreview = ({ lockAmount = Big(0), lockWeeks = 0 }: Partial<Lock>) => {
        setLock({ lockAmount, lockWeeks })
    }

    const lockerInfoPreview = useMemo<LockerInfoPreviewProps>(() => {
        // case 1: provide both amount & weeks
        // case 2: provide new amount, use old weeks
        // case 3: provide new weeks, use old amount
        const amountNext = lockedPerpTokenAmount?.add(lockAmount) ?? lockAmount
        const lockWeeksNext = (weeksToExpiration || 0) + lockWeeks
        const lastValidTimestamp = getEndAtWithCalibrated(serverTimestamp || 0, lockWeeksNext, EPOCH)

        return {
            lockedPerpAmount: amountNext,
            vePerpAmount: VePerpClient.getVePerpAmount(amountNext, lastValidTimestamp, serverTimestamp || 0),
            lastValidTimestamp,
        }
    }, [lockAmount, lockWeeks, lockedPerpTokenAmount, serverTimestamp, weeksToExpiration])

    return {
        isLoading,
        lockerInfoPreview,
        setLockerInfoPreview,
    }
}
