import {
    Box,
    BoxProps,
    Button,
    ButtonProps,
    Center,
    Container,
    ContainerProps,
    Flex,
    FlexProps,
    HStack,
    Image,
    ImageProps,
    ModalContent,
    ModalContentProps,
    SimpleGrid,
    Grid,
    GridItem,
    GridProps,
    GridItemProps,
    SimpleGridProps,
    Stack,
    StackProps,
    Text,
    TextProps,
    VStack,
} from "@chakra-ui/react"
import { motion } from "framer-motion"
import React from "react"

export const MotionFlex = motion<FlexProps>(Flex)
export const MotionText = motion<TextProps>(Text)
export const MotionContainer = motion<ContainerProps>(Container)
export const MotionCenter = motion<StackProps>(Center)
export const MotionBox = motion<BoxProps>(Box)
export const MotionButton = motion<ButtonProps>(Button)
export const MotionImage = motion<ImageProps>(Image)
export const MotionStack = motion<StackProps>(Stack)
export const MotionVStack = motion<StackProps>(VStack)
export const MotionHStack = motion<StackProps>(HStack)
export const MotionSimpleGrid = motion<SimpleGridProps>(SimpleGrid)
export const MotionModalContent = motion<ModalContentProps>(ModalContent)
export const MotionGrid = motion<GridProps>(Grid)
export const MotionGridItem = motion<GridItemProps>(GridItem)

export type MotionFlexProps = React.ComponentProps<typeof MotionFlex>
export type MotionTextProps = React.ComponentProps<typeof MotionText>
export type MotionContainerProps = React.ComponentProps<typeof MotionContainer>
export type MotionCenterProps = React.ComponentProps<typeof MotionCenter>
export type MotionBoxProps = React.ComponentProps<typeof MotionBox>
export type MotionButtonProps = React.ComponentProps<typeof MotionButton>
export type MotionImageProps = React.ComponentProps<typeof MotionImage>
export type MotionStackProps = React.ComponentProps<typeof MotionStack>
export type MotionVStackProps = React.ComponentProps<typeof MotionVStack>
export type MotionHStackProps = React.ComponentProps<typeof MotionHStack>
export type MotionSimpleGridProps = React.ComponentProps<typeof MotionSimpleGrid>
export type MotionModalContentProps = React.ComponentProps<typeof MotionModalContent>
export type MotionGridProps = React.ComponentProps<typeof MotionGrid>
export type MotionGridItemProps = React.ComponentProps<typeof MotionGridItem>
