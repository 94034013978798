import { IconProps } from "@chakra-ui/react"
import { AAVEIcon } from "components/Icons/Tokens/AAVEIcon"
import { APEIcon } from "components/Icons/Tokens/APEIcon"
import { ATOMIcon } from "components/Icons/Tokens/ATOMIcon"
import { AVAXIcon } from "components/Icons/Tokens/AVAXIcon"
import { BNBIcon } from "components/Icons/Tokens/BNBIcon"
import { BTCIcon } from "components/Icons/Tokens/BTCIcon"
import { CRVIcon } from "components/Icons/Tokens/CRVIcon"
import { ETHIcon } from "components/Icons/Tokens/ETHIcon"
import { FLOWIcon } from "components/Icons/Tokens/FLOWIcon"
import { FTMIcon } from "components/Icons/Tokens/FTMIcon"
import { LINKIcon } from "components/Icons/Tokens/LINKIcon"
import { MATICIcon } from "components/Icons/Tokens/MATICIcon"
import { NEARIcon } from "components/Icons/Tokens/NEARIcon"
import { ONEIcon } from "components/Icons/Tokens/ONEIcon"
import { PERPIcon } from "components/Icons/Tokens/PERPIcon"
import { SANDIcon } from "components/Icons/Tokens/SANDIcon"
import { SOLIcon } from "components/Icons/Tokens/SOLIcon"
import React from "react"

interface LiquidityMiningIconFinderProps extends IconProps {
    symbol: string
}

export function LiquidityMiningIconFinder({ symbol, ...props }: LiquidityMiningIconFinderProps) {
    switch (symbol) {
        case "vAPE": {
            return <APEIcon {...props} />
        }
        case "vBTC": {
            return <BTCIcon {...props} />
        }
        case "vBNB": {
            return <BNBIcon {...props} />
        }
        case "vETH": {
            return <ETHIcon {...props} />
        }
        case "vATOM": {
            return <ATOMIcon {...props} />
        }
        case "vFTM": {
            return <FTMIcon {...props} />
        }
        case "vCRV": {
            return <CRVIcon {...props} />
        }
        case "vSAND": {
            return <SANDIcon {...props} />
        }
        case "vLINK": {
            return <LINKIcon {...props} />
        }
        case "vSOL": {
            return <SOLIcon {...props} />
        }
        case "vAVAX": {
            return <AVAXIcon {...props} />
        }
        case "vAAVE": {
            return <AAVEIcon {...props} />
        }
        case "vPERP": {
            return <PERPIcon {...props} />
        }
        case "vMATIC": {
            return <MATICIcon {...props} />
        }
        case "vONE": {
            return <ONEIcon {...props} />
        }
        case "vNEAR": {
            return <NEARIcon {...props} />
        }
        case "vFLOW": {
            return <FLOWIcon {...props} />
        }
        default:
            return <PERPIcon {...props} />
    }
}
