import { WebSocketProvider } from "@ethersproject/providers"
import { useEffect, useMemo } from "react"
import { createContainer } from "unstated-next"

import { METADATA, RPC_URL } from "../../constants/env"
import { IERC20Metadata__factory, VePERP__factory } from "../../contracts/type"
import { walletSlice } from "../../modules/wallet"
import { store } from "../../store"
import { PerpWalletConnectorContainer } from "./usePerpWalletConnectorContainer"

const ContractsContainer = createContainer(useContracts)

function useContracts() {
    const { VOTING_ESCROW } = METADATA
    const { signer } = PerpWalletConnectorContainer.useContainer()
    const provider = useMemo(() => new WebSocketProvider(RPC_URL), [])

    const vePERPAddress = VOTING_ESCROW.contracts.vePERP.address
    const vePERPContract = useMemo(
        () => VePERP__factory.connect(vePERPAddress, signer || provider),
        [provider, vePERPAddress, signer],
    )
    const vePERPContractWs = useMemo(() => VePERP__factory.connect(vePERPAddress, provider), [provider, vePERPAddress])

    const erc20PERPAddress = VOTING_ESCROW.externalContracts.PERP
    const erc20PERPContract = useMemo(
        () => IERC20Metadata__factory.connect(erc20PERPAddress, signer || provider),
        [erc20PERPAddress, signer, provider],
    )
    const erc20PERPContractWs = useMemo(
        () => IERC20Metadata__factory.connect(erc20PERPAddress, provider),
        [erc20PERPAddress, provider],
    )

    useEffect(() => {
        store.dispatch(walletSlice.actions.setSigner(signer))
    }, [signer])

    return {
        vePERPContract,
        vePERPContractWs,
        erc20PERPContract,
        erc20PERPContractWs,
    }
}

export { ContractsContainer }
