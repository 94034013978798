import type { ComponentStyleConfig } from "@chakra-ui/react"

const Button: ComponentStyleConfig = {
    baseStyle: {
        borderRadius: 12,
        fontFamily: "body",
        fontWeight: 500,
        _focus: {
            boxShadow: "none",
        },
    },
    sizes: {
        sm: {
            fontSize: 14,
            px: 5,
            py: 5,
        },
        md: {
            fontSize: 16,
            px: 6,
            py: 6,
        },
        lg: {
            fontSize: 16,
            px: 6,
            py: 7,
        },
    },
    variants: {
        gray: {
            bg: "perp.background.button",
            color: "perp.text.primary",
            _hover: {
                bg: "perp.background.border",
                _disabled: {
                    bg: "perp.background.button",
                    opacity: 0.5,
                },
            },
            _disabled: {
                bg: "perp.background.button",
                opacity: 0.5,
            },
        },
        green: {
            bg: "perp.primary.default",
            color: "perp.background.card",
            _hover: {
                bg: "perp.primary.hover",
                _disabled: {
                    bg: "perp.primary.default",
                    opacity: 0.5,
                },
            },
            _disabled: {
                bg: "perp.primary.default",
                opacity: 0.5,
            },
        },
        blue: {
            bg: "perp.secondary.default",
            color: "perp.background.card",
            _hover: {
                bg: "perp.secondary.hover",
                _disabled: {
                    bg: "perp.secondary.default",
                    opacity: 0.5,
                },
            },
            _disabled: {
                bg: "perp.secondary.default",
                opacity: 0.5,
            },
        },
    },
    defaultProps: {
        size: "md",
        variant: "green",
    },
}

export { Button }
