import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Motion, Size } from "components/Modal/type"

import { ClaimableRewards, ClaimableRewardsItem } from "../../services/AppSyncClient"

interface IModalState {
    isOpen: boolean
    size: Size
    motion: Motion
}

interface ReferralState {
    isReferralLoading: boolean
    modal: {
        createLock: IModalState
        expired: IModalState
        extendTime: IModalState
        claim: IModalState & { items: ClaimableRewardsItem[] }
        createLockByMyStatus: IModalState
        increaseAmountByMyStatus: IModalState
        extendTimeByMyStatus: IModalState
    }
    minLockDuration?: number
    claimableRewards?: ClaimableRewards
}

const initialState: ReferralState = {
    isReferralLoading: false,
    modal: {
        createLock: {
            isOpen: false,
            size: Size.FULL,
            motion: Motion.SLIDE_IN_BOTTOM,
        },
        expired: {
            isOpen: false,
            size: Size.FULL,
            motion: Motion.SLIDE_IN_BOTTOM,
        },
        extendTime: {
            isOpen: false,
            size: Size.FULL,
            motion: Motion.SLIDE_IN_BOTTOM,
        },
        claim: {
            isOpen: false,
            size: Size.FULL,
            motion: Motion.SLIDE_IN_BOTTOM,
            items: [],
        },
        createLockByMyStatus: {
            isOpen: false,
            size: Size.FULL,
            motion: Motion.SLIDE_IN_BOTTOM,
        },
        increaseAmountByMyStatus: {
            isOpen: false,
            size: Size.FULL,
            motion: Motion.SLIDE_IN_BOTTOM,
        },
        extendTimeByMyStatus: {
            isOpen: false,
            size: Size.FULL,
            motion: Motion.SLIDE_IN_BOTTOM,
        },
    },
}

const referralSlice = createSlice({
    name: "referral",
    initialState,
    reducers: {
        openModalExpired(state, action: PayloadAction<{ size: Size; motion: Motion }>) {
            state.modal.expired.isOpen = true
            state.modal.expired.size = action.payload.size
            state.modal.expired.motion = action.payload.motion
        },
        closeModalExpired(state) {
            state.modal.expired.isOpen = false
        },
        openModalCreateLock(state, action: PayloadAction<{ size: Size; motion: Motion }>) {
            state.modal.createLock.isOpen = true
            state.modal.createLock.size = action.payload.size
            state.modal.createLock.motion = action.payload.motion
        },
        closeModalCreateLock(state) {
            state.modal.createLock.isOpen = false
        },
        openModalExtendTime(state, action: PayloadAction<{ size: Size; motion: Motion }>) {
            state.modal.extendTime.isOpen = true
            state.modal.extendTime.size = action.payload.size
            state.modal.extendTime.motion = action.payload.motion
        },
        closeModalExtendTime(state) {
            state.modal.extendTime.isOpen = false
        },
        openModalClaim(state, action: PayloadAction<{ size: Size; motion: Motion; items: ClaimableRewardsItem[] }>) {
            state.modal.claim.isOpen = true
            state.modal.claim.size = action.payload.size
            state.modal.claim.motion = action.payload.motion
            state.modal.claim.items = action.payload.items
        },
        closeModalClaim(state) {
            state.modal.claim.isOpen = false
            state.modal.claim.items = initialState.modal.claim.items
        },
        openModalIncreaseAmountByMyStatus(state, action: PayloadAction<{ size: Size; motion: Motion }>) {
            state.modal.increaseAmountByMyStatus.isOpen = true
            state.modal.increaseAmountByMyStatus.size = action.payload.size
            state.modal.increaseAmountByMyStatus.motion = action.payload.motion
        },
        closeModalIncreaseAmountByMyStatus(state) {
            state.modal.increaseAmountByMyStatus.isOpen = false
        },
        openModalCreateLockByMyStatus(state, action: PayloadAction<{ size: Size; motion: Motion }>) {
            state.modal.createLockByMyStatus.isOpen = true
            state.modal.createLockByMyStatus.size = action.payload.size
            state.modal.createLockByMyStatus.motion = action.payload.motion
        },
        closeModalCreateLockByMyStatus(state) {
            state.modal.createLockByMyStatus.isOpen = false
        },
        openModalExtendTimeByMyStatus(state, action: PayloadAction<{ size: Size; motion: Motion }>) {
            state.modal.extendTimeByMyStatus.isOpen = true
            state.modal.extendTimeByMyStatus.size = action.payload.size
            state.modal.extendTimeByMyStatus.motion = action.payload.motion
        },
        closeModalExtendTimeByMyStatus(state) {
            state.modal.extendTimeByMyStatus.isOpen = false
        },
    },
})

export { referralSlice }
export type { ReferralState }
