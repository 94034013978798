import {
    Button,
    Modal as ChakraModal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Portal,
    Text,
    VStack,
} from "@chakra-ui/react"
import { Header } from "components/Modal/Header"
import { NOTIFICATION_DURATION } from "constants/config"
import { analyticsTx, EventGroup, EventName, EventTypeInteraction } from "modules/analytics"
import { IStatus, notificationSlice } from "modules/notification/slice"
import { useVePerpRedeemMutation } from "modules/vePerp/flow"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { analyticsClient } from "services/AnalyticsClient"
import { useAppSelector } from "store"

import { referralSlice } from "../../slice"

/**
 * NOTE: This component will open when the user is trying to claim rewards
 * but whose PERP locked time is invalid.
 */
function ModalExpired() {
    const dispatch = useDispatch()
    const [redeem] = useVePerpRedeemMutation()
    const { isOpen, size, motion } = useAppSelector(state => state.referral.modal.expired)
    const { isTxLoading } = useAppSelector(state => state.wallet)

    const { open } = notificationSlice.actions
    const { closeModalExpired } = referralSlice.actions

    const handleClose = useCallback(() => {
        dispatch(closeModalExpired())
    }, [closeModalExpired, dispatch])

    const handleUnlock = useCallback(async () => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.BUTTON_CLICKED,
            eventName: EventName.REFERRAL_UNLOCK_BUTTON_CLICKED,
        })
        const result = await redeem()
        if ("error" in result) {
            analyticsTx.vePerp.withdraw.failed(result.error)
            dispatch(
                open({
                    status: IStatus.error,
                    description: "PERP unlock failed.",
                    duration: NOTIFICATION_DURATION.SHORT,
                }),
            )
        } else {
            analyticsTx.vePerp.withdraw.succeeded(result.data)
            dispatch(
                open({
                    status: IStatus.success,
                    description: "PERP unlocked successfully. Please continue to claim your rewards.",
                    duration: NOTIFICATION_DURATION.SHORT,
                }),
            )
        }
        dispatch(closeModalExpired())
    }, [closeModalExpired, dispatch, open, redeem])

    return (
        <Portal>
            <ChakraModal motionPreset={motion} onClose={handleClose} size={size} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent
                    bg="perp.background.background"
                    color="perp.text.primary"
                    boxShadow="0px 20px 20px 0px rgba(0, 0, 0, 0.15)"
                >
                    <Header onCloseButtonClick={handleClose}>Lock Time Ended</Header>
                    <ModalBody>
                        <VStack spacing={6} align="stretch">
                            <Text variant={"body2"}>
                                Your Lock Time has ended. In order to claim rewards in Locked PERP, you will need to
                                unlock and re-lock.
                            </Text>
                            <Button
                                variant="green"
                                w="100%"
                                onClick={handleUnlock}
                                isLoading={isTxLoading}
                                disabled={isTxLoading}
                                loadingText="Waiting for transaction"
                            >
                                Unlock PERP
                            </Button>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </ChakraModal>
        </Portal>
    )
}

export { ModalExpired }
