import Big from "big.js"
import { BigNumber } from "ethers"
import { useEffect, useState } from "react"
import { useGetServerTimestampQuery } from "services/AWSClient/flow"
import { createContainer } from "unstated-next"

import { POLLING_PERIOD } from "../../constants/config"
import { big2BigNum, bigNum2Big } from "../../utils/number"
import { useTxCallback } from "../useTxCallback"
import { ContractsContainer } from "./useContractsContainer"
import { PerpWalletConnectorContainer } from "./usePerpWalletConnectorContainer"

type DepositEventArgsArray = [string, BigNumber, BigNumber, BigNumber, BigNumber]
type WithdrawEventArgsArray = [string, BigNumber, BigNumber]

const VePerpByUserContainer = createContainer(useVePerpByUser)

function useVePerpByUser() {
    // NOTE: containers
    const { account } = PerpWalletConnectorContainer.useContainer()
    const { vePERPContract, vePERPContractWs } = ContractsContainer.useContainer()
    const { data: serverTimestampData } = useGetServerTimestampQuery()
    const timestamp = serverTimestampData?.timestamp

    // NOTE: states
    const [secsToExpiration, setSecsToExpiration] = useState<number>()
    const [daysToExpiration, setDaysToExpiration] = useState<number>()
    const [weeksToExpiration, setWeeksToExpiration] = useState<number>()
    const [lockedPerpTokenAmount, setLockedPerpTokenAmount] = useState<Big>()
    const [lockedPerpTokenValidDateInSec, setLockedPerpTokenValidDateInSec] = useState<number>()
    const [vePerpTokenAmount, setVePerpTokenAmount] = useState<Big>()
    const [vePerpTokenAmountUnweighted, setVePerpTokenAmountUnweighted] = useState<Big>()

    // NOTE: state derivation
    const hasLockedAlready = lockedPerpTokenAmount?.gt(0)
    const hasExpired = !!(
        hasLockedAlready &&
        lockedPerpTokenValidDateInSec &&
        timestamp &&
        lockedPerpTokenValidDateInSec <= timestamp
    )
    const lockedPerpTokenValidDate =
        lockedPerpTokenValidDateInSec &&
        new Date(lockedPerpTokenValidDateInSec * 1000).toDateString().split(" ").slice(1, 4).join(", ")

    // NOTE: init
    useEffect(() => {
        async function init() {
            if (!account) return
            try {
                const [locked, balanceOfWeighted, balanceOf] = await Promise.all([
                    vePERPContract.locked(account),
                    vePERPContract["balanceOfWeighted(address)"](account),
                    vePERPContract["balanceOf(address)"](account),
                ])
                const lockedPerpTokenAmount = bigNum2Big(locked.amount)
                const lockedPerpTokenValidDateInSec = bigNum2Big(locked.end, 0).toNumber()
                const vePerpTokenAmount = bigNum2Big(balanceOfWeighted)
                const vePerpTokenAmountUnweighted = bigNum2Big(balanceOf)
                setVePerpTokenAmount(vePerpTokenAmount)
                setVePerpTokenAmountUnweighted(vePerpTokenAmountUnweighted)
                setLockedPerpTokenAmount(lockedPerpTokenAmount)
                setLockedPerpTokenValidDateInSec(lockedPerpTokenValidDateInSec)
            } catch (error) {
                console.error(error)
            }
        }
        init()

        // NOTE: register listener
        const handleDeposit = (...args: DepositEventArgsArray) => {
            const [_provider, value, lockTime, type, _ts] = args
            const typeIndex = type.toNumber()
            const lockedPerpTokenAmount = bigNum2Big(value)
            const lockedPerpTokenValidDateInSec = bigNum2Big(lockTime, 0).toNumber()

            // NOTE: deposit by other user will not trigger this handler
            if (typeIndex !== 3)
                setLockedPerpTokenAmount(prevLockedPerpTokenAmount =>
                    prevLockedPerpTokenAmount
                        ? prevLockedPerpTokenAmount.add(lockedPerpTokenAmount)
                        : lockedPerpTokenAmount,
                )
            if (typeIndex !== 2) setLockedPerpTokenValidDateInSec(lockedPerpTokenValidDateInSec)
        }
        const handleWithdraw = (..._args: WithdrawEventArgsArray) => {
            setLockedPerpTokenAmount(Big(0))
            setLockedPerpTokenValidDateInSec(undefined)
        }
        const filterDeposit = vePERPContractWs.filters.Deposit(account)
        const filterWithdraw = vePERPContractWs.filters.Withdraw(account)
        vePERPContractWs.on<DepositEventArgsArray, unknown>(filterDeposit, handleDeposit)
        vePERPContractWs.on<WithdrawEventArgsArray, unknown>(filterWithdraw, handleWithdraw)

        // NOTE: register polling
        const timer = setInterval(async () => {
            if (!account) return
            const [vePerpTokenAmount, vePerpTokenAmountUnweighted] = await Promise.all([
                vePERPContract["balanceOfWeighted(address)"](account),
                vePERPContract["balanceOf(address)"](account),
            ])
            setVePerpTokenAmount(vePerpTokenAmount && bigNum2Big(vePerpTokenAmount))
            setVePerpTokenAmountUnweighted(vePerpTokenAmountUnweighted && bigNum2Big(vePerpTokenAmountUnweighted))
        }, POLLING_PERIOD.SHORT)

        // NOTE: clean up when unmounted
        return () => {
            clearInterval(timer)
            vePERPContractWs.removeListener<DepositEventArgsArray, unknown>(filterDeposit, handleDeposit)
            vePERPContractWs.removeListener<WithdrawEventArgsArray, unknown>(filterWithdraw, handleWithdraw)
        }
    }, [account, vePERPContractWs, vePERPContract])

    useEffect(() => {
        if (!lockedPerpTokenValidDateInSec || !timestamp) {
            setSecsToExpiration(undefined)
            setDaysToExpiration(undefined)
            setWeeksToExpiration(undefined)
            return
        }
        setSecsToExpiration(_getSecsToExpiration(lockedPerpTokenValidDateInSec, timestamp))
        setDaysToExpiration(_getDaysToExpiration(lockedPerpTokenValidDateInSec, timestamp))
        setWeeksToExpiration(_getWeeksToExpiration(lockedPerpTokenValidDateInSec, timestamp))
    }, [lockedPerpTokenValidDateInSec, timestamp])

    // NOTE: actions > createLock
    const createLock = useTxCallback(
        (value: Big, unlockTime: Big) => vePERPContract.create_lock(big2BigNum(value), big2BigNum(unlockTime, 0)),
        [vePERPContract],
    )

    // NOTE: actions > increaseAmount
    const increaseAmount = useTxCallback(
        (value: Big) => vePERPContract.increase_amount(big2BigNum(value)),
        [vePERPContract],
    )

    // NOTE: actions > increaseUnlockTime
    const increaseUnlockTime = useTxCallback(
        (unlockTime: Big) => vePERPContract.increase_unlock_time(big2BigNum(unlockTime, 0)),
        [vePERPContract],
    )

    // NOTE: actions > redeem
    const redeem = useTxCallback(() => vePERPContract.withdraw(), [vePERPContract])

    return {
        hasExpired,
        hasLockedAlready,
        secsToExpiration,
        daysToExpiration,
        weeksToExpiration,
        lockedPerpTokenAmount,
        lockedPerpTokenValidDate,
        lockedPerpTokenValidDateInSec,
        vePerpTokenAmount,
        vePerpTokenAmountUnweighted,
        createLock,
        increaseAmount,
        increaseUnlockTime,
        redeem,
    }
}

export { VePerpByUserContainer }

function _getSecsToExpiration(validDate: number, currentTime: number) {
    const secs = validDate - currentTime
    return secs <= 0 ? 0 : secs
}

function _getDaysToExpiration(validDate: number, currentTime: number) {
    const secs = validDate - currentTime
    const days = Math.ceil(secs / 86400)
    return days <= 0 ? 0 : days
}

function _getWeeksToExpiration(validDate: number, currentTime: number) {
    const secs = validDate - currentTime
    const weeks = Math.ceil(secs / (86400 * 7))
    return weeks <= 0 ? 0 : weeks
}
