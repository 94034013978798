import {
    Box,
    Modal as ChakraModal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Portal,
    Text,
    VStack,
} from "@chakra-ui/react"
import { Header } from "components/Modal/Header"
import { PerpWalletConnectorContainer } from "hooks/containers/usePerpWalletConnectorContainer"
import { EventGroup, EventName, EventTypeInteraction } from "modules/analytics"
import { useVePerpInfo } from "modules/vePerp/hooks/useVePerpInfo"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { analyticsClient } from "services/AnalyticsClient"
import { useGetServerTimestampQuery } from "services/AWSClient/flow"
import { useAppSelector } from "store"

import { useReferralRewardsQuery } from "../../flow"
import { selectSoonestUnlockDate } from "../../selector"
import { referralSlice } from "../../slice"
import { ModalWidgetExtendTime } from "./ModalWidgetExtendTime"

/**
 * NOTE: This component will open when the user is trying to claim rewards
 * but whose PERP locked time is smaller than min-lock-duration.
 */

function ModalExtendTime() {
    const { isOpen, size, motion } = useAppSelector(state => state.referral.modal.extendTime)

    const { data: serverTimestampData } = useGetServerTimestampQuery()
    const { lockedEndTimestamp } = useVePerpInfo()
    const timestamp = serverTimestampData?.timestamp

    const { account } = PerpWalletConnectorContainer.useContainer()
    const { soonestUnlockDate, weeksToSoonestUnlockDate } = useReferralRewardsQuery(
        { account: account! },
        { skip: !account, selectFromResult: selectSoonestUnlockDate(timestamp, lockedEndTimestamp) },
    )

    const dispatch = useDispatch()
    const { closeModalExtendTime } = referralSlice.actions
    const handleClose = useCallback(() => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.MODAL_CLOSED,
            eventName: EventName.REFERRAL_EXTEND_TIME_MODAL_CLOSED,
        })
        dispatch(closeModalExtendTime())
    }, [closeModalExtendTime, dispatch])

    return (
        <Portal>
            <ChakraModal motionPreset={motion} onClose={handleClose} size={size} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent
                    bg="perp.background.background"
                    color="perp.text.primary"
                    boxShadow="0px 20px 20px 0px rgba(0, 0, 0, 0.15)"
                >
                    <Header onCloseButtonClick={handleClose}>Longer Lock Time Required</Header>
                    <ModalBody>
                        <VStack spacing={6} align="stretch">
                            <Text variant={"body2"}>
                                Minimum Lock Time requirement not met. You'll need to extend your Lock Time for at least{" "}
                                <Box color="perp.primary.default" fontWeight={"bold"} as="span">
                                    {" "}
                                    {weeksToSoonestUnlockDate ? weeksToSoonestUnlockDate : "--"} more weeks
                                </Box>
                                .{" "}
                            </Text>
                            <Text variant={"body2"} opacity={0.65}>
                                The new Lock Time will end on {soonestUnlockDate ? soonestUnlockDate : "--"}.
                            </Text>
                            <ModalWidgetExtendTime onFinished={handleClose} />
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </ChakraModal>
        </Portal>
    )
}

export { ModalExtendTime }
