import { createContainer } from "unstated-next"
import { useAccount, useNetwork, useSigner } from "wagmi"

const PerpWalletConnectorContainer = createContainer(usePerpWalletConnector)

function usePerpWalletConnector() {
    // NOTE
    const { address: account } = useAccount()
    const { chain } = useNetwork()
    const chainId = chain ? chain.id : undefined

    // NOTE: signer
    const { data: signer } = useSigner()

    return {
        chainId,
        account,
        signer: signer || undefined,
    }
}

export { PerpWalletConnectorContainer }
