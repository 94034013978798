import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons"
import {
    Box,
    Button,
    Divider,
    Flex,
    Heading,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react"
import { Article } from "components/Article"
import { MotionBox } from "components/Motion"
import { useScroll } from "framer-motion"
import { PerpWalletConnectorContainer } from "hooks/containers/usePerpWalletConnectorContainer"
import { CurrentVePerpWithRequirement } from "./ReferralCurrentVePerpWithRequirement"
import React, { useEffect, useState } from "react"

import { useReferralDelegateInfoQuery } from "../"
import { ReferralAsPartner } from "./ReferralAsPartner"
import { ReferralAsPartnerStatus } from "./ReferralAsPartnerStatus"
import { ReferralAsTrader } from "./ReferralAsTrader"
import { ReferralAsTraderStatus } from "./ReferralAsTraderStatus"

const tabListStyle = {
    borderBottom: "1px solid",
    borderColor: "perp.background.border",
    gap: "32px",
    w: "100%",
}

const tabStyle = {
    fontSize: "16px",
    lineHeight: "72px",
    paddingX: 0,
    height: "72px",
    color: "perp.text.secondary",
    _selected: {
        color: "perp.text.primary",
        marginBottom: "-1px",
        borderBottom: "2px solid",
        borderColor: "perp.text.primary",
    },
}

function ReferralContent() {
    const { isOpen: overViewIsOpen, onOpen: overViewOnOpen } = useDisclosure()
    const { isOpen: participationIsOpen, onOpen: participationOnOpen } = useDisclosure()
    const initialHeight = "110px"

    // controlled tab
    const [tabIndex, setTabIndex] = useState(0)

    const handleTabsChange = (index: React.SetStateAction<number>) => {
        setTabIndex(index)
    }

    // scroll animation
    const { scrollY } = useScroll()
    const [scrollDistance, setScrollDistance] = useState(0)
    useEffect(() => {
        return scrollY.onChange(latest => {
            setScrollDistance(latest)
        })
    }, [scrollY])

    // vePERP requirement
    const { account } = PerpWalletConnectorContainer.useContainer()
    const { data: delegateInfo } = useReferralDelegateInfoQuery({ account: account! }, { skip: !account })
    const qualifiedMultiplier = delegateInfo?.qualifiedMultiplier
    const vePerpAmountRequiredAtSnapshot = qualifiedMultiplier ? (qualifiedMultiplier * 10).toString() : undefined

    return (
        <Tabs index={tabIndex} onChange={handleTabsChange} variant="unstyled">
            {/* full width tab background box*/}
            <Box
                position={"fixed"}
                top="72px"
                zIndex={1}
                left={0}
                right={0}
                width={"100%"}
                h="1px"
                bg="perp.background.border"
                opacity={scrollDistance > 394 ? 1 : 0}
            />

            <Flex top={0} zIndex={5} position="sticky" w={"100%"} mb={12} bg="perp.background.card">
                <TabList {...tabListStyle}>
                    <Tab {...tabStyle}>Partner</Tab>
                    <Tab {...tabStyle}>Trader</Tab>
                </TabList>
            </Flex>
            {/* TODO: sync the final hygraph slug */}
            <MotionBox
                overflow={"hidden"}
                initial={{ height: initialHeight }}
                animate={{ height: overViewIsOpen ? "auto" : initialHeight }}
            >
                <Article route="referralv10" />
            </MotionBox>

            {!overViewIsOpen && (
                <Box pt={4} bgGradient={"linear(to-t, blackAlpha.100, rgba(16,15,20,1))"}>
                    <Button
                        onClick={overViewOnOpen}
                        rightIcon={overViewIsOpen ? <ChevronUpIcon boxSize={6} /> : <ChevronDownIcon boxSize={6} />}
                        variant="link"
                    >
                        Learn more
                    </Button>
                </Box>
            )}
            <Divider my={12} />
            <GeneralParticipationInfo />
            {!participationIsOpen && (
                <Box pb={12} pt={4} bgGradient={"linear(to-t, blackAlpha.100, rgba(16,15,20,1))"}>
                    <Button
                        onClick={participationOnOpen}
                        rightIcon={
                            participationIsOpen ? <ChevronUpIcon boxSize={6} /> : <ChevronDownIcon boxSize={6} />
                        }
                        variant="link"
                        mb={10}
                    >
                        Pariticipate as a {tabIndex === 0 ? "Partner" : "Trader"}
                    </Button>
                    <Divider />
                </Box>
            )}
            <TabPanels>
                <TabPanel p="0">
                    <MotionBox
                        mt={8}
                        overflow={"hidden"}
                        initial={{ height: 0 }}
                        animate={{ height: participationIsOpen ? "auto" : 0 }}
                    >
                        <ReferralAsPartner />
                    </MotionBox>

                    <ReferralAsPartnerStatus />
                    <Text py={4} fontSize="sm" opacity={0.5}>
                        * Final qualified referred volume. It may differ from the total referred volume of the current
                        week.
                    </Text>
                </TabPanel>
                <TabPanel p="0">
                    <MotionBox
                        mt={8}
                        overflow={"hidden"}
                        initial={{ height: 0 }}
                        animate={{ height: participationIsOpen ? "auto" : 0 }}
                    >
                        <ReferralAsTrader />
                    </MotionBox>
                    <ReferralAsTraderStatus />
                </TabPanel>
            </TabPanels>
            <CurrentVePerpWithRequirement vePerpAmountRequiredAtSnapshot={vePerpAmountRequiredAtSnapshot} />
        </Tabs>
    )
}

export { ReferralContent }

const GeneralParticipationInfo = () => {
    return (
        <VStack align="stretch" spacing={6}>
            <Heading variant="h3">Participation</Heading>
            <Text maxWidth="100%" color="perp.text.article">
                Please follow the steps below to participate in the referral program.
            </Text>
        </VStack>
    )
}
