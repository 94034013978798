import { Icon, IconProps } from "@chakra-ui/react"
import React from "react"

export function SOLIcon({ ...props }: IconProps) {
    return (
        <Icon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                fill="url(#paint0_linear_2_4211)"
            />
            <path
                d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                fill="#9C65D8"
            />
            <g style={{ mixBlendMode: "multiply" }} opacity="0.8">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.92443 8.97626C10.0376 8.86539 10.1869 8.80072 10.3408 8.80072H24.705C24.9675 8.80072 25.0987 9.12408 24.9131 9.31347L22.0756 12.2098C21.967 12.3207 21.8176 12.3853 21.6593 12.3853H7.29507C7.03259 12.3853 6.90135 12.062 7.0869 11.8726L9.92443 8.97626ZM9.92443 19.7902C10.033 19.6793 10.1824 19.6147 10.3408 19.6147H24.705C24.9675 19.6147 25.0987 19.938 24.9131 20.1274L22.0756 23.0238C21.967 23.1346 21.8176 23.1993 21.6593 23.1993H7.29507C7.03259 23.1993 6.90135 22.8759 7.0869 22.6865L9.92443 19.7902ZM21.6593 14.173C21.8176 14.173 21.967 14.2377 22.0756 14.3486L24.9131 17.2449C25.0987 17.4343 24.9675 17.7577 24.705 17.7577H10.3408C10.1824 17.7577 10.033 17.693 9.92443 17.5821L7.0869 14.6858C6.90135 14.4964 7.03259 14.173 7.29507 14.173H21.6593Z"
                    fill="#141319"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_2_4211"
                    x1="44.5"
                    y1="-64"
                    x2="-12.708"
                    y2="-57.7174"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#00FFA3" />
                    <stop offset="1" stopColor="#DC1FFF" />
                </linearGradient>
            </defs>
        </Icon>
    )
}
