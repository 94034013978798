import React from "react"
import { Center, Portal, Progress, VStack } from "@chakra-ui/react"

function PerpLoading() {
    return (
        <Portal>
            <Center
                overflow="hidden"
                zIndex={2}
                position="fixed"
                width="100vw"
                height="100vh"
                left="0"
                top="0"
                bottom="0"
                right="0"
            >
                <VStack w="60px" spacing={4}>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.9902 7.47177C14.7557 5.74228 17.5802 5.74227 19.3457 7.47177L23.5255 11.5662C25.3433 13.3469 25.3433 16.2735 23.5255 18.0542L16.2412 25.1898L21.4653 30.3073C22.4607 31.2824 22.4771 32.8796 21.5021 33.875C20.5271 34.8703 18.9298 34.8868 17.9345 33.9117L5.27849 21.514C3.46072 19.7334 3.46072 16.8068 5.27848 15.0261L12.9902 7.47177ZM12.636 21.6582L19.6267 14.8102L16.168 11.422L9.17728 18.2701L12.636 21.6582Z"
                            fill="#3CEAAA"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M23.5861 10.9316C25.3516 9.20213 28.1761 9.20213 29.9416 10.9316L34.1214 15.0261C35.9391 16.8068 35.9391 19.7333 34.1214 21.514L21.4654 33.9117C20.47 34.8867 18.8728 34.8703 17.8977 33.875C16.9227 32.8796 16.9392 31.2823 17.9345 30.3073L23.1587 25.1898L19.4063 21.514C17.5886 19.7333 17.5886 16.8068 19.4063 15.0261L23.5861 10.9316ZM26.7638 21.6582L30.2226 18.2701L26.7638 14.8819L23.3051 18.2701L26.7638 21.6582Z"
                            fill="#03BCE4"
                        />
                        <path
                            opacity="0.95"
                            d="M23.3032 18.2786L19.629 14.7888L16.105 18.2498L19.6919 21.8183L23.3032 18.2786Z"
                            fill="#00A4E1"
                        />
                    </svg>
                    <Progress w="80%" borderRadius="full" size="xs" isIndeterminate />
                </VStack>
            </Center>
        </Portal>
    )
}

export { PerpLoading }
