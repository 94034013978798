import { configureStore } from "@reduxjs/toolkit"
import { lazyRiverSlice } from "modules/lazyRiver"
import { homeSlice } from "modules/home"
import { insuranceFundQueryApi } from "modules/insuranceFund"
import { liquidityMiningMutationApi, liquidityMiningQueryApi, liquidityMiningSlice } from "modules/liquidityMining"
import { notificationSlice } from "modules/notification"
import { referralMutationApi, referralQueryApi, referralSlice } from "modules/referral"
import { usdcFeeDistributorMutationApi, usdcFeeDistributorQueryApi } from "modules/usdcFeeDistributor"
import { vePerpMutationApi, vePerpQueryApi } from "modules/vePerp"
import {
    vePERPEmissionFeeDistributorMutationApi,
    vePERPEmissionFeeDistributorQueryApi,
} from "modules/vePERPEmissionFeeDistributor"
import { walletSlice } from "modules/wallet/slice"
import { TypedUseSelectorHook, useSelector as useReduxSelector } from "react-redux"
import { awsClientQueryApi } from "services/AWSClient/flow"
import { coingeckoClientQueryApi } from "services/CoingeckoClient/flow"
import { vePERPDistributorQueryApi, vePERPDistributorMutationApi } from "modules/vePerpDistributor"

const store = configureStore({
    reducer: {
        [notificationSlice.name]: notificationSlice.reducer,
        [walletSlice.name]: walletSlice.reducer,
        [homeSlice.name]: homeSlice.reducer,
        [referralSlice.name]: referralSlice.reducer,
        [referralQueryApi.reducerPath]: referralQueryApi.reducer,
        [referralMutationApi.reducerPath]: referralMutationApi.reducer,
        [vePerpQueryApi.reducerPath]: vePerpQueryApi.reducer,
        [vePerpMutationApi.reducerPath]: vePerpMutationApi.reducer,
        [liquidityMiningSlice.name]: liquidityMiningSlice.reducer,
        [liquidityMiningQueryApi.reducerPath]: liquidityMiningQueryApi.reducer,
        [liquidityMiningMutationApi.reducerPath]: liquidityMiningMutationApi.reducer,
        [lazyRiverSlice.name]: lazyRiverSlice.reducer,
        [vePERPEmissionFeeDistributorQueryApi.reducerPath]: vePERPEmissionFeeDistributorQueryApi.reducer,
        [vePERPEmissionFeeDistributorMutationApi.reducerPath]: vePERPEmissionFeeDistributorMutationApi.reducer,
        [usdcFeeDistributorQueryApi.reducerPath]: usdcFeeDistributorQueryApi.reducer,
        [usdcFeeDistributorMutationApi.reducerPath]: usdcFeeDistributorMutationApi.reducer,
        [vePERPDistributorQueryApi.reducerPath]: vePERPDistributorQueryApi.reducer,
        [vePERPDistributorMutationApi.reducerPath]: vePERPDistributorMutationApi.reducer,
        [insuranceFundQueryApi.reducerPath]: insuranceFundQueryApi.reducer,
        [awsClientQueryApi.reducerPath]: awsClientQueryApi.reducer,
        [coingeckoClientQueryApi.reducerPath]: coingeckoClientQueryApi.reducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat([
            referralQueryApi.middleware,
            referralMutationApi.middleware,
            vePerpQueryApi.middleware,
            vePerpMutationApi.middleware,
            liquidityMiningQueryApi.middleware,
            liquidityMiningMutationApi.middleware,
            vePERPEmissionFeeDistributorQueryApi.middleware,
            vePERPEmissionFeeDistributorMutationApi.middleware,
            usdcFeeDistributorQueryApi.middleware,
            usdcFeeDistributorMutationApi.middleware,
            vePERPDistributorQueryApi.middleware,
            vePERPDistributorMutationApi.middleware,
            insuranceFundQueryApi.middleware,
            awsClientQueryApi.middleware,
            coingeckoClientQueryApi.middleware,
        ]),
})

type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
const useAppSelector: TypedUseSelectorHook<RootState> = useReduxSelector

export { store, useAppSelector }
export type { RootState }
