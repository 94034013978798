import { Box, Button } from "@chakra-ui/react"
import Big from "big.js"
import { LockerSlider } from "components/Locker/LockerSlider"
import { EPOCH } from "constants/config"
import { useLockerSlider } from "hooks"
import { analyticsTx, EventGroup, EventName, EventTypeInteraction } from "modules/analytics"
import { IStatus, notificationSlice } from "modules/notification/slice"
import { useVePerpIncreaseTimeMutation } from "modules/vePerp/flow"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { analyticsClient } from "services/AnalyticsClient"
import { useAppSelector } from "store"
import { getEndAt } from "utils/time"

import { useVePerpInfo } from "../../vePerp/hooks/useVePerpInfo"

interface Data {
    lockWeeks: number
}
type ModalWidgetExtendTimeProps = {
    onChange?: (data: Data) => void
    onSucceed?: () => void
    onFinished?: () => void
}

export function ModalWidgetExtendTime({
    onChange,
    onSucceed = () => {},
    onFinished = () => {},
}: ModalWidgetExtendTimeProps) {
    // NOTE: redux
    const { open } = notificationSlice.actions
    const dispatch = useDispatch()

    // NOTE: containers
    const { isTxLoading } = useAppSelector(state => state.wallet)

    const [increaseUnlockTime] = useVePerpIncreaseTimeMutation()
    const { weeksToExpiration, lockedEndTimestamp } = useVePerpInfo()

    // NOTE: states
    const { week, setWeek, from, to, isDisabled } = useLockerSlider(weeksToExpiration)

    // NOTE: handlers
    const handleLockTimeChange = useCallback(
        (weekNext: number) => {
            setWeek(weekNext)
            onChange?.({ lockWeeks: weekNext })
        },
        [onChange, setWeek],
    )
    const handleExtendTime = useCallback(async () => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.BUTTON_CLICKED,
            eventName: EventName.EMISSION_EXTEND_TIME_BUTTON_CLICKED,
        })
        if (!lockedEndTimestamp) {
            dispatch(open({ status: IStatus.error, description: "You haven’t locked any PERP yet." }))
            return
        }
        const endAt = getEndAt(lockedEndTimestamp, week, EPOCH)
        const result = await increaseUnlockTime([Big(endAt)])
        if ("error" in result) {
            analyticsTx.vePerp.increaseUnlockTime.failed(result.error)
            dispatch(open({ status: IStatus.error, description: "Extend Lock PERP time failed." }))
        } else {
            analyticsTx.vePerp.increaseUnlockTime.succeeded(result.data)
            dispatch(open({ status: IStatus.success, description: "You have locked PERP successfully." }))
            onSucceed()
        }
        onFinished()
    }, [onFinished, lockedEndTimestamp, week, increaseUnlockTime, dispatch, open, onSucceed])

    return (
        <Box color="perp.text.primary">
            <Box h="96px">
                <LockerSlider
                    key={to}
                    from={from}
                    to={to}
                    step={1}
                    isDisabled={isDisabled}
                    onChange={handleLockTimeChange}
                    value={week}
                />
            </Box>
            <Box mt="24px">
                <Button
                    w="100%"
                    size="lg"
                    isDisabled={isDisabled}
                    isLoading={isTxLoading}
                    onClick={handleExtendTime}
                    loadingText="Waiting for transaction"
                >
                    Extend
                </Button>
            </Box>
        </Box>
    )
}
