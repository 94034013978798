import { useBreakpointValue } from "@chakra-ui/react"

export function useIsMobile() {
    return !!useBreakpointValue({ base: true, md: false })
}

export function useIsTablet() {
    return !!useBreakpointValue({ base: false, md: true, lg: false })
}

export function useIsDesktop() {
    return !!useBreakpointValue({ base: false, md: false, lg: true })
}
