import { Flex, Skeleton, Text } from "@chakra-ui/react"
import Big from "big.js"
import { VePERPIcon } from "components/Icons/Tokens/VePERPIcon"
import { NUMBER_PLACE_HOLDER } from "constants/strings"
import React from "react"
import { formatNumber } from "utils"

interface IVePerpCardTotalProps {
    data: {
        totalVePerp?: Big
    }
}

export function VePerpCardTotal({ data: { totalVePerp } }: IVePerpCardTotalProps) {
    return (
        <Flex
            bg="perp.background.card"
            borderRadius="12px"
            p="16px"
            alignContent="center"
            alignItems={"center"}
            flexGrow={1}
        >
            <Text flex={1} fontSize="14px" color="perp.text.secondary">
                {"Total vePERP"}
            </Text>
            <Flex alignItems="center" gap="4px">
                <VePERPIcon boxSize="14px" />
                <Skeleton isLoaded={!!totalVePerp}>
                    <Text fontSize="14px" color="perp.text.primary">
                        {(totalVePerp && formatNumber(totalVePerp)) || NUMBER_PLACE_HOLDER}
                    </Text>
                </Skeleton>
            </Flex>
        </Flex>
    )
}
