import { Text } from "@chakra-ui/react"
import React, { useCallback } from "react"
import { useLocalStorage } from "react-use"

export function LazyRiverLockerWarning() {
    const [doNotShow, setDoNotShow] = useLocalStorage("emission-modal-create-lock-do-not-show", "")
    const handleDoNotShow = useCallback(() => {
        setDoNotShow("true")
    }, [setDoNotShow])

    return doNotShow === "true" ? null : (
        <Text variant={"body2"}>
            Your locked PERP can only be unlocked after the lock period.{" "}
            <Text as="span" color="perp.primary.default" onClick={handleDoNotShow} cursor="pointer">
                Don’t show again
            </Text>
        </Text>
    )
}
