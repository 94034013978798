/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  IMarketRegistry,
  IMarketRegistryInterface,
} from "../IMarketRegistry";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint24",
        name: "feeRatio",
        type: "uint24",
      },
    ],
    name: "FeeRatioChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint24",
        name: "feeRatio",
        type: "uint24",
      },
    ],
    name: "InsuranceFundFeeRatioChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint8",
        name: "maxOrdersPerMarket",
        type: "uint8",
      },
    ],
    name: "MaxOrdersPerMarketChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint24",
        name: "feeRatio",
        type: "uint24",
      },
      {
        indexed: true,
        internalType: "address",
        name: "pool",
        type: "address",
      },
    ],
    name: "PoolAdded",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
      {
        internalType: "uint24",
        name: "feeRatio",
        type: "uint24",
      },
    ],
    name: "addPool",
    outputs: [
      {
        internalType: "address",
        name: "pool",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
    ],
    name: "getFeeRatio",
    outputs: [
      {
        internalType: "uint24",
        name: "feeRatio",
        type: "uint24",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
    ],
    name: "getInsuranceFundFeeRatio",
    outputs: [
      {
        internalType: "uint24",
        name: "feeRatio",
        type: "uint24",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
    ],
    name: "getMarketInfo",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "pool",
            type: "address",
          },
          {
            internalType: "uint24",
            name: "exchangeFeeRatio",
            type: "uint24",
          },
          {
            internalType: "uint24",
            name: "uniswapFeeRatio",
            type: "uint24",
          },
          {
            internalType: "uint24",
            name: "insuranceFundFeeRatio",
            type: "uint24",
          },
        ],
        internalType: "struct IMarketRegistry.MarketInfo",
        name: "info",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getMaxOrdersPerMarket",
    outputs: [
      {
        internalType: "uint8",
        name: "maxOrdersPerMarket",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
    ],
    name: "getPool",
    outputs: [
      {
        internalType: "address",
        name: "pool",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getQuoteToken",
    outputs: [
      {
        internalType: "address",
        name: "quoteToken",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getUniswapV3Factory",
    outputs: [
      {
        internalType: "address",
        name: "factory",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
    ],
    name: "hasPool",
    outputs: [
      {
        internalType: "bool",
        name: "hasPool",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
      {
        internalType: "uint24",
        name: "feeRatio",
        type: "uint24",
      },
    ],
    name: "setFeeRatio",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "baseToken",
        type: "address",
      },
      {
        internalType: "uint24",
        name: "insuranceFundFeeRatioArg",
        type: "uint24",
      },
    ],
    name: "setInsuranceFundFeeRatio",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "maxOrdersPerMarketArg",
        type: "uint8",
      },
    ],
    name: "setMaxOrdersPerMarket",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class IMarketRegistry__factory {
  static readonly abi = _abi;
  static createInterface(): IMarketRegistryInterface {
    return new utils.Interface(_abi) as IMarketRegistryInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IMarketRegistry {
    return new Contract(address, _abi, signerOrProvider) as IMarketRegistry;
  }
}
