import { DefaultMarket, DefaultMarkets } from "services/AppSyncClient"

import { getVSymbolFullName } from "../util"

export function selectorDefaultMarkets(result: { data?: DefaultMarkets }) {
    if (!result.data) {
        return {}
    }
    const parsedItems: IParsedDefaultMarket[] = result.data.items.map(item => ({
        ...item,
        lowerRewardApr: item.lowerRewardApr + item.lowerRewardOpApr,
        lowerRewardPerpApr: item.lowerRewardApr,
        lowerAprTotal: item.lowerBaseApr + item.lowerRewardApr + item.lowerRewardOpApr,
        upperRewardApr: item.upperRewardApr + item.upperRewardOpApr,
        upperRewardPerpApr: item.upperRewardApr,
        upperAprTotal: item.upperBaseApr + item.upperRewardApr + item.upperRewardOpApr,
        base: item.baseSymbol.substring(1),
        quote: item.quoteSymbol.substring(1),
        baseFullName: getVSymbolFullName(item.baseSymbol),
    }))
    return {
        data: parsedItems,
    }
}

export default interface IParsedDefaultMarket extends DefaultMarket {
    lowerRewardApr: number
    lowerRewardPerpApr: number
    lowerAprTotal: number
    upperRewardApr: number
    upperRewardPerpApr: number
    upperAprTotal: number
    base: string
    quote: string
    baseFullName: string
}
