import { Box } from "@chakra-ui/react"
import { Article } from "components/Article"
import { PerpWalletConnectorContainer } from "hooks/containers/usePerpWalletConnectorContainer"
import React from "react"

import { LiquidityMiningMyPoolRewards } from "./LiquidityMiningMyPoolRewards"
import { LiquidityMiningPoolRewards } from "./LiquidityMiningPoolRewards"

export function LiquidityMiningContent() {
    const { account } = PerpWalletConnectorContainer.useContainer()
    return (
        <Box>
            <Article route="pool-party" />
            {account && <LiquidityMiningMyPoolRewards account={account} />}
            <LiquidityMiningPoolRewards />
        </Box>
    )
}
