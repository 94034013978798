import { CURIE_DEPLOYMENTS_VERSION, VERSION } from "constants/env"
import { segment } from "services/AnalyticsClient/Segment"

import { AnalyticsService, AnalyticsTrackData, AnalyticsTrackPageData } from "./types"

class AnalyticsClient {
    readonly service: AnalyticsService

    constructor(service: AnalyticsService) {
        this.service = service
    }

    init() {
        this.service.init()
    }

    track(data: AnalyticsTrackData) {
        this.service.track({
            ...data,
            version: {
                app: VERSION,
                curieDeployments: CURIE_DEPLOYMENTS_VERSION,
            },
        })
    }

    trackPage(data: AnalyticsTrackPageData) {
        this.service.trackPage({ ...data })
    }
}

export const analyticsClient = new AnalyticsClient(segment)
