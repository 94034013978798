import { Global } from "@emotion/react"
import React from "react"

//load custom fonts

export const Fonts = () => (
    <Global
        styles={`
        @font-face{ 
            font-family: 'Modernist'; 
						font-style: bold;
						font-weight: 700;
            src: url('/font/Sk-Modernist-Bold.otf') format("openType");
         }
				`}
    />
)
