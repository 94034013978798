import React from "react"
import { Link, LinkProps } from "@chakra-ui/react"

export function ExternalLink({ children, ...props }: LinkProps) {
    return (
        <Link {...props} target="_blank" rel="noreferrer noopener">
            {children}
        </Link>
    )
}
