import * as React from "react"
import { Icon, IconProps } from "@chakra-ui/icon"

function CheckIcon({ ...props }: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" fill="none" {...props}>
            <path
                d="M15.5303 10.5303L16.0607 10L15 8.93934L14.4697 9.46967L15.5303 10.5303ZM11 14L10.4697 14.5303C10.7626 14.8232 11.2374 14.8232 11.5303 14.5303L11 14ZM9.53033 11.4697L9 10.9393L7.93934 12L8.46967 12.5303L9.53033 11.4697ZM14.4697 9.46967L10.4697 13.4697L11.5303 14.5303L15.5303 10.5303L14.4697 9.46967ZM11.5303 13.4697L9.53033 11.4697L8.46967 12.5303L10.4697 14.5303L11.5303 13.4697Z"
                fill="currentColor"
            />
            <path
                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
        </Icon>
    )
}

function ErrorIcon({ ...props }: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" fill="none" {...props}>
            <path d="M11.25 17.75V16.25H12.75V17.75H11.25Z" fill="currentColor" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.25 12C2.25 6.61807 6.61807 2.25 12 2.25C17.3819 2.25 21.75 6.61807 21.75 12C21.75 17.3819 17.3819 21.75 12 21.75C6.61807 21.75 2.25 17.3819 2.25 12ZM3.75 12C3.75 16.5481 7.45193 20.25 12 20.25C16.5481 20.25 20.25 16.5481 20.25 12C20.25 7.45193 16.5481 3.75 12 3.75C7.45193 3.75 3.75 7.45193 3.75 12Z"
                fill="currentColor"
            />
            <rect x="11.25" y="6.25" width="1.5" height="8.5" fill="currentColor" />
        </Icon>
    )
}

function InfoIcon({ ...props }: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" fill="none" {...props}>
            <path d="M11.25 16.25V17.75H12.75V16.25H11.25Z" fill="currentColor" />
            <path d="M12.75 14.75V6.25H11.25V14.75H12.75Z" fill="currentColor" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2.25C6.61807 2.25 2.25 6.61807 2.25 12C2.25 17.3819 6.61807 21.75 12 21.75C17.3819 21.75 21.75 17.3819 21.75 12C21.75 6.61807 17.3819 2.25 12 2.25ZM12 20.25C7.45193 20.25 3.75 16.5481 3.75 12C3.75 7.45193 7.45193 3.75 12 3.75C16.5481 3.75 20.25 7.45193 20.25 12C20.25 16.5481 16.5481 20.25 12 20.25Z"
                fill="currentColor"
            />
        </Icon>
    )
}

export { CheckIcon, ErrorIcon, InfoIcon }
