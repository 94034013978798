import { Box, Container, Flex, Text } from "@chakra-ui/react"
import { useProgramDetail } from "hooks/useCMS"
import React from "react"

function LiquidityMiningHeader() {
    const { data } = useProgramDetail("pool-party")

    return (
        <Box
            color="perp.text.primary"
            h="100%"
            bgColor="perp.background.background"
            bgImage={data && [data.program.banner.sm.url, data.program.banner.md.url, data.program.banner.lg.url]}
            bgRepeat="no-repeat"
            bgSize={["contain", "cover"]}
            bgPos="bottom"
        >
            <Container maxW="1080px" h="100%" display="flex" pt={["12px"]}>
                <Flex alignItems="center">
                    <Text fontSize="48px" borderColor="perp.primary.default" pl="24px">
                        Pool Party
                    </Text>
                </Flex>
            </Container>
        </Box>
    )
}

export { LiquidityMiningHeader }
