import { PerpWalletConnectorContainer } from "./containers/usePerpWalletConnectorContainer"
import { getSupportedChainInfo } from "../utils/getChainInfo"

type ExplorerBuilder = (data: string, type: "transaction" | "token" | "address" | "block") => string

function useSupportedChain() {
    const { chainId } = PerpWalletConnectorContainer.useContainer()
    const { link, urlBuilder, name, alias, id } = getSupportedChainInfo()

    const isAccountOnSupportedChain = chainId ? chainId === id : true
    const getExplorerLink: ExplorerBuilder = (data, type) => urlBuilder(link, data, type)

    return {
        isAccountOnSupportedChain,
        id,
        name,
        alias,
        chainId,
        getExplorerLink,
    }
}

export { useSupportedChain }
