import { HStack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { USDCIcon } from "components/Icons/Tokens/USDCIcon"
import { useHistoryRewards } from "modules/lazyRiver/hook/useHistoryRewards"
import React from "react"
import { LAZY_RIVER_2_START_TIME } from "constants/config"
import { formatNumber, getTimeString } from "utils"
import { useLastTokenTimeDeviation } from "modules/lazyRiver/hook/useLastTokenTimeDeviation"

interface IHistoryRowProps {
    weekStart: string
    usdcAmount: string
}

function HistoryRow({ weekStart, usdcAmount }: IHistoryRowProps) {
    return (
        <Tr fontSize="sm">
            <Td>{weekStart}</Td>
            <Td isNumeric>
                <HStack display="inline-flex">
                    <USDCIcon boxSize="14px" />
                    <Text as="span">{usdcAmount}</Text>
                </HStack>
            </Td>
        </Tr>
    )
}

export function History() {
    const { adjustedWeekStartTime } = useLastTokenTimeDeviation()

    const historyRewards = useHistoryRewards({
        startTime: LAZY_RIVER_2_START_TIME,
        endTime: adjustedWeekStartTime || 0,
    })

    return (
        <VStack align="start" spacing={6} my={12}>
            <TableContainer whiteSpace={"normal"} maxWidth={"100%"} width="100%" maxHeight="470px" overflowY="scroll">
                <Table variant="simple" overflow={"visible"}>
                    <Thead bg="perp.background.background" zIndex={1} position={"sticky"} top="0">
                        <Tr>
                            <Th>Week Start</Th>
                            <Th isNumeric>USDC Rewards</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {historyRewards.map(reward => (
                            <HistoryRow
                                key={reward.timestamp}
                                weekStart={getTimeString(reward.timestamp)}
                                usdcAmount={formatNumber(reward.usdcReward)}
                            />
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </VStack>
    )
}
