import { Box, Grid, GridItem, HStack, Text } from "@chakra-ui/react"
import Big from "big.js"
import { DownIcon } from "components/Icons/DownIcon"
import { OPIcon } from "components/Icons/Tokens/OPIcon"
import { PERPIcon } from "components/Icons/Tokens/PERPIcon"
import { UpIcon } from "components/Icons/UpIcon"
import { PerpWalletConnectorContainer } from "hooks/containers/usePerpWalletConnectorContainer"
import React, { useState } from "react"

import { useLMRewardsQuery } from "../flow"
import { useLMClaim } from "../hook/useLMClaim"
import { LiquidityMiningClaimCard } from "./LiquidityMiningClaimCard"

export function LiquidityMiningMobileMenu() {
    const { account } = PerpWalletConnectorContainer.useContainer()
    const { data, isLoading } = useLMRewardsQuery({ account: account! }, { skip: !account })
    const { onLMPerpClaim, onLMOPClaim } = useLMClaim()

    const [isExpanded, setIsExpanded] = useState(false)

    return isExpanded ? (
        <Box
            position="fixed"
            left="0"
            bottom="0"
            width="100%"
            background="perp.background.background"
            color="perp.text.primary"
            borderTop={"1px solid"}
            borderColor="perp.background.border"
            borderRadius={"16px"}
            p="24px"
            zIndex={1}
        >
            <Grid templateColumns="1fr auto auto" w="100%" pb="16px">
                <Box as="text" fontSize="20px">
                    Your Rewards
                </Box>
                <Grid alignItems="center">
                    <DownIcon onClick={() => setIsExpanded(!isExpanded)} boxSize="16px" />
                </Grid>
            </Grid>
            <Grid gap="16px" color="perp.text.primary" boxShadow="0px 20px 20px 0px rgba(0, 0, 0, 0.15)">
                <Box bg="perp.background.card" borderRadius="12px" p="16px" alignContent="center">
                    <LiquidityMiningClaimCard
                        icon={<PERPIcon boxSize="48px" />}
                        title="PERP (Locked)"
                        amount={data?.totalBalance ? data.totalBalance : Big(0)}
                        onClick={onLMPerpClaim}
                        isLoading={isLoading}
                    />
                </Box>
                <Box bg="perp.background.card" borderRadius="12px" p="16px" alignContent="center">
                    <LiquidityMiningClaimCard
                        icon={<OPIcon boxSize="48px" />}
                        title="OP"
                        amount={data?.totalBalanceOp ? data.totalBalanceOp : Big(0)}
                        onClick={onLMOPClaim}
                        isLoading={isLoading}
                    />
                </Box>
            </Grid>
        </Box>
    ) : (
        <Box
            position="fixed"
            left="0"
            bottom="0"
            width="100%"
            background="perp.background.background"
            height="114px"
            color="perp.text.primary"
            borderTop={"1px solid"}
            borderColor="perp.background.border"
            borderRadius={"16px"}
            display="flex"
            justifyContent="center"
            alignItems="center"
            p="16px"
            zIndex={1}
        >
            <Grid templateColumns="1fr auto auto" w="100%">
                <Grid templateRows="2">
                    <GridItem rowSpan={1}>
                        <Text>Your Rewards</Text>
                    </GridItem>
                    <GridItem rowSpan={1}>
                        <HStack>
                            <PERPIcon boxSize="16px" />
                            <Text ml="4px">6,969</Text>
                            <OPIcon boxSize="16px" />
                            <Text ml="4px">6,969</Text>
                        </HStack>
                    </GridItem>
                </Grid>
                <Grid alignItems="center">
                    <UpIcon boxSize="16px" onClick={() => setIsExpanded(!isExpanded)} />
                </Grid>
            </Grid>
        </Box>
    )
}
