import { CMS_URL, CMS_URL_AUTH } from "constants/env"
import { GraphQLClient } from "graphql-hooks"

export class CMSClient {
    private readonly graphqlClient: GraphQLClient

    constructor() {
        this.graphqlClient = this.getGraphqlClient()
    }

    getClient() {
        return this.graphqlClient
    }

    private getGraphqlClient = () => {
        return new GraphQLClient({
            url: CMS_URL,
            headers: {
                Authorization: CMS_URL_AUTH,
            },
        })
    }
}

export const cmsClient = new CMSClient()
