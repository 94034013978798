import { Flex, Grid, GridItem, Image, Text } from "@chakra-ui/react"
import { ConnectKitButton } from "connectkit"
import React, { useEffect } from "react"
import { Link } from "react-router-dom"

import logoPerpSvg from "../../assets/images/logo-perp.svg"
import { BannerContainer } from "../../hooks/containers/useBannerContainer"
import { useSupportedChain } from "../../hooks/useSupportedChain"

function Header() {
    return (
        <Flex h="72px" justifyContent="space-between">
            <GridItem>
                <LeftSection />
            </GridItem>
            <GridItem>
                <RightSection />
            </GridItem>
        </Flex>
    )
}

function LeftSection() {
    return (
        <Link to="/">
            <Grid h="100%" templateColumns="auto 1fr" placeItems="center" pl="24px" pt="24px" pb="24px">
                <GridItem>
                    <Image boxSize="24px" src={logoPerpSvg} alt="Logo Perp" />
                </GridItem>
                <GridItem pl="8px">
                    <Text color="#FFFFFF" fontSize="20px" fontWeight="bold" fontFamily="brand">
                        Token
                    </Text>
                </GridItem>
            </Grid>
        </Link>
    )
}

function RightSection() {
    // NOTE: containers
    const { show, clear } = BannerContainer.useContainer()

    // NOTE: hooks
    const { isAccountOnSupportedChain } = useSupportedChain()

    useEffect(() => {
        isAccountOnSupportedChain ? clear() : show({ content: "SWITCH_NETWORK", status: "INFO" })
    }, [clear, isAccountOnSupportedChain, show])

    return (
        <Grid h="100%" placeItems="center" pt="16px" pr="24px" pb="16px">
            <GridItem>
                <ConnectKitButton />
            </GridItem>
        </Grid>
    )
}

export { Header }
