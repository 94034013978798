import { useDisclosure } from "@chakra-ui/react"
import { useState } from "react"
import { createContainer } from "unstated-next"
import { BannerContents, BannerStatuses } from "../../components/Banner/constants"

const BannerContainer = createContainer(useBanner)

function useBanner() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [bannerContent, setBannerContent] = useState<BannerContents>()
    const [status, setStatus] = useState<BannerStatuses>()

    const show = ({ content, status }: { content: BannerContents; status: BannerStatuses }) => {
        setBannerContent(content)
        setStatus(status)
        onOpen()
    }

    const clear = () => {
        setBannerContent(undefined)
        setStatus(undefined)
        onClose()
    }

    return {
        isOpen,
        bannerContent,
        status,
        show,
        clear,
    }
}

export { BannerContainer }
