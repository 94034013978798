import { EPOCH } from "constants/config"
import { DateTime } from "luxon"

export function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

export function getEndAt(startFrom: number, steps: number, epoch: number) {
    const endAt = Math.floor(startFrom + steps * epoch)
    return endAt
}

export function getEndAtWithCalibrated(startFrom: number, steps: number, epoch: number) {
    const startFromWithCalibrated = getCalibratedTime(startFrom, epoch)
    return getEndAt(startFromWithCalibrated, steps, epoch)
}

export function getWeeksDiff(timeOneInSec: number, timeTwoInSec: number) {
    const diff = timeTwoInSec - timeOneInSec
    return Math.ceil(diff / (86400 * 7))
}

export function getDayHourMinSec(timeInSec: number) {
    const day = Math.floor(timeInSec / 86400)
    const hour = Math.floor((timeInSec - day * 86400) / 3600)
    const min = Math.floor((timeInSec - day * 86400 - hour * 3600) / 60)
    const sec = timeInSec - day * 86400 - hour * 3600 - min * 60
    return [day, hour, min, sec]
}

export function getCalibratedTime(timeInSec: number, epoch: number) {
    return Math.floor(timeInSec / epoch) * epoch
}

export function getTimeString(t: number) {
    const dt = DateTime.fromMillis(t * 1000)
    return dt.toLocaleString(DateTime.DATE_MED)
}

export function getTimestampSlots(startTime: number, endTime: number): Array<number> {
    const startTimeCalibrated = getCalibratedTime(startTime, EPOCH)
    const endTimeCalibrated = getCalibratedTime(endTime, EPOCH)
    const slots = []
    for (let i = startTimeCalibrated; i < endTimeCalibrated; i += EPOCH) {
        slots.push(i)
    }
    return slots
}
