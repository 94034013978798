import { Heading, HStack, LinkBox, LinkOverlay, Text, VStack } from "@chakra-ui/react"
import { OutboundLinkIcon } from "components/Icons/OutboundLinkIcon"
import { MotionVStack } from "components/Motion"
import { useQuery } from "graphql-hooks"
import { EventGroup, EventName, EventTypeInteraction } from "modules/analytics"
import React, { useCallback } from "react"
import { analyticsClient } from "services/AnalyticsClient"

import { colors } from "../../../theme/colors"
import { LoadingCard } from "./LoadingCard"

const RELATEDS_QUERY = `query MyQuery {
      relateds() {
        id
        link
        overline
        title
        description
        linkUrl
      }
    }
    `

export function RelatedCards() {
    const { data, loading } = useQuery(RELATEDS_QUERY)

    return (
        <>
            {loading && <LoadingCard />}
            {data &&
                data.relateds.map((related: any) => {
                    return (
                        <Post
                            key={related.id}
                            category={related.overline}
                            title={related.title}
                            description={related.description}
                            url={related.linkUrl}
                            link={related.link}
                        />
                    )
                })}
        </>
    )
}

export interface PostProps {
    title: string
    description: string
    category: string
    link: string
    url: string
}

function Post({ title, description, category, url }: PostProps) {
    const handleClick = useCallback(() => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.BUTTON_CLICKED,
            eventName: EventName.HOME_RELATED_CARD_CLICKED,
        })
    }, [])
    return (
        <LinkBox onClick={handleClick}>
            <MotionVStack
                whileTap={{ y: 2, scale: 0.98 }}
                whileHover={{ y: -6 }}
                spacing={4}
                align="stretch"
                bgColor="black"
                borderRadius="16px"
                cursor="pointer"
                p={"40px"}
            >
                <VStack spacing={2} align="stretch">
                    <Text fontSize="12px" color="perp.text.overline">
                        {category.toUpperCase()}
                    </Text>
                    <Heading variant="h3">{title}</Heading>
                </VStack>
                <Text>{description}</Text>
                <LinkOverlay pt="16px" href={url} isExternal color={colors.perp.primary.default}>
                    <HStack spacing={1}>
                        <Heading variant="sub1">Learn More</Heading>
                        <OutboundLinkIcon boxSize={"24px"} />
                    </HStack>
                </LinkOverlay>
            </MotionVStack>
        </LinkBox>
    )
}
