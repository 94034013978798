import { EPOCH } from "constants/config"
import { PerpWalletConnectorContainer } from "hooks/containers/usePerpWalletConnectorContainer"
import { useUsdcFeeDistributorRewardQuery, useUsdcSurplusQuery } from "modules/usdcFeeDistributor"
import { useVePerpInfoAtQuery, useVePerpUserDataAtQuery } from "modules/vePerp"
import { useVePERPEmissionFeeDistributorLastRewardQuery } from "modules/vePERPEmissionFeeDistributor"
import { useGetServerTimestampQuery } from "services/AWSClient/flow"
import { getCalibratedTime, getRatio, getTimeString } from "utils"

export interface IUseEpochStatusArgs {
    epochDeviation: number // 0 for this round epoch
}

export function useEpochStatus({ epochDeviation = 0 }: IUseEpochStatusArgs) {
    const { account } = PerpWalletConnectorContainer.useContainer()

    const { data } = useGetServerTimestampQuery()
    const currentTimestamp = data?.timestamp
    const weekStartTimestamp = currentTimestamp
        ? getCalibratedTime(currentTimestamp, EPOCH) - EPOCH * epochDeviation
        : undefined
    const weekEndTimestamp = currentTimestamp
        ? getCalibratedTime(currentTimestamp + EPOCH, EPOCH) - EPOCH * epochDeviation
        : undefined

    const { data: vePERPEmissionFeeRewards } = useVePERPEmissionFeeDistributorLastRewardQuery(1 + epochDeviation)
    const { data: usdcFeeDistributorReward } = useUsdcFeeDistributorRewardQuery(
        { timestamp: weekStartTimestamp! },
        { skip: !weekStartTimestamp },
    )
    const { data: usdcSurplusData } = useUsdcSurplusQuery(
        { timestamp: weekStartTimestamp! },
        { skip: !weekStartTimestamp },
    )

    const { data: vePerpInfoAt } = useVePerpInfoAtQuery(weekStartTimestamp!, { skip: !weekStartTimestamp })
    const { data: vePerpUserDataAt, isLoading: isLoadingVePerpUserDataAt } = useVePerpUserDataAtQuery(
        { account: account!, timestamp: weekStartTimestamp! },
        { skip: !account || !weekStartTimestamp },
    )

    const startDateStr = weekStartTimestamp ? getTimeString(weekStartTimestamp) : undefined
    const endDateStr = weekEndTimestamp ? getTimeString(weekEndTimestamp) : undefined
    const vePERPRewardStartDateStr = vePERPEmissionFeeRewards?.weekStart
        ? getTimeString(vePERPEmissionFeeRewards.weekStart)
        : undefined
    const vePERPRewardEndDateStr = vePERPEmissionFeeRewards?.weekEnd
        ? getTimeString(vePERPEmissionFeeRewards.weekEnd)
        : undefined

    const totalPerpRewards = vePERPEmissionFeeRewards?.totalReward
    const totalUsdcFeeRewards =
        epochDeviation === 0 ? usdcSurplusData?.totalSurplus.times(0.75) : usdcFeeDistributorReward?.totalReward
    const totalVePerp = vePerpInfoAt?.totalUnweightedVePerpAmount

    const myVePerp = vePerpUserDataAt && vePerpUserDataAt.vePerpTokenAmountUnweighted
    const myVeRatio = totalVePerp && myVePerp && getRatio(totalVePerp, myVePerp)
    const myPerpRewards = myVeRatio && totalPerpRewards && totalPerpRewards.mul(myVeRatio)
    const myUsdcRewards = myVeRatio && totalUsdcFeeRewards && totalUsdcFeeRewards.mul(myVeRatio)
    return {
        totalPerpRewards,
        totalUsdcFeeRewards,
        totalVePerp,
        myVePerp,
        myPerpRewards,
        myUsdcRewards,
        isLoadingVePerpUserDataAt,
        startDateStr,
        endDateStr,
        vePERPRewardStartDateStr,
        vePERPRewardEndDateStr,
    }
}
