import Big from "big.js"
import { VePerpClient } from "contracts/VePerpClient"
import { PerpWalletConnectorContainer } from "hooks/containers/usePerpWalletConnectorContainer"
import { useMemo } from "react"
import { useGetServerTimestampQuery } from "services/AWSClient/flow"

import { useVePerpUserDataQuery } from "../flow"

interface IVePerpInfo {
    isLoading: boolean
    hasExpired: boolean
    hasLockedAlready: boolean
    lockedPerpTokenAmount: Big | undefined
    lockedPerpTokenValidDate: string | undefined
    lockedEndTimestamp: number | undefined
    vePerpAmountUnweighted: Big | undefined
    secsToExpiration: number | undefined
    daysToExpiration: number | undefined
    weeksToExpiration: number | undefined
}

export function useVePerpInfo(): IVePerpInfo {
    const { account } = PerpWalletConnectorContainer.useContainer()
    const { data: vePerpUserData, isLoading: isLoadingVePerpUserData } = useVePerpUserDataQuery(
        { account: account! },
        { skip: !account },
    )
    const { data: serverTimestampData } = useGetServerTimestampQuery()
    const timestamp = serverTimestampData?.timestamp

    return useMemo(() => {
        if (!vePerpUserData || !timestamp) {
            return {
                isLoading: isLoadingVePerpUserData || !timestamp,
                hasExpired: false,
                hasLockedAlready: false,
                lockedPerpTokenAmount: undefined,
                lockedPerpTokenValidDate: undefined,
                lockedEndTimestamp: 0,
                vePerpAmountUnweighted: undefined,
                secsToExpiration: undefined,
                daysToExpiration: undefined,
                weeksToExpiration: undefined,
            }
        }

        const {
            lockedAmount: lockedPerpTokenAmount,
            lockedEndTimestamp,
            vePerpTokenAmountUnweighted: vePerpAmountUnweighted,
        } = vePerpUserData
        const hasLockedAlready = lockedPerpTokenAmount?.gt(0)
        const hasExpired = !!(hasLockedAlready && lockedEndTimestamp && lockedEndTimestamp <= timestamp)
        const lockedPerpTokenValidDate = lockedEndTimestamp
            ? new Date(lockedEndTimestamp * 1000).toLocaleDateString(undefined, {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
              })
            : undefined
        const { secs, weeks, days } =
            lockedEndTimestamp && timestamp
                ? VePerpClient.getCountDownToExpiration(lockedEndTimestamp, timestamp)
                : { secs: undefined, weeks: undefined, days: undefined }

        return {
            isLoading: false,
            hasExpired,
            hasLockedAlready,
            lockedPerpTokenAmount,
            lockedPerpTokenValidDate,
            lockedEndTimestamp,
            vePerpAmountUnweighted,
            secsToExpiration: secs,
            daysToExpiration: days,
            weeksToExpiration: weeks,
        }
    }, [vePerpUserData, timestamp, isLoadingVePerpUserData])
}
