import { TransactionReceipt } from "@ethersproject/providers"

import { EventNameTx } from "./types"
import { analyticsTxFailed, analyticsTxSucceeded } from "./util"

// TODO: Deprecate this and invoke analyticsTxFailed/analyticsTxSucceeded directly when needed.
export const analyticsTx = {
    vePerp: {
        withdraw: {
            succeeded: (receipt: TransactionReceipt) =>
                analyticsTxSucceeded(EventNameTx.TX_SUCCEEDED_VEPERP_WITHDRAW, receipt),
            failed: (error: unknown) =>
                analyticsTxFailed(
                    error,
                    EventNameTx.TX_FAILED_VEPERP_WITHDRAW,
                    EventNameTx.TX_REJECTED_VEPERP_WITHDRAW,
                ),
        },
        createLock: {
            succeeded: (receipt: TransactionReceipt) =>
                analyticsTxSucceeded(EventNameTx.TX_SUCCEEDED_VEPERP_CREATE_LOCK, receipt),
            failed: (error: unknown) =>
                analyticsTxFailed(
                    error,
                    EventNameTx.TX_FAILED_VEPERP_CREATE_LOCK,
                    EventNameTx.TX_REJECTED_VEPERP_CREATE_LOCK,
                ),
        },
        increaseAmount: {
            succeeded: (receipt: TransactionReceipt) =>
                analyticsTxSucceeded(EventNameTx.TX_SUCCEEDED_VEPERP_INCREASE_AMOUNT, receipt),
            failed: (error: unknown) =>
                analyticsTxFailed(
                    error,
                    EventNameTx.TX_FAILED_VEPERP_INCREASE_AMOUNT,
                    EventNameTx.TX_REJECTED_VEPERP_INCREASE_AMOUNT,
                ),
        },
        increaseUnlockTime: {
            succeeded: (receipt: TransactionReceipt) =>
                analyticsTxSucceeded(EventNameTx.TX_SUCCEEDED_VEPERP_INCREASE_UNLOCK_TIME, receipt),
            failed: (error: unknown) =>
                analyticsTxFailed(
                    error,
                    EventNameTx.TX_FAILED_VEPERP_INCREASE_UNLOCK_TIME,
                    EventNameTx.TX_REJECTED_VEPERP_INCREASE_UNLOCK_TIME,
                ),
        },
    },
    feeDistributor: {
        claim: {
            succeeded: (receipt: TransactionReceipt) =>
                analyticsTxSucceeded(EventNameTx.TX_SUCCEEDED_FEE_DISTRIBUTOR_CLAIM, receipt),
            failed: (error: unknown) =>
                analyticsTxFailed(
                    error,
                    EventNameTx.TX_FAILED_FEE_DISTRIBUTOR_CLAIM,
                    EventNameTx.TX_REJECTED_FEE_DISTRIBUTOR_CLAIM,
                ),
        },
    },
    erc20: {
        approve: {
            succeeded: (receipt: TransactionReceipt) =>
                analyticsTxSucceeded(EventNameTx.TX_SUCCEEDED_ERC20_APPROVE, receipt),
            failed: (error: unknown) =>
                analyticsTxFailed(error, EventNameTx.TX_FAILED_ERC20_APPROVE, EventNameTx.TX_REJECTED_ERC20_APPROVE),
        },
    },
    vePerpRewardDistributor: {
        claimWeeks: {
            succeeded: (receipt: TransactionReceipt) =>
                analyticsTxSucceeded(EventNameTx.TX_SUCCEEDED_VEPERP_REWARD_DISTRIBUTOR_CLAIM_WEEKS, receipt),
            failed: (error: unknown) =>
                analyticsTxFailed(
                    error,
                    EventNameTx.TX_FAILED_VEPERP_REWARD_DISTRIBUTOR_CLAIM_WEEKS,
                    EventNameTx.TX_REJECTED_VEPERP_REWARD_DISTRIBUTOR_CLAIM_WEEKS,
                ),
        },
    },
    perpLiquidityMining: {
        claimWeeks: {
            succeeded: (receipt: TransactionReceipt) =>
                analyticsTxSucceeded(EventNameTx.TX_SUCCEEDED_PERP_LIQUIDITY_MINING_CLAIM_WEEKS, receipt),
            failed: (error: unknown) =>
                analyticsTxFailed(
                    error,
                    EventNameTx.TX_FAILED_PERP_LIQUIDITY_MINING_CLAIM_WEEKS,
                    EventNameTx.TX_REJECTED_PERP_LIQUIDITY_MINING_CLAIM_WEEKS,
                ),
        },
    },
    perpReferral: {
        createReferralCode: {
            succeeded: (receipt: TransactionReceipt) =>
                analyticsTxSucceeded(EventNameTx.TX_SUCCEEDED_PERP_REFERRAL_CREATE_REFERRAL_CODE, receipt),
            failed: (error: unknown) =>
                analyticsTxFailed(
                    error,
                    EventNameTx.TX_FAILED_PERP_REFERRAL_CREATE_REFERRAL_CODE,
                    EventNameTx.TX_REJECTED_PERP_REFERRAL_CREATE_REFERRAL_CODE,
                ),
        },
        setReferralCode: {
            succeeded: (receipt: TransactionReceipt) =>
                analyticsTxSucceeded(EventNameTx.TX_SUCCEEDED_PERP_REFERRAL_SET_REFERRAL_CODE, receipt),
            failed: (error: unknown) =>
                analyticsTxFailed(
                    error,
                    EventNameTx.TX_FAILED_PERP_REFERRAL_SET_REFERRAL_CODE,
                    EventNameTx.TX_REJECTED_PERP_REFERRAL_SET_REFERRAL_CODE,
                ),
        },
    },
}
