import { Icon, IconProps } from "@chakra-ui/react"
import React from "react"

export function MATICIcon({ ...props }: IconProps) {
    return (
        <Icon width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 0C24.825 0 32 7.1681 32 16C32 24.8319 24.825 32 16 32C7.175 32 0 24.8319 0 16C0 7.1681 7.175 0 16 0Z"
                fill="#8C57E4"
            />
            <g style={{ mixBlendMode: "multiply" }} opacity="0.8">
                <g style={{ mixBlendMode: "multiply" }} opacity="0.8">
                    <path
                        d="M20.6292 12.8225C20.3003 12.6297 19.8773 12.6297 19.5013 12.8225L16.8695 14.4131L15.0836 15.4253L12.4987 17.016C12.1697 17.2088 11.7467 17.2088 11.3708 17.016L9.34987 15.7627C9.02089 15.5699 8.7859 15.1843 8.7859 14.7505V12.3405C8.7859 11.9549 8.97389 11.5692 9.34987 11.3282L11.3708 10.1232C11.6997 9.93041 12.1227 9.93041 12.4987 10.1232L14.5196 11.3764C14.8486 11.5692 15.0836 11.9549 15.0836 12.3887V13.9793L16.8695 12.9189V11.28C16.8695 10.8944 16.6815 10.5088 16.3055 10.2678L12.5457 8.00238C12.2167 7.80957 11.7937 7.80957 11.4178 8.00238L7.56397 10.316C7.18799 10.5088 7 10.8944 7 11.28V15.8109C7 16.1965 7.18799 16.5821 7.56397 16.8231L11.3708 19.0886C11.6997 19.2814 12.1227 19.2814 12.4987 19.0886L15.0836 17.5462L16.8695 16.4857L19.4543 14.9433C19.7833 14.7505 20.2063 14.7505 20.5822 14.9433L22.6031 16.1483C22.9321 16.3411 23.1671 16.7267 23.1671 17.1606V19.5706C23.1671 19.9562 22.9791 20.3418 22.6031 20.5828L20.6292 21.7878C20.3003 21.9806 19.8773 21.9806 19.5013 21.7878L17.4804 20.5828C17.1514 20.39 16.9164 20.0044 16.9164 19.5706V18.0282L15.1305 19.0886V20.6792C15.1305 21.0648 15.3185 21.4504 15.6945 21.6914L19.5013 23.9569C19.8303 24.1497 20.2533 24.1497 20.6292 23.9569L24.436 21.6914C24.765 21.4986 25 21.113 25 20.6792V16.1001C25 15.7145 24.812 15.3289 24.436 15.0879L20.6292 12.8225Z"
                        fill="#141319"
                    />
                </g>
            </g>
        </Icon>
    )
}
