import { Modal as ChakraModal, ModalBody, ModalContent, ModalOverlay, Portal, Text, VStack } from "@chakra-ui/react"
import Big from "big.js"
import { DEFAULT_WEEKS_MIN } from "components/Locker/constants"
import { CreateLockData, LockerCreateLock } from "components/Locker/LockerCreateLock"
import { Header } from "components/Modal/Header"
import { EventGroup, EventName, EventTypeInteraction } from "modules/analytics"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { analyticsClient } from "services/AnalyticsClient"
import { useAppSelector } from "store"

import { referralSlice } from "../../slice"
import { LockerInfoPreview } from "./LockerInfoPreview"
import { useLockerInfoPreview } from "./useLockerInfoPreview"

const INIT_PERP_INFO_PREVIEW = { lockAmount: Big(0), lockWeeks: DEFAULT_WEEKS_MIN }

export function ModalCreateLock() {
    const { isOpen, size, motion } = useAppSelector(state => state.referral.modal.createLockByMyStatus)

    const dispatch = useDispatch()
    const { closeModalCreateLockByMyStatus } = referralSlice.actions
    const { lockerInfoPreview, setLockerInfoPreview } = useLockerInfoPreview(INIT_PERP_INFO_PREVIEW)

    const handleClose = useCallback(() => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.MODAL_CLOSED,
            eventName: EventName.REFERRAL_CREATE_LOCK_BY_MY_STATUS_MODAL_CLOSED,
        })
        dispatch(closeModalCreateLockByMyStatus())
        setLockerInfoPreview(INIT_PERP_INFO_PREVIEW)
    }, [closeModalCreateLockByMyStatus, dispatch, setLockerInfoPreview])

    const handleClick = useCallback(({ lockAmount, lockWeeks }: CreateLockData) => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.BUTTON_CLICKED,
            eventName: EventName.REFERRAL_CREATE_LOCK_BY_MY_STATUS_BUTTON_CLICKED,
            payload: { lockAmount: lockAmount.toString(), lockWeeks },
        })
    }, [])

    return (
        <Portal>
            <ChakraModal motionPreset={motion} onClose={handleClose} size={size} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent
                    bg="perp.background.background"
                    color="perp.text.primary"
                    boxShadow="0px 20px 20px 0px rgba(0, 0, 0, 0.15)"
                >
                    <Header onCloseButtonClick={handleClose}>Locked PERP</Header>
                    <ModalBody>
                        <VStack spacing={6} align="stretch">
                            <Text variant={"body2"}>
                                You don't have any Locked PERP currently. You'll need to lock some PERP.
                            </Text>
                            <LockerCreateLock
                                onClick={handleClick}
                                onFinished={handleClose}
                                onChange={setLockerInfoPreview}
                                successMsg="You have locked PERP successfully. Please continue to claim your rewards."
                            />
                            <LockerInfoPreview {...lockerInfoPreview} />
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </ChakraModal>
        </Portal>
    )
}
