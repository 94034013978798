import { Icon, IconProps } from "@chakra-ui/react"
import React from "react"

export function CopyIcon({ ...props }: IconProps) {
    return (
        <Icon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_1857_3455)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 3.25C4.48122 3.25 3.25 4.48122 3.25 6V18H4.75V6C4.75 5.30964 5.30964 4.75 6 4.75H18V3.25H6ZM9 8.5H19C19.2761 8.5 19.5 8.72386 19.5 9V19C19.5 19.2761 19.2761 19.5 19 19.5H9C8.72386 19.5 8.5 19.2761 8.5 19V9C8.5 8.72386 8.72386 8.5 9 8.5ZM7 9C7 7.89543 7.89543 7 9 7H19C20.1046 7 21 7.89543 21 9V19C21 20.1046 20.1046 21 19 21H9C7.89543 21 7 20.1046 7 19V9Z"
                    fill="white"
                    fillOpacity="0.8"
                />
            </g>
            <defs>
                <clipPath id="clip0_1857_3455">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </Icon>
    )
}
