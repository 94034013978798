import {
    Button,
    Grid,
    Modal as ChakraModal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Portal,
    Text,
} from "@chakra-ui/react"
import { Header } from "components/Modal/Header"
import { NOTIFICATION_DURATION } from "constants/config"
import { PerpWalletConnectorContainer } from "hooks/containers/usePerpWalletConnectorContainer"
import { analyticsTx, EventGroup, EventName, EventTypeInteraction } from "modules/analytics"
import { Claim, SelectableItem, SelectAll, useSelectable } from "modules/claim"
import { IStatus, notificationSlice } from "modules/notification/slice"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { analyticsClient } from "services/AnalyticsClient"
import { AppDispatch, useAppSelector } from "store"
import { formatNumber } from "utils/format"

import { useReferralClaimWeeksMutation, useReferralRewardsQuery } from "../../flow"
import { referralSlice } from "../../slice"

/**
 * NOTE: This component will open when the user is trying to claim rewards.
 */

function ModalClaim() {
    const { isOpen, size, motion, items } = useAppSelector(state => state.referral.modal.claim)

    const { account } = PerpWalletConnectorContainer.useContainer()
    const { isTxLoading } = useAppSelector(state => state.wallet)
    const { refetch } = useReferralRewardsQuery({ account: account! }, { skip: !account })
    const [claimWeeks] = useReferralClaimWeeksMutation()

    const { selected, onSelectChange, onSelectAll, isSelectedAll, selectedAmount, selectedLength } =
        useSelectable(items)

    const dispatch = useDispatch<AppDispatch>()
    const { closeModalClaim } = referralSlice.actions
    const { open, close } = notificationSlice.actions
    const navigate = useNavigate()

    const handleClose = useCallback(() => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.MODAL_CLOSED,
            eventName: EventName.REFERRAL_CLAIM_MODAL_CLOSED,
        })
        dispatch(closeModalClaim())
    }, [closeModalClaim, dispatch])

    const handleClaim = useCallback(async () => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.BUTTON_CLICKED,
            eventName: EventName.REFERRAL_CLAIM_BUTTON_CLICKED,
            payload: { selectedAmount: selectedAmount.toString() },
        })
        if (!account) {
            return
        }
        const result = await claimWeeks([account, Claim.getClaimsBySelected(selected)])
        if ("error" in result) {
            analyticsTx.vePerpRewardDistributor.claimWeeks.failed(result.error)
            dispatch(
                open({
                    status: IStatus.error,
                    description: "Claim rewards failed.",
                    duration: NOTIFICATION_DURATION.SHORT,
                }),
            )
        } else {
            analyticsTx.vePerpRewardDistributor.claimWeeks.succeeded(result.data)
            refetch()
            dispatch(
                open({
                    status: IStatus.success,
                    description: (
                        <Text>
                            Locked PERP rewards successfully claimed! Please check your balance{" "}
                            <Text
                                onClick={() => {
                                    navigate("/")
                                    dispatch(close())
                                }}
                                as="span"
                                textDecoration="underline"
                                cursor="pointer"
                            >
                                here
                            </Text>
                            .
                        </Text>
                    ),
                }),
            )
        }
        handleClose()
    }, [account, claimWeeks, close, dispatch, handleClose, navigate, open, refetch, selected, selectedAmount])

    return (
        <Portal>
            <ChakraModal
                motionPreset={motion}
                onClose={handleClose}
                size={size}
                isOpen={isOpen}
                isCentered
                scrollBehavior="inside"
            >
                <ModalOverlay />
                <ModalContent
                    bg="perp.background.background"
                    color="perp.text.primary"
                    boxShadow="0px 20px 20px 0px rgba(0, 0, 0, 0.15)"
                >
                    <Header onCloseButtonClick={handleClose}>Claim by Week</Header>
                    <ModalBody borderBottom="1px solid" borderColor="perp.background.border" p={0}>
                        <Text opacity={0.65} variant={"body2"} px={6} py={4}>
                            Due to smart contract constraints, you can only claim up to 10 weeks per transaction.
                        </Text>

                        {items?.map((item, index) => (
                            <SelectableItem
                                key={index}
                                index={index}
                                data={item}
                                selected={selected}
                                onChange={onSelectChange}
                                isLast={index === items.length - 1}
                                selectedLength={selectedLength}
                            />
                        ))}
                    </ModalBody>
                    <ModalFooter>
                        <Grid w="100%" gap="16px">
                            <SelectAll isChecked={isSelectedAll} onChange={onSelectAll} />
                            <Button
                                variant="green"
                                onClick={handleClaim}
                                isLoading={isTxLoading}
                                disabled={isTxLoading || (selectedAmount.toNumber() ? false : true)}
                                loadingText="Waiting for transaction"
                            >
                                Claim {formatNumber(selectedAmount)} Locked PERP
                            </Button>
                        </Grid>
                    </ModalFooter>
                </ModalContent>
            </ChakraModal>
        </Portal>
    )
}

export { ModalClaim }
