const colors = {
    perp: {
        background: {
            background: "rgba(0, 0, 0, 1)",
            card: "rgba(20, 19, 25, 1)",
            input: "rgba(83, 79, 111, 0.24)",
            button: "rgba(83, 79, 111, 0.24)",
            border: "rgba(85, 82, 107, 0.54)",
            gray: "rgba(20, 19, 25, 1)",
            selected: "rgba(60, 234, 170, 0.2)",
        },
        text: {
            primary: "rgba(255, 255, 255, 1)",
            secondary: "rgba(235, 235, 245, 0.75)",
            disable: "rgba(235, 235, 245, 0.3)",
            overline: "rgba(147, 147, 141, 1)",
            article: "rgba(255, 255, 255, 0.8);",
        },
        primary: {
            pressed: "rgba(51, 199, 144, 1)",
            default: "rgba(60, 234, 170, 1)",
            hover: "rgba(109, 239, 191, 1)",
        },
        secondary: {
            pressed: "rgba(3, 160, 194, 1)",
            default: "rgba(3, 188, 228, 1)",
            hover: "rgba(109, 239, 191, 1)",
        },
        support: {
            error: "rgba(241, 103, 105, 1)",
            alert: "rgba(255, 180, 89, 1)",
        },
        link: {
            default: "#3CEAAA",
            subtle: "rgba(235, 235, 245, 0.6)",
        },
    },
}

export { colors }
