import { Box, Button } from "@chakra-ui/react"
import Big from "big.js"
import { EPOCH, NOTIFICATION_DURATION } from "constants/config"
import { useLockerSlider } from "hooks"
import { analyticsTx } from "modules/analytics"
import { IStatus, notificationSlice } from "modules/notification/slice"
import { useVePerpIncreaseTimeMutation } from "modules/vePerp"
import React, { ReactNode, useCallback } from "react"
import { useDispatch } from "react-redux"

import { useVePerpInfo } from "../../modules/vePerp/hooks/useVePerpInfo"
import { useAppSelector } from "../../store"
import { getEndAt } from "../../utils/time"
import { LockerSlider } from "./LockerSlider"

export interface ExtendTimeData {
    lockWeeks: number
}

type LockerExtendTimeProps = {
    onClick?: (data: ExtendTimeData) => void
    onChange?: (data: ExtendTimeData) => void
    onSucceed?: () => void
    onFinished?: () => void
    successMsg?: string | ReactNode
    failedMsg?: string | ReactNode
}

export function LockerExtendTime({
    onClick = () => {},
    onChange = () => {},
    onSucceed = () => {},
    onFinished = () => {},
    successMsg = "You have extended Lock Time successfully.",
    failedMsg = "Extend Lock PERP time failed.",
}: LockerExtendTimeProps) {
    // NOTE: redux
    const { open } = notificationSlice.actions
    const dispatch = useDispatch()

    // NOTE: containers
    const { isTxLoading } = useAppSelector(state => state.wallet)

    const [increaseUnlockTime] = useVePerpIncreaseTimeMutation()
    const { lockedEndTimestamp, weeksToExpiration } = useVePerpInfo()

    // NOTE: states
    const { week, setWeek, from, to, isDisabled } = useLockerSlider(weeksToExpiration)

    // NOTE: handlers
    const handleLockTimeChange = useCallback(
        (weekNext: number) => {
            setWeek(weekNext)
            onChange?.({ lockWeeks: weekNext })
        },
        [onChange, setWeek],
    )

    const handleExtendTime = useCallback(async () => {
        onClick?.({ lockWeeks: week })
        if (!lockedEndTimestamp) return
        const endAt = getEndAt(lockedEndTimestamp, week, EPOCH)
        const result = await increaseUnlockTime([Big(endAt)])
        if ("error" in result) {
            analyticsTx.vePerp.increaseUnlockTime.failed(result.error)
            dispatch(
                open({
                    status: IStatus.error,
                    description: failedMsg,
                    duration: NOTIFICATION_DURATION.SHORT,
                }),
            )
        } else {
            analyticsTx.vePerp.increaseUnlockTime.succeeded(result.data)
            dispatch(
                open({
                    status: IStatus.success,
                    description: successMsg,
                    duration: NOTIFICATION_DURATION.SHORT,
                }),
            )
            onSucceed()
        }
        onFinished()
    }, [
        onClick,
        week,
        lockedEndTimestamp,
        increaseUnlockTime,
        onFinished,
        dispatch,
        open,
        failedMsg,
        successMsg,
        onSucceed,
    ])

    return (
        <Box color="perp.text.primary">
            <Box h="96px">
                <LockerSlider
                    key={to}
                    from={from}
                    to={to}
                    step={1}
                    isDisabled={isDisabled}
                    onChange={handleLockTimeChange}
                    value={week}
                />
            </Box>
            <Box mt="24px">
                <Button
                    w="100%"
                    size="lg"
                    isDisabled={isDisabled}
                    isLoading={isTxLoading}
                    onClick={handleExtendTime}
                    loadingText="Waiting for transaction"
                >
                    Extend
                </Button>
            </Box>
        </Box>
    )
}
