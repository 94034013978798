export const Heading = {
    baseStyle: {
        fontWeight: "medium",
    },
    variants: {
        h1: {
            fontSize: "48px",
            lineHeight: "56px",
        },
        h2: {
            fontSize: "32px",
            lineHeight: "40px",
        },

        h3: {
            fontSize: "24px",
            lineHeight: "32px",
        },
        h4: {
            fontSize: "20px",
            lineHeight: "24px",
        },
        sub1: {
            fontSize: "16px",
            lineHeight: "24px",
        },
        sub2: {
            fontSize: "14px",
            lineHeight: "20px",
        },
    },
}
