import React, { RefObject, useEffect } from "react"
import { useLocation } from "react-router"

const ScrollToTop = ({ target, children }: { target: RefObject<HTMLDivElement>; children: JSX.Element }) => {
    const location = useLocation()
    useEffect(() => {
        target.current ? (target.current.scrollTop = 0) : window.scrollTo(0, 0)
    }, [location, target])

    return <>{children}</>
}

export default ScrollToTop
