import Big from "big.js"
import { EPOCH } from "constants/config"
import { PerpWalletConnectorContainer } from "hooks/containers/usePerpWalletConnectorContainer"
import { useUsdcFeeDistributorRewardQuery } from "modules/usdcFeeDistributor"
import { useVePerpInfoAtQuery, useVePerpUserDataAtQuery } from "modules/vePerp"
import { useVePERPEmissionFeeDistributorLastRewardQuery } from "modules/vePERPEmissionFeeDistributor"
import { useGetServerTimestampQuery } from "services/AWSClient/flow"
import { getCalibratedTime, getRatio } from "utils"
import { IUseEpochStatusArgs } from "./useEpochStatus"

// TODO: need to rename
export function useLastEpochMyAPR({ epochDeviation = 1 }: IUseEpochStatusArgs) {
    const { data } = useGetServerTimestampQuery()
    const currentTimestamp = data?.timestamp
    const weekStartTimestamp = currentTimestamp ? getCalibratedTime(currentTimestamp, EPOCH) : undefined

    const { account } = PerpWalletConnectorContainer.useContainer()
    const { data: lastEpochVePERPEmissionFeeRewards } = useVePERPEmissionFeeDistributorLastRewardQuery(2)
    const { data: lastEpochUsdcFeeDistributorReward } = useUsdcFeeDistributorRewardQuery(
        { timestamp: weekStartTimestamp! - EPOCH * epochDeviation },
        {
            skip: !weekStartTimestamp,
        },
    )
    const { data: lastEpochVePerpInfoAt } = useVePerpInfoAtQuery(weekStartTimestamp! - EPOCH * epochDeviation, {
        skip: !weekStartTimestamp,
    })
    const { data: lastEpochVePerpUserDataAt } = useVePerpUserDataAtQuery(
        { account: account!, timestamp: weekStartTimestamp! - EPOCH * epochDeviation },
        { skip: !account || !weekStartTimestamp },
    )

    const lastEpochPerp7dTwap = lastEpochVePERPEmissionFeeRewards?.perp7dTwap
    const lastEpochUsdcTotalReward = lastEpochUsdcFeeDistributorReward?.totalReward
    const lastEpochTotalVePerp = lastEpochVePerpInfoAt?.totalUnweightedVePerpAmount
    const lastEpochMyVePerp = lastEpochVePerpUserDataAt && lastEpochVePerpUserDataAt.vePerpTokenAmountUnweighted

    const lastEpochMyRatio =
        lastEpochTotalVePerp && lastEpochMyVePerp && getRatio(lastEpochTotalVePerp, lastEpochMyVePerp)

    const lastEpochMyLockedAmount = lastEpochVePerpUserDataAt && lastEpochVePerpUserDataAt.lockedAmount
    const lastEpochMyUsdcReward =
        lastEpochMyRatio && lastEpochUsdcTotalReward && lastEpochUsdcTotalReward.mul(lastEpochMyRatio)
    const lastEpochMyApr = lastEpochMyLockedAmount?.eq(0)
        ? Big(0)
        : lastEpochMyLockedAmount &&
          lastEpochPerp7dTwap &&
          lastEpochMyUsdcReward &&
          lastEpochMyUsdcReward.div(lastEpochMyLockedAmount.mul(lastEpochPerp7dTwap)).mul(52).mul(100)

    return {
        lastEpochMyApr,
    }
}
