import { Box, Button, Grid, Text } from "@chakra-ui/react"
import Big from "big.js"
import { LockerSlider } from "components/Locker/LockerSlider"
import { PerpInfoRow } from "components/PerpInfoRow"
import { EPOCH } from "constants/config"
import { useLockerSlider } from "hooks"
import { PerpWalletConnectorContainer } from "hooks/containers/usePerpWalletConnectorContainer"
import { analyticsTx, EventGroup, EventName, EventTypeInteraction } from "modules/analytics"
import { IStatus, notificationSlice } from "modules/notification/slice"
import { useVePerpInfo } from "modules/vePerp"
import { useVePerpIncreaseTimeMutation } from "modules/vePerp/flow"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { analyticsClient } from "services/AnalyticsClient"
import { useGetServerTimestampQuery } from "services/AWSClient/flow"
import { useAppSelector } from "store"
import { getEndAt } from "utils/time"

import { useReferralRewardsQuery } from "../../flow"
import { selectSoonestUnlockDate } from "../../selector"

type ModalWidgetExtendTimeProps = {
    onSucceed?: () => void
    onFinished?: () => void
}

export function ModalWidgetExtendTime({ onSucceed = () => {}, onFinished = () => {} }: ModalWidgetExtendTimeProps) {
    // NOTE: redux
    const { open } = notificationSlice.actions
    const dispatch = useDispatch()

    // NOTE: containers
    const { isTxLoading } = useAppSelector(state => state.wallet)
    const { data: serverTimestampData } = useGetServerTimestampQuery()
    const timestamp = serverTimestampData?.timestamp

    const [increaseUnlockTime] = useVePerpIncreaseTimeMutation()

    const { daysToExpiration, weeksToExpiration, lockedEndTimestamp, lockedPerpTokenValidDate } = useVePerpInfo()

    const { account } = PerpWalletConnectorContainer.useContainer()
    const { weeksToSoonestUnlockDate } = useReferralRewardsQuery(
        { account: account! },
        { skip: !account, selectFromResult: selectSoonestUnlockDate(timestamp, lockedEndTimestamp) },
    )

    // NOTE: states
    const { week, setWeek, from, to, isDisabled } = useLockerSlider(weeksToExpiration, weeksToSoonestUnlockDate)

    // NOTE: handlers
    const handleExtendTime = useCallback(async () => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.BUTTON_CLICKED,
            eventName: EventName.REFERRAL_EXTEND_TIME_BUTTON_CLICKED,
            payload: { week },
        })
        if (!lockedEndTimestamp) {
            dispatch(open({ status: IStatus.error, description: "You haven’t locked any PERP yet." }))
            return
        }
        const endAt = getEndAt(lockedEndTimestamp, week, EPOCH)
        const result = await increaseUnlockTime([Big(endAt)])
        if ("error" in result) {
            analyticsTx.vePerp.increaseUnlockTime.failed(result.error)
            dispatch(open({ status: IStatus.error, description: "Extend Lock PERP time failed." }))
        } else {
            analyticsTx.vePerp.increaseUnlockTime.succeeded(result.data)
            dispatch(open({ status: IStatus.success, description: "You have locked PERP successfully." }))
            onSucceed()
        }
        onFinished()
    }, [onFinished, lockedEndTimestamp, week, increaseUnlockTime, dispatch, open, onSucceed])

    return (
        <Box color="perp.text.primary">
            <Box h="96px">
                <LockerSlider
                    key={to}
                    from={from}
                    to={to}
                    step={1}
                    isDisabled={isDisabled}
                    onChange={setWeek}
                    value={week}
                />
            </Box>
            <Box mt="24px">
                <Button
                    w="100%"
                    size="lg"
                    isDisabled={isDisabled}
                    isLoading={isTxLoading}
                    onClick={handleExtendTime}
                    loadingText="Waiting for transaction"
                >
                    Extend
                </Button>
            </Box>
            <Grid mt="16px" gap="8px">
                <PerpInfoRow
                    title="Locked Through"
                    content={
                        <Box>
                            {lockedPerpTokenValidDate}{" "}
                            <Text as="span" color="perp.text.secondary">
                                ({daysToExpiration} Days)
                            </Text>
                        </Box>
                    }
                />
            </Grid>
        </Box>
    )
}
