import Big from "big.js"
import { BigNumber, constants } from "ethers"
import { useEffect, useState } from "react"
import { createContainer } from "unstated-next"
import { PerpWalletConnectorContainer } from "./usePerpWalletConnectorContainer"
import { bigNum2Big } from "../../utils/number"
import { useTxCallback } from "../useTxCallback"
import { ContractsContainer } from "./useContractsContainer"

type EventArgsArrayTransfer = [string, string, BigNumber]
type EventArgsArrayApproval = [string, string, BigNumber]

const Erc20PERPContainer = createContainer(useErc20PERP)

function useErc20PERP() {
    const { vePERPContract, erc20PERPContract, erc20PERPContractWs } = ContractsContainer.useContainer()
    const { account } = PerpWalletConnectorContainer.useContainer()
    const [balance, setBalance] = useState<Big>()
    const [allowance, setAllowance] = useState<Big>()

    useEffect(() => {
        async function init() {
            if (!account) return
            const balance = await erc20PERPContract.balanceOf(account)
            const allowance = await erc20PERPContract.allowance(account, vePERPContract.address)
            setBalance(bigNum2Big(balance))
            setAllowance(bigNum2Big(allowance))
        }
        init()

        // NOTE: register listener
        const handlerTransfer = (from: string, to: string, amount: BigNumber) => {
            if (account && from === account) setBalance(preBalance => preBalance?.minus(bigNum2Big(amount)))
            if (account && to === account) setBalance(preBalance => preBalance?.plus(bigNum2Big(amount)))
        }
        const filterTransferFrom = erc20PERPContractWs.filters.Transfer(account, null)
        const filterTransferTo = erc20PERPContractWs.filters.Transfer(null, account)
        erc20PERPContractWs.on<EventArgsArrayTransfer, unknown>(filterTransferFrom, handlerTransfer)
        erc20PERPContractWs.on<EventArgsArrayTransfer, unknown>(filterTransferTo, handlerTransfer)

        const handlerApproval = (_owner: string, _spender: string, value: BigNumber) => {
            setAllowance(bigNum2Big(value))
        }
        const filterApproval = erc20PERPContractWs.filters.Approval(account, vePERPContract.address)
        erc20PERPContractWs.on<EventArgsArrayApproval, unknown>(filterApproval, handlerApproval)

        return () => {
            erc20PERPContractWs.removeListener<EventArgsArrayTransfer, unknown>(filterTransferFrom, handlerTransfer)
            erc20PERPContractWs.removeListener<EventArgsArrayTransfer, unknown>(filterTransferTo, handlerTransfer)
            erc20PERPContractWs.removeListener<EventArgsArrayApproval, unknown>(filterApproval, handlerApproval)
        }
    }, [account, erc20PERPContract, erc20PERPContractWs, vePERPContract.address])

    // NOTE: actions > approve
    const approve = useTxCallback(
        () => erc20PERPContract.approve(vePERPContract.address, constants.MaxUint256),
        [erc20PERPContract, vePERPContract.address],
    )

    return {
        allowance,
        balance,
        approve,
    }
}

export { Erc20PERPContainer }
