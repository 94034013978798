import Big from "big.js"

/**
 * @param num Big
 * @returns ex: "1,234.56" or "< 0.01"
 */
export function formatReadableNumber(num: Big) {
    return num.lt(0.01) ? "< 0.01" : formatNumber(num)
}

/**
 * @param num Big
 * @param option Intl.NumberFormat options
 * @returns ex: "1,234.56"
 */
export function formatNumber(num: Big, option: Intl.NumberFormatOptions = { maximumFractionDigits: 2 }) {
    const roundedNum = num.round(option.maximumFractionDigits, Big.roundDown)
    return new Intl.NumberFormat(undefined, option).format(roundedNum.toNumber())
}

/**
 * @param timestamp number
 * @returns ex: "Jul 7, 2022"
 */
export function formatDate(timestamp: number) {
    const options = { year: "numeric", month: "short", day: "numeric" } as Intl.DateTimeFormatOptions
    return new Intl.DateTimeFormat("en-US", options).format(new Date(timestamp))
}

/**
 * @param str string
 * @param options ellipsis options = { minimumLength: 11, frontLength: 6, endLength: 4 }
 * @returns ex: ""
 */
export function formatToEllipsisStr(
    str: string,
    options: { frontLength?: number; endLength?: number; minimumLength?: number } = {},
) {
    const { minimumLength = 11, frontLength = 6, endLength = 4 } = options
    if (str.length > minimumLength) {
        return str.substring(0, frontLength) + "..." + str.substring(str.length - endLength, str.length)
    }
    return str
}

/**
 * @param obj Object
 * @param obj.input string
 * @param obj.precision [0] number
 * @returns
 */
export function formatPrecisionNumber({ input, precision = 0 }: { input: string | number; precision?: number }) {
    let value = `${input}`
    value = formatRemoveNonNumChar(value)
    value = formatRemoveLeadingZero(value)
    value = formatRemoveMultiDots(value)

    // NOTE: if digits = 2, then convert "1.005" -> "1.00"
    if (precision > 0 && value.includes(".") && value.length > value.indexOf(".") + (precision + 1)) {
        value = value.substring(0, value.indexOf(".") + (precision + 1))
    }

    return value
}

/**
 * @param str string
 * @returns
 */
export function formatRemoveNonNumChar(str: string) {
    return str.replace(/[^0-9.]/g, "")
}

/**
 * @param str string
 * @returns
 */
export function formatRemoveLeadingZero(str: string) {
    return str.replace(/^0+(?=\d)/, "")
}

/**
 * @param str string
 * @returns ex: "1.00...5" -> "1.005"
 */
export function formatRemoveMultiDots(str: string) {
    return str.replace(/^([^.]*\.)(.*)$/, function (a, b, c) {
        return b + c.replace(/\./g, "")
    })
}
