import { Box, Flex, Skeleton, Text } from "@chakra-ui/react"
import Big from "big.js"
import { USDCIcon } from "components/Icons/Tokens/USDCIcon"
import { NUMBER_PLACE_HOLDER } from "constants/strings"
import React from "react"
import { formatNumber } from "utils"

interface IRewardCardTotalProps {
    data: {
        totalUsdcRewards?: Big
    }
}

export function RewardCardTotal({ data: { totalUsdcRewards } }: IRewardCardTotalProps) {
    return (
        <Box flexGrow={1} bg="perp.background.card" borderRadius="12px" p="16px" alignContent="center">
            <Flex alignItems={"center"}>
                <Text flex={1} fontSize="14px" color="perp.text.secondary">
                    Total USDC Rewards
                </Text>
                <Flex alignItems="center" gap="4px">
                    <USDCIcon boxSize="14px" />
                    <Skeleton isLoaded={!!totalUsdcRewards}>
                        <Text fontSize="14px" color="perp.text.primary">
                            {(totalUsdcRewards && formatNumber(totalUsdcRewards)) || NUMBER_PLACE_HOLDER}
                        </Text>
                    </Skeleton>
                </Flex>
            </Flex>
        </Box>
    )
}
