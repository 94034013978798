import { TransactionReceipt } from "@ethersproject/providers"
import { QueryCacheLifecycleApi } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import { createApi } from "@reduxjs/toolkit/query/react"
import Big from "big.js"
import { perpClient } from "contracts/ERC20Client"
import { BigNumber } from "ethers"
import { bugsnagClient } from "services/BugsnagClient"
import { bigNum2Big, createMutation } from "utils"

import { POLLING_PERIOD } from "../../constants/config"
import { vePerpClient } from "../../contracts/VePerpClient"

// NOTE: ResultType
interface IVePerpInfo {
    totalLockedPerpAmount: Big
    totalUnweightedVePerpAmount: Big
    emergencyUnlockActive: boolean
}

interface IVePerpInfoAt {
    totalLockedPerpAmount: Big
    totalUnweightedVePerpAmount: Big
    balancePERP: Big
}

interface IVePerpUserData {
    lockedAmount: Big
    vePerpTokenAmount: Big
    vePerpTokenAmountUnweighted: Big
    lockedEndTimestamp: number | undefined
}

interface IVePerpUserDataAt {
    lockedAmount: Big
    vePerpTokenAmountUnweighted: Big
}

// NOTE: QueryArg
interface IVePerpUserDataArg {
    account: string
}

interface IVePerpUserDataAtArg {
    account: string
    timestamp: number
}

// NOTE: Query api
type IQueryApi = QueryCacheLifecycleApi<IVePerpUserDataArg, any, IVePerpUserData, "vePerpQuery">

// NOTE: Contract event args
type DepositEventArgsArray = [string, BigNumber, BigNumber, BigNumber, BigNumber]
type WithdrawEventArgsArray = [string, BigNumber, BigNumber]

// NOTE: RTK Api
export const vePerpQueryApi = createApi({
    reducerPath: "vePerpQuery",
    baseQuery: () => ({ data: undefined }),
    endpoints: builder => ({
        vePerpInfo: builder.query<IVePerpInfo, void>({
            queryFn: vePerpInfoQuery,
        }),
        vePerpUserData: builder.query<IVePerpUserData, IVePerpUserDataArg>({
            queryFn: vePerpUserDataQuery,
            onCacheEntryAdded: vePerpUserDataCache,
        }),
        vePerpInfoAt: builder.query<IVePerpInfoAt, number>({
            queryFn: vePerpInfoAtQuery,
        }),
        vePerpUserDataAt: builder.query<IVePerpUserDataAt, IVePerpUserDataAtArg>({
            queryFn: vePerpUserDataAtQuery,
        }),
    }),
})

export const vePerpMutationApi = createApi({
    reducerPath: "vePerpMutation",
    baseQuery: () => ({ data: undefined }),
    endpoints: builder => ({
        vePerpCreateLock: builder.mutation<TransactionReceipt, [Big, Big]>({
            queryFn: createMutation((amount: Big, unlockTime: Big) => vePerpClient.getCreateLockTx(amount, unlockTime)),
        }),
        vePerpIncreaseAmount: builder.mutation<TransactionReceipt, [Big]>({
            queryFn: createMutation((amount: Big) => vePerpClient.getIncreaseAmountTx(amount)),
        }),
        vePerpIncreaseTime: builder.mutation<TransactionReceipt, [Big]>({
            queryFn: createMutation((unlockTime: Big) => vePerpClient.getIncreaseUnlockTimeTx(unlockTime)),
        }),
        vePerpRedeem: builder.mutation<TransactionReceipt, void>({
            // @ts-ignore TODO: fix createMutation args typing issue
            queryFn: createMutation(() => vePerpClient.getRedeemTx()),
        }),
    }),
})

export const { useVePerpInfoQuery, useVePerpUserDataQuery, useVePerpInfoAtQuery, useVePerpUserDataAtQuery } =
    vePerpQueryApi
export const {
    useVePerpCreateLockMutation,
    useVePerpIncreaseAmountMutation,
    useVePerpIncreaseTimeMutation,
    useVePerpRedeemMutation,
} = vePerpMutationApi

async function vePerpInfoQuery() {
    try {
        const data = await fetchVePerpInfo()
        return { data }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}

async function vePerpInfoAtQuery(t: number) {
    try {
        const data = await fetchVePerpInfoAt(t)
        return { data }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}

async function vePerpUserDataQuery(args: IVePerpUserDataArg) {
    try {
        const { account } = args
        const data = await fetchVePerpUserData(account)
        return { data }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}

async function vePerpUserDataAtQuery(args: IVePerpUserDataAtArg) {
    try {
        const { account, timestamp } = args
        const data = await fetchVePerpUserDataAt(account, timestamp)
        return { data }
    } catch (error) {
        bugsnagClient.sendError(error as Error)
        return { error }
    }
}

async function vePerpUserDataCache(args: IVePerpUserDataArg, api: IQueryApi) {
    try {
        const { account } = args
        const { cacheDataLoaded, cacheEntryRemoved } = api
        await cacheDataLoaded
        const handlerDepositEvent = getHandlerDepositEvent(api)
        const handlerWithdrawEvent = getHandlerWithdrawEvent(api)
        const handlerUserBalance = getHandlerUserBalance(api, args)
        const timer = setInterval(handlerUserBalance, POLLING_PERIOD.SHORT)
        const filterDeposit = vePerpClient.getContract().filters.Deposit(account)
        const filterWithdraw = vePerpClient.getContract().filters.Withdraw(account)
        vePerpClient.getContract().on<DepositEventArgsArray, unknown>(filterDeposit, handlerDepositEvent)
        vePerpClient.getContract().on<WithdrawEventArgsArray, unknown>(filterWithdraw, handlerWithdrawEvent)
        await cacheEntryRemoved
        vePerpClient.getContract().removeListener<DepositEventArgsArray, unknown>(filterDeposit, handlerDepositEvent)
        vePerpClient.getContract().removeListener<WithdrawEventArgsArray, unknown>(filterWithdraw, handlerWithdrawEvent)
        clearInterval(timer)
    } catch (error) {
        bugsnagClient.sendError(error as Error)
    }
}

async function fetchVePerpInfo() {
    const [totalLockedPerpAmount, totalUnweightedVePerpAmount, emergencyUnlockActive] = await Promise.all([
        vePerpClient.getTotalLockedPerpAmount(),
        vePerpClient.getTotalUnweightedVePerpAmount(),
        vePerpClient.getEmergencyUnlockActive(),
    ])
    return {
        totalLockedPerpAmount,
        totalUnweightedVePerpAmount,
        emergencyUnlockActive,
    }
}

async function fetchVePerpInfoAt(t: number) {
    const [totalUnweightedVePerpAmount, totalLockedPerpAmount, balancePERP] = await Promise.all([
        vePerpClient.getTotalUnweightedVePerpAmountAt(t),
        vePerpClient.getTotalLockedPerpAmountAt(t),
        perpClient.balanceOfAt(vePerpClient.getContract().address, t),
    ])
    return {
        totalLockedPerpAmount,
        totalUnweightedVePerpAmount,
        balancePERP,
    }
}

async function fetchVePerpUserData(account: string) {
    const [locked, vePerpTokenAmount, vePerpTokenAmountUnweighted] = await Promise.all([
        vePerpClient.getLocked(account),
        vePerpClient.getBalanceOfWeighted(account),
        vePerpClient.getBalanceOf(account),
    ])
    return {
        lockedAmount: locked.amount,
        lockedEndTimestamp: locked.end.toNumber(),
        vePerpTokenAmount,
        vePerpTokenAmountUnweighted,
    }
}

async function fetchVePerpUserDataAt(account: string, timestamp: number) {
    const [vePerpTokenAmountUnweighted, locked] = await Promise.all([
        vePerpClient.getBalanceOfAt(account, timestamp),
        vePerpClient.getLockedAt(account, timestamp),
    ])
    return {
        lockedAmount: locked.amount,
        vePerpTokenAmountUnweighted,
    }
}

function getHandlerDepositEvent(api: IQueryApi) {
    return (...args: DepositEventArgsArray) => {
        const { updateCachedData } = api
        const [_provider, value, lockedTime, type, _ts] = args
        const typeIndex = type.toNumber()
        const lockedAmount = bigNum2Big(value)
        const lockedEndTimestamp = bigNum2Big(lockedTime, 0).toNumber()

        // NOTE: deposit by other user will not trigger this handler
        if (typeIndex !== 3) {
            updateCachedData((state: IVePerpUserData) => {
                state.lockedAmount = state.lockedAmount.add(lockedAmount)
            })
        }
        if (typeIndex !== 2) {
            updateCachedData((state: IVePerpUserData) => {
                state.lockedEndTimestamp = lockedEndTimestamp
            })
        }
    }
}

function getHandlerWithdrawEvent(api: IQueryApi) {
    return (..._args: WithdrawEventArgsArray) => {
        const { updateCachedData } = api
        updateCachedData((state: IVePerpUserData) => {
            state.lockedAmount = Big(0)
            state.lockedEndTimestamp = undefined
            state.vePerpTokenAmount = Big(0)
            state.vePerpTokenAmountUnweighted = Big(0)
        })
    }
}

function getHandlerUserBalance(api: IQueryApi, args: IVePerpUserDataArg) {
    return async () => {
        const { account } = args
        const { updateCachedData } = api
        const [vePerpTokenAmount, vePerpTokenAmountUnweighted] = await Promise.all([
            vePerpClient.getBalanceOfWeighted(account),
            vePerpClient.getBalanceOf(account),
        ])
        updateCachedData((state: IVePerpUserData) => {
            state.vePerpTokenAmount = vePerpTokenAmount
            state.vePerpTokenAmountUnweighted = vePerpTokenAmountUnweighted
        })
    }
}
