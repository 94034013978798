import { useQuery } from "graphql-hooks"

/**
 * Fetch program detail from CMS
 */
export function useProgramDetail(slug: String) {
    const PROGRAMS_QUERY = `
    query Program($slug: String = "referral") {
      program(where: {slug: $slug}) {
        slug
        title
        id
        banner {
          sm{
            url
          }
          md{
            url
          }
          lg {
            url
          }
        }
        description {
          references {
            __typename
            ... on EligibilityBlock {
              id
              eligibility {
                __typename
                ... on Eligibility {
                  id
                  title
                  description
                }
              }
            }
          }
          json
        }
      }
    }
    
        `
    const { loading, error, data } = useQuery(PROGRAMS_QUERY, {
        variables: { slug: slug },
    })
    return { data, loading, error }
}
