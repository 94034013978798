import { Box, Checkbox, Flex, Text } from "@chakra-ui/react"
import React from "react"

import { PERPIcon } from "../../../components/Icons/Tokens/PERPIcon"
import { ClaimableRewardsItem } from "../../../services/AppSyncClient"
import { formatDate, formatNumber } from "../../../utils/format"
import { Selected } from "../type"

interface SelectableItemProps {
    index: number
    data: ClaimableRewardsItem
    selected: Selected
    onChange: (index: number) => void
    isLast: boolean
    selectedLength: number
}

function SelectableItem({ data, selected, onChange, index, isLast, selectedLength }: SelectableItemProps) {
    const isChecked = !!selected[index]
    return (
        <Box
            px="24px"
            bgColor={isChecked ? "perp.background.selected" : "perp.background.background"}
            onClick={() => onChange(index)}
            _hover={{ bgColor: "perp.background.button" }}
        >
            <Flex
                justifyContent="space-between"
                h="56px"
                alignItems="center"
                borderBottom={isLast ? "0px" : "1px solid"}
                borderColor="perp.background.button"
            >
                <Checkbox
                    colorScheme="perp.primary.default"
                    as="div"
                    isChecked={isChecked}
                    isDisabled={selectedLength >= 10 && !isChecked ? true : false}
                >
                    {formatDate(new Date(data.weekEndTimeIso).getTime())}
                </Checkbox>
                <Flex>
                    <Text mr="8px" color={"perp.primary.default"}>
                        {formatNumber(data.balance)}
                    </Text>
                    <PERPIcon boxSize="24px" />
                </Flex>
            </Flex>
        </Box>
    )
}

export { SelectableItem }
