import { Button, Grid, GridItem, Text } from "@chakra-ui/react"
import Big from "big.js"
import React from "react"

import { formatNumber } from "../../../utils/format"
import { ModalClaim } from "./ModalClaim"
import { ModalClaimOp } from "./ModalClaimOp"
import { ModalCreateLock } from "./ModalCreateLock"
import { ModalExpired } from "./ModalExpired"
import { ModalExtendTime } from "./ModalExtendTime"

interface LiquidityClaimCardProps {
    icon: JSX.Element
    title: string
    amount: Big
    onClick: () => void
    isLoading: boolean
}

export function LiquidityMiningClaimCard({ icon, title, amount, onClick, isLoading }: LiquidityClaimCardProps) {
    const isDisabled = isLoading || amount.lte(0)

    return (
        <Grid templateColumns="auto 1fr auto" w="100%">
            <GridItem mr="16px">{icon}</GridItem>
            <GridItem>
                <Text fontSize="14px" color="perp.text.secondary">
                    {title}
                </Text>
                <Text fontSize="16px">{isLoading ? "0.0" : formatNumber(amount)}</Text>
            </GridItem>
            <GridItem>
                <Button onClick={onClick} disabled={isDisabled}>
                    <Text fontWeight="bold">Claim</Text>
                </Button>
            </GridItem>
            <ModalCreateLock />
            <ModalExpired />
            <ModalExtendTime />
            <ModalClaim />
            <ModalClaimOp />
        </Grid>
    )
}
