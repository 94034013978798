import { Big } from "big.js"
import { EPOCH } from "constants/config"
import { useInsuranceFundMetadataQuery } from "modules/insuranceFund"
import { useUsdcSurplusQuery } from "modules/usdcFeeDistributor"
import { useGetServerTimestampQuery } from "services/AWSClient/flow"
import { getCalibratedTime } from "utils"

export function useProtocolRevenueEstimated() {
    // current server time
    const { data } = useGetServerTimestampQuery()
    const currentTimestamp = data?.timestamp
    const weekStartTimestamp = currentTimestamp ? getCalibratedTime(currentTimestamp, EPOCH) : undefined

    // pending rewards
    const { data: insuranceFundMetadata } = useInsuranceFundMetadataQuery()
    const capacity = insuranceFundMetadata?.capacity
    const distributionThreshold = insuranceFundMetadata?.distributionThreshold
    const freeCollateralUSDC = insuranceFundMetadata?.freeCollateralUSDC
    const overDistributionThreshold = capacity && distributionThreshold && capacity.sub(distributionThreshold)

    // allocated rewards
    const { data: usdcSurplusData } = useUsdcSurplusQuery(
        { timestamp: weekStartTimestamp! },
        { skip: !weekStartTimestamp },
    )

    const insuranceFundDistributedFee = usdcSurplusData?.totalSurplus

    // if distributionThreshold not yet set, revenue is 0
    const revenue =
        insuranceFundDistributedFee && overDistributionThreshold && freeCollateralUSDC && distributionThreshold.gt(0)
            ? insuranceFundDistributedFee.plus(Math.min(+overDistributionThreshold, +freeCollateralUSDC))
            : Big(0)

    return {
        revenue,
    }
}
