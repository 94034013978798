import { Icon, IconProps } from "@chakra-ui/react"
import React from "react"

export function EditIcon({ ...props }: IconProps) {
    return (
        <Icon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.8232 5.23741C15.9209 5.13978 16.0791 5.13978 16.1768 5.23741L16.7009 4.71332L16.1768 5.23741L18.7626 7.82319C18.8602 7.92083 18.8602 8.07912 18.7626 8.17675L16 10.9393L13.0606 7.99999L15.8232 5.23741L15.2962 4.71037L15.8232 5.23741ZM12 9.06065L4.75 16.3106V19.25H7.68934L14.9393 12L12 9.06065ZM16.5263 12.5344L8.53033 20.5303C8.38968 20.671 8.19891 20.75 8 20.75H4C3.58579 20.75 3.25 20.4142 3.25 20V16C3.25 15.8011 3.32902 15.6103 3.46967 15.4696L14.7626 4.17675C15.446 3.49333 16.554 3.49333 17.2374 4.17675L19.8232 6.76253L19.2929 7.29286L19.8232 6.76254C20.5066 7.44595 20.5066 8.55399 19.8232 9.23741L16.5344 12.5263C16.533 12.5276 16.5317 12.529 16.5303 12.5303C16.529 12.5317 16.5276 12.533 16.5263 12.5344Z"
                fill="white"
                fillOpacity="0.8"
            />
        </Icon>
    )
}
