import { Modal as ChakraModal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Portal } from "@chakra-ui/react"
import { ExtendTimeData, LockerExtendTime } from "components/Locker/LockerExtendTime"
import { Header } from "components/Modal/Header"
import { EventGroup, EventName, EventTypeInteraction } from "modules/analytics"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { analyticsClient } from "services/AnalyticsClient"
import { useAppSelector } from "store"

import { homeSlice } from "../slice"

function ModalExtendTime() {
    const { isOpen, size, motion } = useAppSelector(state => state.home.modal.extendTime)

    const dispatch = useDispatch()
    const { closeModalExtendTime } = homeSlice.actions

    const handleClick = useCallback(({ lockWeeks }: ExtendTimeData) => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.BUTTON_CLICKED,
            eventName: EventName.HOME_EXTEND_TIME_BUTTON_CLICKED,
            payload: { lockWeeks },
        })
    }, [])

    const handleClose = useCallback(() => {
        analyticsClient.track({
            eventGroup: EventGroup.INTERACTION,
            eventType: EventTypeInteraction.MODAL_CLOSED,
            eventName: EventName.HOME_EXTEND_TIME_MODAL_CLOSED,
        })
        dispatch(closeModalExtendTime())
    }, [closeModalExtendTime, dispatch])

    return (
        <Portal>
            <ChakraModal motionPreset={motion} onClose={handleClose} size={size} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent
                    bg="perp.background.background"
                    color="perp.text.primary"
                    boxShadow="0px 20px 20px 0px rgba(0, 0, 0, 0.15)"
                >
                    <Header onCloseButtonClick={handleClose}>Extend Lock Time</Header>
                    <ModalBody>
                        <LockerExtendTime onClick={handleClick} onFinished={handleClose} />
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </ChakraModal>
        </Portal>
    )
}

export { ModalExtendTime }
