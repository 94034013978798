import { SEGMENT_API_KEY } from "constants/env"

import { AnalyticsTrackData, AnalyticsTrackPageData } from "./types"

export class Segment {
    readonly apiKey: string

    constructor(apiKey: string) {
        this.apiKey = apiKey
    }

    init() {
        var analytics = (window.analytics = window.analytics || [])
        if (!analytics.initialize) {
            if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice.")
            else {
                analytics.invoked = !0
                analytics.methods = [
                    "trackSubmit",
                    "trackClick",
                    "trackLink",
                    "trackForm",
                    "pageview",
                    "identify",
                    "reset",
                    "group",
                    "track",
                    "ready",
                    "alias",
                    "debug",
                    "page",
                    "once",
                    "off",
                    "on",
                    "addSourceMiddleware",
                    "addIntegrationMiddleware",
                    "setAnonymousId",
                    "addDestinationMiddleware",
                ]
                // @ts-ignore
                analytics.factory = function (e) {
                    return function () {
                        var t = Array.prototype.slice.call(arguments)
                        t.unshift(e)
                        analytics.push(t)
                        return analytics
                    }
                }
                for (var e = 0; e < analytics.methods.length; e++) {
                    var key = analytics.methods[e]
                    analytics[key] = analytics.factory(key)
                }
                // @ts-ignore
                analytics.load = function (key, e) {
                    var t = document.createElement("script")
                    t.type = "text/javascript"
                    t.async = !0
                    t.src = "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js"
                    var n = document.getElementsByTagName("script")[0]
                    // @ts-ignore
                    n.parentNode.insertBefore(t, n)
                    analytics._loadOptions = e
                }
                analytics._writeKey = this.apiKey
                analytics.SNIPPET_VERSION = "4.15.3"
                analytics.load(this.apiKey)
                analytics.page()
            }
        }
    }

    track(data: AnalyticsTrackData) {
        const { eventGroup, ...rest } = data
        analytics.track(eventGroup, { ...rest })
    }

    trackPage(data: AnalyticsTrackPageData) {
        analytics.page({ ...data })
    }
}

export const segment = new Segment(SEGMENT_API_KEY)
