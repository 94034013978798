import { Heading, Spacer } from "@chakra-ui/react"
import { PERPIcon } from "components/Icons/Tokens/PERPIcon"
import React, { useState } from "react"

import { useVePERPEmissionFeeDistributorClaim } from "modules/vePERPEmissionFeeDistributor"
import { LazyRiverClaimCard } from "./LazyRiverClaimCard"
import { MotionBox, MotionHStack, MotionStack, MotionVStack } from "components/Motion"
import { ChevronDownIcon } from "@chakra-ui/icons"
import { USDCIcon } from "components/Icons/Tokens/USDCIcon"
import { LockedPERPIcon } from "components/Icons/Tokens/LockedPERPIcon"
import { LockedPERPAlert } from "./LazyRiverControllerPanel"
import { useUsdcFeeDistributorClaim } from "modules/usdcFeeDistributor"
import { useVePERPDistributorClaim } from "modules/vePerpDistributor"

export function LazyRiverMobileMenu() {
    const {
        data: vePERPEmissionData,
        isLoading: vePERPEmissionIsLoading,
        handleClaim: vePERPEmissionHandleClaim,
    } = useVePERPEmissionFeeDistributorClaim()
    const {
        data: usdcFeeDistributorData,
        isLoading: usdcFeeDistributorIsLoading,
        handleClaim: usdcFeeDistributorHandleClaim,
    } = useUsdcFeeDistributorClaim()
    const {
        data: vePERPDistributorData,
        isLoading: vePERPDistributorIsLoading,
        handleClaim: vePERPDistributorHandleClaim,
        error: vePERPDistributorError,
    } = useVePERPDistributorClaim()
    const [isExpanded, setIsExpanded] = useState(false)

    return (
        <MotionVStack
            layout
            spacing={5}
            position="fixed"
            left="0"
            bottom="0"
            width="100%"
            background="perp.background.background"
            height="auto"
            color="perp.text.primary"
            borderTop={"1px solid"}
            borderColor="perp.background.border"
            borderRadius={"16px"}
            display="flex"
            justifyContent="center"
            alignItems="center"
            p="16px"
            zIndex={1}
            cursor={!isExpanded ? "pointer" : "auto"}
            onClick={() => !isExpanded && setIsExpanded(true)}
            // click to expand only. disable once expanded to prevent claim events
        >
            <MotionHStack
                layout
                w="100%"
                cursor={"pointer"}
                onClick={() => isExpanded && setIsExpanded(false)}
                // click to collapse only.
            >
                <Heading variant={isExpanded ? "h4" : "sub1"}>Your Rewards</Heading>
                <Spacer />
                <MotionBox animate={{ rotate: isExpanded ? 0 : 180 }}>
                    <ChevronDownIcon boxSize={6} />
                </MotionBox>
            </MotionHStack>

            <MotionStack layout spacing={6} width="100%" direction={isExpanded ? "column" : "row"}>
                <MotionBox layout>
                    <LazyRiverClaimCard
                        isInline={!isExpanded}
                        icon={<USDCIcon boxSize={!isExpanded ? "20px" : "48px"} />}
                        title="USDC"
                        amount={usdcFeeDistributorData?.amount}
                        onClick={usdcFeeDistributorHandleClaim}
                        isLoading={usdcFeeDistributorIsLoading}
                    />
                </MotionBox>
                <MotionBox layout>
                    <LazyRiverClaimCard
                        isInline={!isExpanded}
                        icon={<LockedPERPIcon boxSize={!isExpanded ? "20px" : "48px"} />}
                        title="Locked PERP"
                        amount={vePERPDistributorData?.amount}
                        onClick={vePERPDistributorHandleClaim}
                        isLoading={vePERPDistributorIsLoading}
                    />
                    {isExpanded && <LockedPERPAlert error={vePERPDistributorError as Error} />}
                </MotionBox>
                <MotionBox layout>
                    <LazyRiverClaimCard
                        isInline={!isExpanded}
                        icon={<PERPIcon boxSize={!isExpanded ? "20px" : "48px"} />}
                        title="PERP (Lazy River 1.0)"
                        amount={vePERPEmissionData?.amount}
                        onClick={vePERPEmissionHandleClaim}
                        isLoading={vePERPEmissionIsLoading}
                    />
                </MotionBox>
            </MotionStack>
        </MotionVStack>
    )
}
