import {
    Button,
    Grid,
    Modal as ChakraModal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Portal,
} from "@chakra-ui/react"
import { Header } from "components/Modal/Header"
import { PerpWalletConnectorContainer } from "hooks/containers/usePerpWalletConnectorContainer"
import { Claim, SelectableItem, SelectAll, useSelectable } from "modules/claim"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { AppDispatch, useAppSelector } from "store"
import { formatNumber } from "utils/format"

import { useLMClaimWeeksOpMutation } from "../flow"
import { liquidityMiningSlice } from "../slice"

/**
 * NOTE: This component will open when the user is trying to claim rewards.
 */

export function ModalClaimOp() {
    const [claimWeeksOp] = useLMClaimWeeksOpMutation()
    const { isOpen, size, motion, items } = useAppSelector(state => state.liquidityMining.modal.claimOp)

    const { account } = PerpWalletConnectorContainer.useContainer()
    const { isTxLoading } = useAppSelector(state => state.wallet)

    const { selected, onSelectChange, onSelectAll, isSelectedAll, selectedAmount, selectedLength } =
        useSelectable(items)

    const dispatch = useDispatch<AppDispatch>()
    const { closeModalClaimOp } = liquidityMiningSlice.actions

    const handleClose = useCallback(() => {
        dispatch(closeModalClaimOp())
    }, [closeModalClaimOp, dispatch])

    const handleClaim = useCallback(async () => {
        if (!account) return
        await claimWeeksOp([account, Claim.getClaimsBySelected(selected)])
        handleClose()
    }, [account, claimWeeksOp, handleClose, selected])

    return (
        <Portal>
            <ChakraModal
                motionPreset={motion}
                onClose={handleClose}
                size={size}
                isOpen={isOpen}
                isCentered
                scrollBehavior="inside"
            >
                <ModalOverlay />
                <ModalContent
                    bg="perp.background.background"
                    color="perp.text.primary"
                    boxShadow="0px 20px 20px 0px rgba(0, 0, 0, 0.15)"
                >
                    <Header onCloseButtonClick={handleClose}>Claim Rewards</Header>
                    <ModalBody borderBottom="1px solid" borderColor="perp.background.border" p={0}>
                        {items?.map((item, index) => (
                            <SelectableItem
                                key={index}
                                index={index}
                                data={item}
                                selected={selected}
                                onChange={onSelectChange}
                                isLast={index === items.length - 1}
                                selectedLength={selectedLength}
                            />
                        ))}
                    </ModalBody>
                    <ModalFooter>
                        <Grid w="100%" gap="16px">
                            <SelectAll isChecked={isSelectedAll} onChange={onSelectAll} />
                            <Button
                                variant="green"
                                onClick={handleClaim}
                                isLoading={isTxLoading}
                                disabled={isTxLoading || (selectedAmount.toNumber() ? false : true)}
                                loadingText="Waiting for transaction"
                            >
                                Claim {formatNumber(selectedAmount)} OP
                            </Button>
                        </Grid>
                    </ModalFooter>
                </ModalContent>
            </ChakraModal>
        </Portal>
    )
}
